import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import Select from "react-select";
import TableGroupHealth from "../../components/department/pageGroupHealth/TableGroupHealth";
import TableAllInDOY from "../../components/department/pageGroupHealth/TableAllInDOY";
import TableAllInDOYAPPG from "../../components/department/pageGroupHealth/TableAllInDOYAPPG";
import GraphAllGroupHealth from "../../components/department/pageGroupHealth/GraphAllGroupHealth";
import GraphDOYGroupHEalth from "../../components/department/pageGroupHealth/GraphDOYGroupHEalth";
import GraphDOYAPPGGroupHealth from "../../components/department/pageGroupHealth/GraphDOYAPPGGroupHealth";
import Widget from "../../components/department/pageGroupHealth/Widget";

const GroupHealth = () => {
    const [years, setYears] = useState<any[]>([{value: new Date().getFullYear(), label: new Date().getFullYear()}]);

    const customStyles = {
        control: (ds: any) => ({
            ...ds,
            backgroundColor: "#fff",
        }),
        option: (ds: any, state: any) => ({
            ...ds,
            backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
            color: state.isFocused ? "#fff" : "#000"
        })
    }

    function yearsLab(data: number) {
        let currentYear = new Date().getFullYear(), years: any[] = [];
        data = 1980;
        while ( data <= currentYear ) {
            const d = data++
            years.push({value: d, label: d});
        }
        return years;
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Группы здоровья</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <Select options={yearsLab(new Date().getFullYear())}
                                        value={years}
                                        onChange={(newValue) => {
                                            setYears(newValue as any[])
                                        }}
                                        closeMenuOnSelect={false}
                                        name="stock"
                                        styles={customStyles}
                                        placeholder="Поиск"
                                        maxMenuHeight={500}
                                        minMenuHeight={200}
                                        isMulti
                                        className="mb-2"/>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Widget/>
                            </Row>
                            <Row>
                                <Col xxl={4} xl={6}>
                                    <GraphAllGroupHealth/>
                                </Col>
                                <Col xxl={4} xl={6}>
                                    <GraphDOYGroupHEalth/>
                                </Col>
                                <Col xxl={4}>
                                    <GraphDOYAPPGGroupHealth/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <TableGroupHealth years={years}/>
                            <TableAllInDOY/>
                            <TableAllInDOYAPPG/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default GroupHealth;

import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getCategoriesOvz} from "../../store/reducers/department/ActionCreator";
import {Alert, Col, Container, Row} from "reactstrap";
import SelectToUpdateCategoryOvz from "../../components/department/categoryOvz/SelectToUpdateCategoryOvz";
import CategoryOvzAdd from "../../components/department/categoryOvz/CategoryOvzAdd";
import CategoryOvzUpdate from "../../components/department/categoryOvz/CategoryOvzUpdate";

const SettingsCategoryOvz = () => {
    const dispatch = useAppDispatch();

    const {isError, categoriesOvz} = useAppSelector(state => state.DepartmentReducer)

    const [addCategoryOvz, setAddCategoryOvz] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getCategoriesOvz())
    }, [dispatch]);


    return (
        <div className="page-content">
            {isError && <Alert className="alert-danger mb-4">{isError}</Alert>}
            <Container fluid>
                <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                    <SelectToUpdateCategoryOvz
                        setAddCategoryOvz={(val) => setAddCategoryOvz(val)}
                        categoriesOvz={categoriesOvz}/>
                    <div className="w-100 overflow-hidden">
                        <div className="d-lg-flex">
                            <div className="w-100">
                                <div>
                                    <div className="mt-1 mt-sm-1 mt-lg-0 mt-xl-0 mt-xxl-0" id="users-chat">
                                        <Row >
                                            <Col>
                                                {
                                                    addCategoryOvz ? <CategoryOvzAdd /> : <CategoryOvzUpdate/>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SettingsCategoryOvz;

import React, {FC} from 'react';
import {Col, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import AxiosClient from "../../api/AxiosClient";
import {useNavigate} from "react-router-dom";

interface IProps {
    openGame: boolean,
    setOpenGame: (value: boolean) => void
}
const LoginModalGame: FC<IProps> = ({setOpenGame, openGame}) => {
    const navigate = useNavigate();
    return (
        <Modal id="createProjectModal" isOpen={openGame} toggle={() => setOpenGame(!openGame)}
               modalClassName="zoomIn" tabIndex={-1} centered size="sm">
            <ModalHeader toggle={() => setOpenGame(!openGame)} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Вход к игре
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        'password': ''
                    }}
                    validationSchema={
                        Yup.object({
                            password: Yup.string().required('Введите пароль'),
                        })
                    }
                    onSubmit={({password}, {setFieldError}) => {
                            AxiosClient.post('/game/get_access_game', {password})
                            .then((r) => {
                                navigate(`/game/play/private/${r.data.id}`)
                            })
                            .catch(() => {
                                setFieldError('password', 'Не верный пароль доступа')
                            })
                    }}
                >
                    <Form>
                        <Row>
                            <Col>
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-1">
                                            <Label htmlFor="pas" className="form-label">Пароль</Label>
                                            <Field className="form-control" id="pas" name="password"/>
                                            <ErrorMessage name="password">{(msg) => (<p className="text-danger">{msg}</p>)}</ErrorMessage>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <button type="submit" className="btn btn-primary mb-3" id="gameOpen" style={{'width': '100%'}}>Войти</button>
                    </Form>
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default LoginModalGame;

import {AxiosResponse} from "axios";
import $api from "../../AxiosClient";
import {IEmployee} from "../../../models/IEmployee";

class EmployeeService {
    static async getEmployees(): Promise<AxiosResponse<IEmployee[]>>{
        return $api.post<IEmployee[]>("/employees");
    }

    static async addEmployee(value: object): Promise<AxiosResponse<void>>{
        return $api.post<void>("/employee/add", value);
    }

    static async updateEmployee(value: object, id: number): Promise<AxiosResponse<void>>{
        return $api.post<void>(`/employee/update/${id}`, value);
    }
}

export default EmployeeService;
import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router-dom";
import AxiosClient from "../../api/AxiosClient";
import bg from '../../assetsGame/icons/bgStartEnd.jpg'
import btn from '../../assetsGame/b_3.png'
import {Col, Row, Spinner} from "reactstrap";
import {IShot} from "../../models/game/IShot";
import {IGame} from "../../models/game/IGame";
import Shot from "./play/Shot";
import {useStateContext} from "../../hooks/useStateContext";
import './style.scss'
import cn from "classnames";
import Seconder from "./timer/Seconder";
import moment from "moment";
const OpenGame = () => {
    const {id} = useParams()
    const {user} = useStateContext();
    const [start, setStart] = useState(true);
    const [isFullScreen, setIsFullScreen] = useState(true);
    const [end, setEnd] = useState(false);
    const [game, setGame] = useState<IGame | null>(null);
    const [shots, setShots] = useState<IShot[]>([]);
    const [shotIndex, setShotIndex] = useState(-1);
    const [nextSelectShot, setNextSelectShot] = useState<IShot | null>(null);
    const [selectShot, setSelectShot] = useState<IShot | null>(null);
    const [rotate, setRotate] = useState(false);
    const [loadingGame, setLoadingGame] = useState(true);
    const [result, setResult] = useState<{all: number, true: number, error: number, shots: any[], time: string, typeTime: string}>({
        all: 0,
        true: 0,
        error: 0,
        shots: [],
        time: '00:00:00',
        typeTime: ''
    });
    const [seconds, setSeconds] = useState(0);
    const [timerStart, setTimerStart] = useState(false);

    useEffect(() => {
        id &&
        AxiosClient.get<IGame>(`/game/game/${id}`)
            .then((r) => {
                setGame(r.data)
                setShots(r.data.shots)
                setLoadingGame(false)
                let typeTime = ''
                if (r.data.timer) {
                    if (r.data.closeTimer) {
                        typeTime = 'timer'
                        setSeconds(r.data.time ? moment.duration(r.data.time).asSeconds() : 0)
                    } else {
                        typeTime = 'seconder';
                    }
                }
                setResult((prevState) => ({
                    ...prevState,
                    all: r.data.shots.filter((el) => {
                        const s = JSON.parse(el.settingsAutoPlay as any)
                        if (s && s !== 'null')
                            return !s.play
                        return true
                    }).length,
                    typeTime: typeTime
                }))
            })
            .catch(() => setLoadingGame(false))
    }, [id]);

    useEffect(() => {
        const checkOrientation = () => {
            if (window.screen.orientation.type.startsWith("portrait")) {
                setRotate(false)
                console.warn("Пожалуйста, переверните устройство в альбомную ориентацию для лучшего игрового опыта.");
            } else {
                setRotate(true)
                console.log("Устройство находится в альбомной ориентации.");
            }
            if (document.body.requestFullscreen) {
                document.body.requestFullscreen().catch(err => {
                    console.error("Не удалось перейти в полноэкранный режим:", err);
                });
            } else {
                console.warn("Ваш браузер не поддерживает полноэкранный режим.");
            }
        };

        // Первоначальная проверка ориентации при загрузке
        checkOrientation();

        // Проверка ориентации при её изменении
        window.addEventListener("orientationchange", checkOrientation);
        // Удаление обработчика события при размонтировании компонента
        return () => {
            window.removeEventListener("orientationchange", checkOrientation);
        };
    }, []);


    const nextShot = (value?: {id: number, time: number}) => {
        if (shotIndex === shots.length - 1){
            setSelectShot(null)
            setEnd(true)
            setTimerStart(false)
            if (value) {
                setResult((result) => (
                    {
                        ...result,
                        shots: [...result.shots, value],
                        time: moment.utc(moment.duration(seconds, "seconds").asMilliseconds()).format("HH:mm:ss")
                    }
                ))
            }
        } else {
            if (value) {
                setResult((result) => (
                    {
                        ...result,
                        shots: [...result.shots, value]
                    }
                ))
            }
            setShotIndex(prevState => prevState + 1)
            setSelectShot(shots[shotIndex + 1])
            setNextSelectShot(shots[shotIndex + 2] ?? null)
        }
    }

    const onChangeTrue = () => {
        setResult((prevState) => ({
            ...prevState,
            true: prevState.true + 1
        }))
    }

    const onChangeError = () => {
        setResult((prevState) => ({
            ...prevState,
            error: prevState.error + 1
        }))
    }

    const showSeconder = () => {
        if(game && !start && !end) {
            if (game.timer) {
                return <div className="bg-white bg-opacity-75">
                    <Seconder game={game} setSeconds={setSeconds} seconds={seconds} timerStart={timerStart} setTimerStart={setTimerStart} type={result.typeTime as any}/>
                </div>
            }
        }
    }

    const back = () => {
        if (!game) return bg;
        if (!game.file) return bg;
        return process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL + game.file : bg
    }

    return (
        <>
            <div className={cn({normal: !isFullScreen, fullScreen: isFullScreen})} id="window">
                {
                    loadingGame && <div
                        className="d-flex justify-content-center align-items-center bg-dark bg-opacity-75 position-absolute top-0 start-0 w-100 h-100"
                        style={{zIndex: 9999}}>
                        <div className="text-center">
                            <div className="text-light fs-3 mb-3">Загрузка игры</div>
                            <Spinner className="text-light" style={{width: '3rem', height: '3rem'}}/>
                        </div>
                    </div>
                }
                {
                    !rotate && !loadingGame && <div
                        className="d-flex justify-content-center align-items-center bg-dark bg-opacity-75 position-absolute top-0 start-0 w-100 h-100"
                        style={{zIndex: 9999}}>
                        <div className="text-center">
                            <div className="text-light fs-3 mb-3">Переверните телефон</div>
                        </div>
                    </div>
                }
                <div className="position-absolute start-0 m-2" style={{zIndex: 10}}>
                    {
                        showSeconder()
                    }
                </div>
                {
                    start &&
                    <div className="h-100 w-100">
                        <div className="position-absolute end-0 m-2 cursor-pointer" id="openFull" style={{zIndex: 10}} onClick={() => {
                            setIsFullScreen((prevState) => {
                                if (!prevState) {
                                    document.body.requestFullscreen()
                                    return !prevState
                                }
                                document.fullscreenElement &&
                                    document.exitFullscreen()
                                return !prevState
                            })
                        }}>
                            <i className="bx bx-fullscreen fs-1 text-black fw-bold"></i>
                        </div>
                        <div className="h-100 w-100 d-flex justify-content-center align-items-center position-relative"
                             style={{backgroundImage: `url(${back()})`, backgroundSize: '100% 100%', backgroundPosition: 'center'}}>
                            <Col xs={7} sm={3} className="position-relative cursor-pointer" onClick={() => {
                                setStart(false)
                                nextShot()
                                setTimerStart(true)
                            }}>
                                <img src={btn} className="w-100" style={{position: 'relative'}} />
                                <div className="position-absolute top-50 start-50 translate-middle text-center">
                                    <h1 className="m-0">Начать</h1>
                                </div>
                            </Col>
                        </div>
                    </div>
                }
                {
                    selectShot && game &&
                    <Shot nextSelectShot={nextSelectShot} height={game?.height} width={game.width} shot={selectShot} nextShot={nextShot} onChangeError={onChangeError} onChangeTrue={onChangeTrue}/>
                }
                {
                    end &&
                    <div className="h-100 w-100 gap-5 justify-content-center align-items-end position-relative"
                         style={{backgroundImage: `url(${back()})`, backgroundSize: '100% 100%', backgroundPosition: 'center'}}>
                        <div className="position-absolute end-0 m-2 cursor-pointer" style={{zIndex: 10}} onClick={() => {
                            setIsFullScreen((prevState) => {
                                if (!prevState) {
                                    document.body.requestFullscreen()
                                    return !prevState
                                }
                                document.fullscreenElement &&
                                    document.exitFullscreen()
                                return !prevState
                            })
                        }}>
                            <i className="bx bx-fullscreen fs-1 text-black fw-bold"></i>
                        </div>
                        <Row className="mx-3 justify-content-end">
                            <Col>
                                <div className="mt-2 fs-2">
                                    <h3 className="text-center">Результаты</h3>
                                    <div>
                                        <h4>Всего вопросов: {result.all}</h4>
                                    </div>
                                    <div>
                                        <h4>Всего правильных ответов: {result.true}</h4>
                                    </div>
                                    <div>
                                        <h4>Всего ошибок: {result.error}</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={4} xl={3} xxl={3}>
                                <Row className="justify-content-center">
                                    <Col xs={8} sm={6} lg={12} className="position-relative cursor-pointer my-2">
                                        <img src={btn} className="w-100" style={{position: 'relative'}} />
                                        <div className="position-absolute top-50 start-50 translate-middle text-center">
                                            <h1 className="m-0" onClick={() => {
                                                setSelectShot(null)
                                                setShotIndex(-1)
                                                setEnd(false)
                                                setStart(true)
                                                setResult((prevState) => ({
                                                    ...prevState,
                                                    true: 0,
                                                    error: 0
                                                }))
                                            }}>Играть заново</h1>
                                        </div>
                                    </Col>
                                    <Col xs={8} sm={6} lg={12} className="position-relative cursor-pointer my-2">
                                        <img src={btn} className="w-100" style={{position: 'relative'}} />
                                        <div className="position-absolute top-50 start-50 translate-middle text-center" onClick={() => {
                                            setIsFullScreen(() => {
                                                document.fullscreenElement &&
                                                document.exitFullscreen()
                                                return false
                                            })
                                            user.term.id === 2 ? window.location.href = '/games/teacher' : window.location.href = '/games'
                                        }}>
                                            <h1 className="m-0">Все игры</h1>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        </>
    );
};

export default OpenGame;

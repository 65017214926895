import React, {FC, useEffect, useState} from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import DataTable from "react-data-table-component";
import AxiosClient from "../../../api/AxiosClient";

interface IChoiceAddTypePortfolioPage {
    portfolio: any,
    setPortfolio(value: any): any,
    selectThemePortfolio: any,
    typePortfolio: any,
    setFormAddPage(value: any): any,
    setOpenForm(value: boolean): any
}
const ChoiceAddTypePortfolioPage: FC <IChoiceAddTypePortfolioPage> = ({portfolio, setPortfolio, typePortfolio, selectThemePortfolio, setFormAddPage, setOpenForm}) => {
    const [sortTypePortfolio, setSortTypePortfolio] = useState<any>();
    const [selectTypePortfolio, setSelectTypePortfolio] = useState<string>('');

    useEffect(() => {
        const sortedTypePortfolio = typePortfolio?.filter((value: any) => value.theme_page_portfolio_id === selectThemePortfolio.value);
        setSortTypePortfolio(sortedTypePortfolio);
        if (sortedTypePortfolio?.length > 0)  setSelectTypePortfolio(sortedTypePortfolio[0].id);
    }, [selectThemePortfolio])

    return (
        <div>
            <Card className="team-box">
                <CardBody className="p-4">
                    <Row>
                        {sortTypePortfolio?.map((option: any, index: any) => (
                            <Col  xxl={3} xl={3} lg={3} sm={6}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        value={option.id}
                                        checked={selectTypePortfolio == option.id}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {setSelectTypePortfolio(e.target.value)}}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        {option.name}
                                    </label>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <Row className='mt-4'>
                        <Col xxl={3} xl={3} lg={3} sm={6}>
                            <button className="btn btn-primary w-100 mt-1"
                                    onClick={() => {
                                        AxiosClient.get('create/page/parent/portfolio', {params: {id_portfolio: portfolio?.id, id_type_page: selectTypePortfolio}})
                                            .then(r => {
                                                //setTypePortfolio(r.data)
                                                setPortfolio(r.data)
                                            })
                                            .catch(() => console.log('error'))
                                    }}
                            >Добавить страницу</button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};

export default ChoiceAddTypePortfolioPage;

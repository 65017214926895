import React, {useEffect, useState} from 'react';
import TableFoodRation from "../../components/parent/table/TableFoodRation";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getChildren} from "../../store/reducers/parent/ActionCreator";
import {ParentSlice} from "../../store/reducers/parent/ParentSlice";
import TableAllPfcFoodRation from "../../components/parent/table/TableAllPFCFoodRation";
import TablePFCFoodRation from "../../components/parent/table/TablePFCFoodRation";
import {Helmet} from "react-helmet";

const ChildrenFoodRation = () => {

    const dispatch = useAppDispatch();

    const [date, setDate] = useState<Date>(new Date());
    const {child, children} = useAppSelector(state => state.ParentReducer)

    useEffect(() => {
        dispatch(getChildren()).then()
    }, [dispatch]);


    return (
        <div className="page-content">
            <Container fluid>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Рацион питания</title>
                </Helmet>
                <Row>
                    <Col lg={3}>
                        <div className="col-auto mb-3">
                            <div className="input-group">
                                <Flatpickr
                                    className="form-control border-0 dash-filter-picker shadow"
                                    value={date}
                                    options={{
                                        locale: Russian, dateFormat: "d m Y",
                                    }}
                                    onClose={(date) => {
                                        setDate(date[0])
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <Card>
                                <CardHeader>
                                    Ребенок:
                                    <select className="form-select mt-1" value={child}
                                            onChange={event => dispatch(ParentSlice.actions.setChild(event.target.value))}>
                                        {
                                            children.map(child => <option key={child.account_uid}
                                                                          value={child.account_uid}>{child.account_name}</option>)
                                        }
                                    </select>
                                </CardHeader>
                            </Card>
                            <Card>
                                <CardBody>
                                    <TablePFCFoodRation date={date}/>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <TableAllPfcFoodRation date={date}/>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                    <Col>
                        <Card>
                            <CardHeader>
                                <h5>Меню на день</h5>
                            </CardHeader>
                            <CardBody>
                                <TableFoodRation date={date}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ChildrenFoodRation;

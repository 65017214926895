import React, {useEffect, useState} from 'react';
import {Col, Row} from "reactstrap";
import CardPortfolioAllEvents from "./CardPortfolioAllEvents";
import AxiosClient from "../../api/AxiosClient";
import {useAppSelector} from "../../hooks/redux";
import {IPortfolio} from "../../models/kindergarten/IPortfolio";

const PortfolioEvents = () => {

    const {child} = useAppSelector(state => state.ParentReducer)
    const [portfolios, setPortfolios] = useState<IPortfolio[]>([]);

    useEffect(() => {
        AxiosClient.get<IPortfolio[]>('/parent/portfolio', {params: {child: child}})
            .then(r => setPortfolios(r.data))
    }, [child]);

    return (
        <Row>
            {
                portfolios.map(i => {
                    return (
                        <div className="mt-4" key={i.year}>
                            <Col>
                                <h4>{i.year}</h4>
                                <CardPortfolioAllEvents months={i.months}/>
                            </Col>
                        </div>
                    )

                })
            }
        </Row>
    );
};

export default PortfolioEvents;

import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
    getCabinets, getDoGroups, getEmployees,
    getOrganizations,
    getRegions,
    getTypesGroup
} from "../../store/reducers/department/ActionCreator";
import {Alert, Col, Container, Row} from "reactstrap";
import SelectToUpdateDoGroup from "../../components/department/doGroup/SelectToUpdateDoGroup";
import DoGroupAdd from "../../components/department/doGroup/DoGroupAdd";
import DoGroupUpdate from "../../components/department/doGroup/DoGroupUpdate";

const SettingsDoGroup = () => {
    const dispatch = useAppDispatch();

    const {isError,doGroups} = useAppSelector(state => state.DepartmentReducer)

    const [addDoGroup, setAddDoGroup] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getDoGroups())
        dispatch(getRegions())
        dispatch(getCabinets())
        dispatch(getEmployees())
        dispatch(getOrganizations())
        dispatch(getTypesGroup())
    }, [dispatch]);


    return (
        <div className="page-content">
            {isError && <Alert className="alert-danger mb-4">{isError}</Alert>}
            <Container fluid>
                <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                    <SelectToUpdateDoGroup
                        setAddDoGroup={(val) => setAddDoGroup(val)}
                        doGroups={doGroups}/>
                    <div className="w-100 overflow-hidden">
                        <div className="d-lg-flex">
                            <div className="w-100">
                                <div>
                                    <div className="mt-1 mt-sm-1 mt-lg-0 mt-xl-0 mt-xxl-0" id="users-chat">
                                        <Row >
                                            <Col>
                                                {
                                                    addDoGroup ? <DoGroupAdd /> : <DoGroupUpdate/>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SettingsDoGroup;

import React, {FC} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import moment from "moment";

interface ISubmissionDocumentsSuccess {
    success: boolean,
    setSuccess: (value: boolean) => void
    time: any
}

const SubmissionDocumentsSuccess: FC<ISubmissionDocumentsSuccess> = ({setSuccess, success, time}) => {
    return (
        <Modal id="createProjectModal" isOpen={success}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader
                toggle={() => setSuccess(!success)}
                className="p-3 bg-soft-primary"
                id="createProjectModalLabel">
                Ваш талон
            </ModalHeader>
            <ModalBody>
                <div>
                    <label className="fs-18">Номер талона</label>
                    <p className="fs-20">{time.id}</p>
                </div>
                <div>
                    <label className="fs-18">Время записи</label>
                    <p className="fs-20">{time.time ? moment(time.time[0] * 1000).format('DD.MM.Y H:mm') + " - " + moment(time.time[1] * 1000).format('DD.MM.Y H:mm') : ''}</p>
                </div>
                <div>
                    <p>Сделайте скриншот или сфотографируйте информацию, что бы не забыть</p>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default SubmissionDocumentsSuccess;

import {AxiosResponse} from "axios";
import $api from "../../AxiosClient";

class MyEventService {
    static async addEvent(data: {startdate: Date, enddate: Date, name: string, type: string, person: string, description: string, author: string}): Promise<AxiosResponse<any>>{
        return $api.post<any>("/add/event", {data});
    }

    static async getEvents(): Promise<AxiosResponse<any>>{
        return $api.get<any>("/get_events");
    }
}

export default MyEventService;
import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import {Card, CardBody, Col, Row} from "reactstrap";

const Widget = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)
    const [data, setData] = useState([
        {
            org: 'Организация 1',
            children: 2,
            out: 5,
            podGroup: 6
        },
        {
            org: 'Организация 2',
            children: 2,
            out: 5,
            podGroup: 6
        },
        {
            org: 'Организация 3',
            children: 2,
            out: 5,
            podGroup: 6
        },
        {
            org: 'Организация 4',
            children: 2,
            out: 5,
            podGroup: 6
        }
    ]);
    const [outData, setOutData] = useState({children: 0, out: 0, podGroup: 0});

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        let org = data.filter(d => selOrg.includes(d?.org))
        const children = org.map((o) => o.children)
        const out = org.map((o) => o.out)
        const podGroup = org.map((o) => o.podGroup)
        setOutData({
            children: children.length > 0 ? children.reduce((a, b) => a + b) : 0,
            out: out.length > 0 ? out.reduce((a, b) => a + b) : 0,
            podGroup: podGroup.length > 0 ? podGroup.reduce((a, b) => a + b) : 0
        })
    }, [selectOrganizations]);

    return (
        <div className="">
            <Row>
                <Col xxl={4} xl={6} lg={6} sm={6} xs={12}>
                    <Card className="card-animate flex-1 card-height-100">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">Справки</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold"><span
                                        className="counter-value">{outData.children}</span></h2>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-primary rounded-circle fs-2">
                                                        <i className="ri-team-line"></i>
                                                    </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xxl={4} xl={6} lg={6} sm={6} xs={12}>
                    <Card className="card-animate flex-1 card-height-100">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">ОВЗ</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold"><span
                                        className="counter-value">{outData.children}</span></h2>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-primary rounded-circle fs-2">
                                                        <i className="ri-user-shared-2-line"></i>
                                                    </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xxl={4} xl={12}>
                    <Card className="card-animate flex-1 card-height-100">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">Инвалиды</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold"><span
                                        className="counter-value">{outData.podGroup}</span></h2>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-primary rounded-circle fs-2">
                                                        <i className="ri-user-5-line"></i>
                                                    </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Widget;

import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import * as Yup from "yup";
import {Col, Label, Row} from "reactstrap";
import {updateRecommendedActive} from "../../../store/reducers/department/ActionCreator";

const UpdateDataRecommendedActiveForm = () => {
    const dispatch = useAppDispatch();
    const {selectRecommendedActive, positions} = useAppSelector(state => state.DepartmentReducer)

    return (
        <Formik
            enableReinitialize
            initialValues={{
                data_begin: `${selectRecommendedActive?.data_begin}`,
                data_end: `${selectRecommendedActive?.data_end}`,
                name: `${selectRecommendedActive?.name}`,
                position_id: selectRecommendedActive?.position_id
            }}
            validationSchema={Yup.object({
                name: Yup.string().required('Обязательное поле')
            })}
            onSubmit={(value) => {
                dispatch(updateRecommendedActive(value, selectRecommendedActive!.id))
            }}
        >
            <Form>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="nameInput" className="form-label">Начальная
                                дата</Label>
                            <Field type="date" className="form-control" id="nameInput"
                                   name="data_begin"/>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="nameInput" className="form-label">Конечная
                                дата</Label>
                            <Field type="date" className="form-control" id="nameInput"
                                   name="data_end"/>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="typeCabinetInput"
                                   className="form-label">Название</Label>
                            <Field type="text" className="form-control"
                                   id="typeCabinetInput"
                                   name="name"/>
                            <ErrorMessage name="name">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="organization_idInput"
                                   className="form-label">Должность</Label>
                            <Field as="select" className="form-select"
                                   id="organization_idInput"
                                   name="position_id">
                                {
                                    positions.map(item => (
                                        <option key={item.id}
                                                value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Field>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="mt-3">
                        <div className="hstack gap-2 justify-content-end">
                            <button type="submit"
                                    className="btn btn-primary">Обновить
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Formik>
    );
};

export default UpdateDataRecommendedActiveForm;

import React, {FC, useState} from 'react';
import {Card, CardBody} from "reactstrap";
import DataTable from "react-data-table-component";
import moment from "moment/moment";

interface ITableFeedback {
    listFeedback:any,
    setSelectFeedback(value: any) : any,
    setShowFeedback(value: boolean) : any,
}

const TableFeedback: FC <ITableFeedback> = ({listFeedback, setShowFeedback, setSelectFeedback}) => {

    const columns: any = [
        {
            id: 'number',
            name: <span className='font-weight-bold fs-13'>№</span>,
            selector: (row: any) => row.id,
            width: '60px'
        },
        {
            id: 'date_feedback',
            name: <span className='font-weight-bold fs-13'>Дата обращения</span>,
            selector: (row: any) => moment(row.created_at).format('DD.MM.YYYY'),
            wrap: true,
            center: true,
            width: '150px'
        },
        {
            id: 'short_description',
            name: <span className='font-weight-bold fs-13'>Краткое описание</span>,
            selector: (row: any) => row.short_description,
        },
        {
            id: 'decision',
            name: <span className='font-weight-bold fs-13'>Решение</span>,
            selector: (row: any) => row.decision ? <i className="bx bx-check fs-3"></i> : '',
            center: true,
            width: '90px'
        },
        {
            id: 'date_decision',
            name: <span className='font-weight-bold fs-13'>Дата решения</span>,
            selector: (row: any) => moment(row.date_decision).format('DD.MM.YYYY'),
            width: '150px'
        }
    ];

    return (
        <Card className="mt-3">
            <CardBody>
                <DataTable
                    columns={columns}
                    data={listFeedback}
                    noDataComponent={'У вас нет обращений'}
                    //onChangePage={handlePageChange}
                    //onChangeRowsPerPage={handleRowsPerPageChange}
                    pagination
                    paginationRowsPerPageOptions={[100, 500, 1000]}
                    paginationPerPage={100}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно обращений:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5",
                            },
                        },
                    }}
                    onRowDoubleClicked={(row:any) => {
                        setSelectFeedback(row)
                        setShowFeedback(true)
                    }}

                />
            </CardBody>
            
        </Card>
    );
};

export default TableFeedback;

import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import {useAppSelector} from "../../../hooks/redux";


const Widgets = () => {

    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)


    const [data, setData] = useState([
        {
            org: 'Организация 1',
            children: 2,
            teacher: 5,
            kc: 6,
            zased: 5
        },
        {
            org: 'Организация 2',
            children: 2,
            teacher: 5,
            kc: 6,
            zased: 5
        },
        {
            org: 'Организация 3',
            children: 2,
            teacher: 5,
            kc: 6,
            zased: 5
        },
        {
            org: 'Организация 4',
            children: 2,
            teacher: 5,
            kc: 6,
            zased: 5
        },
    ]);
    const [outData, setOutData] = useState({
        children: 0,
        teacher: 0,
        kc: 0,
        zased: 0
    });

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        let org = data.filter(d => selOrg.includes(d?.org))
        const children = org.map((o) => o.children)
        const teacher = org.map((o) => o.teacher)
        const kc = org.map((o) => o.kc)
        const zased = org.map((o) => o.zased)
        setOutData({
            children: children.length > 0 ? children.reduce((a, b) => a + b) : 0,
            teacher: teacher.length > 0 ? teacher.reduce((a, b) => a + b) : 0,
            kc: kc.length > 0 ? kc.reduce((a, b) => a + b) : 0,
            zased: zased.length > 0 ? zased.reduce((a, b) => a + b) : 0,
        })
    }, [selectOrganizations]);

    return (
        <Row>
            <Col xxl={3} xl={6} lg={6} sm={6} xs={12}>
                <Card className="card-animate flex-1 card-height-100">
                    <CardBody>
                        <div className="d-flex justify-content-between">
                            <div>
                                <p className="fw-medium text-muted mb-0">Всего детей</p>
                                <h2 className="mt-4 ff-secondary fw-semibold"><span
                                    className="counter-value">{outData.children}</span>
                                </h2>
                            </div>
                            <div>
                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-primary rounded-circle fs-2">
                                                        <i className="ri-team-line"></i>
                                                    </span>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col xxl={3} xl={6} lg={6} sm={6} xs={12}>
                <Card className="card-animate flex-1 card-height-100">
                    <CardBody>
                        <div className="d-flex justify-content-between">
                            <div>
                                <p className="fw-medium text-muted mb-0">Учителей</p>
                                <h2 className="mt-4 ff-secondary fw-semibold"><span
                                    className="counter-value">{outData.teacher}</span>
                                </h2>
                            </div>
                            <div>
                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-primary rounded-circle fs-2">
                                                        <i className="ri-group-line"></i>
                                                    </span>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col xxl={3} xl={6} lg={6} sm={6} xs={12}>
                <Card className="card-animate flex-1 card-height-100">
                    <CardBody>
                        <div className="d-flex justify-content-between">
                            <div>
                                <p className="fw-medium text-muted mb-0">КЦ</p>
                                <h2 className="mt-4 ff-secondary fw-semibold"><span
                                    className="counter-value">{outData.kc}</span>
                                </h2>
                            </div>
                            <div>
                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-primary rounded-circle fs-2">
                                                        <i className="bx bx-building"></i>
                                                    </span>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
            <Col xxl={3} xl={6} lg={6} sm={6}>
                <Card className="card-animate flex-1 card-height-100">
                    <CardBody>
                        <div className="d-flex justify-content-between">
                            <div>
                                <p className="fw-medium text-muted mb-0">Заседаний</p>
                                <h2 className="mt-4 ff-secondary fw-semibold"><span
                                    className="counter-value">{outData.zased}</span>
                                </h2>
                            </div>
                            <div>
                                <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-primary rounded-circle fs-2">
                                                        <i className="bx bx-user-circle"></i>
                                                    </span>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default Widgets;

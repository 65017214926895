import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import PhoneOrPc from "../../components/department/PhoneOrPC";
import CountChildrenAdditionalEducationInPreSchoolFiveSeven
    from "../../components/department/AdditionalEducationInPreSchool/CountChildrenAdditionalEducationInPreSchoolFiveSeven";
import CountChildrenAdditionalEducationInPreSchoolPart1
    from "../../components/department/AdditionalEducationInPreSchool/TablePhone/CountChildrenAdditionalEducationInPreSchoolPart1";
import CountChildrenAdditionalEducationInPreSchoolPart2
    from "../../components/department/AdditionalEducationInPreSchool/TablePhone/CountChildrenAdditionalEducationInPreSchoolPart2";
import CountChildrenAdditionalEducationInPreSchoolPart3
    from "../../components/department/AdditionalEducationInPreSchool/TablePhone/CountChildrenAdditionalEducationInPreSchoolPart3";
import CountChildrenAdditionalEducationInPreSchoolThreeFive
    from "../../components/department/AdditionalEducationInPreSchool/CountChildrenAdditionalEducationInPreSchoolThreeFive";
import CountChildrenAdditionalEducationInPreSchoolOneThree
    from "../../components/department/AdditionalEducationInPreSchool/CountChildrenAdditionalEducationInPreSchoolOneThree";
import GraphChildrenAdditionalEducationInPreSchoolOneThree
    from "../../components/department/AdditionalEducationInPreSchool/GraphChildrenAdditionalEducationInPreSchoolOneThree";
import GraphChildrenAdditionalEducationInPreSchoolThreeFive
    from "../../components/department/AdditionalEducationInPreSchool/GraphChildrenAdditionalEducationInPreSchoolThreeFive";
import GraphChildrenAdditionalEducationInPreSchoolFiveSeven
    from "../../components/department/AdditionalEducationInPreSchool/GraphChildrenAdditionalEducationInPreSchoolFiveSeven";
import Widget from "../../components/department/AdditionalEducationInPreSchool/Widget";
import CountChildrenAdditionalEducationInPreSchoolThreeFivePart1
    from "../../components/department/AdditionalEducationInPreSchool/TablePhone/CountChildrenAdditionalEducationInPreSchoolThreeFivePart1";
import CountChildrenAdditionalEducationInPreSchoolThreeFivePart2
    from "../../components/department/AdditionalEducationInPreSchool/TablePhone/CountChildrenAdditionalEducationInPreSchoolThreeFivePart2";
import CountChildrenAdditionalEducationInPreSchoolThreeFivePart3
    from "../../components/department/AdditionalEducationInPreSchool/TablePhone/CountChildrenAdditionalEducationInPreSchoolThreeFivePart3";
import CountChildrenAdditionalEducationInPreSchoolOneThreePart1
    from "../../components/department/AdditionalEducationInPreSchool/TablePhone/CountChildrenAdditionalEducationInPreSchoolOneThreePart1";
import CountChildrenAdditionalEducationInPreSchoolOneThreePart2
    from "../../components/department/AdditionalEducationInPreSchool/TablePhone/CountChildrenAdditionalEducationInPreSchoolOneThreePart2";
import CountChildrenAdditionalEducationInPreSchoolOneThreePart3
    from "../../components/department/AdditionalEducationInPreSchool/TablePhone/CountChildrenAdditionalEducationInPreSchoolOneThreePart3";

const AdditionalEducationInPreSchool = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Доп. образование в ДОУ</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                           <Row>
                               <SelectOrganization/>
                           </Row>
                            <Row>
                                <Widget/>
                            </Row>
                            <Row>
                                <Col xxl={4} xl={6} lg={12}><GraphChildrenAdditionalEducationInPreSchoolOneThree/></Col>
                                <Col xxl={4} xl={6} lg={12}><GraphChildrenAdditionalEducationInPreSchoolThreeFive/></Col>
                                <Col xxl={4} xl={12} lg={12}><GraphChildrenAdditionalEducationInPreSchoolFiveSeven/></Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <PhoneOrPc phone={[<CountChildrenAdditionalEducationInPreSchoolPart1/>, <CountChildrenAdditionalEducationInPreSchoolPart2/>, <CountChildrenAdditionalEducationInPreSchoolPart3/>,
                                                <CountChildrenAdditionalEducationInPreSchoolThreeFivePart1/>, <CountChildrenAdditionalEducationInPreSchoolThreeFivePart2/>, <CountChildrenAdditionalEducationInPreSchoolThreeFivePart3/>,
                                                <CountChildrenAdditionalEducationInPreSchoolOneThreePart1/>, <CountChildrenAdditionalEducationInPreSchoolOneThreePart2/>, <CountChildrenAdditionalEducationInPreSchoolOneThreePart3/>]}
                                       pc={[<CountChildrenAdditionalEducationInPreSchoolFiveSeven/>, <CountChildrenAdditionalEducationInPreSchoolThreeFive/>, <CountChildrenAdditionalEducationInPreSchoolOneThree/>]}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )

};

export default AdditionalEducationInPreSchool;

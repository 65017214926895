import React, {FC, useState, useRef, useEffect, useCallback} from 'react';
import AxiosClient from "../../../../api/AxiosClient";
import {setIsSend} from "../../../../store/reducers/widgets/WidgetsSlice";
import {toJpeg, toPng} from "html-to-image";
import {useAppDispatch} from "../../../../hooks/redux";

interface ITitlePortfolioPage{
    dispatch(value: any): any,
    portfolio: any,
    setPortfolio(value: any): any,
    setSelectEditPagePortfolio(value: boolean) : any
}

const TitlePortfolioPage: FC <ITitlePortfolioPage> = ({portfolio, setPortfolio, setSelectEditPagePortfolio}) => {
    const dispatch = useAppDispatch();
    const [images, setImages] = useState<{img1: any, img2: any}>({ img1: '', img2: '' });
    const ref = useRef<HTMLDivElement>(null);
    const handleCombineImagesTitle = () => {
        AxiosClient.post('get/pattern/portfolio', { nameImage: 'title.png', id_type: portfolio?.selectPage?.type_page_portfolio_id, id_pattern: portfolio?.id_pattern})
            .then(r => {
                setImages({img1: r.data.img1, img2: portfolio?.photo_download});
            })
            .catch(() => console.log('error'));
    };

    useEffect(() => {
        onButtonClick();
    }, [images])

    const onButtonClick = useCallback(() => {
        const image = new Image();
        image.src = `data:image/png;base64,${images.img1}`;
        image.onload = () => {
            if (ref.current === null || !images.img1) {
                return;
            }
            toJpeg(ref.current, { cacheBust: true, quality: 0.95 })
                .then((dataUrl) => {
                    fetch(dataUrl)
                        .then(res => res.blob())
                        .then(blob => {
                            // console.log(portfolio?.photo)
                            const formData = new FormData();
                            formData.append('photo_page', blob);
                            formData.append('id', portfolio?.id);
                            formData.append('uid', portfolio?.child_uid);
                            formData.append('last_name', portfolio?.last_name);
                            formData.append('name', portfolio?.name);
                            formData.append('middle_name', portfolio?.middle_name);
                            formData.append('photo', portfolio?.photo);
                            formData.append('path_photo', portfolio?.path_photo);
                            formData.append('path_photo_page', portfolio?.path_photo_page);

                            AxiosClient.post('photo/save/portfolio', formData, {headers: {"Content-Type": 'multipart/form-data'}})
                                .then(r => {
                                    setPortfolio(r.data);
                                    setSelectEditPagePortfolio(false)
                                    dispatch(setIsSend(false))
                                })
                                .catch(error => {
                                    console.error("Ошибка при выгрузке титульной страницы:", error);
                                    dispatch(setIsSend(false))
                                });
                        })
                        .catch(error => console.error('Ошибка при создании Blob:', error));
                })
                .catch((err) => {
                    console.log(err);
                });
        };
    }, [ref, images, portfolio, dispatch, setPortfolio, setSelectEditPagePortfolio]);


    return (
        < div >
            <div
                ref={ref}
                style={{
                    position: 'relative',
                    padding: '10px',
                    height: '1123px',
                    width: '794px',
                    backgroundImage: `url('data:image/png;base64,${images.img1}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <img
                    src={images.img2}
                    alt="Pattern from backend"
                    style={{
                        position: 'absolute',
                        marginLeft: '185px',
                        marginTop: '457px',
                        transform: 'translateX(-50%)',
                        width: '338px',
                        height: '427px',
                        borderRadius: '10px',
                        zIndex: 1,
                    }}
                />
                <div
                    style={{
                        position: 'relative',
                        zIndex: 2,
                        fontSize: '48px',
                        fontFamily: 'domino',
                        color: '#491584',
                        marginLeft: '408px',
                        marginTop: '485px',
                        maxWidth: '350px',
                        padding: '20px',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        borderRadius: '10px',
                        boxShadow: '0 0 30px rgba(0, 0, 0, 0.7)',
                    }}
                >
                    <p>{portfolio?.last_name}</p>
                    <p>{portfolio?.name}</p>
                    <p>{portfolio?.middle_name}</p>
                </div>
            </div>
            <button onClick={handleCombineImagesTitle} id='handleCombineImagesTitle'>Click me</button>
        </div>
    );
};

export default TitlePortfolioPage;

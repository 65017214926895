import React from 'react';
import {Container} from "reactstrap";
import FormAddNews from "../../components/news/FormAddNews";
import {Helmet} from "react-helmet";

const NewsAdd = () => {
    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Добавление новости</title>
            </Helmet>
            <Container fluid>
                <FormAddNews/>
            </Container>
        </div>
    );
};

export default NewsAdd;

import React, {useEffect} from 'react';
import {Card, CardHeader, Col} from "reactstrap";
import {setGroupAllDeviationsDO} from "../../../store/reducers/widgets/WidgetsSlice";
import {getGroups} from "../../../store/reducers/widgets/ActionCreator";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";

const WidgetRequiredSelectGroupDO = () => {

    const dispatch = useAppDispatch()

    const {groupsDO, groupAllDeviationsDO, date} = useAppSelector(state => state.WidgetReducer);

    useEffect(() => {
        dispatch(getGroups(date, 'True')).then()
    }, [date, dispatch]);

    return (
        <Col xxl={12} id='WidgetRequiredSelectGroup'>
            <div className="col">
                <Card>
                    <CardHeader className="fs-18 shadow">
                        Группа ДО:
                        <select className="form-select mt-1 shadow" value={groupAllDeviationsDO}
                                onChange={(event) => dispatch(setGroupAllDeviationsDO(event.target.value))}
                        >
                            {
                                groupsDO.map((group) => {
                                    return <option key={group.group_id}
                                                   value={group.group_id}>{group.group_name}</option>
                                })
                            }
                        </select>
                    </CardHeader>
                </Card>
            </div>
        </Col>
    );
};

export default WidgetRequiredSelectGroupDO;

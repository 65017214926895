import React, {FC, useState, useRef, useEffect, useCallback} from 'react';
import AxiosClient from "../../../../api/AxiosClient";
import {setIsSend} from "../../../../store/reducers/widgets/WidgetsSlice";
import {toJpeg, toPng} from "html-to-image";

interface ITreeFamily {
    dispatch(value: any): any,
    page: any,
    setPortfolio(value: any): any,
    setSelectEditPagePortfolio(value: boolean): any
}

const TreeFamily: FC<ITreeFamily> = ({dispatch, page, setPortfolio, setSelectEditPagePortfolio}) => {
    const [images, setImages] = useState<{ img1: any, img2: any, img3: any, img4: any, img5: any, img6: any, img7: any, img8: any }>({
        img1: '',
        img2: '',
        img3: '',
        img4: '',
        img5: '',
        img6: '',
        img7: '',
        img8: ''
    });
    const ref = useRef<HTMLDivElement>(null);
    const handleCombineImagesMyFamily = () => {
        AxiosClient.post('get/pattern/portfolio', {
            nameImage: 'treeFamily.png',
            id_type: page?.selectPage?.type_page_portfolio_id,
            id_pattern: page?.id_pattern
        })
            .then(r => {
                let img2 = page?.photo_download_base64?.[0];
                let img3 = page?.photo_download_base64?.[1];
                let img4 = page?.photo_download_base64?.[2];
                let img5 = page?.photo_download_base64?.[3];
                let img6 = page?.photo_download_base64?.[4];
                let img7 = page?.photo_download_base64?.[5];
                let img8 = page?.photo_download_base64?.[6];

                if (img2 && !img2.startsWith('data:image/png;base64,')) {
                    img2 = 'data:image/png;base64,' + img2;
                }
                if (img3 && !img3.startsWith('data:image/png;base64,')) {
                    img3 = 'data:image/png;base64,' + img3;
                }
                if (img4 && !img4.startsWith('data:image/png;base64,')) {
                    img4 = 'data:image/png;base64,' + img4;
                }
                if (img5 && !img5.startsWith('data:image/png;base64,')) {
                    img5 = 'data:image/png;base64,' + img5;
                }
                if (img6 && !img6.startsWith('data:image/png;base64,')) {
                    img6 = 'data:image/png;base64,' + img6;
                }
                if (img7 && !img7.startsWith('data:image/png;base64,')) {
                    img7 = 'data:image/png;base64,' + img7;
                }
                if (img8 && !img8.startsWith('data:image/png;base64,')) {
                    img8 = 'data:image/png;base64,' + img8;
                }

                setImages({
                    img1: r.data.img1,
                    img2: img2,
                    img3: img3,
                    img4: img4,
                    img5: img5,
                    img6: img6,
                    img7: img7,
                    img8: img8
                });
            })
            .catch(() => console.log('error'));
    };

    useEffect(() => {
        onButtonClick();
    }, [images]);

    const onButtonClick = useCallback(() => {
        const image = new Image();
        image.src = `data:image/png;base64,${images.img1}`;
        image.onload = () => {
            if (ref.current === null || !images.img1 || !images.img2 || !images.img3 || !images.img4 || !images.img5 || !images.img6 || !images.img7 || !images.img8) {
                return;
            }
            toJpeg(ref.current, {cacheBust: true, quality: 0.95})
                .then((dataUrl) => {
                    fetch(dataUrl)
                        .then(res => res.blob())
                        .then(blob => {
                            const formData = new FormData();
                            formData.append('photo_page', blob);
                            formData.append('id_portfolio', page?.selectPage.portfolio_child_id);
                            formData.append('uid', page?.uid);
                            const content = {
                                description: '',
                            };
                            formData.append('content', JSON.stringify(content));
                            formData.append('photo1', page.photo);
                            formData.append('photo2', page.photo2);
                            formData.append('photo3', page.photo3);
                            formData.append('photo4', page.photo4);
                            formData.append('photo5', page.photo5);
                            formData.append('photo6', page.photo6);
                            formData.append('photo7', page.photo7);
                            formData.append('countPhoto', (page.photo || page.photo2 || page.photo3 || page.photo4 || page.photo5 || page.photo6 || page.photo7) ? '7' : '0');
                            formData.append('list_path_photo', page?.path_photo);
                            formData.append('path_photo_page', page?.path_photo_page);

                            AxiosClient.post(`update/page/parent/portfolio/${page.selectPage.id}`, formData, {headers: {"Content-Type": 'multipart/form-data'}})
                                .then(r => {
                                    setPortfolio(r.data);
                                    setSelectEditPagePortfolio(false);
                                    dispatch(setIsSend(false));
                                })
                                .catch(error => {
                                    console.error("Ошибка при выгрузке страницы:", error);
                                    dispatch(setIsSend(false));
                                });
                        })
                        .catch(error => console.error('Ошибка при создании Blob:', error));
                })
                .catch((err) => {
                    console.log(err);
                });
        };
    }, [ref, images, page, dispatch, setPortfolio, setSelectEditPagePortfolio]);

    return (
        <div
            ref={ref}
            style={{
                position: 'relative',
                height: '1123px',
                width: '794px',
                zIndex: 1,
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    marginLeft: '354px',
                    marginTop: '102px',
                    width: '122px',
                    height: '122px',
                    backgroundImage: `url('${images.img2}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 2,
                }}
            ></div>
            <div
                style={{
                    position: 'absolute',
                    marginLeft: '155px',
                    marginTop: '302px',
                    width: '122px',
                    height: '122px',
                    backgroundImage: `url('${images.img3}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 2,
                }}
            ></div>
            <div
                style={{
                    position: 'absolute',
                    marginLeft: '549px',
                    marginTop: '299px',
                    width: '122px',
                    height: '122px',
                    backgroundImage: `url('${images.img4}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 2,
                }}
            ></div>
            <div
                style={{
                    position: 'absolute',
                    marginLeft: '16px',
                    marginTop: '452px',
                    width: '122px',
                    height: '122px',
                    backgroundImage: `url('${images.img5}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 2,
                }}
            ></div>
            <div
                style={{
                    position: 'absolute',
                    marginLeft: '284px',
                    marginTop: '452px',
                    width: '122px',
                    height: '122px',
                    backgroundImage: `url('${images.img6}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 2,
                }}
            ></div>
            <div
                style={{
                    position: 'absolute',
                    marginLeft: '431px',
                    marginTop: '452px',
                    width: '122px',
                    height: '122px',
                    backgroundImage: `url('${images.img7}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 2,
                }}
            ></div>
            <div
                style={{
                    position: 'absolute',
                    marginLeft: '662px',
                    marginTop: '452px',
                    width: '122px',
                    height: '122px',
                    backgroundImage: `url('${images.img8}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 2,
                }}
            ></div>
            <div
                ref={ref}
                style={{
                    position: 'absolute',
                    padding: '3px',
                    height: '1123px',
                    width: '794px',
                    backgroundImage: `url('data:image/png;base64,${images.img1}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 3,
                }}
            >
            </div>
            <button onClick={handleCombineImagesMyFamily} id='handleCombineImagesTreeFamily'>Click me</button>
        </div>
    );
};

export default TreeFamily;

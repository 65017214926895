import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
    getCabinets, getGroups,
    getOrganizations,
    getRegions,
    getSubdivision,
    getTypesGroup
} from "../../store/reducers/department/ActionCreator";
import {Alert, Col, Container, Row} from "reactstrap";
import SelectToUpdateGroup from "../../components/department/group/SelectToUpdateGroup";
import GroupAdd from "../../components/department/group/GroupAdd";
import GroupUpdate from "../../components/department/group/GroupUpdate";

const SettingsGroup = () => {
    const dispatch = useAppDispatch();

    const {isError,groups} = useAppSelector(state => state.DepartmentReducer)

    const [addGroup, setAddGroup] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getRegions())
        dispatch(getCabinets())
        dispatch(getOrganizations())
        dispatch(getTypesGroup())
        dispatch(getSubdivision())
        dispatch(getGroups())
    }, [dispatch]);


    return (
        <div className="page-content">
            {isError && <Alert className="alert-danger mb-4">{isError}</Alert>}
            <Container fluid>
                <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                    <SelectToUpdateGroup
                        setAddGroup={(val) => setAddGroup(val)}
                        groups={groups}/>

                    <div className="w-100 overflow-hidden">
                        <div className="d-lg-flex">
                            <div className="w-100">
                                <div>
                                    <div className="mt-1 mt-sm-1 mt-lg-0 mt-xl-0 mt-xxl-0" id="users-chat">
                                        <Row >
                                            <Col>
                                                {
                                                    addGroup ? <GroupAdd /> : <GroupUpdate/>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SettingsGroup;

import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Card, CardBody, CardFooter, CardHeader, Col, Container, Row} from "reactstrap";
import ElemSeminar from "../components/seminar/ElemSeminar";
import AxiosClient from "../api/AxiosClient";
import {useStateContext} from "../hooks/useStateContext";
import {customStyles} from "../components/settings";
import Select from "react-select";
import CityService from "../api/services/cityService/CityService";
import {Form, Formik} from "formik";

const Seminar = () => {

    const {user} = useStateContext()

    //TODO: сделать боллее оптимизированную синхронизацию с БД.
    const [seminars, setSeminars] = useState<any[]>([]);
    const [cities, setCities] = useState<{value: number, label: string}[]>([]);
    const [selectCity, setSelectCity] = useState<any>({});
    useEffect(() => {
        !user.city?.id &&
            CityService.getCities().then(r => setCities(r.data.map(city => ({value: city.id, label: city.name}))))
        AxiosClient.get('/seminars')
            .then((r) => setSeminars(r.data))
    }, [user]);

    useEffect(() => {
        setSelectCity(cities[0])
    }, [cities]);


    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Семинары</title>
            </Helmet>
            <Container fluid>
                {
                    user.city ?
                    seminars.map((year) => (
                        year.months.map((month: any, index: any) => (
                            <Row key={index}>
                                <Card>
                                    <CardHeader>
                                        <h5>{month.month}</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row className="team-list grid-view-filter">
                                            {
                                                month.elem.map((seminar: any) => (
                                                    <ElemSeminar seminar={seminar} key={seminar.id}/>
                                                ))
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Row>
                        ))
                    ))
                        :
                        <Row className="justify-content-center">
                            <Col xxl={3}>
                                <Formik
                                    initialValues={{city: selectCity?.id}}
                                    onSubmit={() => {
                                        AxiosClient.post('/seminar_update_city', {city: selectCity.value})
                                            .then(() => window.location.reload())
                                    }}
                                >
                                    <Form>
                                        <Card>
                                            <CardHeader>
                                                <h5>
                                                    Выберите город
                                                </h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Select options={cities}
                                                        value={selectCity}
                                                        onChange={(change: any) => {
                                                            setSelectCity({value: change.value, label: change.label})
                                                        }}
                                                        styles={customStyles}
                                                        placeholder="Поиск"
                                                />
                                            </CardBody>
                                            <CardFooter>
                                                <div className="hstack gap-2">
                                                    <button type="submit" className="btn btn-primary" id="addNewProject">Сохранить</button>
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Form>
                                </Formik>
                            </Col>
                        </Row>
                }
            </Container>
        </div>
    );
};

export default Seminar;

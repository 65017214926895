import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import ModalKKalAllThreeSeven from "./ModalKKalAllThreeSeven";

const TableKKalThreeSeven = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [isOpen, setIsOpen] = useState(false);

    const [data, setData] = useState([
        {
            org: "Организация 1",
            norm: '109',
            kkal: '1850',
            protein: '76',
            fats: '100',
            carbohydrates: '90',
        },
        {
            org: "Организация 2",
            norm: '112',
            kkal: '1550',
            protein: '76',
            fats: '100',
            carbohydrates: '90',
        },
        {
            org: "Организация 3",
            norm: '100',
            kkal: '1780',
            protein: '66',
            fats: '100',
            carbohydrates: '90',
        },
        {
            org: "Организация 4",
            norm: '105',
            kkal: '1550',
            protein: '76',
            fats: '100',
            carbohydrates: '90',
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns: any[] = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>ССНП</span>,
            selector: (row: any) => row.norm,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>ККал</span>,
            selector: (row: any) => row.kkal,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Белки</span>,
            selector: (row: any) => row.protein,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Жиры</span>,
            selector: (row: any) => row.fats,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Углеводы</span>,
            selector: (row: any) => row.carbohydrates,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'></span>,
            selector: () => <button className="btn btn-info w-100" onClick={() => setIsOpen(true)}>Подробнее</button>,
            center: true
        }
    ]

    return (
        <Card>
            <CardHeader>
                Среднесуточный набор продуктов (3-7 лет)
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
            <ModalKKalAllThreeSeven isOpen={isOpen} setIsOpen={(value) => setIsOpen(value)}/>
        </Card>
    );
};

export default TableKKalThreeSeven;

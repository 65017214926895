import React, {FC, useEffect, useState} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

interface ITableAprEmployeesYear{
    years: any[]
}
const TableAprEmployeesYear : FC<ITableAprEmployeesYear>= ({years}) => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)


    const [data, setData] = useState([
        {
            year: 2023,
            data: [
                {
                    org: "Организация 1",
                    year: 2023,
                    vkk: [
                        '23',
                        '23',
                        '23'
                    ],
                    oneVkk: [
                        '23',
                        '23',
                        '23'
                    ],
                    szd: [
                        '23',
                        '23',
                        '23'
                    ],
                    pr: [
                        '23',
                        '23',
                        '23'
                    ],
                },
                {
                    org: "Организация 2",
                    year: 2023,
                    vkk: [
                        '23',
                        '23',
                        '23'
                    ],
                    oneVkk: [
                        '23',
                        '23',
                        '23'
                    ],
                    szd: [
                        '23',
                        '23',
                        '23'
                    ],
                    pr: [
                        '23',
                        '23',
                        '23'
                    ],
                },
                {
                    org: "Организация 3",
                    year: 2023,
                    vkk: [
                        '23',
                        '23',
                        '23'
                    ],
                    oneVkk: [
                        '23',
                        '23',
                        '23'
                    ],
                    szd: [
                        '23',
                        '23',
                        '23'
                    ],
                    pr: [
                        '23',
                        '23',
                        '23'
                    ],
                },
                {
                    org: "Организация 4",
                    year: 2023,
                    vkk: [
                        '23',
                        '23',
                        '23'
                    ],
                    oneVkk: [
                        '23',
                        '23',
                        '23'
                    ],
                    szd: [
                        '23',
                        '23',
                        '23'
                    ],
                    pr: [
                        '23',
                        '23',
                        '23'
                    ],
                },
            ]
        },
        {
            year: 2022,
            data: [
                {
                    org: "Организация 1",
                    year: 2022,
                    vkk: [
                        '23',
                        '23',
                        '23'
                    ],
                    oneVkk: [
                        '23',
                        '23',
                        '23'
                    ],
                    szd: [
                        '23',
                        '23',
                        '23'
                    ],
                    pr: [
                        '23',
                        '23',
                        '23'
                    ],
                },
                {
                    org: "Организация 2",
                    year: 2022,
                    vkk: [
                        '23',
                        '23',
                        '23'
                    ],
                    oneVkk: [
                        '23',
                        '23',
                        '23'
                    ],
                    szd: [
                        '23',
                        '23',
                        '23'
                    ],
                    pr: [
                        '23',
                        '23',
                        '23'
                    ],
                },
                {
                    org: "Организация 3",
                    year: 2022,
                    vkk: [
                        '23',
                        '23',
                        '23'
                    ],
                    oneVkk: [
                        '23',
                        '23',
                        '23'
                    ],
                    szd: [
                        '23',
                        '23',
                        '23'
                    ],
                    pr: [
                        '23',
                        '23',
                        '23'
                    ],
                },
                {
                    org: "Организация 4",
                    year: 2022,
                    vkk: [
                        '23',
                        '23',
                        '23'
                    ],
                    oneVkk: [
                        '23',
                        '23',
                        '23'
                    ],
                    szd: [
                        '23',
                        '23',
                        '23'
                    ],
                    pr: [
                        '23',
                        '23',
                        '23'
                    ],
                },
            ]
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        const selOrg = selectOrganizations.map(s => s.label)
        let year = years.map(s => s.value)
        let d = data.filter(b => year.includes(b.year))
        let newD2: any[] = []
        d.forEach((e) => newD2.push(...e.data))
        if (newD2.length > 0)
            setOutData(newD2.filter(a => selOrg.includes(a?.org)))
    }, [selectOrganizations, years]);


    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>ВКК ГОД/АППГ/ИЗМ</span>,
            selector: (row: any) => (`${row.vkk[0]}/${row.vkk[1]}/${row.vkk[2]}`),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>1КК ГОД/АППГ/ИЗМ</span>,
            selector: (row: any) => (`${row.oneVkk[0]}/${row.oneVkk[1]}/${row.oneVkk[2]}`),
        },
        {
            name: <span className='font-weight-bold fs-13'>СЗД ГОД/АППГ/ИЗМ</span>,
            selector: (row: any) => (`${row.szd[0]}/${row.szd[1]}/${row.szd[2]}`),
        },
        {
            name: <span className='font-weight-bold fs-13'>Доля атт. ПР от общ. числа педагогов ГОД%/АППГ%/ИЗМ%</span>,
            selector: (row: any) => (`${row.pr[0]}/${row.pr[1]}/${row.pr[2]}`),
        }
    ]

    return (
        <Card>
            <CardHeader>
                С сравнением с АППГ
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableAprEmployeesYear;

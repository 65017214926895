import React, {FC} from 'react';
import {Alert, Modal, ModalBody, ModalHeader} from "reactstrap";
import {useAppSelector} from "../../../hooks/redux";
import Sleep from "./FormDailyReport/Sleep";

interface IModalAddDayliReportNutrition {
    modalAdd: boolean,
    setModalAdd: (modalAdd: boolean) => void
}
const ModalAddDayliReportSleep: FC<IModalAddDayliReportNutrition> = ({modalAdd, setModalAdd}) => {

    const {isError, successUpdate} = useAppSelector(state => state.WidgetReducer);


    return (
        <Modal id="createProjectModal" isOpen={modalAdd} toggle={() => setModalAdd(!modalAdd)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setModalAdd(!modalAdd)} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Отчет за день(сон)
            </ModalHeader>
            <ModalBody>
                {successUpdate && <Alert>{successUpdate}</Alert>}
                {isError && <Alert color="danger">{isError}</Alert>}
                <Sleep setModalAdd={(value) => setModalAdd(value)}/>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddDayliReportSleep;

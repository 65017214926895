import React from 'react';
import {Col, Container, Row} from "reactstrap";
import {Helmet} from "react-helmet";
import WidgetRequiredFlatpikerPeriod from "../../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import Widget from "../../components/department/AttendanceInOrganization/Widget";
import TableCountChildrenAttendanceOrganization
    from "../../components/department/AttendanceInOrganization/TableCountChildrenAttendanceOrganization";
import TableCountChildrenAttendance
    from "../../components/department/AttendanceInOrganization/TableCountChildrenAttendance";
import GraphCountChildrenOrganization
    from "../../components/department/AttendanceInOrganization/GraphCountChildrenOrganization";
import GraphCountChildrenYear from "../../components/department/AttendanceInOrganization/GraphCountChildrenYear";
import PhoneOrPC from "../../components/department/PhoneOrPC";
import TableChildrenAttendancePart1
    from "../../components/department/AttendanceInOrganization/TablePhone/TableChildrenAttendancePart1";
import TableChildrenAttendancePart2
    from "../../components/department/AttendanceInOrganization/TablePhone/TableChildrenAttendancePart2";
import TableCountChildrenAttendanceOrganizationPart1
    from "../../components/department/AttendanceInOrganization/TablePhone/TableCountChildrenAttendanceOrganizationPart1";
import TableCountChildrenAttendanceOrganizationPart2
    from "../../components/department/AttendanceInOrganization/TablePhone/TableCountChildrenAttendanceOrganizationPart2";
import SelectOrganization from "../../components/department/SelectOrganization";

const AttendanceInOrganizations = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Посещаемость в организациях</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xxl={3}>
                            <WidgetRequiredFlatpikerPeriod/>
                            <SelectOrganization/>
                            <GraphCountChildrenOrganization/>
                            <GraphCountChildrenYear/>
                        </Col>
                        <Col xxl={9}>
                            <Widget/>
                            <PhoneOrPC phone={[<TableCountChildrenAttendanceOrganizationPart1/>, <TableCountChildrenAttendanceOrganizationPart2/>,
                                            <TableChildrenAttendancePart1/>, <TableChildrenAttendancePart2/>]}
                                       pc={[<TableCountChildrenAttendanceOrganization/>, <TableCountChildrenAttendance/>]}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default AttendanceInOrganizations;

import {EditorState, Modifier, RichUtils} from 'draft-js';
import * as React from 'react';
import {useCallback, useState} from 'react';
import {BlockType, ColorInline, COLORS, InlineStyle} from "../components/Game/Editor/config";
import {HTMLtoState, stateToHTML} from "../components/Game/Editor/Convert";

export type EditorApi = {
    state: EditorState;
    toHtml: () => string;
    onChange: (state: EditorState) => void;
    toggleBlockType: (blockType: BlockType) => void;
    currentBlockType: BlockType;
    hasInlineStyle: (inlineStyle: InlineStyle) => boolean
    toggleInlineStyle: (inlineStyle: InlineStyle) => void
    currentInlineColor: ColorInline | number
    applyColorToWholeText: (inlineStyle: InlineStyle) => void
}

export const useEditor = (html?: string): EditorApi => {
    const [state, setState] = React.useState(() => html
        ? EditorState.createWithContent(HTMLtoState(html))
        : EditorState.createEmpty());
    const [color, setColor] = useState<ColorInline>(COLORS.find(el => html?.includes(el.color))?.inline as any ?? ColorInline.BLACK);
    const toggleBlockType = useCallback((blockType: BlockType) => {
        setState((currentState) => RichUtils.toggleBlockType(currentState, blockType))
    }, []);


    const applyColorToWholeText = useCallback((inlineStyle: InlineStyle) => {
        const contentState = state.getCurrentContent();
        const selection = state.getSelection();

        // Получаем весь текст
        const textLength = contentState.getPlainText().length;

        // Создаем новое выделение, которое охватывает весь текст
        const newSelection = selection.merge({
            anchorOffset: 0,
            focusOffset: textLength,
        });

        let newContentState = Modifier.removeInlineStyle(
            contentState,
            newSelection,
            color
        );

        newContentState = Modifier.applyInlineStyle(
            newContentState,
            newSelection,
            inlineStyle
        );

        // Обновляем состояние редактора с новым контентом
        const newEditorState = EditorState.push(
            state,
            newContentState,
            'change-inline-style'
        );

        // Устанавливаем новое состояние редактора и текущий цвет
        setState(() => newEditorState);
        setColor(inlineStyle as any);
    }, [state, color]);

    const currentBlockType = React.useMemo(() => {
        /* Шаг 1 */
        const selection = state.getSelection();
        /* Шаг 2 */
        const content = state.getCurrentContent();
        /* Шаг 3 */
        const block = content.getBlockForKey(selection.getStartKey());
        /* Шаг 4 */
        return block.getType() as BlockType;
    }, [state]);

    const currentInlineColor = React.useMemo(() => {
        return color
    }, [state, color]);

    const toggleInlineStyle = useCallback((inlineStyle: InlineStyle) => {
        setState((currentState) => RichUtils.toggleInlineStyle(currentState, inlineStyle));
    }, []);

    const hasInlineStyle = React.useCallback((inlineStyle: InlineStyle) => {
        /* Получаем иммутабельный Set с ключами стилей */
        const currentStyle = state.getCurrentInlineStyle();
        /* Проверяем содержится ли там переданный стиль */
        return currentStyle.has(inlineStyle);
    }, [state]);

    const toHtml = React.useCallback(() => {
        return stateToHTML(state.getCurrentContent());
    }, [state]);

    return React.useMemo(() => ({
        state,
        onChange: setState,
        toggleBlockType,
        currentBlockType,
        toggleInlineStyle,
        hasInlineStyle,
        currentInlineColor,
        applyColorToWholeText,
        toHtml
    }), [state])
}
import React, {useEffect} from 'react';
import {Card, CardHeader} from "reactstrap";
import {setGroupAllDeviations} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getGroups} from "../../../store/reducers/widgets/ActionCreator";

const WidgetRequiredSelectGroup = () => {

    const dispatch = useAppDispatch()

    const {groups, groupAllDeviations, date} = useAppSelector(state => state.WidgetReducer);

    useEffect(() => {
        dispatch(getGroups(date)).then()
    }, [date, dispatch]);

    return (
        <div className="col">
            <Card>
                <CardHeader className="fs-18 shadow">
                    Группа:
                    <select className="form-select mt-1 shadow" value={groupAllDeviations}
                            onChange={(event) => dispatch(setGroupAllDeviations(event.target.value))}
                    >
                        {
                            groups.map((group) => {
                                return <option key={group.group_id}
                                               value={group.group_id}>{group.group_name}</option>
                            })
                        }
                    </select>
                </CardHeader>
            </Card>
        </div>
    );
};

export default WidgetRequiredSelectGroup;

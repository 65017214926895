import React, {FC, useCallback, useEffect, useState} from 'react';
import Draggable, {DraggableEvent} from "react-draggable";
import {ISettingsAnswerShot, ISettingsCountTextures, ISettingsShot, IShot} from "../../../models/game/IShot";
import {TypeSettings} from "../../../models/game/typesSettings/TypeSettings";
import {
    ISettingsAppearance,
    ISettingsCollect,
    ISettingsSelectAnswer,
    ISettingsSelectCollect,
    ISettingsTexture
} from "../../../models/game/ISettingsTexture";
import {TypeSettingsSelectAnswer} from "../../../models/game/typesSettings/TypeSettingsSelectAnswer";
import {ITexture} from "../../../models/game/ITexture";
import Recorder from 'recorder-js';
import AxiosClient from "../../../api/AxiosClient";
import cn from 'classnames'
import {Badge, Col, Row} from "reactstrap";
import {TypeSettingsShot} from "../../../models/game/typesSettings/TypeSettingsShot";
import {motion} from 'framer-motion';
import {IBackgroundSound} from "../../../models/game/IBackgroundSound";
import './style.scss'
import {useLocation} from "react-router-dom";

interface IProps {
    shot: IShot,
    nextSelectShot: IShot | null,
    nextShot: (value?: {id: number, time: number}) => void,
    onChangeError: () => void,
    onChangeTrue: () => void,
    height: number,
    width: number
}

const Shot: FC<IProps> = ({shot, nextShot, onChangeTrue, onChangeError, width, height, nextSelectShot}) => {
    const [countTrue, setCountTrue] = useState(0);
    const [toNext, setToNext] = useState(0);
    const location = useLocation();
    const [bgAudio, setBgAudio] = useState<HTMLAudioElement | null>(null);
    const [field, setField] = useState(false);
    const [fieldValue, setFieldValue] = useState('');
    const [recorder, setRecorder] = useState<Recorder | null>(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isKeyboard, setIsKeyboard] = useState(false);
    const [keyboardValue, setKeyboardValue] = useState('');
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    const [backgroundSound, setBackgroundSound] = useState<IBackgroundSound | null>(null);
    const [showsTimeTextures, setShowsTimeTextures] = useState<any[]>([]);

    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let interval: NodeJS.Timer | null = null
        if (countTrue){
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds + 1);
            }, 1000);
        }


        return () => {
            interval && clearInterval(interval)
        };
    }, [countTrue, shot]);

    useEffect(() => {
        const audioContext = new (window.AudioContext)();

        navigator.mediaDevices.getUserMedia({audio: true})
            .then(stream => {
                const rec = new Recorder(audioContext);
                rec.init(stream);
                setRecorder(rec);
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
            });
    }, []);

    useEffect(() => {
        let bgAutoPlay : HTMLAudioElement | null = null
        let timer: NodeJS.Timeout | null = null
        let timersShow: NodeJS.Timeout[] = []
        let timersHide: NodeJS.Timeout[] = []
        const settingsAutoPlay = JSON.parse(shot.settingsAutoPlay as any)
        if (shot.background_sound) {
            if (!backgroundSound) {
                bgAutoPlay = new Audio(process.env.REACT_APP_API_BASE_URL + shot.background_sound.path);
                bgAutoPlay.loop = true;
                bgAutoPlay.volume = 0.1;
                setBgAudio(bgAutoPlay);
                setBackgroundSound(shot.background_sound);
                bgAutoPlay.play();
            } else if (backgroundSound.id !== shot.background_sound.id) {
                bgAudio?.pause();
                setBackgroundSound(shot.background_sound);
                bgAutoPlay = new Audio(process.env.REACT_APP_API_BASE_URL + shot.background_sound.path);
                bgAutoPlay.loop = true;
                bgAutoPlay.volume = 0.1;
                setBgAudio(bgAutoPlay);
                bgAutoPlay.play();
            } else {
                bgAutoPlay = bgAudio
                bgAudio?.play();
            }
        }
        if (shot.soundStartText) {
            const audio = new Audio(process.env.REACT_APP_API_BASE_URL + shot.soundStartText)
            audio.play()
            const arrayTexture = shot.textures.filter((t) => JSON.parse(t.pivot.settings as any).type === TypeSettings.APPEARANCE).map((t) => {
                let audioDuration = 0;
                if ((JSON.parse(t.pivot.settings as any).settings as ISettingsAppearance).text) {
                    const audio = new Audio(process.env.REACT_APP_API_BASE_URL + (JSON.parse(t.pivot.settings as any).settings as ISettingsAppearance).path)
                    audio.load();
                    audio.addEventListener("loadedmetadata", () => {
                        const audioD = audio.duration;
                        if (!isNaN(audioD)) {
                            audioDuration = audioD
                        } else {
                            console.error("Не удалось получить длительность аудиофайла");
                        }
                    });
                }
                return {
                    id: t.id,
                    showAfterVoice: (JSON.parse(t.pivot.settings as any).settings as ISettingsAppearance).showAfterVoice,
                    texture: t,
                    time: ((JSON.parse(t.pivot.settings as any).settings as ISettingsAppearance).timeShow * 1000),
                    hide: (JSON.parse(t.pivot.settings as any).settings as ISettingsAppearance).hide,
                    timeEnd: (JSON.parse(t.pivot.settings as any).settings as ISettingsAppearance).timeHide * 1000,
                    audioDuration: audioDuration,
                    show: (JSON.parse(t.pivot.settings as any).settings as ISettingsAppearance).showAfterVoice,
                    pivot: t.pivot
                }
            })

            const maxShowTime = Math.max(...arrayTexture.map(item => item.time))
            const maxTimeItem = arrayTexture.reduce((maxItem, item) =>
                    item.time > maxItem.time ? item : maxItem,
                arrayTexture[0]
            );


            setShowsTimeTextures(arrayTexture)

            arrayTexture.forEach((item) => {
                if (!item.showAfterVoice) {
                    const showTimer = setTimeout(() => {
                        if (((JSON.parse(item.texture.pivot.settings as any) as ISettingsTexture).settings as ISettingsAppearance).text) {
                            const audioPlay = playAudio(((JSON.parse(item.texture.pivot.settings as any) as ISettingsTexture).settings as ISettingsAppearance).path)
                            audioPlay.addEventListener('ended', () => {
                                if (item.hide) {
                                    const hideTimer = setTimeout(() => {
                                        setShowsTimeTextures((prevState) => prevState.map(el => {
                                            if (el.id === item.id) {
                                                return {
                                                    ...el,
                                                    show: false
                                                }
                                            }
                                            return el
                                        }))
                                    }, item.timeEnd);
                                    if (maxTimeItem.pivot.name === item.pivot.name) {
                                        if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                                            if (settingsAutoPlay.play) {
                                                timer = setTimeout(() => {
                                                    if (stopBgSoundToNextShot()) {
                                                        bgAutoPlay?.pause()
                                                        setBgAudio(null)
                                                    }
                                                    nextShot();
                                                }, (+settingsAutoPlay.time * 1000))
                                            }
                                        }
                                    }
                                    timersHide.push(hideTimer);
                                } else {
                                    if (maxTimeItem.pivot.name === item.pivot.name) {
                                        if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                                            if (settingsAutoPlay.play) {
                                                timer = setTimeout(() => {
                                                    if (stopBgSoundToNextShot()) {
                                                        bgAutoPlay?.pause()
                                                        setBgAudio(null)
                                                    }
                                                    nextShot();
                                                }, (+settingsAutoPlay.time * 1000))
                                            }
                                        }
                                    }
                                }
                            })
                        }
                        else {
                            if (item.hide) {
                                const hideTimer = setTimeout(() => {
                                    setShowsTimeTextures((prevState) => prevState.map(el => {
                                        if (el.id === item.id) {
                                            return {
                                                ...el,
                                                show: false
                                            }
                                        }
                                        return el
                                    }))
                                }, item.timeEnd);
                                if (maxTimeItem.pivot.name === item.pivot.name) {
                                    if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                                        if (settingsAutoPlay.play) {
                                            timer = setTimeout(() => {
                                                if (stopBgSoundToNextShot()) {
                                                    bgAutoPlay?.pause()
                                                    setBgAudio(null)
                                                }
                                                nextShot();
                                            }, (+settingsAutoPlay.time * 1000))
                                        }
                                    }
                                }
                                timersHide.push(hideTimer);
                            } else {
                                if (maxTimeItem.pivot.name === item.pivot.name) {
                                    if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                                        if (settingsAutoPlay.play) {
                                            timer = setTimeout(() => {
                                                if (stopBgSoundToNextShot()) {
                                                    bgAutoPlay?.pause()
                                                    setBgAudio(null)
                                                }
                                                nextShot();
                                            }, (+settingsAutoPlay.time * 1000))
                                        }
                                    }
                                }
                            }
                        }
                        setShowsTimeTextures((prevState) => prevState.map(el => {
                            if (el.id === item.id) {
                                return {
                                    ...el,
                                    show: true
                                }
                            }
                            return el
                        }))
                    }, item.time);
                    timersShow.push(showTimer);
                }
                else {
                    if (((JSON.parse(item.texture.pivot.settings as any) as ISettingsTexture).settings as ISettingsAppearance).text) {
                        const audioPlay = playAudio(((JSON.parse(item.texture.pivot.settings as any) as ISettingsTexture).settings as ISettingsAppearance).path)

                        audioPlay.addEventListener('ended', () => {
                            if (item.hide) {
                                const hideTimer = setTimeout(() => {
                                    setShowsTimeTextures((prevState) => prevState.map(el => {
                                        if (el.id === item.id) {
                                            return {
                                                ...el,
                                                show: false
                                            }
                                        }
                                        return el
                                    }))
                                }, item.timeEnd);
                                timersHide.push(hideTimer);
                                if (maxTimeItem.pivot.name === item.pivot.name) {
                                    if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                                        if (settingsAutoPlay.play) {
                                            timer = setTimeout(() => {
                                                if (stopBgSoundToNextShot()) {
                                                    bgAutoPlay?.pause()
                                                    setBgAudio(null)
                                                }
                                                nextShot();
                                            }, (+settingsAutoPlay.time * 1000))
                                        }
                                    }
                                }
                            } else {
                                if (maxTimeItem.pivot.name === item.pivot.name) {
                                    if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                                        if (settingsAutoPlay.play) {
                                            timer = setTimeout(() => {
                                                if (stopBgSoundToNextShot()) {
                                                    bgAutoPlay?.pause()
                                                    setBgAudio(null)
                                                }
                                                nextShot();
                                            }, (+settingsAutoPlay.time * 1000))
                                        }
                                    }
                                }
                            }
                        })
                    }
                    else {
                        if (item.hide) {
                            const hideTimer = setTimeout(() => {
                                setShowsTimeTextures((prevState) => prevState.map(el => {
                                    if (el.id === item.id) {
                                        return {
                                            ...el,
                                            show: false
                                        }
                                    }
                                    return el
                                }))
                            }, item.timeEnd);
                            timersHide.push(hideTimer);
                            if (maxTimeItem.pivot.name === item.pivot.name) {
                                if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                                    if (settingsAutoPlay.play) {
                                        timer = setTimeout(() => {
                                            if (stopBgSoundToNextShot()) {
                                                bgAutoPlay?.pause()
                                                setBgAudio(null)
                                            }
                                            nextShot();
                                        }, (+settingsAutoPlay.time * 1000))
                                    }
                                }
                            }
                        } else {
                            if (maxTimeItem.pivot.name === item.pivot.name) {
                                if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                                    if (settingsAutoPlay.play) {
                                        timer = setTimeout(() => {
                                            if (stopBgSoundToNextShot()) {
                                                bgAutoPlay?.pause()
                                                setBgAudio(null)
                                            }
                                            nextShot();
                                        }, (+settingsAutoPlay.time * 1000))
                                    }
                                }
                            }
                        }
                    }
                }
            });

            arrayTexture.length === 0 &&
            audio.addEventListener('ended', () => {
                if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                    if (settingsAutoPlay.play) {
                        timer = setTimeout(() => {
                            if (stopBgSoundToNextShot()) {
                                bgAutoPlay?.pause()
                                setBgAudio(null)
                            }
                            nextShot();
                        }, (+settingsAutoPlay.time * 1000))
                    }
                }
            })
        }
        else {
            const arrayTexture = shot.textures.filter((t) => JSON.parse(t.pivot.settings as any).type === TypeSettings.APPEARANCE).map((t) => {
                let audioDuration = 0;
                if ((JSON.parse(t.pivot.settings as any).settings as ISettingsAppearance).text) {
                    const audio = new Audio(process.env.REACT_APP_API_BASE_URL + (JSON.parse(t.pivot.settings as any).settings as ISettingsAppearance).path)
                    audio.load();
                    audio.addEventListener("loadedmetadata", () => {
                        const audioD = audio.duration;
                        if (!isNaN(audioD)) {
                            audioDuration = audioD
                        } else {
                            console.error("Не удалось получить длительность аудиофайла");
                        }
                    });
                }
                return {
                    id: t.id,
                    showAfterVoice: (JSON.parse(t.pivot.settings as any).settings as ISettingsAppearance).showAfterVoice,
                    texture: t,
                    time: ((JSON.parse(t.pivot.settings as any).settings as ISettingsAppearance).timeShow * 1000),
                    hide: (JSON.parse(t.pivot.settings as any).settings as ISettingsAppearance).hide,
                    timeEnd: (JSON.parse(t.pivot.settings as any).settings as ISettingsAppearance).timeHide * 1000,
                    audioDuration: audioDuration,
                    show: (JSON.parse(t.pivot.settings as any).settings as ISettingsAppearance).showAfterVoice,
                    pivot: t.pivot
                }
            })

            const maxShowTime = Math.max(...arrayTexture.map(item => item.time))
            const maxTimeItem = arrayTexture.reduce((maxItem, item) =>
                    item.time > maxItem.time ? item : maxItem,
                arrayTexture[0]
            );
            setShowsTimeTextures(arrayTexture)

            arrayTexture.forEach((item) => {
                if (!item.showAfterVoice) {
                    const showTimer = setTimeout(() => {
                        if (((JSON.parse(item.texture.pivot.settings as any) as ISettingsTexture).settings as ISettingsAppearance).text) {
                            const audioPlay = playAudio(((JSON.parse(item.texture.pivot.settings as any) as ISettingsTexture).settings as ISettingsAppearance).path)

                            audioPlay.addEventListener('ended', () => {
                                if (item.hide) {
                                    const hideTimer = setTimeout(() => {
                                        setShowsTimeTextures((prevState) => prevState.map(el => {
                                            if (el.id === item.id) {
                                                return {
                                                    ...el,
                                                    show: false
                                                }
                                            }
                                            return el
                                        }))
                                        if (maxTimeItem.pivot.name === item.pivot.name) {
                                            if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                                                if (settingsAutoPlay.play) {
                                                    timer = setTimeout(() => {
                                                        if (stopBgSoundToNextShot()) {
                                                            bgAutoPlay?.pause()
                                                            setBgAudio(null)
                                                        }
                                                        nextShot();
                                                    }, (+settingsAutoPlay.time * 1000))
                                                }
                                            }
                                        }
                                    }, item.timeEnd);
                                    timersHide.push(hideTimer);
                                } else {
                                    if (maxTimeItem.pivot.name === item.pivot.name) {
                                        if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                                            if (settingsAutoPlay.play) {
                                                timer = setTimeout(() => {
                                                    if (stopBgSoundToNextShot()) {
                                                        bgAutoPlay?.pause()
                                                        setBgAudio(null)
                                                    }
                                                    nextShot();
                                                }, (+settingsAutoPlay.time * 1000))
                                            }
                                        }
                                    }
                                }
                            })
                        }
                        else {
                            if (item.hide) {
                                const hideTimer = setTimeout(() => {
                                    setShowsTimeTextures((prevState) => prevState.map(el => {
                                        if (el.id === item.id) {
                                            return {
                                                ...el,
                                                show: false
                                            }
                                        }
                                        return el
                                    }))
                                    if (maxTimeItem.pivot.name === item.pivot.name) {
                                        if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                                            if (settingsAutoPlay.play) {
                                                timer = setTimeout(() => {
                                                    if (stopBgSoundToNextShot()) {
                                                        bgAutoPlay?.pause()
                                                        setBgAudio(null)
                                                    }
                                                    nextShot();
                                                }, (+settingsAutoPlay.time * 1000))
                                            }
                                        }
                                    }
                                }, item.timeEnd);
                                timersHide.push(hideTimer);
                            } else {
                                if (maxTimeItem.pivot.name === item.pivot.name) {
                                    if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                                        if (settingsAutoPlay.play) {
                                            timer = setTimeout(() => {
                                                if (stopBgSoundToNextShot()) {
                                                    bgAutoPlay?.pause()
                                                    setBgAudio(null)
                                                }
                                                nextShot();
                                            }, (+settingsAutoPlay.time * 1000))
                                        }
                                    }
                                }
                            }
                        }
                        setShowsTimeTextures((prevState) => prevState.map(el => {
                            if (el.id === item.id) {
                                return {
                                    ...el,
                                    show: true
                                }
                            }
                            return el
                        }))
                    }, item.time);
                    timersShow.push(showTimer);
                } else {
                    if (((JSON.parse(item.texture.pivot.settings as any) as ISettingsTexture).settings as ISettingsAppearance).text) {
                        const audioPlay = playAudio(((JSON.parse(item.texture.pivot.settings as any) as ISettingsTexture).settings as ISettingsAppearance).path)

                        audioPlay.addEventListener('ended', () => {
                            if (item.hide) {
                                const hideTimer = setTimeout(() => {
                                    setShowsTimeTextures((prevState) => prevState.map(el => {
                                        if (el.id === item.id) {
                                            return {
                                                ...el,
                                                show: false
                                            }
                                        }
                                        return el
                                    }))
                                    if (maxTimeItem.pivot.name === item.pivot.name) {
                                        if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                                            if (settingsAutoPlay.play) {
                                                timer = setTimeout(() => {
                                                    if (stopBgSoundToNextShot()) {
                                                        bgAutoPlay?.pause()
                                                        setBgAudio(null)
                                                    }
                                                    nextShot();
                                                }, (+settingsAutoPlay.time * 1000))
                                            }
                                        }
                                    }
                                }, item.timeEnd);
                                timersHide.push(hideTimer);
                            } else {
                                if (maxTimeItem.pivot.name === item.pivot.name) {
                                    if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                                        if (settingsAutoPlay.play) {
                                            timer = setTimeout(() => {
                                                if (stopBgSoundToNextShot()) {
                                                    bgAutoPlay?.pause()
                                                    setBgAudio(null)
                                                }
                                                nextShot();
                                            }, (+settingsAutoPlay.time * 1000))
                                        }
                                    }
                                }
                            }
                        })
                    }
                    else {
                        if (item.hide) {
                            const hideTimer = setTimeout(() => {
                                setShowsTimeTextures((prevState) => prevState.map(el => {
                                    if (el.id === item.id) {
                                        return {
                                            ...el,
                                            show: false
                                        }
                                    }
                                    return el
                                }))
                                if (maxTimeItem.pivot.name === item.pivot.name) {
                                    if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                                        if (settingsAutoPlay.play) {
                                            timer = setTimeout(() => {
                                                if (stopBgSoundToNextShot()) {
                                                    bgAutoPlay?.pause()
                                                    setBgAudio(null)
                                                }
                                                nextShot();
                                            }, (+settingsAutoPlay.time * 1000))
                                        }
                                    }
                                }
                            }, item.timeEnd);
                            timersHide.push(hideTimer);
                        } else {
                            if (maxTimeItem.pivot.name === item.pivot.name) {
                                if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                                    if (settingsAutoPlay.play) {
                                        timer = setTimeout(() => {
                                            if (stopBgSoundToNextShot()) {
                                                bgAutoPlay?.pause()
                                                setBgAudio(null)
                                            }
                                            nextShot();
                                        }, (+settingsAutoPlay.time * 1000))
                                    }
                                }
                            }
                        }
                    }
                }
            });
            if (arrayTexture.length === 0)
                if (settingsAutoPlay && settingsAutoPlay !== 'null') {
                    if (settingsAutoPlay.play) {
                        timer = setTimeout(() => {
                            if (stopBgSoundToNextShot()) {
                                bgAutoPlay?.pause()
                                setBgAudio(null)
                            }
                            nextShot();
                        }, (+settingsAutoPlay.time * 1000))
                    }
                }
        }


        let trueQues = 0;

        shot.textures.forEach((el) => {
            if ((JSON.parse(el.pivot.settings as any) as ISettingsTexture).type === TypeSettings.SELECT_ANSWER) {
                if (((JSON.parse(el.pivot.settings as any) as ISettingsTexture).settings as ISettingsSelectAnswer).select === TypeSettingsSelectAnswer.TRUE) {
                    trueQues += 1
                }
            }
            if ((JSON.parse(el.pivot.settings as any) as ISettingsTexture).type === TypeSettings.COLLECT) {
                trueQues += 1
            }
        })

        if (shot.settings as any !== 'null') {
            const settings = ((JSON.parse(shot.settings as any) as ISettingsShot))
            if (settings.type === TypeSettingsShot.COUNT_TEXTURE) {
                setIsKeyboard(true)
                trueQues += 1
            } else {
                setIsKeyboard(false)
            }
            if (settings.type === TypeSettingsShot.ANSWER) {
                trueQues += 1
                setField(true)
            }
        } else {
            setIsKeyboard(false)
        }
        setCountTrue(trueQues)
        setToNext(0)
        return () => {
            timer && clearTimeout(timer)
            timersShow.forEach((timer) => clearTimeout(timer));
            timersHide.forEach((timer) => clearTimeout(timer));
        }
    }, [shot]);

    const toggleRecording = () => {
        if (!isRecording) {
            recorder?.start()
                .then(() => {
                    setIsRecording(true);
                    console.log('Recording started');
                })
                .catch(err => console.error('Recording error: ', err));
        } else {
            // Остановить запись
            recorder?.stop()
                .then(({blob}) => {
                    setIsRecording(false);
                    console.log('Recording stopped');
                    sendAudioToBackend(blob);
                })
                .catch(err => console.error('Stop recording error: ', err));
        }
    };

    const sendAudioToBackend = (blob: Blob) => {
        const formData = new FormData();
        formData.append('audio', blob, 'recording.wav');
        AxiosClient.post('game/mess_gpt', formData, {headers: {"Content-Type": "multipart/form-data"}})
            .then((r) => {
                const audio = new Audio(process.env.REACT_APP_API_BASE_URL + r.data)
                audio.play()
                    .then(() => {
                        AxiosClient.post('game/mess_gpt/delete', {path: r.data})
                            .catch(() => console.log('error'))
                    })
            })
            .catch(() => {
                console.log('error')
            })
    }

    const defaultPosition = useCallback(
        (percentX: number, percentY: number) => {
            const elem = document.getElementById('window')
            const onePercentX = elem?.offsetWidth ? elem.offsetWidth / 100 : 0;
            const onePercentY = elem?.offsetHeight ? elem.offsetHeight / 100 : 0;
            const x = onePercentX * percentX
            const y = onePercentY * percentY
            return {x, y}
        },
        [shot],
    );


    const playStartSound = () => {
        if (shot.soundStartText) {
            const audio = playAudio(shot.soundStartText)
        }
    }

    const playAudio = (path: string): HTMLAudioElement => {
        const newSound = new Audio(process.env.REACT_APP_API_BASE_URL + path)
        try {
            if (audio) {
                audio.remove();
                setAudio(newSound)
                newSound.play();
            } else {
                setAudio(newSound)
                newSound.play();
            }
        } catch (e) {
            console.error(e)
        }
        return newSound;
    }

    const playSoundTexture = (path: string) => {
        const audio = playAudio(path)
        return audio
    }

    const stopBgSoundToNextShot = () => {
        if (nextSelectShot) {
            if (nextSelectShot.backgroundSound?.id === shot.backgroundSound?.id) {
                return false
            }
        }
        return true
    }

    const showTrueBadge = (texture: ITexture) => {
        const trueBadge = document.getElementById(`texture-success-${texture.pivot.name}`)
        if (trueBadge) {
            trueBadge.classList.remove('d-none')
        }
    }

    const hideTrueBadge = (texture: ITexture) => {
        const trueBadge = document.getElementById(`texture-success-${texture.pivot.name}`)
        if (trueBadge) {
            if (!trueBadge.classList.contains('d-none')){
                trueBadge.classList.add('d-none')
            }
        }
    }

    const showFailBadge = (texture: ITexture) => {
        const failBadge = document.getElementById(`texture-fail-${texture.pivot.name}`)
        if (failBadge) {
            failBadge.classList.remove('d-none')
        }
    }
    const hideFailBadge = (texture: ITexture) => {
        const failBadge = document.getElementById(`texture-fail-${texture.pivot.name}`)
        if (failBadge) {
            if(!failBadge.classList.contains('d-none')){
                failBadge.classList.add('d-none')
            }
        }
    }

    const checkNext = (texture: ITexture) => {
        const settings = ((JSON.parse(texture.pivot.settings as any) as ISettingsTexture).settings as ISettingsSelectAnswer)

        if (settings.select === TypeSettingsSelectAnswer.TRUE) {
            showTrueBadge(texture)
            const audio = playSoundTexture(settings.path)
            audio.addEventListener('ended', () => {
                if (toNext + 1 === countTrue) {
                    onChangeTrue()
                    nextShot({id: shot.id, time: seconds})
                    if (stopBgSoundToNextShot()) {
                        bgAudio?.pause()
                        setBgAudio(null)
                    }
                }
            });
            setToNext(prevState => prevState + 1)
        }
        if (settings.select === TypeSettingsSelectAnswer.FALSE) {
            showFailBadge(texture)
            playSoundTexture(settings.path)
            onChangeError()
        }

        if (settings.select === TypeSettingsSelectAnswer.NO_ACTIVE) {
            playSoundTexture(settings.path)
        }
    }

    const isOverlapping = (rect1: DOMRect, rect2: DOMRect) => {
        return !(
            rect1.right < rect2.left ||
            rect1.left > rect2.right ||
            rect1.bottom < rect2.top ||
            rect1.top > rect2.bottom
        );
    };

    const checkCountTexture = () => {
        const settings = ((JSON.parse(shot.settings as any) as ISettingsShot).settings as ISettingsCountTextures)
        if (Number(settings.answer) === Number(keyboardValue)) {
            onChangeTrue()
            const audio = playAudio(settings.pathTrue)
            audio.addEventListener('ended', () => {
                if (toNext + 1 === countTrue) {
                    nextShot({id: shot.id, time: seconds})
                    if (stopBgSoundToNextShot()) {
                        bgAudio?.pause()
                        setBgAudio(null)
                    }
                }
            });
        } else {
            onChangeError()
            playAudio(settings.pathFalse)
            setKeyboardValue('')
        }
    }

    const checkAllCollect = (e: DraggableEvent, el: ITexture) => {
        const type = (JSON.parse(el.pivot.settings as any) as ISettingsTexture).type
        let parentId: null | string = null;
        let audio: HTMLAudioElement = new Audio();
        if (type === TypeSettings.COLLECT) {
            parentId = el.pivot.name
        }
        if (type === TypeSettings.SELECT_COLLECT) {
            const parent = ((JSON.parse(el.pivot.settings as any) as ISettingsTexture).settings as ISettingsSelectCollect)
            parentId = parent.parent.pivot.name
        }
        if (type === TypeSettings.SELECT_COLLECT) {
            const parentElem = document.querySelector(`#texture-${parentId}`) as HTMLElement;
            const parentRect = parentElem.getBoundingClientRect();
            if (parentId) {
                const parentTexture = shot.textures.find(el => el.pivot.name === parentId)
                let texturesCount = 0
                if (parentTexture) {
                    texturesCount = ((JSON.parse(parentTexture.pivot.settings as any) as ISettingsTexture).settings as ISettingsCollect).textures.length
                }
                let overlapping = 0
                if (parentTexture) {
                    const isIssetInParent = ((JSON.parse(parentTexture.pivot.settings as any) as ISettingsTexture).settings as ISettingsCollect).textures.find((otherEl) => otherEl.pivot.name === el.pivot.name);
                    if (isIssetInParent) {
                        ((JSON.parse(parentTexture.pivot.settings as any) as ISettingsTexture).settings as ISettingsCollect).textures.forEach((otherEl) => {
                            const otherElement = document.querySelector(`#texture-${otherEl.pivot.name}`) as HTMLElement;
                            if (otherElement) {
                                const otherRect = otherElement.getBoundingClientRect();
                                if (isOverlapping(parentRect, otherRect)) {
                                    overlapping += 1
                                    if (otherEl.pivot.name === el.pivot.name) {
                                        const audioPath = ((JSON.parse(parentTexture.pivot.settings as any) as ISettingsTexture).settings as ISettingsCollect).pathTextTrue
                                        audio = playAudio(audioPath)
                                        showTrueBadge(el)
                                    }
                                } else {
                                    if (otherEl.pivot.name === el.pivot.name) {
                                        hideTrueBadge(el)
                                        hideFailBadge(el)
                                        audioErrorTexture(el)
                                    }
                                }
                            }
                        });
                    }
                }
                if (texturesCount === overlapping) {
                    audio.addEventListener('ended', () => {
                        if (toNext + 1 === countTrue) {
                            onChangeTrue()
                            nextShot({id: shot.id, time: seconds})
                            if (stopBgSoundToNextShot()) {
                                bgAudio?.pause()
                                setBgAudio(null)
                            }
                        }
                    });
                    setToNext(prevState => prevState + 1)
                }
            }
        }
        if (type !== TypeSettings.COLLECT && type !== TypeSettings.SELECT_COLLECT) {
            if(!audioErrorTexture(el))
            {
                console.log(0)
                showFailBadge(el)
            } else {
                console.log(1)
                hideFailBadge(el)
            }
        }
    }

    const audioErrorTexture = (el: ITexture) => {
        let audio: HTMLAudioElement = new Audio();
        const parentTextures = shot.textures.filter(e => (JSON.parse(e.pivot.settings as any) as ISettingsTexture).type === TypeSettings.COLLECT)
        const otherElem = document.querySelector(`#texture-${el.pivot.name}`) as HTMLElement;
        const otherRect = otherElem.getBoundingClientRect();
        let p = true
        parentTextures.forEach((texture) => {
            const parentElem = document.querySelector(`#texture-${texture.pivot.name}`) as HTMLElement;
            const parentRect = parentElem.getBoundingClientRect();
            if (isOverlapping(parentRect, otherRect)) {
                const audioPath = ((JSON.parse(texture.pivot.settings as any) as ISettingsTexture).settings as ISettingsCollect).pathTextFalse
                audio = playAudio(audioPath)
                onChangeError()
                p = false
            }
        })
        return p
    }

    const zIndexDragTexture = (el: ITexture) => {
        const parentTextures = shot.textures.filter(e => (JSON.parse(e.pivot.settings as any) as ISettingsTexture).type === TypeSettings.COLLECT)
        const otherElem = document.querySelector(`#texture-${el.pivot.name}`) as HTMLElement;
        const otherRect = otherElem.getBoundingClientRect();

        parentTextures.forEach((texture) => {
            const show = ((JSON.parse(texture.pivot.settings as any) as ISettingsTexture).settings as ISettingsCollect).show
            const parentElem = document.querySelector(`#texture-${texture.pivot.name}`) as HTMLElement;
            const parentRect = parentElem.getBoundingClientRect();
            if (isOverlapping(parentRect, otherRect)) {
                if (show === 'up')
                    otherElem.style.zIndex = '10';
                else
                    otherElem.style.zIndex = '5';
            }
        })
    }
    const showKeyboard = () => {
        if (shot.settings as any !== 'null') {
            if ((JSON.parse(shot.settings as any) as ISettingsShot).type === TypeSettingsShot.COUNT_TEXTURE) {
                return <div className="d-flex gap-1 position-absolute end-0 bottom-0 w-100 p-1">
                    <button onClick={() => setIsKeyboard(!isKeyboard)} className="btn btn-primary fs-3">
                        <i className='bx bxs-keyboard'></i>
                    </button>
                </div>
            }
        }

        return <></>
    }

    const soundHint = () => {
        playAudio(shot.pathHint)
    }

    const sized = (startWidth: number, startHeight: number) => {
        const element = document.getElementById('window');
        let newWidth = 0
        let newHeight = 0
        if (element) {
            const wElem = element.offsetWidth
            const hElem = element.offsetHeight
            let scaleW = wElem > width ? wElem / width : width / wElem
            let scaleH = hElem > height ? hElem / height : height / hElem

            newWidth = wElem > width ? scaleW * startWidth : startWidth / scaleW
            newHeight = hElem > height ? scaleH * startHeight : startHeight / scaleH
        }
        return {width: newWidth, height: newHeight}
    }

    const sendAnswer = () => {
        const settings = (JSON.parse(shot.settings as any) as ISettingsShot).settings as ISettingsAnswerShot
        if (settings.isGPTChat) {
            AxiosClient.post('game/check_answer_gpt', {answer: fieldValue, answerResult: settings.textAnswer})
                .then((r) => {
                    if (r.data.result) {
                        const audio = playAudio(settings.path)
                        audio.addEventListener('ended', () => {
                            if (toNext + 1 === countTrue) {
                                onChangeTrue()
                                nextShot({id: shot.id, time: seconds})
                                if (stopBgSoundToNextShot()) {
                                    bgAudio?.pause()
                                    setBgAudio(null)
                                }
                            }
                        });
                        setFieldValue('')
                        setField(false)
                    } else {
                        onChangeError()
                        playAudio(settings.pathFalse)
                    }
                })
                .catch(() => console.log('error'))
        } else {
            if (settings.textAnswer.toLowerCase() === fieldValue.toLowerCase()) {
                const audio = playAudio(settings.path)
                audio.addEventListener('ended', () => {
                    if (toNext + 1 === countTrue) {
                        onChangeTrue()
                        nextShot({id: shot.id, time: seconds})
                        if (stopBgSoundToNextShot()) {
                            bgAudio?.pause()
                            setBgAudio(null)
                        }
                    }
                });
                setFieldValue('')
                setField(false)
            } else {
                onChangeError()
                playAudio(settings.pathFalse)
            }
        }
    }

    const animationElem = (el: ITexture) => {
        const animation = (JSON.parse(el.pivot.settings as any) as ISettingsTexture).typeAnimation;
        const textureShow = showsTimeTextures.find((el1) => el.pivot.name === el1.pivot.name)
        if (animation === "pulse") {
            return <motion.div
                initial={{opacity: textureShow ? textureShow.showAfterVoice ? 1 : 0 : 1}}
                animate={{opacity: textureShow ? textureShow.show ? 1 : 0 : 1}} // Анимация
                transition={{
                    duration: 0, // Длительность одного цикла анимации
                    ease: "easeInOut", // Плавность перехода
                }}
            >
                <motion.div
                    animate={{scale: [1, 0.9, 1]}} // Анимация
                    transition={{
                        duration: 1, // Длительность одного цикла анимации
                        ease: "easeInOut", // Плавность перехода
                        repeat: Infinity, // Повторять бесконечно
                        repeatType: "loop", // Циклическая анимация
                    }}
                >
                    <img src={process.env.REACT_APP_API_BASE_URL + el.path} alt=""
                         style={sized((JSON.parse(el.pivot.settings as any) as ISettingsTexture).width, (JSON.parse(el.pivot.settings as any) as ISettingsTexture).height)}/>
                </motion.div>
            </motion.div>
        }
        if (animation === "shadow") {
            return <motion.div
                initial={{opacity: textureShow ? textureShow.showAfterVoice ? 1 : 0 : 1}}
                animate={{opacity: textureShow ? textureShow.show ? 1 : 0 : 1}} // Анимация
                transition={{
                    duration: 0, // Длительность одного цикла анимации
                    ease: "easeInOut", // Плавность перехода
                }}
            >
                <motion.div
                    animate={{filter: ['drop-shadow(5px 5px 5px #fff)', 'drop-shadow(0px 0px 0px #000)', 'drop-shadow(5px 5px 5px #fff)',]}} // Анимация
                    transition={{
                        duration: 1.5, // Длительность одного цикла анимации
                        ease: "easeInOut", // Плавность перехода
                        repeat: Infinity, // Повторять бесконечно
                        repeatType: "loop", // Циклическая анимация
                    }}
                >
                    <img src={process.env.REACT_APP_API_BASE_URL + el.path} alt=""
                         style={sized((JSON.parse(el.pivot.settings as any) as ISettingsTexture).width, (JSON.parse(el.pivot.settings as any) as ISettingsTexture).height)}/>
                </motion.div>
            </motion.div>
        }
        return <motion.div
            initial={{opacity: textureShow ? textureShow.showAfterVoice ? 1 : 0 : 1}}
            animate={{opacity: textureShow ? textureShow.show ? 1 : 0 : 1}} // Анимация
            transition={{
                duration: 0, // Длительность одного цикла анимации
                ease: "easeInOut", // Плавность перехода
            }}
        >
            <img src={process.env.REACT_APP_API_BASE_URL + el.path} alt=""
                 style={sized((JSON.parse(el.pivot.settings as any) as ISettingsTexture).width, (JSON.parse(el.pivot.settings as any) as ISettingsTexture).height)}/>
        </motion.div>
    }

    const showCustomText = () => {
        if (JSON.parse(shot.settings as any)) {
            if ((JSON.parse(shot.settings as any) as ISettingsShot).type === TypeSettingsShot.ANSWER) {
                return <Draggable

                    bounds="parent"
                    defaultPosition={defaultPosition(((JSON.parse(shot.settings as any) as ISettingsShot).settings as ISettingsAnswerShot).percentX, ((JSON.parse(shot.settings as any) as ISettingsShot).settings as ISettingsAnswerShot).percentY)}
                >
                    <div className="position-absolute">
                        <div
                            style={sized(((JSON.parse(shot.settings as any) as ISettingsShot).settings as ISettingsAnswerShot).width, ((JSON.parse(shot.settings as any) as ISettingsShot).settings as ISettingsAnswerShot).height)}
                            dangerouslySetInnerHTML={{
                                __html: ((JSON.parse(shot.settings as any) as ISettingsShot).settings as ISettingsAnswerShot).html
                            }}
                        />
                    </div>
                </Draggable>
            }
        }
    }

    const zIndexInfo = (el: ITexture) => {
        const up = 10;
        const down = 5;
        const settings = (JSON.parse(el.pivot.settings as any) as ISettingsTexture)
        if (settings.type === TypeSettings.COLLECT) {
            if ((settings.settings as ISettingsCollect).show === 'up') {
                return {
                    zIndex: down,
                }
            } else {
                return {
                    zIndex: up,
                }
            }
        }

        if (settings.type === TypeSettings.SELECT_COLLECT) {
            const settingsSelectCollect = (settings.settings as ISettingsSelectCollect)
            const settings2 = settingsSelectCollect.parent.pivot.settings
            if (settings2.type === TypeSettings.COLLECT) {
                if ((settings2.settings as ISettingsCollect).show === 'up') {
                    return {
                        zIndex: up,
                    }
                } else {
                    return {
                        zIndex: down,
                    }
                }
            }
        }

        return {
            zIndex: 'auto',
        }
    }

    return (
        <div className="position-absolute top-0 start-0 h-100 w-100 bg-white col-xxl-3" style={shot.background ? {
            backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL + shot.background.path})`,
            backgroundSize: '100% 100%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
        } : {}}>
            {shot.textures.map((el, index) => (
                <Draggable
                    key={`${shot.id}-${index}`}
                    enableUserSelectHack={false}
                    disabled={(JSON.parse(el.pivot.settings as any) as ISettingsTexture).type === TypeSettings.COLLECT || (!document.getElementById(`texture-success-${el.pivot.name}`)?.classList.contains('d-none') && (JSON.parse(el.pivot.settings as any) as ISettingsTexture).type === TypeSettings.SELECT_COLLECT)}
                    defaultPosition={defaultPosition(el.pivot.percentX, el.pivot.percentY)}
                    bounds="parent"
                    onStop={(e, data) => {
                        const type = (JSON.parse(el.pivot.settings as any) as ISettingsTexture).type
                        if (type !== TypeSettings.SELECT_ANSWER)
                            checkAllCollect(e, el)
                    }}
                    onDrag={() => {
                        zIndexDragTexture(el)
                    }}
                    onMouseDown={() => {
                        if ((JSON.parse(el.pivot.settings as any) as ISettingsTexture).type !== TypeSettings.NO_SETTINGS) {
                            if ((JSON.parse(el.pivot.settings as any) as ISettingsTexture).type === TypeSettings.SELECT_ANSWER) {
                                checkNext(el)
                            }
                        }
                    }}
                >
                    <div className="position-absolute"
                         id={`texture-${el.pivot.name}`}
                         style={zIndexInfo(el)}
                    >
                        <Badge color="success" style={{width: 40, height: 40, zIndex: 20}}
                               id={`texture-success-${el.pivot.name}`}
                               className="rounded-circle d-flex justify-content-center align-items-center position-absolute top-50 start-50 translate-middle d-none"><i
                            className="bx bx-check fs-1"></i></Badge>
                        <Badge color="danger" style={{width: 40, height: 40, zIndex: 20}}
                               id={`texture-fail-${el.pivot.name}`}
                               className="rounded-circle d-flex justify-content-center align-items-center position-absolute top-50 start-50 translate-middle d-none"><i
                            className="bx bx-x fs-1"></i></Badge>
                        {
                            animationElem(el)
                        }
                    </div>
                </Draggable>
            ))}
            {
                showCustomText()
            }
            {shot.personages.map((el, index) => (
                <Draggable
                    key={`${shot.id}-${index}`}
                    bounds="parent"
                    disabled
                    enableUserSelectHack={true}
                    defaultPosition={defaultPosition(el.pivot.percentX, el.pivot.percentY)}
                    onMouseDown={playStartSound}
                >
                    <div className="position-absolute"
                    >
                        <img src={process.env.REACT_APP_API_BASE_URL + el.path} alt=""
                             style={sized(el.pivot.width, el.pivot.height)}/>
                    </div>
                </Draggable>
            ))}
            {
                Boolean(shot.isGPTChat) && !field &&
                <button className={cn('position-absolute end-0 bottom-0 m-5 btn-rounded btn btn-info', {
                    'btn-danger': isRecording
                })} onClick={toggleRecording}>
                    <i className="bx bx-microphone fs-1"></i>
                </button>
            }
            {
                field &&
                <div className="d-flex gap-1 position-absolute end-0 bottom-0 w-100 p-1">
                    <input type="text" className="form-control fs-3" value={fieldValue} placeholder="Напиши ответ"
                           onChange={(event) => setFieldValue(event.target.value)}/>
                    {/*<button className="btn btn-primary p-3 m-0">*/}
                    {/*    <i className="bx bx-x fs-3 m-0 p-0"></i>*/}
                    {/*</button>*/}
                    <button className="btn btn-primary p-3 m-0" onClick={sendAnswer}>
                        <i className="bx bx-send fs-3 m-0 p-0"></i>
                    </button>
                </div>
            }
            {
                !isKeyboard &&
                showKeyboard()
            }
            {
                isKeyboard &&
                <div className="d-flex position-absolute end-0 bottom-0 w-100 p-1">
                    <Row>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((number) => (
                            <Col key={number} className="mt-1" xxl={1} xl={2} lg={2} sm={2} xs={4}>
                                <button
                                    // style={{width: '75px', height: '75px'}}
                                    className={cn('rounded-circle fs-3 btn button_keyboard', {
                                        'btn-light': !keyboardValue.includes(String(number)),
                                        'btn-danger': keyboardValue.includes(String(number))
                                    })}
                                    onClick={() => setKeyboardValue((prevState) => prevState + String(number))}
                                >
                                    {number}
                                </button>
                            </Col>
                        ))}
                        <Col className="mt-1" xxl={1} xl={2} lg={2} sm={2} xs={4}>
                            <button className="rounded-circle btn btn-success button_keyboard" //style={{width: '75px', height: '75px'}}
                                    onClick={() => checkCountTexture()}>
                                <i className="bx bx-send fs-3 "></i>
                            </button>
                        </Col>
                        <Col className="mt-1" xxl={1} xl={2} lg={2} sm={2} xs={4}>
                            <button className="rounded-circle btn btn-danger button_keyboard" //style={{width: '75px', height: '75px'}}
                                    onClick={() => {
                                        setIsKeyboard(false)
                                        setKeyboardValue('')
                                    }}>
                                <i className="bx bxs-keyboard fs-3"></i>
                            </button>
                        </Col>
                    </Row>
                </div>
            }
            {
                shot.pathHint &&
                <div className="d-flex position-absolute justify-content-end w-100 p-1">
                    <button className="rounded-circle btn btn-info d-flex justify-content-center align-items-center"
                            style={{width: '50px', height: '50px'}} onClick={() => {
                        soundHint()
                    }}>
                        <i className="bx bx-question-mark fs-3"></i>
                    </button>
                </div>
            }
        </div>
    );
};

export default Shot;

import React, {FC, useEffect, useState} from 'react';
import {Label, Button, Alert} from "reactstrap";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from 'yup';
import AuthService from "../../api/services/authService/AuthService";
import LoginModal from "./LoginModal";
import {useStateContext} from "../../hooks/useStateContext";
import {Link} from "react-router-dom";
import LoginModalQrTelegram from "./LoginModalQRTelegram";
import LoginModalGame from "./LoginModalGame";


const LoginForm: FC = () => {

    const [errorAuth, setErrorAuth] = useState<string[]>([]);
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [code, setCode] = useState<boolean>(false);
    const [modalCode, setModalCode] = useState<boolean>(false);
    const [phone, setPhone] = useState<string>('')
    const [openGame, setOpenGame] = useState(false);
    const [crypt, setCrypt] = useState('');
    const {user, setUser, setToken, setTokenSession} = useStateContext()
    const [modalShowQR, setModalShowQR] = useState<boolean>(false);

    useEffect(() => {
        if (code) {
           const btn = document.getElementById('ComeIn')
           if (btn){
               (btn as HTMLElement).click()
           }
        }
    }, [code])

    return (
        <>
            {errorAuth.length > 0 ?
                <Alert color={errorAuth[1]}>{errorAuth[0]}</Alert> : null}

            <div className="p-2 m-2">
                <Formik
                    enableReinitialize
                    initialValues={{
                        login: '',
                        password: '',
                        remember: false
                    }}
                    validationSchema={
                        Yup.object({
                            login: Yup.string().required('Введите логин'),
                            password: Yup.string().required('Введите пароль'),
                            remember: Yup.boolean()
                        })
                    }
                    onSubmit={
                        ({login, password, remember}) => {
                            AuthService.login(login, password, code)
                                .then(({data}) =>{
                                    if (!code){
                                        setPhone((data as any).phone.toString())
                                        setCrypt((data as any).crypt.toString())
                                        setErrorAuth([])
                                        setModalCode(true)
                                    }else{
                                        setModalCode(false)
                                        setUser(data.user)
                                        remember ? setToken(data.token) : setTokenSession(data.token)
                                        window.location.pathname = data.user.term.base_url
                                    }
                                })
                                .catch((err: any) => {
                                    const response = err.response;
                                    if (response && response.status === 422) {
                                        if(!response.data.message)
                                            setErrorAuth([response.data.errors, 'danger'])
                                        else
                                            setErrorAuth([response.data.message, 'danger'])
                                    }
                                })
                        }
                    }
                >
                    <Form>
                        <div className="mb-3">
                            <Label htmlFor="login" className="form-label">Логин</Label>
                            <Field
                                id="login"
                                name="login"
                                className="form-control mb-1"
                                placeholder="Введите логин"
                                type="text"
                            />
                            <ErrorMessage name="login">
                                {err => <Alert color="danger" >{err}</Alert>}
                            </ErrorMessage>
                        </div>
                        <div className="mb-3">
                            <Label className="form-label" htmlFor="password">Пароль</Label>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                                <Field
                                    name="password"
                                    type={passwordShow ? "text" : "password"}
                                    className="form-control pe-5 mb-1"
                                    placeholder="Введите пароль"
                                />
                                <span
                                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                    id="password-addon"
                                    onClick={() => setPasswordShow(!passwordShow)}><i
                                    className="ri-eye-fill align-middle"></i></span>
                                <ErrorMessage name="password">
                                    {err => <Alert color="danger">{err}</Alert>}
                                </ErrorMessage>
                            </div>
                        </div>
                        <div className="form-check">
                            <Field className="form-check-input" type="checkbox"
                                   name="remember" id="auth-remember-check"/>
                            <Label className="form-check-label"
                                   htmlFor="auth-remember-check">Запомнить меня</Label>
                        </div>
                        Не подключены к боту? <Link to={"#"} onClick={() => setModalShowQR(true)}>Подключиться</Link>
                        <div className="mt-4">
                            <Button color="success" className="btn btn-success w-100"
                                    type="submit" id="ComeIn">
                                Войти
                            </Button>

                        </div>
                    </Form>
                </Formik>

                <Formik initialValues={{}}
                        onSubmit={() => {
                            window.location.href = "https://api.mydou.ru/vk/auth"
                        }}
                >
                    <Form>
                        <button type={"submit"} className="btn mt-2 btn-info w-100">Для семинаров (Войти через VK)</button>
                    </Form>
                </Formik>

                <button type="button" className="btn mt-2 btn-info w-100" onClick={() => setOpenGame(true)}>Подключиться к игре</button>
            </div>
            <LoginModalGame openGame={openGame} setOpenGame={(value) => setOpenGame(value)}/>
            <LoginModal crypt={crypt} modalCode={modalCode} setModalCode={(modalCode: boolean) => setModalCode(modalCode)}
                        phone={phone} setCode={(code) => setCode(code)}/>
            <LoginModalQrTelegram modalShowQR={modalShowQR} setModalShowQR={(modalShowQR: boolean) => setModalShowQR(modalShowQR)}/>
        </>
    );
};

export default LoginForm;

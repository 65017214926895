import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getGroups} from "../../store/reducers/widgets/ActionCreator";
import AxiosClient from "../../api/AxiosClient";
import Select from "react-select";
import {customStyles} from "../../components/settings";
import CardPortfolio from "../../components/parent/perentPortfolio/CardPortfolio";
import {getChildren} from "../../store/reducers/parent/ActionCreator";
import moment from "moment/moment";

const PerentPortfolioList = () => {
    const navigate = useNavigate()
    const {groups, portfolios, successDeletePortfolio, selectDeletePortfolio} = useAppSelector(state => state.WidgetReducer);
    const [selectChildren, setSelectChildren] = useState({value: '', label:''});
    const [student, setStudent] = useState([]);
    const dispatch = useAppDispatch();
    const {children, child} = useAppSelector(state => state.ParentReducer)

    useEffect(() => {
        dispatch(getGroups([new Date(), new Date()])).then()
        dispatch(getChildren()).then()
    }, [dispatch]);

    useEffect(() => {
        children?.length > 0 ? setSelectChildren({value: children[0].account_uid, label: children[0].account_name}) : setSelectChildren({value: '', label:''})
    }, [children])

    useEffect(() => {
        if(selectChildren.value!==''){
            // const selectGroup = children.find((value) => value.account_uid === selectChildren.value)?.group_code;
            // AxiosClient.get('/user/list-students', {params: {date: moment(Date()).format('DD.MM.YYYY'), group: selectGroup}})
            //     .then(r => {
            //         setStudent(r.data)
            //         if(r.data?.length>0){
            //             const sort = [...r.data].sort((a, b) => a['fio'].localeCompare(b['fio']));
            //             const itemIndex = sort.findIndex((value: any) => value.code == selectChildren.value);
            //             const newItems: any  = [...(sort)];
            //             newItems.splice(itemIndex, 1);
            //             newItems.unshift(sort.splice(itemIndex, 1)[0]);
            //             setStudent(newItems)
            //             student.map((value: any, index) => {
            //                 AxiosClient.get('all/parent/portfolio', {params: {uid: value.code}})
            //                     .then(r => {
            //                         if(r.data){
            //                             setStudent(portfolio: r.data, ...student)
            //                         }
            //                         else{
            //                             AxiosClient.get('create/parent/portfolio', {params: {uid: student.code, fio: student.fio}})
            //                                 .then(request => {
            //                                     setStudent(portfolio: r.data, ...student)
            //                                 })
            //                         }
            //                     })
            //             })
            //         }
            //     })
            const selectGroup = children.find((value) => value.account_uid === selectChildren.value)?.group_code;

            AxiosClient.get('/user/list-students', {
                params: {
                    date: moment(Date()).format('DD.MM.YYYY'),
                    group: selectGroup
                }
            })
                .then(async (response) => {
                    const students = response.data;
                    if (students?.length > 0) {
                        const sortedStudents = [...students].sort((a, b) => a['fio'].localeCompare(b['fio']));
                        const itemIndex = sortedStudents.findIndex((value) => value.code == selectChildren.value);
                        if (itemIndex !== -1) {
                            const [selectedStudent] = sortedStudents.splice(itemIndex, 1);
                            sortedStudents.unshift(selectedStudent);
                        }
                        const updatedStudents: any = await Promise.all(sortedStudents.map(async (student) => {
                            try {
                                const portfolioResponse = await AxiosClient.get('all/parent/portfolio', {
                                    params: { uid: student.code }
                                });
                                if (portfolioResponse.data) {
                                    return { ...student, portfolio: portfolioResponse.data };
                                } else {
                                    const createResponse = await AxiosClient.get('create/parent/portfolio', {
                                        params: { uid: student.code, fio: student.fio }
                                    });
                                    return { ...student, portfolio: createResponse.data };
                                }
                            } catch (error) {
                                console.error(`Ошибка при получении портфолио для студента ${student.fio}:`, error);
                                return student;
                            }
                        }));
                        setStudent(updatedStudents);
                    }
                })
                .catch(() => console.log('error'))
        }
    }, [selectChildren])

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Портфолио</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col sm={5} lg={4} xl={3} xxl={2} className="mt-2 mt-sm-0 mt-lg-0 mt-xl-0 mt-xxl-0">
                        <label className="w-100">Ребенок:
                            <Select
                                options={children?.length > 0 ? [
                                    ...children.map(v => ({ value: v.account_uid, label: v.account_name }))] : [{ value: '', label: 'Не выбрано' }]}
                                value={selectChildren}
                                onChange={(change: any) => {
                                    setSelectChildren({ value: change.value, label: change.label })
                                }}
                                styles={customStyles}
                                placeholder="Поиск"
                                name="name"
                            />
                        </label>
                    </Col>
                </Row>
                <Row className="team-list grid-view-filter">
                    {
                        student.map((value, index) => (<Col key={index}><CardPortfolio student={value}></CardPortfolio></Col>))
                    }
                </Row>
            </Container>

        </div>
    );
};

export default PerentPortfolioList;

import React, {FC, useState} from 'react';
import {Col} from "reactstrap";
import cn from "classnames";
import {IShot} from "../../../models/game/IShot";

interface IProps {
    handleOpenSettings: () => void
    shotName: string,
    selectShot: () => void,
    deleteShot: () => void,
    index: number;
    onDragStart: (index: number) => void;
    onDragEnter: (index: number) => void;
    onDrop: () => void;
    isHighlighted: boolean;
    copyShot: () => void
}
const Shot: FC<IProps> = ({handleOpenSettings, shotName, selectShot, deleteShot, index, onDrop, isHighlighted, onDragEnter, onDragStart, copyShot}) => {
    const [isHoverUpdate, setIsHoverUpdate] = useState(false);
    const [isHoverCopy, setIsHoverCopy] = useState(false);
    const [isHoverDelete, setIsHoverDelete] = useState(false);
    return (
        <Col xxl={3} className="mt-2"
             draggable
             onDragStart={() => onDragStart(index)}
             onDragEnter={() => onDragEnter(index)}
             onDragOver={(e) => e.preventDefault()} // позволяет событие onDrop
             onDrop={onDrop}
        >
            <div className={cn('bg-white p-1 rounded d-flex justify-content-between align-items-center shadow', {
                'border border-1 border-dark': isHighlighted
            })}>
                <div className="d-flex align-items-center">
                    <i className={cn("bx bx-pencil fs-3 cursor-pointer", {
                           'text-primary': isHoverUpdate
                       })}
                       onMouseEnter={() => setIsHoverUpdate(true)}
                       onMouseLeave={() => setIsHoverUpdate(false)}
                       onClick={() => {
                           handleOpenSettings()
                           selectShot()
                       }}
                    ></i>
                </div>
                <h3 className="m-0">{shotName}</h3>
                <div className="d-flex align-items-center gap-2">
                    <i className={cn("bx bx-trash fs-3 cursor-pointer", {
                        'text-primary': isHoverDelete
                    })}
                       onClick={() => deleteShot()}
                       onMouseEnter={() => setIsHoverDelete(true)}
                       onMouseLeave={() => setIsHoverDelete(false)}
                    ></i>
                    <i className={cn("bx bx-copy fs-3 cursor-pointer", {
                           'text-primary': isHoverCopy
                       })}
                       onClick={() => copyShot()}
                       onMouseEnter={() => setIsHoverCopy(true)}
                       onMouseLeave={() => setIsHoverCopy(false)}
                    ></i>
                </div>
            </div>
        </Col>
    );
};

export default Shot;

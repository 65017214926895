import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../hooks/redux";
import {getEmployees} from "../../store/reducers/department/ActionCreator";
import {ErrorMessage, Field, Form, Formik} from "formik";
import AxiosClient from "../../api/AxiosClient";
import {Alert, Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner,} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import AddOtherImage from "../Kindergarten/AddOtherImage";
import AddOtherDoc from "./AddOtherDoc";
import TableGroupParty from "./TableGroupParty";
import {useStateContext} from "../../hooks/useStateContext";

const FormAddNews = () => {
    const redirect = useNavigate()

    const [groups, setGroups] = useState([]);

    useEffect(() => {
        AxiosClient.get('/get/all-group')
            .then(r => setGroups(r.data))
    }, []);


    const dispatch = useAppDispatch()
    const {user} = useStateContext();
    const [formOtherImages, setFormOtherImages] = useState<Blob[]>([]);
    const [otherImage, setOtherImage] = useState<any[]>([]);
    const [date, setDate] = useState<Date>(new Date());
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [otherDoc, setOtherDoc] = useState<any[]>([]);
    const [formOtherDoc, setFormOtherDoc] = useState<Blob[]>([]);
    const [theme, setTheme] = useState('');
    const [description, setDescription] = useState('');
    const [send, setSend] = useState(false);

    useEffect(() => {
        dispatch(getEmployees()).then()
    }, [date, dispatch])

    const uploadOtherImage = () => {
        document.getElementById('otherImageInput')?.click();
    }

    const uploadOtherDoc = () => {
        document.getElementById('otherDocInput')?.click();
    }
    const showOtherImage = (e: any) => {
        if (e.target.files) {
            const files: File[] = [...e.target.files];
            setOtherImage(otherImage.concat(files.map((i) => URL.createObjectURL(i))))
            setFormOtherImages(formOtherImages.concat(files))
        }
    }

    const showOtherDoc = (e: any) => {
        if (e.target.files) {
            const files: File[] = [...e.target.files];
            setOtherDoc(otherDoc.concat(files.map((i) => ({url: URL.createObjectURL(i), name: i.name}))))
            setFormOtherDoc(formOtherDoc.concat(files))
        }
    }
    return (
        <Formik
            enableReinitialize
            initialValues={{
                mainImage: '',
                theme: theme,
                description: description,
                checkbox: checkAll ? ([...groups] || []).sort((a: any, b: any) => a.group_name.localeCompare(b.group_name)).map((i: any) => ({[i.group_code]: true})) : ([...groups] || []).sort((a: any, b: any) => a.group_name.localeCompare(b.group_name)).map((i: any) => ({[i.group_code]: false})),
                date: new Date(),
                employee: user.id,
                organization_id: user.organization.id,
                rang: '1',
                otherFiles: ''
            }}
            onSubmit={(values) => {
                setSend(true)
                values.date = date
                const data = new FormData();
                data.append('mainImage', values.mainImage)
                formOtherImages.forEach(file => {
                    data.append('otherImage[]', file)
                })
                formOtherDoc.forEach(doc => {
                    data.append('otherDoc[]', doc)
                })
                data.append('values', JSON.stringify(values))
                AxiosClient.post('/news/add', data, {headers: {"Content-Type": 'multipart/form-data'}})
                    .then(() => {
                        setSend(false)
                        redirect('/news')
                    })
                    .catch(() => setSend(false))
            }}
        >
            {
                ({setFieldValue}) => (
                    <Form>
                        <Row>
                            <Col xxl={8}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <h4 className="text-center mt-sm-3 mt-lg-3 mt-xl-3 mt-xll-3">Информация по
                                                новости</h4>
                                            <Col>
                                                <Row className="fs-5">
                                                    <Col>
                                                        <Input name="theme"
                                                               className="mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"
                                                               placeholder="Тема"
                                                               onChange={(e) => setTheme(e.target.value)}/>
                                                        <ErrorMessage name='theme'>{(msg) => <Alert className="mt-1"
                                                                                                    color="danger">{msg}</Alert>}</ErrorMessage>
                                                        <textarea name="description"
                                                                  className="mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0"
                                                                  placeholder="Описание"
                                                                  rows={10}
                                                                  style={{resize: "none"}}
                                                                  onChange={(e) => setDescription(e.target.value)}/>
                                                        <ErrorMessage name='description'>{(msg) => <Alert
                                                            className="mt-1"
                                                            color="danger">{msg}</Alert>}</ErrorMessage>
                                                        <div className="mt-1"><span
                                                            className="fw-medium">Дата:</span>
                                                            <Flatpickr
                                                                value={date}
                                                                name='date'
                                                                className="form-control border-1"
                                                                options={{
                                                                    mode: "single",
                                                                    dateFormat: "d m Y H:i",
                                                                    locale: Russian,
                                                                    enableTime: true,
                                                                    enableSeconds: false,
                                                                    disableMobile: true
                                                                }}
                                                                onClose={(date) => setDate(date[0])}
                                                            />
                                                        </div>
                                                        <div className="mt-1"><span
                                                            className="fw-medium">Сотрудник:</span>
                                                            <Field as="select" name="employee" id=""
                                                                   className="form-control" disabled>
                                                                {
                                                                    <option
                                                                        value={user.id}>{user.name}</option>
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className="mt-1 mb-2"><span
                                                            className="fw-medium">Срочность новости:</span>
                                                            <Field name="rang"
                                                                   as="select"
                                                                   className="mt-2 form-select mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"
                                                                   placeholder="Занятое место">
                                                                <option value={1}>Не срочно</option>
                                                                <option value={2}>Требует внимание</option>
                                                                <option value={3}>Срочно</option>
                                                            </Field>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <TableGroupParty groups={groups}/>
                            </Col>
                        </Row>
                        <Row className="mt-3 mt-sm-3 mt-lg-3 mt-xl-3 mt-xxl-0">
                            <Col>
                                <Card>
                                    <CardHeader>
                                        <Input color="success" id="otherImageInput" name="otherFiles" accept="image/*"
                                               multiple
                                               className="mt-1" type="file" hidden onChange={(e) => showOtherImage(e)}/>
                                        <Input color="success" id="otherDocInput" name="otherFiles"
                                               accept=".docx,.xlsx,.pptx,.txt, .jpg, .png, .pdf"
                                               multiple
                                               className="mt-1" type="file" hidden onChange={(e) => showOtherDoc(e)}/>
                                        <Row>
                                            <Col xxl={3} lg={4}>
                                                <Button color="primary" className="mt-1 w-100"
                                                        onClick={() => uploadOtherImage()}><i
                                                    className="ri-add-fill me-1 align-bottom"></i>Добавить новые
                                                    фото</Button>
                                            </Col>
                                            <Col xxl={3} lg={4}>
                                                <Button color="primary" className="mt-1 w-100"
                                                        onClick={() => uploadOtherDoc()}><i
                                                    className="ri-add-fill me-1 align-bottom"></i>Добавить новые
                                                    документы</Button>
                                            </Col>
                                            <Col xxl={3} lg={4}>
                                                <Button color="primary" className="mt-1 w-100" type="submit" disabled={send}><i
                                                    className="ri-add-fill me-1 align-bottom"></i>{send ?
                                                    <Spinner size={'sm'}>
                                                        Loading...
                                                    </Spinner> : "Сохранить новость"}</Button>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            {
                                                otherDoc && otherDoc.map(url => (
                                                    <Col lg={6} xl={4} xxl={3} className="mt-1" key={url}>
                                                        <AddOtherDoc url={url}/>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                        <Row>
                                            {
                                                otherImage && otherImage.map(url => (
                                                    <Col lg={6} xl={4} xxl={3} className="mt-1" key={url}>
                                                        <AddOtherImage url={url}/>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )
            }
        </Formik>
    );
};

export default FormAddNews;

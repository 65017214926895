import React, {FC, useState} from 'react';
import {Card, CardBody, Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {setIsSend} from "../../store/reducers/widgets/WidgetsSlice";
import AxiosClient from "../../api/AxiosClient";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import Select from "react-select";
import {customStyles} from "../settings";
import SubmitWithLoading from "../layouts/UI/SubmitWithLoading";
import moment from "moment/moment";
import {useStateContext} from "../../hooks/useStateContext";
import {useAppDispatch} from "../../hooks/redux";
import Notification from "../widgets/Notification";

interface IFormAddFeedback {
    addFeedback: boolean,
    setAddFeedback(value: boolean) : any,
}

const FormAddFeedback: FC <IFormAddFeedback> = ({addFeedback, setAddFeedback}) => {
    const dispatch = useAppDispatch();
    const {user} = useStateContext();
    const [dateRecord, setDateRecord] = useState(moment().startOf('day').toDate());
    const [files, setFiles] = useState<File[]>([]);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<string>('');
    const [notificationMessage, setNotificationMessage] = useState<string>('');

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFiles(Array.from(e.target.files));
        }
    };

    return (
        <>
            <Modal id="createProjectModal" isOpen={addFeedback}
                   toggle={() => setAddFeedback(!addFeedback)}
                   modalClassName="zoomIn modal-xl" tabIndex={-1} centered>
                <ModalHeader toggle={() => setAddFeedback(!addFeedback)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Импорт файла студентов
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={
                            {
                                dateRecord: '',
                                FIO: user.last_name+" "+user.name +" "+ user.middle_name,
                                short_description: '',
                                description: '',
                                file: '',
                                date_decision: '',
                                FIO_decision: '',
                                description_decision: '',
                            }
                        }
                        validationSchema={
                            Yup.object({
                                short_description: Yup.string().required('Обязательное поле'),
                                description: Yup.string().required('Обязательное поле'),
                            })
                        }
                        onSubmit={(values) => {
                            // dispatch(setIsSend(true));
                            const formData = new FormData();
                            formData.append('uid_perent', user.user1c);
                            formData.append('FIO_perent', values.FIO);
                            formData.append('group_uid', '');
                            formData.append('short_description', values.short_description);
                            formData.append('description', values.description);
                            files.forEach((file, index) => {
                                formData.append(`file[${index}]`, file);
                            });

                            AxiosClient.post('/feedback/create', formData, {headers: {"Content-Type": 'multipart/form-data'}})
                                .then((r) => {
                                    // AxiosClient.post('/sendMessageFeedback', {message: "Вам пришло новое обращение"})
                                    setAddFeedback(false);
                                    setNotificationType('success');
                                    setNotificationMessage('Готово, обращение отправлено!');
                                    setShowNotification(true);
                                    dispatch(setIsSend(false))
                                })
                                .catch((error) => {
                                    dispatch(setIsSend(false))
                                    setNotificationType('error');
                                    setNotificationMessage('Ошибка, проверьте корректность данных!');
                                    setShowNotification(true);
                                    console.log(error);
                                })
                        }}>
                        {
                            ({errors, touched, setFieldValue}) => (
                                <Form>
                                    <Row>
                                    <Col>
                                        <h4>Обращение</h4>
                                        <Card>
                                            <CardBody>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100" htmlFor="dateRecord" >Дата обращения:
                                                    <Flatpickr
                                                        name="dateRecord"
                                                        id="dateRecord"
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        value={dateRecord}
                                                        options={{
                                                            locale: Russian,
                                                            dateFormat: "d.m.Y"
                                                        }}
                                                        onChange={ (date) => setDateRecord(date[0])}
                                                        disabled={true}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">
                                                    ФИО:
                                                    <Field
                                                        name="FIO"
                                                        className={`form-control`}
                                                        disabled={true}
                                                        onChange={(e: any) => {
                                                            setFieldValue('FIO', e.target.value);
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">
                                                    Краткое описание:
                                                    <Field
                                                        name="short_description"
                                                        className={`form-control ${touched.short_description && errors.short_description ? 'is-invalid' : ''}`}
                                                        maxLength={200}
                                                        onChange={(e: any) => {
                                                            setFieldValue('short_description', e.target.value);
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">
                                                    Подробнее о проблеме:
                                                    <Field
                                                        name="description"
                                                        as="textarea"
                                                        className={`form-control ${touched.description && errors.description ? 'is-invalid' : ''}`}
                                                        rows={6}
                                                        maxLength={3000}
                                                        onChange={(e: any) => {
                                                            setFieldValue('description', e.target.value);
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <label className="w-100" htmlFor="file">Файлы (.jpg, .png, .pdf):
                                                    <Input
                                                        name="file"
                                                        id="file"
                                                        type="file"
                                                        className="mb-2"
                                                        accept=".jpg, .png, .pdf"
                                                        multiple
                                                        onChange={handleFileChange}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                                <Row>
                                                    <Col>
                                                        <ul>
                                                            {files.map((file, index) => (
                                                                <li key={index}>{file.name}</li>
                                                            ))}
                                                        </ul>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col>
                                        <h4>Ответ</h4>
                                        <Card >
                                            <CardBody>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100" htmlFor="dateRecord" >Дата решения:
                                                    <Flatpickr
                                                        name="date_decision"
                                                        id="dateRecord"
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        value={dateRecord}
                                                        options={{
                                                            locale: Russian,
                                                            dateFormat: "d.m.Y"
                                                        }}
                                                        onChange={ (date) => setDateRecord(date[0])}
                                                        disabled={true}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">
                                                    ФИО:
                                                    <Field
                                                        name="FIO_decision"
                                                        className={`form-control`}
                                                        disabled={true}
                                                        onChange={(e: any) => {
                                                            setFieldValue('FIO', e.target.value);
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <label className="w-100">
                                                    Ответ:
                                                    <Field
                                                        name="description_decision"
                                                        as="textarea"
                                                        className={`form-control`}
                                                        rows={6}
                                                        maxLength={3000}
                                                        disabled={true}
                                                        onChange={(e: any) => {
                                                            setFieldValue('description_decision', e.target.value);
                                                        }}
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    </Row>
                                    <Row>
                                        <div className="hstack gap-2">
                                            <SubmitWithLoading text={'Готово'}/>
                                        </div>
                                    </Row>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
            {showNotification && <Notification type={notificationType} message={notificationMessage} setShowNotification={(value: boolean) => setShowNotification(value)}/>}
        </>
    );
};

export default FormAddFeedback;

import React, {FC, memo, useEffect} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import {getCashAccountingGraph} from "../../../store/reducers/parent/ActionCreator";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";

interface IGraphDonutCash {
    child: string,
    dateStart: Date[]
}

const GraphDonutCash: FC<IGraphDonutCash> = ({child, dateStart}) => {
    const dispatch = useAppDispatch();
    const {cashAccountingGraph} = useAppSelector(state => state.ParentReducer)

    useEffect(() => {
        if (child)
            dispatch(getCashAccountingGraph(child, dateStart)).then()
    }, [dispatch, child, dateStart])
    const services = cashAccountingGraph[0]?.detail.map(i => i.services ? +i.services.replace(',', '.').replace(/\s/g, '') : 0).reduce((a, b) => a + b)
    const paid = cashAccountingGraph[0]?.detail.map(i => i ? +i.paid.replace(',', '.').replace(/\s/g, '') : 0).reduce((a, b) => a + b)
    const food = cashAccountingGraph[0]?.detail.map(i => i ? +i.food.replace(',', '.').replace(/\s/g, '') : 0).reduce((a, b) => a + b)
    const options: any = {
        labels: ["Образовательные услуги", "Платные услуги", 'Услуги за питание'],
        chart: {
            type: "donut",
            height: 230,
        },
        plotOptions: {
            pie: {
                size: 100,
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: "80%",
                    labels: {
                        show: false,
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        stroke: {
            lineCap: "round",
            width: 0
        },
    }

    return (
        <>
            <Card>
                <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Денежные средства</h4>
                </CardHeader>

                <CardBody>
                    <ReactApexChart
                        options={options}
                        series={[services ? services : 0, paid ? paid : 0, food ? food : 0]}
                        type="donut"
                        height="150"
                        className="apex-charts"
                    />
                    {
                        cashAccountingGraph[0]?.detail.length > 0 ?
                            <div className="mt-3">
                                <div className="d-flex justify-content-center align-items-center mb-4">
                                    <div>
                                        <p className="text-muted mb-0">Всего затрат</p>
                                    </div>
                                    <h2 className="mx-3 ff-secondary mb-0">{[services ? services : 0, paid ? paid : 0, food ? food : 0].reduce((a, b) => a + b).toFixed(2)}</h2>

                                </div>
                                <div
                                    className="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                                    <p className="fw-medium mb-0">Образовательные услуги</p>
                                    <div>
                                        <span className="text-muted pe-5">{services} руб.</span>
                                    </div>
                                </div>
                                <div
                                    className="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                                    <p className="fw-medium mb-0">Платные услуги</p>
                                    <div>
                                        <span className="text-muted pe-5">{paid} руб.</span>
                                    </div>
                                </div>
                                <div
                                    className="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                                    <p className="fw-medium mb-0">Услуги за питание</p>
                                    <div>
                                        <span className="text-muted pe-5">{food} руб.</span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="d-flex justify-content-center align-items-center">
                                <div>
                                    <p className="text-muted mb-0">Данных нет</p>
                                </div>
                            </div>
                    }
                </CardBody>
            </Card>
        </>
    )
};

export default memo(GraphDonutCash);

import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import {Card, CardBody, Col, Row} from "reactstrap";

const Widget = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState([
        {
            org: 'Организация 1',
            one: 24,
            two: 25,
            three: 23,
            four: 23,
            five: 23
        },
        {
            org: 'Организация 2',
            one: 24,
            two: 25,
            three: 23,
            four: 23,
            five: 23
        },
        {
            org: 'Организация 3',
            one: 24,
            two: 25,
            three: 23,
            four: 23,
            five: 23
        },
        {
            org: 'Организация 4',
            one: 24,
            two: 25,
            three: 23,
            four: 23,
            five: 23
        }
    ]);
    const [outData, setOutData] = useState({
        one: 0,
        two: 0,
        three: 0,
        four: 0,
        five: 0
    });

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        let org = data.filter(d => selOrg.includes(d?.org))
        const one = org.map((o) => o.one)
        const two = org.map((o) => o.two)
        const three = org.map((o) => o.three)
        const four = org.map((o) => o.four)
        const five = org.map((o) => o.five)
        setOutData({
            one: one.length > 0 ? one.reduce((a, b) => a + b) : 0,
            two: two.length > 0 ? two.reduce((a, b) => a + b) : 0,
            three: three.length > 0 ? three.reduce((a, b) => a + b) : 0,
            four: four.length > 0 ? four.reduce((a, b) => a + b) : 0,
            five: five.length > 0 ? five.reduce((a, b) => a + b) : 0
        })
    }, [selectOrganizations]);

    return (
        <div>
            <Row>
                <Col xxl={4} xl={6} lg={6} sm={6} xs={12}>
                    <Card className="card-animate flex-1 card-height-100">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">I</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold"><span
                                        className="counter-value">{outData.one}</span></h2>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-primary rounded-circle fs-2">
                                                        <i className="bx bx-user-circle"></i>
                                                    </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xxl={4} xl={6} lg={6} sm={6} xs={12}>
                    <Card className="card-animate flex-1 card-height-100">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">II</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold"><span
                                        className="counter-value">{outData.two}</span></h2>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-primary rounded-circle fs-2">
                                                        <i className="bx bx-user-circle"></i>
                                                    </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xxl={4} xl={6} lg={6} sm={6} xs={12}>
                    <Card className="card-animate flex-1 card-height-100">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">III</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold"><span
                                        className="counter-value">{outData.three}</span></h2>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-primary rounded-circle fs-2">
                                                        <i className="bx bx-user-circle"></i>
                                                    </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xxl={6} xl={6} lg={6} sm={6} xs={12}>
                    <Card className="card-animate flex-1 card-height-100">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">IV</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold"><span
                                        className="counter-value">{outData.four}</span></h2>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-primary rounded-circle fs-2">
                                                        <i className="bx bx-user-circle"></i>
                                                    </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xxl={6} xl={12} lg={12} sm={12} xs={12}>
                    <Card className="card-animate flex-1 card-height-100">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <p className="fw-medium text-muted mb-0">V</p>
                                    <h2 className="mt-4 ff-secondary fw-semibold"><span
                                        className="counter-value">{outData.five}</span></h2>
                                </div>
                                <div>
                                    <div className="avatar-sm flex-shrink-0">
                                                    <span className="avatar-title bg-primary rounded-circle fs-2">
                                                        <i className="bx bx-user-circle"></i>
                                                    </span>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Widget;

import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import Certificates from "../../components/department/CertificateOvzDisabledPersons/Certificates";
import PhoneOrPc from "../../components/department/PhoneOrPC";
import CertificatesPart1 from "../../components/department/CertificateOvzDisabledPersons/TablePhone/CertificatesPart1";
import CertificatesPart2 from "../../components/department/CertificateOvzDisabledPersons/TablePhone/CertificatesPart2";
import ChildrenTypeDisabledPersons
    from "../../components/department/CertificateOvzDisabledPersons/ChildrenTypeDisabledPersons";
import ChildrenTypeDisabledPersonsPart1
    from "../../components/department/CertificateOvzDisabledPersons/TablePhone/ChildrenTypeDisabledPersonsPart1";
import ChildrenTypeDisabledPersonsPart2
    from "../../components/department/CertificateOvzDisabledPersons/TablePhone/ChildrenTypeDisabledPersonsPart2";
import ChildrenTypeDisabledPersonsPart3
    from "../../components/department/CertificateOvzDisabledPersons/TablePhone/ChildrenTypeDisabledPersonsPart3";
import ChildrenTypeDisabledPersonsPart4
    from "../../components/department/CertificateOvzDisabledPersons/TablePhone/ChildrenTypeDisabledPersonsPart4";
import ChildrenTypeOvz from "../../components/department/CertificateOvzDisabledPersons/ChildrenTypeOvz";
import ChildrenTypeOvzPart1
    from "../../components/department/CertificateOvzDisabledPersons/TablePhone/ChildrenTypeOvzPart1";
import ChildrenTypeOvzPart2
    from "../../components/department/CertificateOvzDisabledPersons/TablePhone/ChildrenTypeOvzPart2";
import ChildrenTypeOvzPart3
    from "../../components/department/CertificateOvzDisabledPersons/TablePhone/ChildrenTypeOvzPart3";
import ChildrenTypeOvzPart4
    from "../../components/department/CertificateOvzDisabledPersons/TablePhone/ChildrenTypeOvzPart4";
import GraphAllDeviations from "../../components/department/CertificateOvzDisabledPersons/GraphAllDeviations";
import Widget from "../../components/department/CertificateOvzDisabledPersons/Widget";

const CertificateOvzDisabledPersons = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Справки, ОВЗ, Инвалиды</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <SelectOrganization/>
                                    <Row>
                                        <Widget/>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <GraphAllDeviations/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <PhoneOrPc phone={[<CertificatesPart1/>, <CertificatesPart2/>,
                                                    <ChildrenTypeDisabledPersonsPart1/>, <ChildrenTypeDisabledPersonsPart2/>, <ChildrenTypeDisabledPersonsPart3/>, <ChildrenTypeDisabledPersonsPart4/>,
                                                    <ChildrenTypeOvzPart1/>, <ChildrenTypeOvzPart2/>, <ChildrenTypeOvzPart3/>, <ChildrenTypeOvzPart4/>]}
                                               pc={[<Certificates/>, <ChildrenTypeDisabledPersons/>, <ChildrenTypeOvz/>]}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default CertificateOvzDisabledPersons;

import React from 'react';
import {Card, CardHeader, Col, Container, Row} from "reactstrap";
import {Helmet} from "react-helmet";
import WidgetTableDayliReportMood from "../components/widgets/tables/WidgetTableDayliReportMood";
import WidgetTableDayliReportNutrition from "../components/widgets/tables/WidgetTableDayliReportNutrition";
import WidgetTableDayliReportSleep from "../components/widgets/tables/WidgetTableDayliReportSleep";
import WidgetRequiredFlatpikerPeriod from "../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import WidgetRequiredSelectGroup from "../components/widgets/requireds/WidgetRequiredSelectGroup";

const MainPaidService = () => {

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Отчеты за день</title>
        </Helmet>
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col xxl={12}>
                        <Row>
                            <Col xxl={6}>
                                <Card>
                                    <CardHeader className="fs-18">
                                        Период:
                                        <WidgetRequiredFlatpikerPeriod/>
                                    </CardHeader>
                                </Card>
                            </Col>
                            <WidgetRequiredSelectGroup/>
                        </Row>
                    </Col>
                    <Col xxl={12}>
                        <WidgetTableDayliReportNutrition/>
                    </Col>
                    <Col xxl={12}>
                        <WidgetTableDayliReportMood/>
                    </Col>
                    <Col xxl={12}>
                        <WidgetTableDayliReportSleep/>
                    </Col>
                </Row>
            </Container>
        </div>
    </>)
};

export default MainPaidService;

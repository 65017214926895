import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import TableMethodologicalWorkOne from "../../components/department/MethodologicalWork/TableMethodologicalWorkOne";
import TableMethodologicalWorkTwo from "../../components/department/MethodologicalWork/TableMethodologicalWorkTwo";
import TableMethodologicalWorkThree from "../../components/department/MethodologicalWork/TableMethodologicalWorkThree";
import TableMethodologicalWorkFour from "../../components/department/MethodologicalWork/TableMethodologicalWorkFour";
import PhoneOrPc from "../../components/department/PhoneOrPC";
import TableMethodologicalWorkOnePart1
    from "../../components/department/MethodologicalWork/TablePhone/TableMethodologicalWorkOnePart1";
import TableMethodologicalWorkOnePart2
    from "../../components/department/MethodologicalWork/TablePhone/TableMethodologicalWorkOnePart2";
import TableMethodologicalWorkTwoPart1
    from "../../components/department/MethodologicalWork/TablePhone/TableMethodologicalWorkTwoPart1";
import TableMethodologicalWorkTwoPart2
    from "../../components/department/MethodologicalWork/TablePhone/TableMethodologicalWorkTwoPart2";
import TableMethodologicalWorkThreePart1
    from "../../components/department/MethodologicalWork/TablePhone/TableMethodologicalWorkThreePart1";
import TableMethodologicalWorkThreePart2
    from "../../components/department/MethodologicalWork/TablePhone/TableMethodologicalWorkThreePart2";
import GraphOne from "../../components/department/MethodologicalWork/GraphOne";
import GraphTwo from "../../components/department/MethodologicalWork/GraphTwo";
import Widget from "../../components/department/MethodologicalWork/Widget";

const MethodologicalWork = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Социальность</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Widget/>
                            </Row>
                            <Row>
                                <Col>
                                    <GraphOne/>
                                </Col>
                                <Col sm={12}>
                                    <GraphTwo/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <PhoneOrPc phone={[<TableMethodologicalWorkOnePart1/>, <TableMethodologicalWorkOnePart2/>,
                                                <TableMethodologicalWorkTwoPart1/>, <TableMethodologicalWorkTwoPart2/>,
                                                <TableMethodologicalWorkThreePart1/>, <TableMethodologicalWorkThreePart2/>]}
                                       pc={[<TableMethodologicalWorkOne/>, <TableMethodologicalWorkTwo/>, <TableMethodologicalWorkThree/>]}/>
                            <TableMethodologicalWorkFour/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default MethodologicalWork;

import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import AxiosClient from "../../../../api/AxiosClient";
import {toJpeg, toPng} from "html-to-image";
import {setIsSend} from "../../../../store/reducers/widgets/WidgetsSlice";

interface IOtherDescription {
    dispatch(value: any): any,
    page: any,
    setPortfolio(value: any): any,
    setSelectEditPagePortfolio(value: boolean): any,
}

const OtherDescription: FC<IOtherDescription> = ({ dispatch, page, setPortfolio, setSelectEditPagePortfolio }) => {
    const [images, setImages] = useState<{ img1: string }>({ img1: '' });
    const ref = useRef<HTMLDivElement>(null);

    const handleCombineImagesOtherDescription = () => {
        AxiosClient.post('get/pattern/portfolio', { nameImage: 'aboutMe.png',  id_type: page?.selectPage?.type_page_portfolio_id, id_pattern: page?.id_pattern})
            .then(r => {
                setImages({img1: r.data.img1});
            })
            .catch(() => console.log('error'));
    };

    useEffect(() => {
        if (images.img1) {
            onButtonClick();
        }
    }, [images.img1]);

    const onButtonClick = useCallback(() => {
        const image = new Image();
        image.src = `data:image/png;base64,${images.img1}`;
        image.onload = () => {
            if (ref.current === null || !images.img1) {
                return;
            }
            toJpeg(ref.current, { cacheBust: true, quality: 0.95 })
                .then((dataUrl) => {
                    fetch(dataUrl)
                        .then(res => res.blob())
                        .then(blob => {
                            const content = {
                                header: page?.header,
                                description: page?.description
                            };
                            const formData = new FormData();
                            formData.append('photo_page', blob);
                            formData.append('id_portfolio', page?.selectPage.portfolio_child_id);
                            formData.append('uid', page?.uid);
                            formData.append('content', JSON.stringify(content));
                            formData.append('photo', '[]');
                            formData.append('list_path_photo', page?.selectPage.photo || []);
                            formData.append('path_photo_page', page?.selectPage.photo_page || '');

                            AxiosClient.post(`update/page/parent/portfolio/${page.selectPage.id}`, formData, { headers: { "Content-Type": 'multipart/form-data' } })
                                .then(r => {
                                    setPortfolio(r.data);
                                    setSelectEditPagePortfolio(false);
                                    dispatch(setIsSend(false))
                                })
                                .catch(error => {
                                    console.error("Ошибка при выгрузке страницы:", error);
                                    dispatch(setIsSend(false))
                                });
                        })
                        .catch(error => console.error('Ошибка при создании Blob:', error));
                })
                .catch((err) => {
                    console.log(err);
                });
        };
    }, [ref, images, page, dispatch, setPortfolio, setSelectEditPagePortfolio]);

    return (
        < div >
            <div
                ref={ref}
                style={{
                    position: 'relative',
                    padding: '10px',
                    height: '1123px',
                    width: '794px',
                    backgroundImage: `url('data:image/png;base64,${images.img1}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <div
                    style={{
                        position: 'relative',
                        zIndex: 2,
                        fontSize: '81px',
                        fontFamily: 'domino',
                        fontWeight: 'bold',
                        color: '#491584',
                        textAlign: 'center',
                        marginLeft: '30px',
                        marginTop: '50px',
                        maxWidth: '700px',
                        padding: '10px',
                    }}
                >
                    <p>{page?.header}</p>
                </div>
                <div
                    style={{
                        position: 'relative',
                        zIndex: 2,
                        fontSize: '32px',
                        fontFamily: 'domino',
                        color: '#491584',
                        marginLeft: '30px',
                        marginTop: '10px',
                        maxWidth: '700px',
                        padding: '20px',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        borderRadius: '10px',
                        boxShadow: '0 0 30px rgba(0, 0, 0, 0.7)',
                    }}
                >
                    <p dangerouslySetInnerHTML={{ __html: page?.description.replace(/\n/g, '<br/>') }} />
                </div>
            </div>
            <button onClick={handleCombineImagesOtherDescription} id='handleCombineImagesOtherDescription'>Click me</button>
        </div>
    );
};

export default OtherDescription;

import React, {FC} from 'react';
import IconFile from "../news/IconFile";

interface Docs {
    url: {url: string, name: string}
}
const Docs: FC<Docs> = ({url}) => {
    return (
        <div className="d-flex border border-dashed p-2 rounded position-relative align-items-center">
            <div className="flex-shrink-0 avatar-xs">
                <div className="avatar-title bg-white rounded">
                    <IconFile name={url.name}/>
                </div>
            </div>
            <div className="flex-shrink-0">
            </div>
            <div className="flex-grow-1 overflow-hidden ms-2">
                <h6 className="text-truncate mb-0"><a href={url.url}  download={url.name}>{url.name}</a></h6>
            </div>
        </div>
    );
};

export default Docs;

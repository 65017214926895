import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {setToUpdateGraphMainPage} from "../../../store/reducers/widgets/WidgetsSlice";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {ProjectsJournalHaccpMorningFilterEmployee} from "../../widgets/ProjectStatusCharts";

const WidgetGraphMorningFilterChildren = () => {
    const {groupAllDeviations, toUpdateGraphMainPage, date, journalHaccpMorningFilterEmployee} = useAppSelector(state => state.WidgetReducer)
    const dispatch = useAppDispatch();

    useEffect(() => {
        toUpdateGraphMainPage &&
        WidgetService.getDeviationGroupMonth(null, date)
            .then(res => {
                dispatch(setToUpdateGraphMainPage(false))
            })
    }, [toUpdateGraphMainPage, date, dispatch])

    return (
        <>
            <Card>
                <CardHeader className="border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Журнал утреннего фильтра сотрулников</h4>
                </CardHeader>

                <CardHeader className="p-0 border-0 bg-soft-light">
                    <Row className="g-0 text-center">
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                         {journalHaccpMorningFilterEmployee.length > 0 ? (
                                             (journalHaccpMorningFilterEmployee.reduce((totalAdmitted, entry) => {
                                                 const admittedCount = entry.employees.filter((child: any) => child.rls !== "Есть" && child.orvi !== "Есть").length;
                                                 return totalAdmitted + admittedCount;
                                             }, 0) / journalHaccpMorningFilterEmployee.length).toFixed()
                                         ) : 0}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Среднее количество здоровых</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {journalHaccpMorningFilterEmployee.length > 0 ? (
                                            (journalHaccpMorningFilterEmployee.reduce((totalAdmitted, entry) => {
                                                const admittedCount = entry.employees.filter((child: any) => child.rls === "Есть").length;
                                                return totalAdmitted + admittedCount;
                                            }, 0) / journalHaccpMorningFilterEmployee.length).toFixed()
                                        ) : 0}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Среднее количество заболевши РЛС</p>
                            </div>
                        </Col>

                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                       {journalHaccpMorningFilterEmployee.length > 0 ? (
                                           (journalHaccpMorningFilterEmployee.reduce((totalAdmitted, entry) => {
                                               const admittedCount = entry.employees.filter((child: any) => child.orvi === "Есть").length;
                                               return totalAdmitted + admittedCount;
                                           }, 0) / journalHaccpMorningFilterEmployee.length).toFixed()
                                       ) : 0}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Среднее количество заболевши  ОРВИ </p>
                            </div>
                        </Col>

                    </Row>
                </CardHeader>

                <CardBody className="p-0 pb-2">
                    <div>
                        {
                            journalHaccpMorningFilterEmployee && <ProjectsJournalHaccpMorningFilterEmployee series={journalHaccpMorningFilterEmployee}
                                                            dataColors='["--vz-green", "--vz-warning", "--vz-danger"]'/>
                        }
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default WidgetGraphMorningFilterChildren;

import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Card, CardBody, CardHeader, Container} from "reactstrap";
import {useParams} from "react-router-dom";
import DataTable from "react-data-table-component";
import AxiosClient from "../../api/AxiosClient";
import moment from "moment";

const GameStatistics = () => {

    const {id} = useParams();
    const [data, setData] = useState<any[]>([]);
    const [dataShot, setDataShot] = useState<any[]>([]);

    useEffect(() => {
        id &&
        AxiosClient.get(`game/get_game_report/${id}`)
            .then((r) => setData(r.data))
            .catch(() => console.error('error'))
    }, [id]);

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Дата / Номер</span>,
            selector: (row: any) => moment(row.date).format('DD.MM.YYYY'),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>ФИО / Название</span>,
            selector: (row: any) => row.name,
            center: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Правильных</span>,
            selector: (row: any) => `${row.true} (${((row.true / row.info.length) * 100).toFixed(2)}%)`,
            center: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Не правильных</span>,
            selector: (row: any) => `${row.error} (${((row.error / row.info.length) * 100).toFixed(2)}%)`,
            center: true,
        }
    ];

    const columnsInfo: any = [
        {
            name: <span className='font-weight-bold fs-13'></span>,
            selector: (row: any, index: number) => <></>,
            width: '48px'
        },
        {
            name: <span className='font-weight-bold fs-13'>Вопрос</span>,
            selector: (row: any, index: number) => index + 1,
        },
        {
            name: <span className='font-weight-bold fs-13'>Название</span>,
            selector: (row: any) => row.name,
            center: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Правильных</span>,
            selector: (row: any) => row.true,
            center: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Не правильных</span>,
            selector: (row: any) => row.error,
            center: true,
        }
    ];

    const columnsDataShot: any = [
        {
            name: <span className='font-weight-bold fs-13'></span>,
            selector: (row: any, index: number) => <></>,
            width: '48px'
        },
        {
            name: <span className='font-weight-bold fs-13'>Вопрос</span>,
            selector: (row: any, index: number) => index + 1,
        },
        {
            name: <span className='font-weight-bold fs-13'>Название</span>,
            selector: (row: any) => row.name,
            center: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Всего</span>,
            selector: (row: any) => row.count,
            center: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Правильных</span>,
            selector: (row: any) => `${row.true} (${((row.true / row.count) * 100).toFixed(2)}%)`,
            center: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Не правильных</span>,
            selector: (row: any) => `${row.error} (${((row.error / row.count) * 100).toFixed(2)}%)`,
            center: true,
        }
    ];

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Статистика по игре</title>
            </Helmet>
            <Container fluid>
                {
                    data.map((el, i) => (
                        <Card key={i}>
                            {
                                el.private ?
                                    <div className="bg-danger h-100 position-absolute rounded-start" style={{width: '5px'}}>
                                    </div>
                                    :
                                    <div className="bg-success h-100 position-absolute rounded-start" style={{width: '5px'}}>
                                    </div>
                            }
                            <CardHeader>
                                <h4>Название игры: {el.game_name}</h4>
                                <h5>Класс: {el.name}</h5>
                                <h5>Тип: {el.private ? 'Приватная' : 'Публичная'}</h5>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <h3>Ответы каждого ученика</h3>
                                    <DataTable
                                        columns={columns}
                                        data={el.data}
                                        pagination
                                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                                        paginationComponentOptions={
                                            {
                                                rowsPerPageText: 'Показать:',
                                                rangeSeparatorText: 'из'
                                            }
                                        }
                                        noDataComponent={'Нет данных'}
                                        defaultSortAsc={false}
                                        highlightOnHover
                                        customStyles={{
                                            rows: {
                                                highlightOnHoverStyle: {
                                                    backgroundColor: "#ffebb5"
                                                }
                                            }
                                        }}
                                        expandableRows
                                        expandableRowsComponent={({data}) => (
                                            <DataTable
                                                columns={columnsInfo}
                                                data={data.info}
                                                noTableHead
                                                noDataComponent={'Нет данных'}
                                                highlightOnHover={true}
                                                customStyles={{
                                                    rows: {
                                                        highlightOnHoverStyle: {
                                                            backgroundColor: "#ffebb5"
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div>
                                    <h3>Статистика ответов по каждому вопросу</h3>
                                    <p className="m-0">Всего вопросов задано: {el.all}</p>
                                    <p className="m-0">Правильно в %: {isNaN(+((el.true / el.all) * 100).toFixed(2)) ? 0.00 : +((el.true / el.all) * 100).toFixed(2)}%</p>
                                    <p className="m-0">Не правильно в %: {isNaN(+((el.error / el.all) * 100).toFixed(2)) ? 0.00 : ((el.error / el.all) * 100).toFixed(2)}%</p>
                                    <DataTable
                                        columns={columnsDataShot}
                                        data={el.data_shot}
                                        pagination
                                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                                        paginationComponentOptions={
                                            {
                                                rowsPerPageText: 'Показать:',
                                                rangeSeparatorText: 'из'
                                            }
                                        }
                                        noDataComponent={'Нет данных'}
                                        defaultSortAsc={false}
                                        highlightOnHover
                                        customStyles={{
                                            rows: {
                                                highlightOnHoverStyle: {
                                                    backgroundColor: "#ffebb5"
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    ))
                }
            </Container>
        </div>
    );
};

export default GameStatistics;

import React, {FC, useEffect, useState} from 'react';
import {IGame} from "../../../models/game/IGame";
import moment from "moment";
import cn from 'classnames'

interface IProps {
    game: IGame,
    setSeconds: (value: number | ((value: number) => number)) => void,
    seconds: number,
    timerStart: boolean,
    setTimerStart: (value: boolean) => void,
    type: 'seconder' | 'timer'
}
const Seconder: FC<IProps> = ({setSeconds, seconds, timerStart, setTimerStart, game, type}) => {

    // const classes = cn('fs-2', {
    //     'text-danger'
    // })

    useEffect(() => {
        let interval: NodeJS.Timer | null = null
        if (timerStart){
            interval = setInterval(() => {
                if (type === "seconder")
                    setSeconds((prevSeconds) => prevSeconds + 1);
                if (type === "timer")
                    setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }


        return () => {
            interval && clearInterval(interval)
        };
    }, [game, timerStart]);
    return (
        <div>
            <p className="fs-2">{moment.utc(moment.duration(seconds, "seconds").asMilliseconds()).format("HH:mm:ss")}</p>
        </div>
    );
};

export default Seconder;

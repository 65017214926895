import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getPositions, getRecommendedActive} from "../../store/reducers/department/ActionCreator";
import {Alert, Col, Container, Row} from "reactstrap";
import SelectToUpdateRecommendedActive
    from "../../components/department/recommendedActive/SelectToUpdateRecommendedActive";
import RecommendedActiveAdd from "../../components/department/recommendedActive/RecommendedActiveAdd";
import RecommendedActiveUpdate from "../../components/department/recommendedActive/RecommendedActiveUpdate";

const SettingsRecommendedActive = () => {
    const dispatch = useAppDispatch();

    const {isError, recommendedActive} = useAppSelector(state => state.DepartmentReducer)

    const [addRecommendedActive, setAddRecommendedActive] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getRecommendedActive())
        dispatch(getPositions())
    }, [dispatch]);


    return (
        <div className="page-content">
            {isError && <Alert className="alert-danger mb-4">{isError}</Alert>}
            <Container fluid>
                <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                    <SelectToUpdateRecommendedActive
                        setAddRecommendedActive={(val) => setAddRecommendedActive(val)}
                        recommendedActive={recommendedActive}/>

                    <div className="w-100 overflow-hidden">
                        <div className="d-lg-flex">
                            <div className="w-100">
                                <div>
                                    <div className="mt-1 mt-sm-1 mt-lg-0 mt-xl-0 mt-xxl-0" id="users-chat">
                                        <Row >
                                            <Col>
                                                {
                                                    addRecommendedActive ? <RecommendedActiveAdd /> : <RecommendedActiveUpdate/>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SettingsRecommendedActive;

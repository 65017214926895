import React from 'react';
import {Container} from "reactstrap";
import FormUpdateNews from "../../components/news/FormUpdateNews";
import {Helmet} from "react-helmet";

const NewsUpdate = () => {
    return (
        <div className="page-content">
            <Container fluid>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Обновление новости</title>
                </Helmet>
                <FormUpdateNews/>
            </Container>
        </div>
    );
};

export default NewsUpdate;

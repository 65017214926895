import React, {FC, useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import {Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import {
    setDateAdd,
    setGroupModalAddDO, setModalAddDO,
    setModalAddEmployee,
    setModalUpdateEmployee
} from "../../../store/reducers/widgets/WidgetsSlice";
import ModalAddEmployee from "../modal/ModalAddEmployee";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import AxiosClient from "../../../api/AxiosClient";
import ModalUpdateEmployee from "../modal/ModalUpdateEmployee";
import ModalSignEmployee from "../modal/ModalSignEmployee";
import moment from "moment/moment";
import {ITableAllInfoGroup} from "../../../models/widgets/ITableAllInfoGroup";

interface WidgetTableAllScoreEmployeeProps {
    date: Date
}
const WidgetTableAllScoreEmployee: FC<WidgetTableAllScoreEmployeeProps> = ({date}) => {
    const dispatch = useAppDispatch();

    const {groupAllDeviations, groupAllDeviationsDO, postSelect} = useAppSelector(state => state.WidgetReducer)

    const [data, setData] = useState<any[]>([]);
    const [dataUpdate, setDataUpdate] = useState<any>();
    const [modalSign, setModalSign] = useState<boolean>(false);


    useEffect(() => {
        postSelect &&
        AxiosClient.get('/get_rezult_employee_list', {params: {date: [moment(date).startOf('month').toDate(), moment(date).endOf('month').toDate()], uid_doljnost: postSelect}})
            .then(r => {
                setData(r.data)
                setDataUpdate(r.data[0])
            })
            .catch(() => console.log('error'))
    }, [date, postSelect]);

    useEffect(() => {
        modalSign &&
            AxiosClient.post('/send_code_employee_sign');
    }, [modalSign]);



    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Дата</span>,
            selector: (row: any) => row.data,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Сумма баллов</span>,
            selector: (row: any) => (row.indicators.map((i: any) => i.max_score).reduce((a: number, b: number) => a + b)),
            center: true,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Зависимые</span>,
            selector: (row: any) => (row.indicators.map((i: any) => i.depend_score).reduce((a: number, b: number) => a + b)),
            center: true,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Независимые</span>,
            selector: (row: any) => (row.indicators.map((i: any) => i.independ_score).reduce((a: number, b: number) => a + b)),
            center: true,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Принятые</span>,
            selector: (row: any) => (row.indicators.map((i: any) => i.depend_score_true + i.independ_score_true).reduce((a: number, b: number) => a + b)),
            center: true,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>% принятых</span>,
            selector: (row: any) => {
                const depend = row.indicators.map((i: any) => i.depend_score_true + i.independ_score_true).reduce((a: number, b: number) => a + b, 0)
                const allScore = row.indicators.map((i: any) => i.max_score).reduce((a: number, b: number) => a + b, 0)
                if (allScore === 0) return 0
                return ((depend / allScore) * 100).toFixed(0)
            },
            center: true,
            sortable: true,
        }
    ];

    return (
        <Card>
            <CardHeader>
                Полная статистика сотрудника
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Показать:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    noDataComponent={'Нет данных'}
                    defaultSortAsc={false}
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                    onRowClicked={
                        (row: ITableAllInfoGroup) => {
                            if(window.innerWidth <= 768){
                                setDataUpdate(row)
                                dispatch(setModalUpdateEmployee(true))
                            }
                        }
                    }
                    onRowDoubleClicked={
                        (row: ITableAllInfoGroup) => {
                            if(window.innerWidth > 768){
                                setDataUpdate(row)
                                dispatch(setModalUpdateEmployee(true))
                            }
                        }
                    }
                />
            </CardBody>
            <CardFooter className="d-flex justify-content-between">
                <div className='d-flex align-items-center'>
                    <div className="button_modal mx-1">
                        <button className="btn btn-primary w-100"
                                onClick={() => dispatch(setModalAddEmployee(true))}>Создать новый документ
                        </button>
                    </div>
                    {/*<div className="button_modal mx-1">*/}
                    {/*    <button className="btn btn-primary w-100"*/}
                    {/*            onClick={() => setModalSign(true)}>Подписать*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
            </CardFooter>
            <ModalUpdateEmployee dates={dataUpdate} setDataUpdate={value => setDataUpdate(value)} setData={(data) => setData(data)}/>
            <ModalAddEmployee setData={(data) => setData(data)}/>
            <ModalSignEmployee modalSign={modalSign} setModalSign={(modalSign) => setModalSign(modalSign)}/>
        </Card>
    );
};

export default WidgetTableAllScoreEmployee;

import React, {FC, useEffect, useState} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import {Card, CardBody} from "reactstrap";
import DataTable from "react-data-table-component";

interface ITableOvzInvYear {
    years: any[]
}
const TableOvzInvYear: FC<ITableOvzInvYear> = ({years}) => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState<any[]>([
        {
            year: 2023,
            data: [
                {
                    org: "Организация 1",
                    year: 2023,
                    ovz: {
                        men: '23',
                        women: '23',
                    },
                    inv: {
                        men: '23',
                        women: '23',
                    }
                },
                {
                    org: "Организация 2",
                    year: 2023,
                    ovz: {
                        men: '23',
                        women: '23',
                    },
                    inv: {
                        men: '23',
                        women: '23',
                    }
                },
                {
                    org: "Организация 3",
                    year: 2023,
                    ovz: {
                        men: '23',
                        women: '23',
                    },
                    inv: {
                        men: '23',
                        women: '23',
                    }
                },
                {
                    org: "Организация 4",
                    year: 2023,
                    ovz: {
                        men: '23',
                        women: '23',
                    },
                    inv: {
                        men: '23',
                        women: '23',
                    }
                },
            ]
        },
        {
            year: 2022,
            data: [
                {
                    org: "Организация 1",
                    year: 2022,
                    ovz: {
                        men: '23',
                        women: '23',
                    },
                    inv: {
                        men: '23',
                        women: '23',
                    }
                },
                {
                    org: "Организация 2",
                    year: 2022,
                    ovz: {
                        men: '23',
                        women: '23',
                    },
                    inv: {
                        men: '23',
                        women: '23',
                    }
                },
                {
                    org: "Организация 3",
                    year: 2022,
                    ovz: {
                        men: '23',
                        women: '23',
                    },
                    inv: {
                        men: '23',
                        women: '23',
                    }
                },
                {
                    org: "Организация 4",
                    year: 2022,
                    ovz: {
                        men: '23',
                        women: '23',
                    },
                    inv: {
                        men: '23',
                        women: '23',
                    }
                },
            ]
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        const selOrg = selectOrganizations.map(s => s.label)
        let year = years.map(s => s.value)
        let d = data.filter(b => year.includes(b.year))
        let newD2: any[] = []
        d.forEach((e) => newD2.push(...e.data))
        if (newD2.length > 0)
            setOutData(newD2.filter(a => selOrg.includes(a?.org)))
    }, [selectOrganizations, years]);

    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Год</span>,
            selector: (row: any) => row.year,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>ОВЗ(М)</span>,
            selector: (row: any) => row.ovz.men,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>ОВЗ(Ж)</span>,
            selector: (row: any) => row.ovz.women,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Инв(М)</span>,
            selector: (row: any) => row.inv.men,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Инв(Ж)</span>,
            selector: (row: any) => row.inv.women,
        }
    ]

    return (
        <Card>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableOvzInvYear;

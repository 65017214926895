import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const TableMtbEvolutionEnvTwo = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState([
        {
            org: "Организация 1",
            roomChildren: '3',
            roomPlayThree: '3',
            roomPlaySeven: '5',
            OOLegalRequirements: 'да',
            CheckAdminOOLegalRequirements: '01.03.2023'
        },
        {
            org: "Организация 2",
            roomChildren: '3',
            roomPlayThree: '3',
            roomPlaySeven: '5',
            OOLegalRequirements: 'да',
            CheckAdminOOLegalRequirements: '01.03.2023'
        },
        {
            org: "Организация 3",
            roomChildren: '3',
            roomPlayThree: '3',
            roomPlaySeven: '5',
            OOLegalRequirements: 'да',
            CheckAdminOOLegalRequirements: '01.03.2023'
        },
        {
            org: "Организация 4",
            roomChildren: '3',
            roomPlayThree: '3',
            roomPlaySeven: '5',
            OOLegalRequirements: 'да',
            CheckAdminOOLegalRequirements: '01.03.2023'
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: typeof data[0]) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Использование площади ДОУ на 1 Воспитанника</span>,
            selector: (row: typeof data[0]) => row.roomChildren,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Площадь групповой(игр.) ком. на 1 ребенка (до 3-х лет)</span>,
            selector: (row: typeof data[0]) => row.roomPlayThree,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Площадь групповой(игр.) ком. на 1 ребенка (3-7 лет)</span>,
            selector: (row: typeof data[0]) => row.roomPlaySeven,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Сайт ОО соотв-ет треб-иям закон-ва. </span>,
            selector: (row: typeof data[0]) => row.OOLegalRequirements,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Дата ревизии адм-ей ОО сайта ОО на соответствие треб-иям закон-ва </span>,
            selector: (row: typeof data[0]) => row.CheckAdminOOLegalRequirements,
            wrap: true
        }
    ]

    return (
        <Card>
            <CardHeader>
                Предметно - развивающая среда
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableMtbEvolutionEnvTwo;

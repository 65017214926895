import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const CertificatesPart1 = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState([
        {
            org: "Организация 1",
            count_oo: '85',
            count: '28',
        },
        {
            org: "Организация 2",
            count_oo: '85',
            count: '28',
        },
        {
            org: "Организация 3",
            count_oo: '85',
            count: '28',
        },
        {
            org: "Организация 4",
            count_oo: '85',
            count: '28',
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true,
            compact: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Число детей в ОО</span>,
            selector: (row: any) => row.count_oo,
            compact: true,
            center: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Число детей</span>,
            selector: (row: any) => row.count,
            compact: true,
            center: true
        }
    ]

    return (
        <Card>
            <CardHeader>
                Имеют справки
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default CertificatesPart1;

import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import TableAchievementDisPersonsAll
    from "../../components/department/AchievementsChildrenDeviations/TableAchievementDisPersonsAll";
import TableAchievementsDisPersonEventsName
    from "../../components/department/AchievementsChildrenDeviations/TableAchievementsDisPersonEventsName";
import TableAchievementOvzAll from "../../components/department/AchievementsChildrenDeviations/TableAchievementOvzAll";
import TableAchievementsOvzEventsName
    from "../../components/department/AchievementsChildrenDeviations/TableAchievementsOvzEventsName";
import GraphIntellect from "../../components/department/AchievementsChildrenDeviations/GraphIntellect";
import GraphCreation from "../../components/department/AchievementsChildrenDeviations/GraphCreation";
import GraphSport from "../../components/department/AchievementsChildrenDeviations/GraphSport";
import GraphParticipant from "../../components/department/AchievementsChildrenDeviations/GraphParticipant";
import Widget from "../../components/department/AchievementsChildrenDeviations/Widget";

const AchievementsChildrenDeviations = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Достижения детей с ОВЗ</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Widget/>
                            </Row>
                            <Row>
                                <Col xxl={6} lg={6}>
                                    <GraphIntellect/>
                                </Col>
                                <Col xxl={6} lg={6}>
                                    <GraphCreation/>
                                </Col>
                                <Col xxl={6} lg={6}>
                                    <GraphSport/>
                                </Col>
                                <Col xxl={6} lg={6}>
                                    <GraphParticipant/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <TableAchievementDisPersonsAll/>
                            <TableAchievementsDisPersonEventsName/>
                            <TableAchievementOvzAll/>
                            <TableAchievementsOvzEventsName/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default AchievementsChildrenDeviations;

import React, {FC, useEffect} from 'react';
import DataTable from "react-data-table-component";
import {IDishes, INutrition} from "../../../models/parent/IFoodRation";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getFoodRation} from "../../../store/reducers/parent/ActionCreator";

interface ITableFoodRation {
    date: Date,
}
const TableFoodRation: FC<ITableFoodRation> = ({date}) => {

    const dispatch = useAppDispatch();
    const {foodRation, child} = useAppSelector(state => state.ParentReducer)

    useEffect(() => {
        dispatch(getFoodRation(date, child)).then()
    }, [date, child, dispatch]);


    const columnsDishes: any = [
        {
            selector: (row: IDishes) => (<span className='font-weight-bold fs-13'>{row.dish} ({row.weight}гр.)</span>),
            wrap: true,
        },
        // {
        //     selector: (row: IDishes) => <span className="fs-13">{row.weight} гр.</span>,
        //     minWidth: "50px",
        //     style: {
        //         justifyContent: 'center'
        //     }
        // },
    ]

    const columnsEating: any = [
        {
            name: <span className='font-weight-bold fs-15'></span>,
            selector: (row: INutrition) => <span className="fs-15">{row.time}</span>,
            wrap: true,
            width: "15%"
        },
        {
            name: <div className='font-weight-bold fs-15 '>
                <div className="d-flex ">
                    <span className='font-weight-bold fs-15 mx-n3'>Блюдо</span>
                </div>
            </div>,
            selector: (row: INutrition) => (
                <DataTable
                    columns={columnsDishes}
                    data={row.dishes}
                    noDataComponent={'Нет данных'}
                    noTableHead
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            ),
            center: true,
            grow: 5,
            wrap: true,
            style: {
                display: 'block'
            }
        }
    ];


    return (
        <DataTable
            columns={columnsEating}
            data={foodRation[0]?.nutrition ? foodRation[0].nutrition : []}
            noDataComponent={'Нет данных'}
            highlightOnHover
            customStyles={{
                rows: {
                    highlightOnHoverStyle: {
                        backgroundColor: "#ffebb5"
                    }
                }
            }}
        />
    );
};

export default TableFoodRation;

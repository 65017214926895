import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
    getJournalHaccpGeneralCleaning, getJournalHaccpMorningFilterEmployee,
    getJournalHaccpVentilationRoom
} from "../../store/reducers/widgets/ActionCreator";
import {Helmet} from "react-helmet";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import WidgetRequiredFlatpikerPeriod from "../../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import WidgetRequiredSelectGroup from "../../components/widgets/requireds/WidgetRequiredSelectGroup";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import {Russian} from "flatpickr/dist/l10n/ru";
import {ParentSlice} from "../../store/reducers/parent/ParentSlice";
import WidgetGraphVentilationRoom from "../../components/journalHaccp/VentilationRoom/WidgetGraphVentilationRoom";
import WidgetTableVentilationRoom from "../../components/journalHaccp/VentilationRoom/WidgetTableVentilationRoom";
import WidgetTableMorningFilterEmployee
    from "../../components/journalHaccp/MorningFilterEmployee/WidgetTableMorningFilterEmployee";
import AxiosClient from "../../api/AxiosClient";
import WidgetGraphMorningFilterEmployee
    from "../../components/journalHaccp/MorningFilterEmployee/WidgetGraphMorningFilterEmployee";

const JournalGeneralCleaning = () => {
    const dispatch = useAppDispatch()
    const {dateMonth, allDeviationChild} = useAppSelector(state => state.ParentReducer)
    const [workDays, setWorkDays] = useState<any>([]);

    const {journalHaccpMorningFilterEmployee, groupAllDeviations, date} = useAppSelector(state => state.WidgetReducer);

    useEffect(() => {
        if(groupAllDeviations){
            dispatch(getJournalHaccpMorningFilterEmployee(date, groupAllDeviations)).then()
            AxiosClient.get('haccp/getWorkDays', {params: {date}})
                .then((r: any) => {
                    setWorkDays(r.data)
                })
                .catch((error) => console.log(error))
        }
    }, [dispatch, date, groupAllDeviations]);

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Журнал утреннего фильтра сотрудников</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col xxl={3}>
                        <WidgetRequiredFlatpikerPeriod/>
                        <WidgetRequiredSelectGroup/>
                        <div className="col">
                            <Card>
                                <CardBody>
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker d-none"
                                        value={[moment(date[0]).toDate(), moment(date[1]).toDate()]}
                                        options={{
                                            locale: Russian,
                                            disableMobile: true,
                                            inline: true,
                                            maxDate: new Date(),
                                        }}
                                        onMonthChange={(dates, currentDateString, self, data) => {
                                            dispatch(ParentSlice.actions.setDate(moment().month(self.currentMonth).year(self.currentYear).startOf('month').toDate()));
                                        }}
                                        onDayCreate={(dates, currentDateString, self, data) => {
                                            const dateStr = moment(data.dateObj).format('DD.MM.YYYY');
                                            const hasTemperatureData = journalHaccpMorningFilterEmployee && journalHaccpMorningFilterEmployee.some((el) => el.data === dateStr);

                                            const isWorkDay = workDays.includes(dateStr);
                                            const isPastDate = data.dateObj < new Date();

                                            data.classList.add('border-2', 'border-white', 'rounded-1', 'shadow-none');

                                            if (hasTemperatureData) {
                                                data.style.backgroundColor = 'rgba(75, 56, 179, 0.5)';
                                                data.classList.add('text-white');
                                            } else if (isWorkDay && isPastDate) {
                                                data.style.backgroundColor = 'rgba(255, 0, 0, 0.5)';
                                                data.classList.add('text-white');
                                            }
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                    <Col>
                        <WidgetGraphMorningFilterEmployee/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="card-height-100">
                            <CardBody >
                                <WidgetTableMorningFilterEmployee />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default JournalGeneralCleaning;

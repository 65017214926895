import React, {FC} from 'react';
import {Link} from "react-router-dom";
import IconFile from "./IconFile";
import {Col, Row} from "reactstrap";
interface IAddOtherDoc {
    url: {url: string, name: string}
}
const NewsDocs: FC<IAddOtherDoc> = ({url}) => {

    const download = (urls: {url: string, name: string}) => {
        const link = document.createElement('a');
        link.href = `${process.env.REACT_APP_API_BASE_URL}/api/download?file=${urls.url}&nameFile=${urls.name}`;
        link.setAttribute('download', urls.name);
        document.body.appendChild(link);
        link.click();
    }

    return (
        <Row>
            <Col xs={9} xl={12}>
                <div className="d-flex border border-dashed p-2 rounded position-relative mt-1">
                    <div className="flex-shrink-0 avatar-xs">
                        <div className="avatar-title bg-white rounded">
                            {
                                <IconFile name={url.name}/>
                            }
                        </div>
                    </div>
                    <div className="flex-shrink-0">

                    </div>
                    <div className="flex-grow-1 overflow-hidden ms-2">
                        <h6 className="text-truncate mb-0"><Link to={"#"} className="stretched-link" onClick={() => download(url)}>{url.name}</Link></h6>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default NewsDocs;

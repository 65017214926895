import React, {FC, useState} from 'react';
import {Alert, Modal, ModalBody, ModalHeader} from "reactstrap";
import {useAppSelector} from "../../../hooks/redux";
import Breakfast from "./FormDailyReport/Breakfast";
import SecondBreakfast from "./FormDailyReport/SecondBreakfast";
import Dinner from "./FormDailyReport/Dinner";
import Snack from "./FormDailyReport/Snack";
import Brunch from "./FormDailyReport/Brunch";
import SecondBrunch from "./FormDailyReport/SecondBrunch";

interface IModalAddDayliReportNutrition {
    modalAdd: boolean,
    setModalAdd: (modalAdd: boolean) => void
}
const ModalAddDayliReportNutrition: FC<IModalAddDayliReportNutrition> = ({modalAdd, setModalAdd}) => {

    const {isError, successUpdate} = useAppSelector(state => state.WidgetReducer);

    const [nutrition, setNutrition] = useState('breakfast');

    const nutritions = (nutrition: string) => {
        switch (nutrition) {
            case 'breakfast':
                return <Breakfast setModalAdd={(value) => setModalAdd(value)}/>
            case 'second_breakfast':
                return <SecondBreakfast setModalAdd={(value) => setModalAdd(value)}/>
            case 'dinner':
                return <Dinner setModalAdd={(value) => setModalAdd(value)}/>
            case 'snack':
                return <Snack setModalAdd={(value) => setModalAdd(value)}/>
            case 'brunch':
                return <Brunch setModalAdd={(value) => setModalAdd(value)}/>
            case 'second_brunch':
                return <SecondBrunch setModalAdd={(value) => setModalAdd(value)}/>
        }
    }

    return (
        <Modal id="createProjectModal" isOpen={modalAdd} toggle={() => setModalAdd(!modalAdd)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setModalAdd(!modalAdd)} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Отчет за день(питание)
            </ModalHeader>
            <ModalBody>
                {successUpdate && <Alert>{successUpdate}</Alert>}
                {isError && <Alert color="danger">{isError}</Alert>}
                <div className="mb-3">
                    <label>Прием пищи:</label>
                    <select className="form-select" value={nutrition} onChange={(e) => setNutrition(e.target.value)}>
                        <option value="breakfast">Завтрак</option>
                        <option value="second_breakfast">Второй завтрак</option>
                        <option value="dinner">Обед</option>
                        <option value="snack">Полдник</option>
                        <option value="brunch">Ужин</option>
                        <option value="second_brunch">Второй ужин</option>
                    </select>
                </div>
                {
                    nutritions(nutrition)
                }
            </ModalBody>
        </Modal>
    );
};

export default ModalAddDayliReportNutrition;

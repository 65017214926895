import {BlockType, CUSTOM_STYLE_MAP, InlineStyle} from './config';
import {convertFromHTML, convertToHTML} from "draft-convert";

export const stateToHTML = convertToHTML<InlineStyle, BlockType>({
    styleToHTML: (style) => {
        switch (style) {
            case InlineStyle.BOLD:
                return <strong />;
            case InlineStyle.ACCENT:
                return <span className="accent" style={CUSTOM_STYLE_MAP[InlineStyle.ACCENT]} />;
            case InlineStyle.RED:
                return <span style={CUSTOM_STYLE_MAP.RED}/>;
            case InlineStyle.GREEN:
                return <span style={CUSTOM_STYLE_MAP.GREEN}/>;
            case InlineStyle.BLUE:
                return <span style={CUSTOM_STYLE_MAP.BLUE}/>;
            case InlineStyle.ORANGE:
                return <span style={CUSTOM_STYLE_MAP.ORANGE}/>;
            case InlineStyle.PURPLE:
                return <span style={CUSTOM_STYLE_MAP.PURPLE}/>;
            case InlineStyle.YELLOW:
                return <span style={CUSTOM_STYLE_MAP.YELLOW}/>;
            case InlineStyle.BLACK:
                return <span style={CUSTOM_STYLE_MAP.BLACK}/>;
            case InlineStyle.WHITE:
                return <span style={CUSTOM_STYLE_MAP.WHITE}/>;
            case InlineStyle.GRAY:
                return <span style={CUSTOM_STYLE_MAP.GRAY}/>;
            case InlineStyle.PINK:
                return <span style={CUSTOM_STYLE_MAP.PINK}/>;
            default:
                return null;
        }
    },
    blockToHTML: (block) => {
        switch (block.type) {
            case BlockType.h1:
                return <h1 style={{fontSize: 100}} />;
            case BlockType.h2:
                return <h2 style={{fontSize: 85}} />;
            case BlockType.h3:
                return <h3 style={{fontSize: 65}} />;
            case BlockType.h4:
                return <h4 style={{fontSize: 45}} />;
            case BlockType.h5:
                return <h5 style={{fontSize: 35}} />;
            case BlockType.h6:
                return <h6 style={{fontSize: 25}} />;
            case BlockType.blockquote:
                return <blockquote />;
            case BlockType.code:
                return <pre />;
            case BlockType.list:
                return <ul />;
            case BlockType.orderList:
                return <ol />;
            case BlockType.cite:
                return <cite />;
            case BlockType.default:
                return <p />;
            default:
                return null;
        }
    },
});

export const HTMLtoState = convertFromHTML<DOMStringMap, BlockType>({
    htmlToStyle: (nodeName, node, currentStyle) => {
        if (nodeName === 'strong') {
            return currentStyle.add(InlineStyle.BOLD);
        }
        if (nodeName === 'underline') {
            return currentStyle.add(InlineStyle.UNDERLINE);
        }
        if (nodeName === 'span' && node.style.color.includes('red')) {
            return currentStyle.add(InlineStyle.RED);
        }
        if (nodeName === 'span' && node.style.color.includes('green')) {
            return currentStyle.add(InlineStyle.GREEN);
        }
        if (nodeName === 'span' && node.style.color.includes('blue')) {
            return currentStyle.add(InlineStyle.BLUE);
        }
        if (nodeName === 'span' && node.style.color.includes('orange')) {
            return currentStyle.add(InlineStyle.ORANGE);
        }
        if (nodeName === 'span' && node.style.color.includes('purple')) {
            return currentStyle.add(InlineStyle.PURPLE);
        }
        if (nodeName === 'span' && node.style.color.includes('yellow')) {
            return currentStyle.add(InlineStyle.YELLOW);
        }
        if (nodeName === 'span' && node.style.color.includes('black')) {
            return currentStyle.add(InlineStyle.BLACK);
        }
        if (nodeName === 'span' && node.style.color.includes('white')) {
            return currentStyle.add(InlineStyle.WHITE);
        }
        if (nodeName === 'span' && node.style.color.includes('gray')) {
            return currentStyle.add(InlineStyle.GRAY);
        }
        if (nodeName === 'span' && node.style.color.includes('pink')) {
            return currentStyle.add(InlineStyle.PINK);
        }
        if (nodeName === 'span' && node.classList.contains('accent')) {
            return currentStyle.add(InlineStyle.ACCENT);
        }

        return currentStyle;
    },
    htmlToBlock: (nodeName, node) => {
        switch (nodeName) {
            case 'h1':
                return BlockType.h1;
            case 'h2':
                return BlockType.h2;
            case 'h3':
                return BlockType.h3;
            case 'h4':
                return BlockType.h4;
            case 'h5':
                return BlockType.h5;
            case 'h6':
                return BlockType.h6;
            case 'div':
                break;
            case 'p':
                return BlockType.default;
            default:
                return null as any;
        }
    },
});
import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import WidgetRequiredFlatpikerPeriod from "../../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import SelectOrganization from "../../components/department/SelectOrganization";
import GraphAdministrativeControlRatings
    from "../../components/department/AdministrativeControl/GraphAdministrativeControlRatings";
import GraphAdministrativeControlTask
    from "../../components/department/AdministrativeControl/GraphAdministrativeControlTask";
import TableAdministrativeControlRatings
    from "../../components/department/AdministrativeControl/controlRating/TableAdministrativeControlRatings";
import TableAdministrativeControlSubjectCheck
    from "../../components/department/AdministrativeControl/subjectCheck/TableAdministrativeControlSubjectCheck";
import TableAdministrativeControlRatingSubjectCheckOrganization
    from "../../components/department/AdministrativeControl/controlRatingSubjectCheckOrganization/TableAdministrativeControlRatingSubjectCheckOrganization";
import ControlRatingSubjectCheckOrganizationEmployee
    from "../../components/department/AdministrativeControl/controlRatingSubjectCheckOrganizationEmployee/ControlRatingSubjectCheckOrganizationEmployee";

const AdministrativeControl = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Административный контроль</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <WidgetRequiredFlatpikerPeriod/>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Col xxl={6} xl={6}>
                                    <GraphAdministrativeControlRatings/>
                                </Col>
                                <Col xxl={6} xl={6}>
                                    <GraphAdministrativeControlTask/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <TableAdministrativeControlRatings/>
                            <TableAdministrativeControlSubjectCheck/>
                            <TableAdministrativeControlRatingSubjectCheckOrganization/>
                            <ControlRatingSubjectCheckOrganizationEmployee/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default AdministrativeControl;

import {AppDispatch} from "../../store";
import {WidgetsSlice} from "./WidgetsSlice";
import WidgetService from "../../../api/services/widgetService/WidgetService";

export const getWidget = (path: string) => async (dispatch: AppDispatch) => {
    try{
        dispatch(WidgetsSlice.actions.dataLoading());
        const response = await WidgetService.getWidgets(path).then(res => res.data)
        dispatch(WidgetsSlice.actions.setLoadingWidgets(response.widgets))
    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const changeWidth = (path: string, width: string, id: number) => async (dispatch: AppDispatch) => {
    try{
        await WidgetService.changeWidth(path, width, id);
        dispatch(WidgetsSlice.actions.dataLoading());
        const response = await WidgetService.getWidgets(path).then(res => res.data)
        dispatch(WidgetsSlice.actions.setLoadingWidgets(response.widgets))
    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const getStudents = (date: Date, group: string, group_do: string = "null") => async (dispatch: AppDispatch) => {
    try{
        dispatch(WidgetsSlice.actions.dataLoading());
        if(group_do === "null"){
            const response = await WidgetService.getStudents(date, group, group_do).then(res => res.data)
            dispatch(WidgetsSlice.actions.setLoadingStudents(response))
        }
        else{
            const response = await WidgetService.getStudents(date, group, group_do).then(res => res.data)
            dispatch(WidgetsSlice.actions.setLoadingStudentsDO(response))
        }

    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const getGroups = (date: Date[], group_do: string = 'null') => async (dispatch: AppDispatch) => {
    try{
        dispatch(WidgetsSlice.actions.dataLoading());
        if (group_do === "null"){
            const response = await WidgetService.getUserGroup(date, group_do).then(res => res.data)
            dispatch(WidgetsSlice.actions.setGroupModalAdd(response[0].group_id))
            dispatch(WidgetsSlice.actions.setGroupModalSign(response[0].group_id))
            dispatch(WidgetsSlice.actions.setGroupAllDeviations(response[0].group_id))
            dispatch(WidgetsSlice.actions.setLoadingGroups(response))
        }
        else {
            const response = await WidgetService.getUserGroup(date, group_do).then(res => res.data)
            dispatch(WidgetsSlice.actions.setGroupModalAddDO(response[0].group_id))
            dispatch(WidgetsSlice.actions.setGroupModalSignDO(response[0].group_id))
            dispatch(WidgetsSlice.actions.setGroupAllDeviationsDO(response[0].group_id))
            dispatch(WidgetsSlice.actions.setLoadingGroupsDO(response))
        }

    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const getAllInfoGroup = (date: Date[]) => async (dispatch: AppDispatch) => {
    try{
        dispatch(WidgetsSlice.actions.dataLoading());
        const response = await WidgetService.getAllInfoGroup(date).then(res => res.data)
        dispatch(WidgetsSlice.actions.setSuccessAllInfoGroup(response))
    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const getAllInfoGroupDo = (date: Date[]) => async (dispatch: AppDispatch) => {
    try{
        dispatch(WidgetsSlice.actions.dataLoading());
        const response = await WidgetService.getAllInfoGroup(date, "True").then(res => res.data)
        dispatch(WidgetsSlice.actions.setSuccessAllInfoGroupDo(response))
    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const getAllInfoPost = (date: Date[]) => async (dispatch: AppDispatch) => {
    try{
        dispatch(WidgetsSlice.actions.dataLoading());
        const response = await WidgetService.getUserPost(date).then(res => res.data)
        dispatch(WidgetsSlice.actions.setLoadingPosts(response))
        dispatch(WidgetsSlice.actions.setPostSelect(response[0].uid))
    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const setAttendance = (data: object, group: string, date: Date) => async (dispatch: AppDispatch) => {
    try{
        dispatch(WidgetsSlice.actions.dataLoading());
        await WidgetService.setAttendance(data);
        const response = await WidgetService.getStudents(date, group, "null").then(res => res.data)
        dispatch(WidgetsSlice.actions.setLoadingStudents(response))
        dispatch(WidgetsSlice.actions.successUpdate("Успешно обновленно"))
    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const setAttendanceDO = (data: object, group: string, date: Date) => async (dispatch: AppDispatch) => {
    try{
        dispatch(WidgetsSlice.actions.dataLoading());
        await WidgetService.setAttendance(data);
        const response = await WidgetService.getStudents(date, group, "True").then(res => res.data)
        dispatch(WidgetsSlice.actions.setLoadingStudentsDO(response))
        dispatch(WidgetsSlice.actions.successUpdate("Успешно обновленно"))
    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const getChildrenAttendanceDataBase = (date: Date, group: string) => async (dispatch: AppDispatch) => {
    try{
        dispatch(WidgetsSlice.actions.dataLoading());
        const response = await WidgetService.getChildrenAttendances(date, group).then(res => res.data)
        dispatch(WidgetsSlice.actions.setSuccessChildrenAttendance(response))
        dispatch(WidgetsSlice.actions.successUpdate("Успешно обновленно"))
    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const getDailyReportNutritional = (date: Date[], group: string) => async (dispatch: AppDispatch) => {
    try{
        dispatch(WidgetsSlice.actions.dataLoading());
        const response = await WidgetService.getAllDailyReportsNutrition(date, group).then(res => res.data)
        dispatch(WidgetsSlice.actions.setSuccessAllDailyReportNutrition(response))

    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const deleteDailyReportNutritional = (date: Date[], deleteDate: Date, group: string) => async (dispatch: AppDispatch) => {
    try{
        await WidgetService.deleteDailyReportsNutrition(deleteDate, group).then()
        dispatch(WidgetsSlice.actions.dataLoading());
        const response = await WidgetService.getAllDailyReportsNutrition(date, group).then(res => res.data)
        dispatch(WidgetsSlice.actions.setSuccessAllDailyReportNutrition(response))

    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const getDailyReportSleep = (date: Date[], group: string) => async (dispatch: AppDispatch) => {
    try{
        dispatch(WidgetsSlice.actions.dataLoading());
        const response = await WidgetService.getAllDailyReportsSleep(date, group).then(res => res.data)
        dispatch(WidgetsSlice.actions.setSuccessAllDailyReportSleep(response))

    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const deleteDailyReportSleep = (date: Date[], deleteDate: Date, group: string) => async (dispatch: AppDispatch) => {
    try{
        await WidgetService.deleteDailyReportsSleep(deleteDate, group).then()
        dispatch(WidgetsSlice.actions.dataLoading());
        const response = await WidgetService.getAllDailyReportsSleep(date, group).then(res => res.data)
        dispatch(WidgetsSlice.actions.setSuccessAllDailyReportSleep(response))

    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const getDailyReportMood = (date: Date[], group: string) => async (dispatch: AppDispatch) => {
    try{
        dispatch(WidgetsSlice.actions.dataLoading());
        const response = await WidgetService.getAllDailyReportsMood(date, group).then(res => res.data)
        dispatch(WidgetsSlice.actions.setSuccessAllDailyReportMood(response))

    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const deleteDailyReportMood = (date: Date[], deleteDate: Date, group: string) => async (dispatch: AppDispatch) => {
    try{
        await WidgetService.deleteDailyReportsMood(deleteDate, group).then()
        dispatch(WidgetsSlice.actions.dataLoading());
        const response = await WidgetService.getAllDailyReportsMood(date, group).then(res => res.data)
        dispatch(WidgetsSlice.actions.setSuccessAllDailyReportMood(response))
    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const getJournalHaccpTemperature = (date: Date[], group_id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(WidgetsSlice.actions.dataLoading())
        const response = await WidgetService.getJournalHaccpTemperature(date, group_id).then(res => res.data)
        dispatch(WidgetsSlice.actions.setSuccessJournalHaccpTemperature(response))
    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const getJournalHaccpVentilationRoom = (date: Date[], group_id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(WidgetsSlice.actions.dataLoading())
        const response = await WidgetService.getJournalHaccpVentilationRoom(date, group_id).then(res => res.data)
        dispatch(WidgetsSlice.actions.setSuccessJournalHaccpVentilationRoom(response))
    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const getJournalHaccpGeneralCleaning = (date: Date[], group_id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(WidgetsSlice.actions.dataLoading())
        const response = await WidgetService.getJournalHaccpGeneralCleaning(date, group_id).then(res => res.data)
        dispatch(WidgetsSlice.actions.setSuccessJournalHaccpGeneralCleaning(response))
    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}

export const getJournalHaccpMorningFilterEmployee = (date: Date[], group_id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(WidgetsSlice.actions.dataLoading())
        const response = await WidgetService.getJournalHaccpMorningFilterEmployee(date, group_id).then(res => res.data)
        dispatch(WidgetsSlice.actions.setSuccessJournalHaccpMorningFilterEmployee(response))
    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}
export const getJournalHaccpMorningFilterChildren = (date: Date[], group_id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(WidgetsSlice.actions.dataLoading())
        const response = await WidgetService.getJournalHaccpMorningFilterChildren(date, group_id).then(res => res.data)
        dispatch(WidgetsSlice.actions.setSuccessJournalHaccpMorningFilterChildren(response))
    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}
export const getJournalHaccpReplacementBedLinen = (date: Date[], group_id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(WidgetsSlice.actions.dataLoading())
        const response = await WidgetService.getJournalHaccpReplacementBedLinen(date, group_id).then(res => res.data)
        dispatch(WidgetsSlice.actions.setSuccessJournalHaccpReplacementBedLinen(response))
    }catch (e: any){
        dispatch(WidgetsSlice.actions.dataLoadingError(e.message))
    }
}
import {AxiosResponse} from "axios";
import $api from "../../AxiosClient";
import {IDoGroup} from "../../../models/IDoGroup";

class DoGroupService{
    static async getDoGroup(): Promise<AxiosResponse<IDoGroup[]>>{
        return $api.post<IDoGroup[]>("/do-groups");
    }

    static async addDoGroup(value: object): Promise<AxiosResponse<void>>{
        return $api.post<void>("/do-group/add", value);
    }

    static async updateDoGroup(value: object, id: number): Promise<AxiosResponse<void>>{
        return $api.post<void>(`/do-group/update/${id}`, value);
    }
}

export default DoGroupService
import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Card, CardBody, CardHeader, Col, Label, Nav, NavItem, NavLink, Row} from "reactstrap";
import {useAppDispatch} from "../../../hooks/redux";
import {addLimitHealth} from "../../../store/reducers/department/ActionCreator";

const LimitHealthAdd = () => {

    const dispatch = useAppDispatch();

    return (
        <>
            <Formik
                initialValues={
                    {
                        name: ''
                    }
                }
                validationSchema={
                    Yup.object(
                        {
                            name: Yup.string().required('Введите ограничение здоровья')
                        }
                    )
                }
                onSubmit={
                    (value, {resetForm}) => {
                        dispatch(addLimitHealth(value))
                        resetForm()
                    }
                }
            >
                <Form>
                    <Card className="mx-n3">
                        <CardHeader>
                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                 role="tablist">
                                <NavItem>
                                    <NavLink
                                        className="active">
                                        <i className="fas fa-home"></i>
                                        Данные о ограничении здоровья
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody className="p-4">
                            <Row>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="roleInput" className="form-label">Название
                                            ограничения здоровья</Label>
                                        <Field type="text" className="form-control" id="roleInput"
                                               name="name"
                                               placeholder="Введите ограничение здоровья"/>
                                        <ErrorMessage name="name">{(msg) => <div
                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col lg={12} className="mt-3">
                                    <div className="hstack gap-2 justify-content-end">
                                        <button type="submit"
                                                className="btn btn-primary">Добавить
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Form>
            </Formik>
        </>
    );
};

export default LimitHealthAdd;

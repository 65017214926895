import {AxiosResponse} from "axios";
import {ITypeGroup} from "../../../models/ITypeGroup";
import $api from "../../AxiosClient";

class TypeGroupService {
    static async getTypeGroup(): Promise<AxiosResponse<ITypeGroup[]>>{
        return $api.post<ITypeGroup[]>('/type-groups');
    }

    static async addTypeGroup(value: object): Promise<AxiosResponse<void>>{
        return $api.post<void>("/type-group/add", value)
    }

    static async updateTypeGroup(value: object, typeGroupId: number): Promise<AxiosResponse<void>>{
        return $api.post<void>(`/type-group/update/${typeGroupId}`, value)
    }
}

export default TypeGroupService;
import React, {FC, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import { ProjectStatusChartsDonutOperationalControl} from "../ProjectStatusCharts";
import {useAppSelector} from "../../../hooks/redux";
import moment from "moment";
import AxiosClient from "../../../api/AxiosClient";

interface IWidgetGraphRoundOperationalControl {
    data: any[]
}

const WidgetGraphRoundOperationalControl: FC<IWidgetGraphRoundOperationalControl> = ({data}) => {

    return (
        <>
            <Card>
                <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Нарушения</h4>
                </CardHeader>

                <CardBody>
                    <ProjectStatusChartsDonutOperationalControl series={data}/>
                    {
                        data.length > 0 ?
                            <div className="mt-3">
                                <div className="d-flex justify-content-center align-items-center mb-4">
                                    <div>
                                        <p className="text-muted mb-0">Всего нарушений</p>
                                    </div>
                                    <h2 className="mx-3 ff-secondary mb-0">{data.map(dev => dev.name === 'Отсутствуют' ? 0 : dev.count).reduce((a, b) => a + b)}</h2>

                                </div>

                                {
                                    (data).map((deviation, id) => {
                                        return (
                                            <div key={id}
                                                 className="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                                                <p className="fw-medium mb-0">{deviation.name}</p>
                                                <div>
                                                        <span
                                                            className="text-muted pe-5">{deviation.count} наруш.</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            :
                            <div className="d-flex justify-content-center align-items-center mb-4">
                                <div>
                                    <p className="text-muted mb-0">Данных нет</p>
                                </div>
                            </div>
                    }
                </CardBody>
            </Card>
        </>
    );
};

export default WidgetGraphRoundOperationalControl;

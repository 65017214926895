import React from 'react';
import {Helmet} from "react-helmet";
import Constructor from "../../components/Game/Constructor";
import {Container} from "reactstrap";

const Game = () => {
    return (
        <div className="page-content vh-100">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Игры</title>
            </Helmet>
            <Container fluid className="h-100 pb-5">
                <Constructor/>
            </Container>
        </div>
    );
};

export default Game;

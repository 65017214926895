import React, {useState} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const TableAllWorkExperience = () => {

    const [data, setData] = useState<any[]>([
        {
            in: 'ОУ',
            ToTwoEndFive: '85',
            ToFiveEndTen: '28',
            ToTenEndTwenty: '12',
            EndTwenty: '12',
        },
        {
            in: 'ДОУ',
            ToTwoEndFive: '85',
            ToFiveEndTen: '28',
            ToTenEndTwenty: '12',
            EndTwenty: '12',
        },
        {
            in: 'УДО',
            ToTwoEndFive: '85',
            ToFiveEndTen: '28',
            ToTenEndTwenty: '12',
            EndTwenty: '12',
        }
    ]);

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'></span>,
            selector: (row: any) => row.in,
        },
        {
            name: <span className='font-weight-bold fs-13'>до 5 лет</span>,
            selector: (row: any) => row.ToTwoEndFive,
        },
        {
            name: <span className='font-weight-bold fs-13'>От 5 до 10 лет</span>,
            selector: (row: any) => row.ToFiveEndTen,
        },
        {
            name: <span className='font-weight-bold fs-13'>От 10 до 20 лет</span>,
            selector: (row: any) => row.ToTenEndTwenty,
        },
        {
            name: <span className='font-weight-bold fs-13'>Свыше 20 лет</span>,
            selector: (row: any) => row.EndTwenty,
        },
    ]

    return (
        <Card>
            <CardHeader>
                Стаж работы сотрудников
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={data}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableAllWorkExperience;

import React, {FC} from 'react';
import IconFile from "./IconFile";

interface IUpdateOtherDoc {
    url: {url: string, name: string, origUrl: string},
    deleteFile: (url: string, name?: string) => void
}
const UpdateOtherDoc:FC<IUpdateOtherDoc> = ({url, deleteFile}) => {
    return (
        <div className="d-flex border border-dashed p-2 rounded position-relative">
            <div className="flex-shrink-0 avatar-xs">
                <div className="avatar-title bg-white rounded">
                    <IconFile name={url.name}/>
                </div>
            </div>
            <div className="flex-shrink-0">

            </div>
            <div className="flex-grow-1 overflow-hidden ms-2">
                <h6 className="text-truncate mb-0"><a href={url.url}  download={url.name}>{url.name}</a></h6>
            </div>
            <a href="#" onClick={() => deleteFile(url.origUrl, url.name)}>
                <i className="ri-delete-bin-2-line fs-24"></i>
            </a>
        </div>
    );
};

export default UpdateOtherDoc;

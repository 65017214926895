import React, {FC, Fragment, ReactElement} from 'react';
import useWidth from "../../hooks/useWidth";

interface IPhoneORPC {
    phone: ReactElement[],
    pc: ReactElement[]
}

const PhoneOrPc: FC<IPhoneORPC> = ({phone, pc}) => {

    const {width} = useWidth();

    function out() {
        if (pc.length === 0 ){
            return phone.map((phoneElem, i) => <Fragment key={i}>{phoneElem}</Fragment>)
        }
        if(phone.length === 0){
            return pc.map((pcElem, i) => <Fragment key={i}>{pcElem}</Fragment>)
        }
        if (width > 800){
            return pc.map((pcElem, i) => <Fragment key={i}>{pcElem}</Fragment>)
        }else {
            return phone.map((phoneElem, i) => <Fragment key={i}>{phoneElem}</Fragment>)
        }
    }

    return (
        <>
            {
                out()
            }
        </>
    );
};

export default PhoneOrPc;

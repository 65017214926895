import React, {FC} from 'react';
import bgImage from "../../assets/images/small/img-5.jpg";
import {Card, CardBody, Col, Row} from "reactstrap";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import {Link} from "react-router-dom";
import {IElem} from "../../models/kindergarten/IPortfolio";

interface ICardPortfolioEvent {
    portf: IElem
}
const CardPortfolioEvent: FC<ICardPortfolioEvent> = ({portf}) => {
    return (
        <Card className="team-box">
            <div className="team-cover">
                {
                    portf.mainImage ?
                        <img src={process.env.REACT_APP_API_BASE_URL + portf.mainImage} alt="" className="img-fluid" />
                        :
                        <img src={bgImage} alt="" className="img-fluid" />
                }
            </div>
            <CardBody className="p-4">
                <Row className="align-items-center team-row">
                    <Col lg={4} className="col">
                        <div className="team-profile-img">
                            <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                <img src={avatar1} alt="" className="img-fluid d-block rounded-circle" />
                            </div>
                            <div className="team-content">
                                <Link to="#" ><h5 className="fs-16 mb-1">{portf.theme}</h5></Link>
                                <p className="text-muted mb-0">{portf.description}</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} className="col">
                        <Row className="text-muted text-center">
                            <Col xs={6} className="border-end border-end-dashed">
                                <h5 className="mb-1">{portf.date}</h5>
                                <p className="text-muted mb-0">Дата</p>
                            </Col>
                            <Col xs={6}>
                                <h5 className="mb-1">{portf.children.length}</h5>
                                <p className="text-muted mb-0">Детей</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={2} className="col">
                        <div className="text-end">
                            <Link to={`/parent/children/portfolio/more/${portf.id}`} className="btn btn-light view-btn">Подробнее</Link>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default CardPortfolioEvent;

import React, {FC, useEffect, useState} from 'react';
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';
import {
    Badge, Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle, Modal,
    ModalBody,
    ModalHeader, Row,
    UncontrolledDropdown
} from "reactstrap";
import SettingsTexture from "./SettingsTexture";
import {ISettingsAnswerShot, ISettingsShot, IShot} from "../../../models/game/IShot";
import {ITexture} from "../../../models/game/ITexture";
import {IPersonage} from "../../../models/game/IPersonage";
import {TypeSettings} from "../../../models/game/typesSettings/TypeSettings";
import {ISettingsCollect} from "../../../models/game/ISettingsTexture";
import SettingsPersonage from "./SettingsPersonage";
import {ResizableBox} from "react-resizable";
import "react-resizable/css/styles.css";
import './style.scss'
import {TypeSettingsShot} from "../../../models/game/typesSettings/TypeSettingsShot";

interface IProps {
    shot: IShot,
    openFullShot: boolean,
    handleCloseShot: (shot: IShot) => void,
    openSettings: boolean
}

const FullShot: FC<IProps> = ({shot, openFullShot, handleCloseShot, openSettings}) => {
    const [updateShot, setUpdateShot] = useState(shot);
    const [openSettingsTexture, setOpenSettingsTexture] = useState(false);
    const [openSettingsPersonage, setOpenSettingsPersonage] = useState(false);
    const [selectTexture, setSelectTexture] = useState<ITexture | null>(null);
    const [selectPersonage, setSelectPersonage] = useState<IPersonage | null>(null);
    const [isOpenCopySettings, setIsOpenCopySettings] = useState(false);
    const [textureToCopy, setTextureToCopy] = useState<ITexture[]>([]);
    useEffect(() => {
        setUpdateShot(shot)
    }, [shot]);

    const toggleOpenTextureCopySettings = () => {
        setSelectTexture(null)
        setIsOpenCopySettings(false)
    }
    const handleStopTexture = (e: DraggableEvent, data: DraggableData, texture: ITexture) => {
        const elem = document.getElementById('window')
        const onePercentX = elem?.offsetWidth ? elem.offsetWidth / 100 : 0;
        const onePercentY = elem?.offsetHeight ? elem.offsetHeight / 100 : 0;


        setUpdateShot((shot) => ({
            ...shot,
            textures: shot.textures.map((el) => {
                if (el.pivot.name === texture.pivot.name) {
                    return {
                        ...el,
                        pivot: {
                            ...el.pivot,
                            x: data.x,
                            y: data.y,
                            percentX: +(data.x / onePercentX).toFixed(0),
                            percentY: +(data.y / onePercentY).toFixed(0)
                        }
                    }
                }
                return el;
            })
        }))
    };

    const copyTexture = (texture: ITexture) => {
        const elem = document.getElementById('window')
        const onePercentX = elem?.offsetWidth ? elem.offsetWidth / 100 : 0;
        const onePercentY = elem?.offsetHeight ? elem.offsetHeight / 100 : 0;
        const x =  elem?.offsetWidth ? elem.offsetWidth / 2 : texture.pivot.x
        const y = elem?.offsetHeight ? elem.offsetHeight / 2 : texture.pivot.y
        setUpdateShot((shot) => {
            const numbers = shot.textures.map(obj => {
                const match = obj.pivot.name.match(/\d+/); // Находим число в 'name'
                return match ? parseInt(match[0], 10) : 0; // Преобразуем в число или возвращаем 0
            });
            const maxNumber = numbers.length > 0 ? Math.max(...numbers) + 1 : 1;
            return {
                ...shot,
                textures: [...shot.textures, {
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        name: 'Текстура' + maxNumber,
                        x: x,
                        y: y,
                        percentX: +(x / onePercentX).toFixed(0),
                        percentY: +(y / onePercentY).toFixed(0)
                    }
                }]
            }
        })
    };

    const handleStopWords = (e: DraggableEvent, data: DraggableData) => {
        const elem = document.getElementById('window')
        const onePercentX = elem?.offsetWidth ? elem.offsetWidth / 100 : 0;
        const onePercentY = elem?.offsetHeight ? elem.offsetHeight / 100 : 0;

        setUpdateShot((shot) => {
            const { settings } = shot;
            if  (settings) {
                return {
                    ...shot,
                    settings: {
                        ...settings,
                        ...(settings.settings && {
                            settings: {
                                ...settings.settings,
                                x: data.x,
                                y: data.y,
                                percentX: +(data.x / onePercentX).toFixed(0),
                                percentY: +(data.y / onePercentY).toFixed(0)
                            }
                        }),
                    }
                };
            }
            return shot
        });
    };

    const handleStopPersonage = (e: DraggableEvent, data: DraggableData, personage: IPersonage) => {
        const elem = document.getElementById('window')
        const onePercentX = elem?.offsetWidth ? elem.offsetWidth / 100 : 0;
        const onePercentY = elem?.offsetHeight ? elem.offsetHeight / 100 : 0;

        setUpdateShot((shot) => ({
            ...shot,
            personages: shot.personages.map((el) => {
                if (el.id === personage.id) {
                    return {
                        ...el,
                        pivot: {
                            ...el.pivot,
                            x: data.x,
                            y: data.y,
                            percentX: +(data.x / onePercentX).toFixed(0),
                            percentY: +(data.y / onePercentY).toFixed(0)
                        }
                    }
                }
                return el;
            })
        }))
    };

    const handleOpenSettingsTexture = () => {
        setOpenSettingsTexture(!openSettingsTexture)
    }

    const handleOpenSettingsPersonage = () => {
        setOpenSettingsPersonage(!openSettingsPersonage)
    }

    const saveSettingsTexture = (texture: ITexture) => {
        if (texture.pivot.settings.type === TypeSettings.COLLECT) {
            const textures = (texture.pivot.settings.settings as ISettingsCollect).textures.map(el => ({
                ...el,
                pivot: {
                    ...el.pivot,
                    settings: {
                        ...el.pivot.settings,
                        type: TypeSettings.SELECT_COLLECT,
                        settings: {
                            parent: texture
                        }
                    }
                }
            } as ITexture))
            setUpdateShot((shot) => ({
                ...shot,
                textures: shot.textures.map((el) => {
                    if (el.pivot.name === texture.pivot.name) {
                        return texture
                    }
                    const t = textures.find((t) => t.pivot.name === el.pivot.name)
                    if (t) {
                        return t
                    }
                    return el
                })
            }))
        } else {
            setUpdateShot((shot) => ({
                ...shot,
                textures: shot.textures.map((el) => {
                    if (el.pivot.name === texture.pivot.name) {
                        return texture
                    }
                    return el
                })
            }))
        }
    }

    const saveSettingsPersonage = (personage: IPersonage) => {
        setUpdateShot((shot) => ({
            ...shot,
            personages: shot.personages.map((el) => {
                if (el.id === personage.id) {
                    return personage
                }
                return el
            })
        }))
    }

    const removeTexture = (texture: ITexture) => {
        setUpdateShot((shot) => ({
            ...shot,
            textures: shot.textures.filter(el => el.pivot.name !== texture.pivot.name)
        }))
    }

    const removePersonage = (personage: IPersonage) => {
        setUpdateShot((shot) => ({
            ...shot,
            personages: shot.personages.filter(el => el.id !== personage.id)
        }))
    }

    const defaultPosition = (percentX: number, percentY: number) => {
        const elem = document.getElementById('window')
        const onePercentX = elem?.offsetWidth ? elem.offsetWidth / 100 : 0;
        const onePercentY = elem?.offsetHeight ? elem.offsetHeight / 100 : 0;

        const x = onePercentX * percentX
        const y = onePercentY * percentY

        return {x, y}
    }

    const copySettings = () => {
        selectTexture &&
        setUpdateShot((shot) => (
            {
                ...shot,
                textures: shot.textures.map(texture => {
                    if (textureToCopy.find(el => el.pivot.name === texture.pivot.name)) {
                        return {
                            ...texture,
                            pivot: {
                                ...texture.pivot,
                                settings: selectTexture.pivot.settings,
                            },
                        }
                    }
                    return texture
                })
            }
        ))
        setIsOpenCopySettings(false)
        setSelectTexture(null)
        setTextureToCopy([])
    }

    return (
        openFullShot && !openSettings ? (
            <div className="position-absolute top-0 start-0 h-100 w-100 bg-white col-xxl-3"
                 style={shot.backgroundImage ? {
                     backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL + shot.backgroundImage.path})`,
                     backgroundSize: '100% 100%',
                     backgroundPosition: 'center'
                 } : {}}>
                {
                    !openSettingsTexture &&
                    <Badge color="primary" className='fs-20 cursor-pointer'
                           style={{position: 'absolute', top: '-25px', left: '10px'}}
                           onClick={() => handleCloseShot(updateShot)}>
                        Сохранить кадр
                    </Badge>
                }
                {updateShot.textures.map((el, index) => (
                    <Draggable
                        key={index}
                        defaultPosition={defaultPosition(el.pivot.percentX, el.pivot.percentY)}
                        bounds="parent"
                        onStop={(e, data) => {
                            handleStopTexture(e, data, el)
                        }}
                    >
                        <div className="position-absolute"
                            style={el.pivot.settings.type === TypeSettings.COLLECT ? {zIndex: 10} : {zIndex: 5}}
                        >
                            <UncontrolledDropdown
                                className="cursor-pointer position-absolute top-0 end-0"
                                direction="end"
                                style={{zIndex: 10}}
                            >
                                <DropdownToggle
                                    color="primary"
                                >
                                    <i className="bx bx-cog fs-20"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => {
                                        handleOpenSettingsTexture()
                                        setSelectTexture(el)
                                    }}>
                                        Редактировать
                                    </DropdownItem>
                                    <DropdownItem onClick={() => {
                                        setIsOpenCopySettings(true)
                                        setSelectTexture(el)
                                    }}>
                                        Копировать настройки
                                    </DropdownItem>
                                    <DropdownItem onClick={() => {
                                        copyTexture(el)
                                    }}>
                                        Копировать
                                    </DropdownItem>
                                    <DropdownItem onClick={() => removeTexture(el)}>
                                        Удалить
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <ResizableBox
                                width={el.pivot.settings.width}
                                height={el.pivot.settings.height}
                                minConstraints={[50, 50]} // Минимальные размеры
                                className="custom-resizable-box"
                                onResize={(w, h) => {
                                    setUpdateShot((shot) => ({
                                        ...shot,
                                        textures: shot.textures.map((t) => {
                                            if (el.id === t.id) {
                                                return {
                                                    ...t,
                                                    pivot: {
                                                        ...t.pivot,
                                                        settings: {
                                                            ...t.pivot.settings,
                                                            width: h.size.width,
                                                            height: h.size.height
                                                        }
                                                    }
                                                }
                                            }
                                            return t
                                        })
                                    }))
                                }}
                                resizeHandles={['nw', 'n', 'w']} // Изменение размера за правый нижний угол
                            >
                                <div className="w-100 h-100">
                                    <div className="position-absolute w-100 h-100">
                                    </div>
                                    <img src={process.env.REACT_APP_API_BASE_URL + el.path} alt=""
                                         style={{width: "100%", height: "100%"}}/>
                                </div>
                            </ResizableBox>
                        </div>
                    </Draggable>
                ))}
                {
                    updateShot.settings &&
                    (updateShot.settings).type === TypeSettingsShot.ANSWER &&
                    <Draggable
                        bounds="parent"
                        defaultPosition={defaultPosition(((updateShot.settings as ISettingsShot).settings as ISettingsAnswerShot).percentX, ((updateShot.settings as ISettingsShot).settings as ISettingsAnswerShot).percentY)}
                        onStop={(e, data) => handleStopWords(e, data)}
                    >
                        <div className="position-absolute">
                            <ResizableBox
                                width={((updateShot.settings as ISettingsShot).settings as ISettingsAnswerShot).width}
                                height={((updateShot.settings as ISettingsShot).settings as ISettingsAnswerShot).height}
                                minConstraints={[100, 100]} // Минимальные размеры
                                maxConstraints={[700, 700]} // Максимальные размеры
                                className="custom-resizable-box"
                                onResize={(w, h) => {
                                    setUpdateShot((shot) => {
                                        const { settings } = shot;
                                        if  (settings) {
                                            return {
                                                ...shot,
                                                settings: {
                                                    ...settings,
                                                    ...(settings.settings && {
                                                        settings: {
                                                            ...settings.settings,
                                                            width: h.size.width,
                                                            height: h.size.height
                                                        }
                                                    }),
                                                }
                                            };
                                        }
                                        return shot
                                    });
                                }}
                                resizeHandles={['nw', 'n', 'w']} // Изменение размера за правый нижний угол
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: (updateShot.settings.settings as ISettingsAnswerShot).html
                                    }}
                                />
                            </ResizableBox>
                        </div>
                    </Draggable>
                }
                {updateShot.personages.map((el, index) => (
                    <Draggable
                        key={index}
                        bounds="parent"
                        defaultPosition={defaultPosition(el.pivot.percentX, el.pivot.percentY)}
                        onStop={(e, data) => handleStopPersonage(e, data, el)}
                    >
                        <div className="position-absolute"
                        >
                            <UncontrolledDropdown
                                className="cursor-pointer position-absolute top-0 end-0"
                                direction="end"
                                style={{zIndex: 10}}
                            >
                                <DropdownToggle
                                    color="primary"
                                >
                                    <i className="bx bx-cog fs-20"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => {
                                        handleOpenSettingsPersonage()
                                        setSelectPersonage(el)
                                    }}>
                                        Редактировать
                                    </DropdownItem>
                                    <DropdownItem onClick={() => removePersonage(el)}>
                                        Удалить
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <ResizableBox
                                width={el.pivot.width}
                                height={el.pivot.width}
                                minConstraints={[100, 100]} // Минимальные размеры
                                maxConstraints={[500, 500]} // Максимальные размеры
                                className="custom-resizable-box"
                                onResize={(w, h) => {
                                    setUpdateShot((shot) => ({
                                        ...shot,
                                        personages: shot.personages.map((t) => {
                                            if (el.id === t.id) {
                                                return {
                                                    ...t,
                                                    pivot: {
                                                        ...t.pivot,
                                                        width: h.size.width,
                                                        height: h.size.height
                                                    }
                                                }
                                            }
                                            return t
                                        })
                                    }))
                                }}
                                resizeHandles={['nw', 'n', 'w']} // Изменение размера за правый нижний угол
                            >
                                <img src={process.env.REACT_APP_API_BASE_URL + el.path} alt=""
                                     style={{transform: `scale(${el.pivot.size})`, width: "100%", height: "100%"}}/>
                            </ResizableBox>
                        </div>
                    </Draggable>
                ))}
                {
                    selectTexture &&
                    <SettingsTexture shot={updateShot} openSettingsTexture={openSettingsTexture}
                                     handleOpenSettingsTexture={handleOpenSettingsTexture} selectTexture={selectTexture}
                                     saveSettingsTexture={(texture) => saveSettingsTexture(texture)}/>
                }
                {
                    selectPersonage &&
                    <SettingsPersonage openSettingsPersonage={openSettingsPersonage}
                                       handleOpenSettingsPersonage={handleOpenSettingsPersonage}
                                       selectPersonage={selectPersonage}
                                       saveSettingsPersonage={(personage) => saveSettingsPersonage(personage)}/>
                }
                {
                    selectTexture &&
                    <Modal isOpen={isOpenCopySettings} toggle={toggleOpenTextureCopySettings} centered>
                        <ModalHeader toggle={toggleOpenTextureCopySettings} className="p-3 bg-soft-primary">Текстуры для копирования настроек</ModalHeader>
                        <ModalBody>
                            <Row>
                                {
                                    updateShot.textures.map((el, index) => (
                                        <Col xxl={4} lg={4} sm={6} xs={6} key={index} className="position-relative d-flex justify-content-center">
                                            {
                                                textureToCopy.find(el1 => el1.id === el.id) &&
                                                <Badge color="success" style={{width: 30, height: 30}}
                                                       className="rounded-circle d-flex justify-content-center align-items-center position-absolute top-0 start-100 translate-middle"><i
                                                    className="bx bx-check fs-3"></i></Badge>
                                            }
                                            <img src={process.env.REACT_APP_API_BASE_URL + el.path}
                                                 alt="user" className="cursor-pointer"
                                                 style={{height: 100, width: 100}}
                                                 onClick={() => {
                                                     if (textureToCopy.find(el1 => el1.id === el.id))
                                                         setTextureToCopy((prev) => prev.filter((el1) => el1.id !== el.id))
                                                     else
                                                        setTextureToCopy((prev) => [...prev, el])
                                                 }}/>
                                        </Col>
                                    ))
                                }
                            </Row>
                            <div className="mt-3 gap-2 d-flex">
                                <button className="btn btn-primary" type="button" onClick={() => copySettings()}>
                                    Копировать
                                </button>
                                <button className="btn btn-primary" type="button" onClick={toggleOpenTextureCopySettings}>
                                    Отмена
                                </button>
                            </div>
                        </ModalBody>
                    </Modal>
                }
            </div>
        ) : <></>
    );
};

export default FullShot;

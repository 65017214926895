import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import {useAppSelector} from "../../../../hooks/redux";

const TableCountChildrenSpecialGroupPart1 = () => {

    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState([
        {
            org: "Организация 1",
            count_group_dou: '85',
            special_group: '28',
            focus_group_count: [
                {
                    name: 'Оздоровительные',
                    count: 10
                },
            ]
        },
        {
            org: "Организация 2",
            count_group_dou: '85',
            special_group: '28',
            focus_group_count: [
                {
                    name: 'Оздоровительные',
                    count: 10
                },
                {
                    name: 'Комбинированная',
                    count: 5
                }
            ]
        },
        {
            org: "Организация 3",
            count_group_dou: '85',
            special_group: '28',
            focus_group_count: [
                {
                    name: 'Оздоровительные',
                    count: 10
                },
                {
                    name: 'Комбинированная',
                    count: 5
                }
            ]
        },
        {
            org: "Организация 4",
            count_group_dou: '85',
            special_group: '28',
            focus_group_count: [
                {
                    name: 'Оздоровительные',
                    count: 10
                },
                {
                    name: 'Комбинированная',
                    count: 5
                }
            ]
        },
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);


    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Кол-во групп в ДОУ</span>,
            selector: (row: any) => row.count_group_dou,
        },
        {
            name: <span className='font-weight-bold fs-13'>Спец-е группы</span>,
            selector: (row: any) => row.special_group,
        },
        {
            name: <span className='font-weight-bold fs-13'>Напрвленность, кол-во</span>,
            selector: (row: any) => (
                <ul className="p-0">
                    {
                        row.focus_group_count[0] &&
                        row.focus_group_count.map((i: any, idx: number) => (
                            <li key={idx} style={{listStyleType: "none"}}
                                className="text-wrap border-bottom border-top border-dark">{i?.name} - {i?.count}</li>))
                    }
                </ul>
            ),
        }
    ]

    return (
        <Card>
            <CardHeader>
                Специализированные группы
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableCountChildrenSpecialGroupPart1;

import React from 'react';
import {Helmet} from "react-helmet";
import ListGames from "../../components/Game/ListGames";
import {Container} from "reactstrap";

const Games = () => {
    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Игры</title>
            </Helmet>
            <Container fluid>
                <ListGames/>
            </Container>
        </div>
    );
};

export default Games;

import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import ListLevelEvolutionChildren from "../components/widgets/modal/tables/ListLevelEvolutionChildren";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import {Russian} from "flatpickr/dist/l10n/ru";
import Flatpickr from "react-flatpickr";
import WidgetRequiredSelectGroup from "../components/widgets/requireds/WidgetRequiredSelectGroup";

const LevelEvolutionChildren = () => {
    const [date, setDate] = useState(new Date());
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Уровень развития ребенка</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <Col xxl={12}>
                                    <Flatpickr
                                        name="dateRecord"
                                        className="form-control border-0 dash-filter-picker shadow mb-2"
                                        value={date}
                                        options={{
                                            plugins: [
                                                monthSelectPlugin({
                                                    shorthand: true,
                                                    dateFormat: "F Y",
                                                    altFormat: "F Y",
                                                    theme: "light",
                                                })
                                            ],
                                            locale: Russian,
                                            disableMobile: true
                                        }}

                                        onChange={ (date) => setDate(date[0])}
                                    />
                                </Col>
                                <Col>
                                    <WidgetRequiredSelectGroup/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xxl={12}>
                                    <ListLevelEvolutionChildren date={date}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    );
};

export default LevelEvolutionChildren;

import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch} from "../../hooks/redux";
import {getEmployees} from "../../store/reducers/department/ActionCreator";
import AxiosClient from "../../api/AxiosClient";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {
    Alert, Button,
    Card,
    CardBody, CardHeader,
    Col, Input,
    Row, Spinner,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import UpdateOtherImage from "../Kindergarten/UpdateOtherImage";
import TableGroupParty from "./TableGroupParty";
import UpdateOtherDoc from "./UpdateOtherDoc";
import {useStateContext} from "../../hooks/useStateContext";

const FormUpdateNews = () => {
    const {id} = useParams();
    const redirect = useNavigate()
    const {user} = useStateContext()
    const dispatch = useAppDispatch();
    const [otherImage, setOtherImage] = useState<any[]>([]);
    const [date, setDate] = useState<Date>(new Date());
    const [formOtherImages, setFormOtherImages] = useState<any[]>([]);
    const [news, setNews] = useState<any>({});
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [otherDoc, setOtherDoc] = useState<any[]>([]);
    const [formOtherDoc, setFormOtherDoc] = useState<Blob[]>([]);
    const [groups, setGroups] = useState([]);
    const [theme, setTheme] = useState('');
    const [description, setDescription] = useState('');
    const [send, setSend] = useState(false);

    useEffect(() => {
        AxiosClient.get('/get/all-group')
            .then(r => setGroups(r.data))
    }, []);


    useEffect(() => {
        dispatch(getEmployees()).then()
    }, [date, dispatch])

    useEffect(() => {
        AxiosClient.get(`/news/${id}`)
            .then((r) => {
                setNews(r.data)
                setTheme(r.data.theme)
                setDescription(r.data.description)
                setDate(new Date(r.data.date))
            });
    }, [id])

    const uploadOtherDoc = () => {
        document.getElementById('otherDocInput')?.click();
    }
    const showOtherDoc = (e: any) => {
        if (e.target.files) {
            const files: File[] = [...e.target.files];
            setOtherDoc(otherDoc.concat(files.map((i) => ({url: URL.createObjectURL(i), name: i.name}))))
            setFormOtherDoc(formOtherDoc.concat(files))
        }
    }


    const uploadOtherImage = () => {
        document.getElementById('otherImageInput')?.click();
    }
    const showOtherImage = (e: any) => {
        if (e.target.files) {
            const files: File[] = [...e.target.files];
            setOtherImage(otherImage.concat(files.map((i) => ({url: URL.createObjectURL(i), name: i.name}))))
            setFormOtherImages(formOtherImages.concat(files))
        }
    }

    const deleteFile = (url: string) => {
        setNews({...news, documents: JSON.stringify(JSON.parse(news.documents).filter((doc: any) => url !== doc.url))})
    }
    const deleteFileNew = (url: string, name: string) => {
        setOtherDoc(otherDoc.filter(doc => doc.url !== url))
        setFormOtherDoc(formOtherDoc.filter(i => (i as File).name !== name))
    }

    const deleteImage = (url: string) => {
        setNews({...news, otherImage: JSON.stringify(JSON.parse(news.otherImage).filter((img: any) => url !== img))})
    }
    const deleteImageNew = (url: string, name: string) => {
        setOtherImage(otherImage.filter(img => img !== url))
        setFormOtherImages(formOtherImages.filter(i => (i as File).name !== name))
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    id: `${news.id}`,
                    theme: theme,
                    description: description,
                    checkbox: checkAll ? news?.group?.map((i: any) => ({[i.group_id]: "true"})) : news?.group?.map((i: any) => ({[i.group_id]: i.value === "true"})),
                    date: new Date(),
                    employee: `${user.id}`,
                    rang: `${news.rang}`,
                    mainDoc: '',
                    mainImage: '',
                    otherFiles: [],
                }}
                validationSchema={
                    Yup.object({
                        theme: Yup.string().required('Введите тему'),
                        description: Yup.string().required('Введите описание'),
                        rang: Yup.string().required('Введите занятое место'),
                    })
                }
                onSubmit={(values) => {
                    setSend(true)
                    values.date = date
                    const data = new FormData();
                    formOtherImages.forEach(file => {
                        data.append('otherImage[]', file)
                    })
                    formOtherDoc.forEach(doc => {
                        data.append('otherDoc[]', doc)
                    })
                    values.mainDoc = news.documents
                    values.mainImage = news.otherImage
                    data.append('values', JSON.stringify(values))
                    AxiosClient.post('/news/update', data, {headers: {"Content-Type": 'multipart/form-data'}})
                        .then(() => {
                            setSend(false)
                            redirect("/news")
                        })
                        .catch(() => setSend(false))
                }}
            >
                <Form>
                    <Row>
                        <Col xxl={8}>
                            <Card>
                                <CardBody>
                                    <h4 className="text-center mt-sm-3 mt-lg-3 mt-xl-3 mt-xll-3">Доп. информация</h4>
                                    <Row className="fs-5">
                                        <Col>
                                            <Field name="theme"
                                                   className="mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0"
                                                   placeholder="Тема"/>
                                            <ErrorMessage name='theme'>{(msg) => <Alert className="mt-1"
                                                                                        color="danger">{msg}</Alert>}</ErrorMessage>
                                            <Field as="textarea" name="description" className="form-control mt-2"
                                                   rows={10}
                                                   style={{resize: "none"}} placeholder="Описание"/>
                                            <ErrorMessage name='description'>{(msg) => <Alert className="mt-1"
                                                                                              color="danger">{msg}</Alert>}</ErrorMessage>
                                            <div className="mt-1"><span
                                                className="fw-medium">Дата:</span>
                                                <Flatpickr
                                                    value={date}
                                                    name='date'
                                                    className="form-control border-1"
                                                    options={{
                                                        mode: "single",
                                                        dateFormat: "d m Y H:i",
                                                        locale: Russian,
                                                        enableTime: true,
                                                        disableMobile: true
                                                    }}
                                                    onChange={(date) => setDate(date[0])}
                                                />
                                            </div>
                                            <div className="mt-1"><span
                                                className="fw-medium">Сотрудник:</span>
                                                <Field as="select" name="employee" id=""
                                                       className="form-control" disabled>
                                                    <option value={user.id}>{user.name}</option>
                                                </Field>
                                            </div>
                                            <div className="mt-1 mb-2"><span
                                                className="fw-medium">Занял место:</span>
                                                <Field name="rang"
                                                       as="select"
                                                       className="mt-2 form-select mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"
                                                       placeholder="Занятое место">
                                                    <option value={1}>Не срочно</option>
                                                    <option value={2}>Требует внимание</option>
                                                    <option value={3}>Срочно</option>
                                                </Field>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <TableGroupParty groups={groups}/>
                        </Col>
                    </Row>
                    <Row className="mt-3 mt-sm-3 mt-lg-3 mt-xl-3 mt-xxl-0">
                        <Col>
                            <Card>
                                <CardHeader>
                                    <Input color="success" id="otherImageInput" accept="image/*" multiple
                                           className="mt-1" type="file" hidden onChange={(e) => showOtherImage(e)}/>
                                    <Input color="success" id="otherDocInput" name="otherFiles"
                                           accept=".docx,.xlsx,.pptx,.txt, .jpg, .png, .pdf"
                                           multiple
                                           className="mt-1" type="file" hidden onChange={(e) => showOtherDoc(e)}/>
                                    <Row>
                                        <Col xxl={3}>
                                            <Button color="primary" className="mt-1 w-100" onClick={() => uploadOtherImage()}><i
                                                className="ri-add-fill me-1 align-bottom"></i>Добавить новые фото</Button>
                                        </Col>
                                        <Col xxl={3}>
                                            <Button color="primary" className="mt-1 w-100" onClick={() => uploadOtherDoc()}><i
                                                className="ri-add-fill me-1 align-bottom"></i>Добавить новые документы</Button>
                                        </Col>
                                        <Col xxl={2}>
                                            <Button color="primary" className="mt-1 w-100" type="submit" disabled={send}><i
                                                className="ri-add-fill me-1 align-bottom"></i>{send ? <Spinner size={'sm'}>
                                                Loading...
                                            </Spinner> : "Сохранить"}</Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        {
                                            news.documents &&
                                            JSON.parse(news.documents)?.map((url: any) => (
                                                <Col lg={6} xl={4} xxl={3} className="mt-1" key={url.url}>
                                                    <UpdateOtherDoc url={{
                                                        url: `${process.env.REACT_APP_API_BASE_URL}/api/download?file=${url.url}&nameFile=${url.name}`,
                                                        name: url.name,
                                                        origUrl: url.url
                                                    }} deleteFile={(url) => deleteFile(url)}/>
                                                </Col>
                                            ))
                                        }
                                        {
                                            otherDoc && otherDoc.map(url => (
                                                <Col lg={6} xl={4} xxl={3} className="mt-1" key={url.url}>
                                                    <UpdateOtherDoc url={{url: url.url, name: url.name, origUrl: url.url}}
                                                                    deleteFile={(url, name) => deleteFileNew(url, name as string)}/>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                    <Row>
                                        {
                                            news.otherImage &&
                                            JSON.parse(news.otherImage)?.map((url: any) => (
                                                <Col lg={6} xl={4} xxl={3} className="mt-1" key={url}>
                                                    <UpdateOtherImage url={{
                                                        url: process.env.REACT_APP_API_BASE_URL + url,
                                                        baseUrl: url
                                                    }} deleteImage={(url) => deleteImage(url)}/>
                                                </Col>
                                            ))
                                        }
                                        {
                                            otherImage && otherImage.map(url => (
                                                <Col lg={6} xl={4} xxl={3} className="mt-1" key={url.url}>
                                                    <UpdateOtherImage url={url.baseUrl = url}
                                                                      deleteImage={(url, name) => deleteImageNew(url, name as string)}/>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Formik>
        </>
    );
};

export default FormUpdateNews;

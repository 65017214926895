import React, {FC, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Nav, NavItem, Row, TabContent, TabPane} from "reactstrap";
import {NavLink} from "react-router-dom";
import classNames from "classnames";
import {convertDateLineInDot} from "../../../../functions/ConvertDate";
import HTMLReactParser from "html-react-parser";

interface ModalFromMyTaskInfoI {
    setOpenModalFromMyTaskInfo: (value: boolean) => void,
    openModalFromMyTaskInfo: boolean,
    infoMyTask: null | any
}
const ModalFromMyTaskInfo: FC<ModalFromMyTaskInfoI> = ({setOpenModalFromMyTaskInfo, openModalFromMyTaskInfo,infoMyTask}) => {
    const [activeTab, setActiveTab] = useState("1");
    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    function parse(info: any) {
        const parse1 = info?.history.replace(/<\/?(html|head|body)>/g, '').replace(/"/g, '')
        return HTMLReactParser(parse1)
    }


    return (
        <Modal id="createProjectModal" isOpen={openModalFromMyTaskInfo}
               toggle={() => setOpenModalFromMyTaskInfo(!openModalFromMyTaskInfo)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setOpenModalFromMyTaskInfo(!openModalFromMyTaskInfo)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Информация о моей задаче
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                             role="tablist">
                            <NavItem className="mt-2">
                                <NavLink
                                    className={`px-2 ${classNames({"border-bottom border-2 border-primary": activeTab === "1"})}`}
                                    onClick={() => {
                                        tabChange("1");
                                    }}
                                    type="button" to="#">
                                    <i className="fas fa-home"></i>
                                    Задача
                                </NavLink>
                            </NavItem>
                            <NavItem className="mt-2">
                                <NavLink
                                    className={`px-2 ${classNames({"border-bottom border-2 border-primary": activeTab === "2"})}`}
                                    onClick={() => {
                                        tabChange("2");
                                    }}
                                    type="button" to="#">
                                    <i className="fas fa-home"></i>
                                    История выполнений
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <Row>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1" className="align-items-center">
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <tbody>
                                            <tr>
                                                <th scope="row" style={{ width: "200px" }}>
                                                    Организация
                                                </th>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Дата старта</th>
                                                <td>{convertDateLineInDot(infoMyTask?.data)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Автор</th>
                                                <td>{infoMyTask?.author}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Дата создания</th>
                                                <td>{convertDateLineInDot(infoMyTask?.data)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Дата завершения</th>
                                                <td>{convertDateLineInDot(infoMyTask?.enddate.split(" ")[0]) + " " + infoMyTask?.enddate.split(" ")[1]}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Название</th>
                                                <td>{infoMyTask?.name}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Описание</th>
                                                <td>{infoMyTask?.description}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Файлы</th>
                                                <td>
                                                    {/*<Docs url={{url: "123.pptx", name: '123.pptx'}}/>*/}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2" className="align-items-center">
                                    <div className="table-responsive pt-2">
                                        {/*<table className="table mb-0">*/}
                                        {/*    <tbody>*/}
                                        {/*    <tr>*/}
                                        {/*        <th scope="row" style={{ width: "200px" }}>30.01.2023</th>*/}
                                        {/*        <td> Выполнить / БутаковаЕА / Выполнена</td>*/}
                                        {/*    </tr>*/}
                                        {/*    </tbody>*/}
                                        {/*</table>*/}
                                        {
                                            parse(infoMyTask)
                                        }
                                    </div>
                                </TabPane>
                            </TabContent>
                        </Row>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default ModalFromMyTaskInfo;

import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../hooks/redux";
import DataTable from "react-data-table-component";
import TableShowEmployee from "./TableShowEmployee";
import {Card, CardBody} from "reactstrap";

const TableShowOrganization = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState([
        {
            org: "Организация 1",
            name: 'Всего',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        },
        {
            org: "Организация 2",
            name: 'Отсутствует',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        },
        {
            org: "Организация 3",
            name: 'Незначительные',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        },
        {
            org: "Организация 4",
            name: 'Грубые',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns: any[] = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Всего</span>,
            selector: (row: any) => row.all,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Завершено</span>,
            selector: (row: any) => row.zav,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Просрочено</span>,
            selector: (row: any) => row.pros,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Выполнено</span>,
            selector: (row: any) => row.vip,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Вып. с задержкой</span>,
            selector: (row: any) => <span className="mx-3">{row.vipzad}</span>,
        }
    ]

    return (
        <Card className="my-1">
            <CardBody>
                <DataTable
                    className="my-1"
                    columns={columns}
                    data={outData}
                    noTableHead
                    expandableRows
                    expandOnRowClicked
                    expandableRowsComponent={
                        () => {
                            return <TableShowEmployee/>
                        }
                    }
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        },
                        expanderRow:{
                            style:{
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableShowOrganization;

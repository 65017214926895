import React, {FC} from 'react';
import {Card, CardBody, CardFooter, CardHeader, Col, Row} from "reactstrap";
import {Editor} from "draft-js";
import {useEditorApi} from "../../../hooks/UseEditorApi";
import {BLOCK_RENDER_MAP, BlockType, ColorInline, COLORS, CUSTOM_STYLE_MAP, InlineStyle} from "./config";
import cn from 'classnames'

interface IPropsTextEditor {
    handleHtml: (html: string) => void
}
const TextEditor: FC<IPropsTextEditor> = ({handleHtml}) => {
    const { state, onChange } = useEditorApi();
    const {toggleBlockType, currentBlockType, toggleInlineStyle, hasInlineStyle, currentInlineColor, applyColorToWholeText, toHtml} = useEditorApi()
    return (
        <Card>
            <CardHeader className="py-1">
                <Row>
                    <Col>
                        <select name="" id="" className="form-select border-0" value={currentBlockType} onChange={(event) => toggleBlockType(event.target.value as BlockType)}>
                            <option value={BlockType.h1} className="fs-1">Заголовок 1</option>
                            <option value={BlockType.h2} className="fs-2">Заголовок 2</option>
                            <option value={BlockType.h3} className="fs-3">Заголовок 3</option>
                            <option value={BlockType.h4} className="fs-4">Заголовок 4</option>
                            <option value={BlockType.h5} className="fs-5">Заголовок 5</option>
                            <option value={BlockType.h6} className="fs-6">Заголовок 6</option>
                            <option value={BlockType.default} className="fs-6">По умолчанию</option>
                        </select>
                    </Col>
                    <Col>
                        <select className="form-select border-0" value={currentInlineColor} onChange={(event) => applyColorToWholeText(event.target.value as InlineStyle)}>
                            {
                                COLORS.map(el => (
                                    <option key={el.inline} value={el.inline}>{el.label}</option>
                                ))
                            }
                        </select>
                    </Col>
                    <Col sm={1}>
                        <div className={cn('d-flex align-items-center', {
                            'bg-black bg-opacity-10': hasInlineStyle(InlineStyle.BOLD)
                        })} onClick={() => toggleInlineStyle(InlineStyle.BOLD)}>
                            <i className="bx bx-bold fs-1"></i>
                        </div>
                    </Col>
                    <Col sm={1}>
                        <div className={cn('d-flex align-items-center', {
                            'bg-black bg-opacity-10': hasInlineStyle(InlineStyle.ITALIC)
                        })} onClick={() => toggleInlineStyle(InlineStyle.ITALIC)}>
                            <i className="bx bx-italic fs-1"></i>
                        </div>
                    </Col>
                    <Col sm={1}>
                        <div className={cn('d-flex align-items-center', {
                            'bg-black bg-opacity-10': hasInlineStyle(InlineStyle.UNDERLINE)
                        })} onClick={() => toggleInlineStyle(InlineStyle.UNDERLINE)}>
                            <i className="bx bx-underline fs-1"></i>
                        </div>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody className={cn('text-black', {'bg-primary': currentInlineColor === ColorInline.WHITE})}>
                <Editor
                    editorState={state}
                    onChange={onChange}
                    blockRenderMap={BLOCK_RENDER_MAP}
                    customStyleMap={CUSTOM_STYLE_MAP}
                    placeholder="Введите текст здесь"
                />
            </CardBody>
            <CardFooter>
                <button className="btn btn-primary" onClick={() => handleHtml(toHtml())}>Применить текст</button>
            </CardFooter>
        </Card>
    );
};

export default TextEditor;

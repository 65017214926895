import React, {FC, useEffect, useState} from 'react';
import {ITableAllInfoGroup} from "../../../models/widgets/ITableAllInfoGroup";
import {Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import ModalAddOperationControl from "../modal/ModalAddOperationControl";
import {useAppSelector} from "../../../hooks/redux";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment";
import ModalUpdateOperationControl from "../modal/ModalUpdateOperationControl";
import {setDateAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {convertDateFull} from "../../../functions/ConvertDate";

interface IWidgetTableAllInfoOperationalControl {
    data: any[],
    updateAllData: () => void
}
const WidgetTableAllInfoOperationalControl: FC<IWidgetTableAllInfoOperationalControl> = ({data, updateAllData}) => {

    const [openAddOperationControl, setOpenAddOperationControl] = useState<boolean>(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [selectPlanControl, setSelectPlanControl] = useState<any | null>(null);

    const columns: any = [
        {
            id: "date",
            name: <span className='font-weight-bold fs-13 mb-0'>Дата</span>,
            selector: (row: ITableAllInfoGroup) => `${moment(row.data).format('DD.MM.YYYY')}`,
            sortable: true,
            center: true,
            width: '120px'
        },
        {
            name: <span className='font-weight-bold fs-13'>Группа</span>,
            selector: (row: any) => row.group_name,
        },
        {
            name: <span className='font-weight-bold fs-13'>Грубые</span>,
            selector: (row: any) => row.remarks.filter((el: any) => el.mark === 'Грубые нарушения').length,
        },
        {
            name: <span className='font-weight-bold fs-13'>Незначительные</span>,
            selector: (row: any) => row.remarks.filter((el: any) => el.mark === 'Незначительные замечания').length,
        },
        {
            name: <span className='font-weight-bold fs-13'>Отсутствуют</span>,
            selector: (row: any) => row.remarks.filter((el: any) => el.mark === 'Замечаний нет').length,
        }
    ];

    return (
        <>
            <Card>
                <CardHeader>
                    Оперативный контроль по группам
                </CardHeader>
                <CardBody className="py-0">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        paginationRowsPerPageOptions={[5, 10, 20, 30]}
                        paginationComponentOptions={
                            {
                                rowsPerPageText: 'Видно дней:',
                                rangeSeparatorText: 'из'
                            }
                        }
                        noDataComponent={'Нет групп'}
                        defaultSortFieldId={"date"}
                        defaultSortAsc={false}
                        highlightOnHover
                        pointerOnHover
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5"
                                }
                            }
                        }}
                        onRowClicked={
                            (row) => {
                                if(window.innerWidth <= 768){
                                    setSelectPlanControl(row)
                                    setOpenUpdate(true)
                                }
                            }
                        }
                        onRowDoubleClicked={
                            (row) => {
                                if(window.innerWidth > 768){
                                    setSelectPlanControl(row)
                                    setOpenUpdate(true)
                                }
                            }
                        }
                    />
                </CardBody>
                <CardFooter className="d-flex justify-content-between">
                    <div className='d-flex'>
                        <div className="button_modal mx-1">
                            <button className="btn btn-primary w-100"
                                    onClick={() => setOpenAddOperationControl(true)}>Добавить
                            </button>
                        </div>
                    </div>
                </CardFooter>
            </Card>
            {
                openAddOperationControl &&
                <ModalAddOperationControl updateAllData={() => updateAllData()} open={openAddOperationControl} setOpen={(value) => setOpenAddOperationControl(value)}/>
            }
            {
                openUpdate &&
                <ModalUpdateOperationControl updateAllData={() => updateAllData()} openUpdate={openUpdate} setOpenUpdate={value => setOpenUpdate(value)} selectPlanControl={selectPlanControl}/>
            }
        </>
    );
};

export default WidgetTableAllInfoOperationalControl;

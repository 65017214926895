import React, {useEffect, useState} from 'react';
import { Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getDailyReportNutritional} from "../../../store/reducers/widgets/ActionCreator";
import ModalAddDayliReportNutrition from "../modal/ModalAddDayliReportNutrition";
import {setDateAdd, setGroupModalAdd, setModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {convertDateFull} from "../../../functions/ConvertDate";
import {IAllDailyReportsNutrition} from "../../../models/widgets/IAllDailyReportsNutrition";
import ModalDeleteDayliReportNutrition from "../modal/ModalDeleteDayliReportNutrition";
import {ITableAllInfoGroup} from "../../../models/widgets/ITableAllInfoGroup";
import moment from "moment/moment";

const WidgetTableDayliReportNutrition = () => {

    const dispatch = useAppDispatch();

    const {groupAllDeviations, date, allDailyReportNutritional} = useAppSelector(state => state.WidgetReducer)

    const [modalAdd, setModalAdd] = useState<boolean>(false);
    const [modalDelete, setModalDelete] = useState<boolean>(false);

    useEffect(() => {
        groupAllDeviations &&
        dispatch(getDailyReportNutritional(date, groupAllDeviations)).then()
    }, [date, groupAllDeviations, dispatch])


    const columns = [
        {
            id: 'date',
            name: <span className='font-weight-bold fs-13'>Дата</span>,
            selector: (row: IAllDailyReportsNutrition) => row.date,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Режим питания</span>,
            selector: (row: IAllDailyReportsNutrition) => row.nutrition,
            sortable: true,
            center: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Съел все</span>,
            selector: (row: IAllDailyReportsNutrition) => row.eat,
            sortable: true,
            center: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Частично</span>,
            selector: (row: IAllDailyReportsNutrition) => row.eat_middle,
            sortable: true,
            center: true,
            width: "21%"
        },
        {
            name: <span className='font-weight-bold fs-13'>Не ел</span>,
            selector: (row: IAllDailyReportsNutrition) => row.not_eat,
            sortable: true,
            center: true,
        }
    ];

    return (
        <>
            <Card>
                <CardHeader>
                    Отчет по питанию ребенка
                </CardHeader>
                <CardBody>
                    <DataTable
                        columns={columns}
                        data={allDailyReportNutritional}
                        noDataComponent={'Нет данных'}
                        highlightOnHover
                        pagination
                        paginationRowsPerPageOptions={[6]}
                        paginationPerPage={6}
                        defaultSortFieldId={'date'}
                        defaultSortAsc={false}
                        pointerOnHover
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5"
                                }
                            }
                        }}
                        onRowClicked={
                            (row) => {
                                if(window.innerWidth <= 768){
                                    dispatch(setDateAdd(new Date(convertDateFull(row.date))))
                                    setModalAdd(true)
                                }
                            }
                        }
                        onRowDoubleClicked={
                            (row) => {
                                if(window.innerWidth > 768){
                                    dispatch(setDateAdd(new Date(convertDateFull(row.date))))
                                    setModalAdd(true)
                                }
                            }
                        }

                    />
                </CardBody>
                <CardFooter className='d-flex gap-2'>
                    <div>
                        <button className="btn btn-primary" onClick={() => setModalAdd(true)}>Добавить данные</button>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={() => setModalDelete(true)}>Удалить</button>
                    </div>
                </CardFooter>
            </Card>
            {
                modalDelete &&
                <ModalDeleteDayliReportNutrition modalDelete={modalDelete} setModalDelete={(value) => setModalDelete(value)}/>
            }
            {
                modalAdd &&
                <ModalAddDayliReportNutrition modalAdd={modalAdd} setModalAdd={(modalAdd) => setModalAdd(modalAdd)}/>
            }
        </>
    );
};

export default WidgetTableDayliReportNutrition;

import {AxiosResponse} from "axios";
import {IRecommendedActive} from "../../../models/IRecommendedActive";
import $api from "../../AxiosClient";

class RecommendedActiveService {
    static async getRecommendedActive(): Promise<AxiosResponse<IRecommendedActive[]>>{
        return $api.post<IRecommendedActive[]>("/recomended-actives")
    }

    static async addRecommendedActive(value: object): Promise<AxiosResponse<void>>{
        return $api.post<void>("/recomended-active/add", value)
    }

    static async updateRecommendedActive(value: object, recommendedActiveId: number): Promise<AxiosResponse<void>>{
        return $api.post<void>(`/recomended-active/update/${recommendedActiveId}`, value)
    }
}

export default RecommendedActiveService
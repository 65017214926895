import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import PhoneOrPc from "../../components/department/PhoneOrPC";
import TableCompetitionsPRPart1 from "../../components/department/Competitions/TablePhone/TableCompetitionsPRPart1";
import TableCompetitionsPRPart2 from "../../components/department/Competitions/TablePhone/TableCompetitionsPRPart2";
import TableCompetitionsPR from "../../components/department/Competitions/TableCompetitionsPR";
import TableCompetitionsSeniorCategoryPart1
    from "../../components/department/Competitions/TablePhone/TableCompetitionsSeniorCategoryPart1";
import TableCompetitionsSeniorCategoryPart2
    from "../../components/department/Competitions/TablePhone/TableCompetitionsSeniorCategoryPart2";
import TableCompetitionsSeniorCategory from "../../components/department/Competitions/TableCompetitionsSeniorCategory";
import GraphCompetitionsPR from "../../components/department/Competitions/GraphCompetitionsPR";
import GraphCompetitionsSeniorCategory from "../../components/department/Competitions/GraphCompetitionsSeniorCategory";

const Competitions = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Конкурсы</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Col>
                                    <GraphCompetitionsPR/>
                                </Col>
                                <Col sm={12}>
                                    <GraphCompetitionsSeniorCategory/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <PhoneOrPc phone={[<TableCompetitionsPRPart1/>, <TableCompetitionsPRPart2/>,
                                                <TableCompetitionsSeniorCategoryPart1/>, <TableCompetitionsSeniorCategoryPart2/>]}
                                       pc={[<TableCompetitionsPR/>, <TableCompetitionsSeniorCategory/>]}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Competitions;

import {AxiosResponse} from "axios";
import $api from "../../AxiosClient";
import {IChildren} from "../../../models/IChildren";

class ChildrenService {
    static async getChildren(): Promise<AxiosResponse<IChildren[]>>{
        return $api.post<IChildren[]>("/childrens")
    }

    static async addChildren(value: object): Promise<AxiosResponse<any>>{
        return $api.post<any>("/children/add", value)
    }

    static async updateChildren(value: object, Id: number): Promise<AxiosResponse<any>>{
        return $api.post<any>(`/children/update/${Id}`, value)
    }
}

export default ChildrenService
import {AxiosResponse} from "axios";
import {ICabinet} from "../../../models/ICabinet";
import $api from "../../AxiosClient";

class CabinetService {
    static async getCabinets(): Promise<AxiosResponse<ICabinet[]>>{
        return $api.post<ICabinet[]>("/cabinets");
    }

    static async addCabinet(value: object): Promise<AxiosResponse<void>>{
        return $api.post<void>("/cabinet/add", value);
    }

    static async updateCabinet(value: object, cabinetId: number): Promise<AxiosResponse<void>>{
        return $api.post<void>(`/cabinet/update/${cabinetId}`, value)
    }
}

export default CabinetService;
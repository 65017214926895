import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import WidgetRequiredFlatpikerPeriod from "../../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import TableMyEvent from "../../components/DocumentsFlow/MyEvent/table/TableMyEvent";
import GraphMyEvent from "../../components/DocumentsFlow/MyEvent/graphs/GraphMyEvent";

const MyEvent = () => {
    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Мои события</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col xxl={4}>
                        <WidgetRequiredFlatpikerPeriod/>
                        {/*<GraphMyEvent/>*/}
                    </Col>
                    <Col>
                        <TableMyEvent/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default MyEvent;

import {AxiosResponse} from "axios";
import $api from "../../AxiosClient";
import {IChildrenMovementRegister} from "../../../models/IChildrenMovementRegister";

class ChildrenMovementRegisterService {
    static async getChildrenMovementRegister(): Promise<AxiosResponse<IChildrenMovementRegister[]>>{
        return $api.post<IChildrenMovementRegister[]>("/movement-registers")
    }

    static async addChildrenMovementRegister(value: object): Promise<AxiosResponse<any>>{
        return $api.post<any>("/movement-register/add", value)
    }

    static async updateChildrenMovementRegister(value: object, Id: number): Promise<AxiosResponse<any>>{
        return $api.post<any>(`/movement-register/update/${Id}`, value)
    }
}

export default ChildrenMovementRegisterService;
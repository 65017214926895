import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useAppDispatch} from "../../../../hooks/redux";
import {Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {setIsSend} from "../../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../../layouts/UI/SubmitWithLoading";
import AboutMePortfolio from "../portfolioPage/AboutMePortfolio";
import ReactCrop, {Crop, PixelCrop} from "react-image-crop";
import userDummayImage from "../../../../assets/images/users/user-dummy-img.jpg";
import ModalCropPhoto from "../ModalCropPhoto";

interface IEditAboutMe {
    portfolio: any,
    setPortfolio(value: any): any,
    selectPagePortfolio: any,
    selectEditPagePortfolio: boolean,
    setSelectEditPagePortfolio(value: boolean): any
}

const EditAboutMePortfolio: FC<IEditAboutMe> = ({ portfolio, setPortfolio, selectEditPagePortfolio, setSelectEditPagePortfolio, selectPagePortfolio }) => {
    const dispatch = useAppDispatch();
    const [editPagePortfolio, setEditPagePortfolio] = useState<any>();
    const [selectModalCropPhoto, setSelectModalCropPhoto]  = useState<boolean>(false);
    const [isDownloadPortfolioPage, setIsDownloadPortfolioPage] = useState<boolean>(false);

    const [content, setContent] = useState(JSON.parse(selectPagePortfolio?.content)); //для вывода контента из бд
    const [photoListBase64, setPhotoListBase64] = useState<any[]>(selectPagePortfolio.photoBase64); // для отображения в диве
    const [photoPathList, setPhotoPathList] = useState<any[]>(JSON.parse(selectPagePortfolio.photo.replace(/'/g, '"')));
    const [pathPhoto, setPathPhoto] = useState(photoPathList?.[0] ? process.env.REACT_APP_API_BASE_URL + photoPathList?.[0] : userDummayImage);
    const [downloadPhoto, setDownloadPhoto] = useState<Blob | null>(); //картинка загруженная блоб
    const [downloadPhoto64, setDownloadPhoto64] = useState<string | ArrayBuffer | null>();
    const [isEditPortfolioPage, setIsEditPortfolioPage] = useState<boolean>(false);
    const [cropPath, setCropPath] = useState<any>();
    const [croppedImage, setCroppedImage] = useState<string | null>(null);

    const handleImageClick = () => {
        if (isDownloadPortfolioPage) {
            document.getElementById('inputForFileMyFamily')?.click();
            setIsDownloadPortfolioPage(false);
        }
    };

    useEffect(() => {
        if (isEditPortfolioPage) {
            document.getElementById('handleCombineImagesAboutMe')?.click();
            setIsEditPortfolioPage(false);
        }
    }, [isEditPortfolioPage]);


    return (
        <>
            <Modal id="createProjectModal" isOpen={selectEditPagePortfolio}
                   toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                   modalClassName="zoomIn" tabIndex={-1} centered >
                <ModalHeader toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Изменение страницы «{selectPagePortfolio?.type_page_portfolio.name}»
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={
                            {
                                name: content.name ? content.name : `${portfolio?.last_name} ${portfolio?.name} ${portfolio?.middle_name}`,
                                date: content.date,
                                zodiac: content.zodiac,
                                address: content.address,
                                photo_download: downloadPhoto64 ? downloadPhoto64 : `data:image/png;base64,${portfolio?.photoBase64}`,
                            }
                        }
                        validationSchema={
                            Yup.object({

                            })
                        }
                        onSubmit={(values) => {
                            dispatch(setIsSend(true));
                            setEditPagePortfolio({
                                name: values.name,
                                date: values.date,
                                zodiac: values.zodiac,
                                address: values.address,
                                uid: portfolio?.child_uid,
                                selectPage: selectPagePortfolio,
                                id_pattern: portfolio?.pattern_page_portfolio_id,
                                photo: downloadPhoto,
                                photo_download_base64: photoListBase64,
                                path_photo: photoPathList,
                                path_photo_page: selectPagePortfolio?.photo_page,
                            });
                            setIsEditPortfolioPage(true);
                        }}
                    >
                        {
                            ({ errors, touched, setFieldValue }) => (
                                <Form>

                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                ФИО:
                                                <Field name="name" className={`form-control ${touched.name && errors.name ? 'is-invalid' : ''}`} />
                                            </label>
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                Дата рождения:
                                                <Field name="date" className={`form-control ${touched.date && errors.date ? 'is-invalid' : ''}`} />
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                Знак зодиак:
                                                <Field name="zodiac" className={`form-control ${touched.zodiac && errors.zodiac ? 'is-invalid' : ''}`} />
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                Место жительства:
                                                <Field name="address" className={`form-control ${touched.address && errors.address ? 'is-invalid' : ''}`} />
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <label className="w-50">Загрузить фото:
                                                <img
                                                    src={cropPath ? cropPath : pathPhoto}
                                                    alt="Загрузить фото"
                                                    className="user-profile-image w-100 h-auto"
                                                    onClick={handleImageClick}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <Input
                                                    name="photo"
                                                    type="file"
                                                    id="inputForFileMyFamily"
                                                    className={`form-control d-none`}
                                                    accept=".png, .jpg"
                                                    onChange={(e: any) => {
                                                        const file = e.target.files[0];

                                                        if (file) {
                                                            const reader = new FileReader();
                                                            reader.onloadend = () => {
                                                                setDownloadPhoto64(reader.result);
                                                                setPhotoListBase64((prevArrList: any) => {
                                                                    const newArrList : any = [...prevArrList];
                                                                    newArrList[0] = reader.result;
                                                                    return newArrList;
                                                                });
                                                                setPathPhoto(reader.result as string);
                                                            };
                                                            reader.readAsDataURL(file);
                                                            setSelectModalCropPhoto(true)
                                                        }
                                                    }}
                                                />
                                            </label>
                                        </Col>
                                    </Row>
                                    <div className="hstack gap-2">
                                        <SubmitWithLoading text={'Готово'} />
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
            { editPagePortfolio && <AboutMePortfolio
                dispatch={(value) => dispatch(value)}
                page={editPagePortfolio}
                setPortfolio={(value) => setPortfolio(value)}
                setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}
            />}

            <ModalCropPhoto portfolio={portfolio} setPortfolio={(value: any) => setPortfolio(value)}
                            selectPagePortfolio={selectPagePortfolio}
                            selectModalCropPhoto={selectModalCropPhoto} setSelectModalCropPhoto={(value: boolean) => setSelectModalCropPhoto(value)}
                            photoPathList={photoPathList} setPhotoListBase64={(value: any) => setPhotoListBase64(value)}
                            pathPhoto={pathPhoto} setDownloadPhoto={(value: any) => setDownloadPhoto(value)}
                            downloadPhoto64={downloadPhoto64} setDownloadPhoto64={(value: any) => setDownloadPhoto64(value)}
                            cropPath={cropPath} setCropPath={(value: any) => setCropPath(value)} relationsParties={4 / 5} croppedImage={croppedImage} />
            </>
    );
};

export default EditAboutMePortfolio;

import $api from "../../AxiosClient";
import {AxiosResponse} from "axios";
import {ILoginResponse} from "./ILoginResponse";
import {IUser} from "../../../models/IUser";

enum AuthServicePath {
    LOGIN = "/login",
    SEND_CODE = "/sendCode",
    CHECK_CODE = "/checkCode",
    LOGOUT = "/logout",
    GET_USER = "/user"
}

class AuthService {
    static async login(login: string, password: string, code: boolean): Promise<AxiosResponse<ILoginResponse>>{
        return $api.post<ILoginResponse>(AuthServicePath.LOGIN, {login, password, code})
    }

    static async sendCode(phone: string, crypt: string): Promise<AxiosResponse<string>>{
        return $api.post<string>(AuthServicePath.SEND_CODE, {phone, crypt})
    }

    static async checkCode(phone: string, code: string, crypt: string): Promise<AxiosResponse<string>>{
        return $api.post<string>(AuthServicePath.CHECK_CODE, {phone, code, crypt})
    }

    static async logout(): Promise<AxiosResponse<void>>{
        return $api.post<void>(AuthServicePath.LOGOUT)
    }

    static async getUser(): Promise<AxiosResponse<IUser>>{
        return $api.get<IUser>(AuthServicePath.GET_USER)
    }

    static async lockscreen(password: string):Promise<AxiosResponse<void>>{
        return $api.post<void>("/unlockscreen", {password})
    }
}

export default AuthService;
import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import ModalAddEvent from "../modal/ModalAddEvent";
import MyEventService from "../../../../api/services/documentsFlowService/MyEventService";
import TableLoaders from "../../../layouts/SceletonLoaders/TableLoaders";

const TableMyEvent = () => {

    const [openModalAddEvent, setOpenModalAddEvent] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true)
        MyEventService.getEvents().then(r => {
            setData(r.data)
            setLoading(false)
        })
    }, []);

    const columns: any = [
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>Дата начало</span>,
            selector: (row: any) => row.startdate,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Дата конец</span>,
            selector: (row: any) => row.enddate,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Наименование</span>,
            selector: (row: any) => row.name,
            wrap: true
        }
    ];

    return (
        <>
            {
                loading ?
                    <TableLoaders/>
                    :
                <Card>
                    <CardHeader>
                        События
                    </CardHeader>
                    <CardBody>
                        <DataTable
                            columns={columns}
                            data={data}
                            noDataComponent={'Нет событий'}
                            defaultSortFieldId={"date"}
                            defaultSortAsc={false}
                            pagination
                            paginationRowsPerPageOptions={[5, 10, 20, 30]}
                            paginationComponentOptions={
                                {
                                    rowsPerPageText: 'Видно актов:',
                                    rangeSeparatorText: 'из'
                                }
                            }
                            highlightOnHover
                            customStyles={{
                                rows: {
                                    highlightOnHoverStyle: {
                                        backgroundColor: "#ffebb5"
                                    }
                                }
                            }}
                            onRowClicked={() => {

                            }}
                        />
                    </CardBody>
                    <CardFooter>
                        <button className="btn btn-primary" onClick={() =>  setOpenModalAddEvent(true)}>Добавить событие</button>
                    </CardFooter>
                    <ModalAddEvent openModalAddEvent={openModalAddEvent} setOpenModalAddEvent={(value) => setOpenModalAddEvent(value)}/>
                </Card>
            }
        </>
    );
};

export default TableMyEvent;

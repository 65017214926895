import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const TableAchievementsAll = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState([
        {
            org: "Организация 1",
            int: {
                pgo: '23',
                region: '22',
                rf: '12',
                percent: '1'
            },
            creat: {
                pgo: '23',
                region: '22',
                rf: '12',
                mn: '2',
                percent: '1'
            },
            sport: {
                pgo: '23',
                region: '22',
                rf: '12',
                percent: '1'
            },
            participant: {
                pgo: '23',
                region: '22',
                rf: '12',
            }
        },
        {
            org: "Организация 2",
            int: {
                pgo: '23',
                region: '22',
                rf: '12',
                percent: '1'
            },
            creat: {
                pgo: '23',
                region: '22',
                rf: '12',
                mn: '2',
                percent: '1'
            },
            sport: {
                pgo: '23',
                region: '22',
                rf: '12',
                percent: '1'
            },
            participant: {
                pgo: '23',
                region: '22',
                rf: '12',
            }
        },
        {
            org: "Организация 3",
            int: {
                pgo: '23',
                region: '22',
                rf: '12',
                percent: '1'
            },
            creat: {
                pgo: '23',
                region: '22',
                rf: '12',
                mn: '2',
                percent: '1'
            },
            sport: {
                pgo: '23',
                region: '22',
                rf: '12',
                percent: '1'
            },
            participant: {
                pgo: '23',
                region: '22',
                rf: '12',
            }
        },
        {
            org: "Организация 4",
            int: {
                pgo: '23',
                region: '22',
                rf: '12',
                percent: '1'
            },
            creat: {
                pgo: '23',
                region: '22',
                rf: '12',
                mn: '2',
                percent: '1'
            },
            sport: {
                pgo: '23',
                region: '22',
                rf: '12',
                percent: '1'
            },
            participant: {
                pgo: '23',
                region: '22',
                rf: '12',
            }
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true,
            width: '200px'
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Направление "Интелект" ПГО/Область/РФ/%</span>,
            selector: (row: any) => (`${row.int.pgo}/${row.int.region}/${row.int.rf}/${row.int.percent}`),
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Направление "Творчество" ПГО/Область/РФ/Международ./%</span>,
            selector: (row: any) => (`${row.creat.pgo}/${row.creat.region}/${row.creat.rf}/${row.creat.mn}/${row.creat.percent}`),
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Направление "Спорт" ПГО/Область/РФ/%</span>,
            selector: (row: any) => (`${row.sport.pgo}/${row.sport.region}/${row.sport.rf}/${row.sport.percent}`),
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Доля учавствующих ПГО/Область/РФ</span>,
            selector: (row: any) => (`${row.participant.pgo}/${row.participant.region}/${row.participant.rf}`),
        },
    ]

    return (
        <Card>
            <CardHeader>
                Всего в достижений
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableAchievementsAll;

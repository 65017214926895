import {AppDispatch} from "../../store";
import {DepartmentSlice} from "./DepartmentSlice";
import CabinetService from "../../../api/services/cabinetService/CabinetService";
import {ICabinet} from "../../../models/ICabinet";
import TypeCabinetService from "../../../api/services/typeCabinetService/TypeCabinetService";
import {ITypeCabinet} from "../../../models/ITypeCabinet";
import CategoryOvzService from "../../../api/services/categoryOvzService/CategoryOvzService";
import {ICategoryOvz} from "../../../models/ICategoryOvz";
import OrganizationService from "../../../api/services/organizationService/OrganizationService";
import TypeGroupService from "../../../api/services/typeGroupService/TypeGroupService";
import {ITypeGroup} from "../../../models/ITypeGroup";
import TypeDeviationService from "../../../api/services/TypeDeviationService/TypeDeviationService";
import {ITypeDeviation} from "../../../models/ITypeDeviation";
import SubdivisionService from "../../../api/services/subdivisionService/SubdivisionService";
import {ISubdivision} from "../../../models/ISubdivision";
import RegionService from "../../../api/services/regionService/RegionService";
import {IRegion} from "../../../models/IRegion";
import RecommendedActiveService from "../../../api/services/recommendedActiveService/RecommendedActiveService";
import {IRecommendedActive} from "../../../models/IRecommendedActive";
import PositionService from "../../../api/services/positionService/PositionService";
import GroupService from "../../../api/services/groupService/GroupService";
import {IGroup} from "../../../models/IGroup";
import GroupHealthService from "../../../api/services/groupHealthService/GroupHealthService";
import {IGroupHealth} from "../../../models/IGroupHealth";
import LimitHealthService from "../../../api/services/limitHealthService/LimitHealthService";
import {ILimitHealth} from "../../../models/ILimitHealth";
import EmployeeService from "../../../api/services/employeeService/EmployeeService";
import {IEmployee} from "../../../models/IEmployee";
import DoGroupService from "../../../api/services/doGroupService/DoGroupService";
import {IDoGroup} from "../../../models/IDoGroup";
import ChildrenService from "../../../api/services/childrenService/ChildrenService";
import childrenService from "../../../api/services/childrenService/ChildrenService";
import {IChildren} from "../../../models/IChildren";
import ParentService from "../../../api/services/parentService/ParentService";
import {IParent} from "../../../models/IParent";
import DeviationService from "../../../api/services/deviationService/DeviationService";
import {IDeviation} from "../../../models/IDeviation";
import ChildrenMovementRegisterService
    from "../../../api/services/childrenMovementRegisterService/ChildrenMovementRegisterService";
import {IChildrenMovementRegister} from "../../../models/IChildrenMovementRegister";

export const getCabinets = () => async (dispatch: AppDispatch) => {
  try{
      dispatch(DepartmentSlice.actions.dataLoading())
      const response = await CabinetService.getCabinets().then(res => res.data)
      dispatch(DepartmentSlice.actions.cabinetsLoadingSuccess(response))
  } catch (e: any) {
      dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
  }
}

export const addCabinet = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await CabinetService.addCabinet(value);
        const cabinets = await CabinetService.getCabinets().then(res => res.data)
        dispatch(DepartmentSlice.actions.cabinetsLoadingSuccess(cabinets))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateCabinet = (value: object, cabinetId: number) => async (dispatch: AppDispatch) => {
    try {
        await CabinetService.updateCabinet(value, cabinetId);
        const cabinets = await CabinetService.getCabinets().then(res => res.data)
        const cabinet = cabinets.find(cabinet => cabinet.id === cabinetId) as ICabinet;
        dispatch(DepartmentSlice.actions.cabinetsLoadingSuccess(cabinets))
        dispatch(DepartmentSlice.actions.setSelectCabinet(cabinet))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectCabinet = (cabinet: ICabinet) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectCabinet(cabinet))
}

export const getTypeCabinets = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await TypeCabinetService.getTypeCabinets().then(res => res.data)
        dispatch(DepartmentSlice.actions.typeCabinetsLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const addTypeCabinet = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await TypeCabinetService.addTypeCabinets(value);
        const typeCabinets = await TypeCabinetService.getTypeCabinets().then(res => res.data)
        dispatch(DepartmentSlice.actions.typeCabinetsLoadingSuccess(typeCabinets))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateTypeCabinet = (value: object, typeCabinetId: number) => async (dispatch: AppDispatch) => {
    try {
        await TypeCabinetService.updateTypeCabinets(value, typeCabinetId);
        const typeCabinets = await TypeCabinetService.getTypeCabinets().then(res => res.data)
        const typeCabinet = typeCabinets.find(typeCabinet => typeCabinet.id === typeCabinetId) as ITypeCabinet;
        dispatch(DepartmentSlice.actions.typeCabinetsLoadingSuccess(typeCabinets))
        dispatch(DepartmentSlice.actions.setSelectTypeCabinet(typeCabinet))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectTypeCabinet = (typeCabinet: ITypeCabinet) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectTypeCabinet(typeCabinet))
}

export const getCategoriesOvz = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await CategoryOvzService.getCategoriesOvz().then(res => res.data)
        dispatch(DepartmentSlice.actions.categoriesOvzLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const addCategoryOvz = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await CategoryOvzService.addCategoryOvz(value);
        const categoriesOvz = await CategoryOvzService.getCategoriesOvz().then(res => res.data)
        dispatch(DepartmentSlice.actions.categoriesOvzLoadingSuccess(categoriesOvz))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateCategoryOvz = (value: object, categoryOvzId: number) => async (dispatch: AppDispatch) => {
    try {
        await CategoryOvzService.updateCategoryOvz(value, categoryOvzId);
        const categoriesOvz = await CategoryOvzService.getCategoriesOvz().then(res => res.data)
        const categoryOvz = categoriesOvz.find(categoryOvz => categoryOvz.id === categoryOvzId) as ICategoryOvz;
        dispatch(DepartmentSlice.actions.categoriesOvzLoadingSuccess(categoriesOvz))
        dispatch(DepartmentSlice.actions.setSelectCategoryOvz(categoryOvz))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectCategoryOvz = (categoryOvz: ICategoryOvz) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectCategoryOvz(categoryOvz))
}

export const getTypesGroup = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await TypeGroupService.getTypeGroup().then(res => res.data)
        dispatch(DepartmentSlice.actions.typesGroupLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const addTypeGroup = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await TypeGroupService.addTypeGroup(value);
        const typesGroup = await TypeGroupService.getTypeGroup().then(res => res.data)
        dispatch(DepartmentSlice.actions.typesGroupLoadingSuccess(typesGroup))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateTypeGroup = (value: object, typeGroupId: number) => async (dispatch: AppDispatch) => {
    try {
        await TypeGroupService.updateTypeGroup(value, typeGroupId);
        const typesGroup = await TypeGroupService.getTypeGroup().then(res => res.data)
        const typeGroup = typesGroup.find(typeGroup => typeGroup.id === typeGroupId) as ITypeGroup;
        dispatch(DepartmentSlice.actions.typesGroupLoadingSuccess(typesGroup))
        dispatch(DepartmentSlice.actions.setSelectTypeGroup(typeGroup))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectTypeGroup = (typeGroup: ITypeGroup) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectTypeGroup(typeGroup))
}

export const getTypesDeviation = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await TypeDeviationService.getTypeDeviation().then(res => res.data)
        dispatch(DepartmentSlice.actions.typesDeviationLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}
export const addTypeDeviation = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await TypeDeviationService.addTypeDeviation(value);
        const typesDeviation = await TypeDeviationService.getTypeDeviation().then(res => res.data)
        dispatch(DepartmentSlice.actions.typesDeviationLoadingSuccess(typesDeviation))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateTypeDeviation = (value: object, typeDeviationId: number) => async (dispatch: AppDispatch) => {
    try {
        await TypeDeviationService.updateTypeDeviation(value, typeDeviationId);
        const typesDeviation = await TypeDeviationService.getTypeDeviation().then(res => res.data)
        const typeDeviation = typesDeviation.find(typeDeviation => typeDeviation.id === typeDeviationId) as ITypeDeviation;
        dispatch(DepartmentSlice.actions.typesDeviationLoadingSuccess(typesDeviation))
        dispatch(DepartmentSlice.actions.setSelectTypeDeviation(typeDeviation))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectTypeDeviation = (typeDeviation: ITypeDeviation) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectTypeDeviation(typeDeviation))
}

export const getSubdivision = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await SubdivisionService.getSubdivisions().then(res => res.data)
        dispatch(DepartmentSlice.actions.subdivisionLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}
export const addSubdivision = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await SubdivisionService.addSubdivision(value);
        const subdivisions = await SubdivisionService.getSubdivisions().then(res => res.data)
        dispatch(DepartmentSlice.actions.subdivisionLoadingSuccess(subdivisions))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateSubdivision = (value: object, subdivisionId: number) => async (dispatch: AppDispatch) => {
    try {
        await SubdivisionService.updateSubdivision(value, subdivisionId);
        const subdivisions = await SubdivisionService.getSubdivisions().then(res => res.data)
        const subdivision = subdivisions.find(subdivision => subdivision.id === subdivisionId) as ISubdivision;
        dispatch(DepartmentSlice.actions.subdivisionLoadingSuccess(subdivisions))
        dispatch(DepartmentSlice.actions.setSelectSubdivision(subdivision))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectSubdivision = (subdivision: ISubdivision) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectSubdivision(subdivision))
}

export const getRegions = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await RegionService.getRegions().then(res => res.data)
        dispatch(DepartmentSlice.actions.regionsLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}
export const addRegion = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await RegionService.addRegions(value);
        const regions = await RegionService.getRegions().then(res => res.data)
        dispatch(DepartmentSlice.actions.regionsLoadingSuccess(regions))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateRegion = (value: object, regionId: number) => async (dispatch: AppDispatch) => {
    try {
        await RegionService.updateRegions(value, regionId);
        const regions = await RegionService.getRegions().then(res => res.data)
        const region = regions.find(region => region.id === regionId) as IRegion;
        dispatch(DepartmentSlice.actions.regionsLoadingSuccess(regions))
        dispatch(DepartmentSlice.actions.setSelectRegion(region))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectRegion = (region: IRegion) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectRegion(region))
}

export const getRecommendedActive = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await RecommendedActiveService.getRecommendedActive().then(res => res.data)
        dispatch(DepartmentSlice.actions.recommendedActiveLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}
export const addRecommendedActive = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await RecommendedActiveService.addRecommendedActive(value);
        const recommendedActives = await RecommendedActiveService.getRecommendedActive().then(res => res.data)
        dispatch(DepartmentSlice.actions.recommendedActiveLoadingSuccess(recommendedActives))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateRecommendedActive = (value: object, recommendedActiveId: number) => async (dispatch: AppDispatch) => {
    try {
        await RecommendedActiveService.updateRecommendedActive(value, recommendedActiveId);
        const recommendedActives = await RecommendedActiveService.getRecommendedActive().then(res => res.data)
        const recommendedActive = recommendedActives.find(recommendedActive => recommendedActive.id === recommendedActiveId) as IRecommendedActive;
        dispatch(DepartmentSlice.actions.recommendedActiveLoadingSuccess(recommendedActives))
        dispatch(DepartmentSlice.actions.setSelectRecommendedActive(recommendedActive))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectRecommendedActive = (recommendedActive: IRecommendedActive) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectRecommendedActive(recommendedActive))
}

export const getPositions = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await PositionService.getPositions().then(res => res.data)
        dispatch(DepartmentSlice.actions.positionsActiveLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const getOrganizations = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await OrganizationService.getOrganizations().then(res => res.data)
        dispatch(DepartmentSlice.actions.organizationsLoadingSuccess(response))
    } catch (e: any){
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const getGroups = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await GroupService.getGroup().then(res => res.data)
        dispatch(DepartmentSlice.actions.groupsLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}
export const addGroup = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await GroupService.addGroup(value);
        const groups = await GroupService.getGroup().then(res => res.data)
        dispatch(DepartmentSlice.actions.groupsLoadingSuccess(groups))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateGroup = (value: object, groupId: number) => async (dispatch: AppDispatch) => {
    try {
        await GroupService.updateGroup(value, groupId);
        const groups = await GroupService.getGroup().then(res => res.data)
        const group = groups.find(group => group.id === groupId) as IGroup;
        dispatch(DepartmentSlice.actions.groupsLoadingSuccess(groups))
        dispatch(DepartmentSlice.actions.setSelectGroup(group))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectGroup = (group: IGroup) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectGroup(group))
}

export const getGroupsHealth = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await GroupHealthService.getGroupsHealth().then(res => res.data)
        dispatch(DepartmentSlice.actions.groupsHealthLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}
export const addGroupHealth = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await GroupHealthService.addGroupHealth(value);
        const groupsHealth = await GroupHealthService.getGroupsHealth().then(res => res.data)
        dispatch(DepartmentSlice.actions.groupsHealthLoadingSuccess(groupsHealth))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateGroupHealth = (value: object, groupHealthId: number) => async (dispatch: AppDispatch) => {
    try {
        await GroupHealthService.updateGroupHealth(value, groupHealthId);
        const groupsHealth = await GroupHealthService.getGroupsHealth().then(res => res.data)
        const groupHealth = groupsHealth.find(groupHealth => groupHealth.id === groupHealthId) as IGroupHealth;
        dispatch(DepartmentSlice.actions.groupsHealthLoadingSuccess(groupsHealth))
        dispatch(DepartmentSlice.actions.setSelectGroupHealth(groupHealth))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectGroupHealth = (groupHealth: IGroupHealth) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectGroupHealth(groupHealth))
}

export const getLimitsHealth = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await LimitHealthService.getLimitsHealth().then(res => res.data)
        dispatch(DepartmentSlice.actions.limitsHealthLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}
export const addLimitHealth = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await LimitHealthService.addLimitHealth(value);
        const limitsHealth = await LimitHealthService.getLimitsHealth().then(res => res.data)
        dispatch(DepartmentSlice.actions.limitsHealthLoadingSuccess(limitsHealth))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateLimitHealth = (value: object, limitHealthId: number) => async (dispatch: AppDispatch) => {
    try {
        await LimitHealthService.updateLimitHealth(value, limitHealthId);
        const limitsHealth = await LimitHealthService.getLimitsHealth().then(res => res.data)
        const limitHealth = limitsHealth.find(limitHealth => limitHealth.id === limitHealthId) as ILimitHealth;
        dispatch(DepartmentSlice.actions.limitsHealthLoadingSuccess(limitsHealth))
        dispatch(DepartmentSlice.actions.setSelectLimitHealth(limitHealth))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectLimitHealth = (limitHealth: ILimitHealth) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectLimitHealth(limitHealth))
}


export const getEmployees = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await EmployeeService.getEmployees().then(res => res.data)
        dispatch(DepartmentSlice.actions.employeesLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}
export const addEmployee = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await EmployeeService.addEmployee(value);
        const employees = await EmployeeService.getEmployees().then(res => res.data)
        dispatch(DepartmentSlice.actions.employeesLoadingSuccess(employees))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateEmployee = (value: object, employeeId: number) => async (dispatch: AppDispatch) => {
    try {
        await EmployeeService.updateEmployee(value, employeeId);
        const employees = await EmployeeService.getEmployees().then(res => res.data)
        const employee = employees.find(employee => employee.id === employeeId) as IEmployee;
        dispatch(DepartmentSlice.actions.employeesLoadingSuccess(employees))
        dispatch(DepartmentSlice.actions.setSelectEmployee(employee))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectEmployee = (employee: IEmployee) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectEmployee(employee))
}

export const getDoGroups = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await DoGroupService.getDoGroup().then(res => res.data)
        dispatch(DepartmentSlice.actions.doGroupsLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}
export const addDoGroup = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await DoGroupService.addDoGroup(value);
        const doGroups = await DoGroupService.getDoGroup().then(res => res.data)
        dispatch(DepartmentSlice.actions.doGroupsLoadingSuccess(doGroups))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateDoGroup = (value: object, doGroupId: number) => async (dispatch: AppDispatch) => {
    try {
        await DoGroupService.updateDoGroup(value, doGroupId);
        const doGroups = await DoGroupService.getDoGroup().then(res => res.data)
        const doGroup = doGroups.find(doGroup => doGroup.id === doGroupId) as IDoGroup;
        dispatch(DepartmentSlice.actions.doGroupsLoadingSuccess(doGroups))
        dispatch(DepartmentSlice.actions.setSelectDoGroup(doGroup))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectDoGroup = (doGroup: IDoGroup) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectDoGroup(doGroup))
}

export const getChildren = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await ChildrenService.getChildren().then(res => res.data)
        dispatch(DepartmentSlice.actions.childrenLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}
export const addChildren = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await ChildrenService.addChildren(value);
        const children = await ChildrenService.getChildren().then(res => res.data)
        dispatch(DepartmentSlice.actions.childrenLoadingSuccess(children))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateChildren = (value: object, childrenId: number) => async (dispatch: AppDispatch) => {
    try {
        await ChildrenService.updateChildren(value, childrenId);
        const children = await childrenService.getChildren().then(res => res.data)
        const child = children.find(child => child.id === childrenId) as IChildren;
        dispatch(DepartmentSlice.actions.childrenLoadingSuccess(children))
        dispatch(DepartmentSlice.actions.setSelectChildren(child))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectChildren = (child: IChildren) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectChildren(child))
}

export const getParent = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await ParentService.getParent().then(res => res.data)
        dispatch(DepartmentSlice.actions.parentsLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}
export const addParent = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await ParentService.addParent(value);
        const parents = await ParentService.getParent().then(res => res.data)
        dispatch(DepartmentSlice.actions.parentsLoadingSuccess(parents))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateParent = (value: object, parentId: number) => async (dispatch: AppDispatch) => {
    try {
        await ParentService.updateParent(value, parentId);
        const parents = await ParentService.getParent().then(res => res.data)
        const parent = parents.find(parent => parent.id === parentId) as IParent;
        dispatch(DepartmentSlice.actions.parentsLoadingSuccess(parents))
        dispatch(DepartmentSlice.actions.setSelectParent(parent))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectParent = (parent: IParent) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectParent(parent))
}

export const getDeviations = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await DeviationService.getDeviation().then(res => res.data)
        dispatch(DepartmentSlice.actions.deviationLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}
export const addDeviation = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await DeviationService.addDeviation(value);
        const deviations = await DeviationService.getDeviation().then(res => res.data)
        dispatch(DepartmentSlice.actions.deviationLoadingSuccess(deviations))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateDeviation = (value: object, deviationId: number) => async (dispatch: AppDispatch) => {
    try {
        await DeviationService.updateDeviation(value, deviationId);
        const deviations = await DeviationService.getDeviation().then(res => res.data)
        const deviation = deviations.find(deviation => deviation.id === deviationId) as IDeviation;
        dispatch(DepartmentSlice.actions.deviationLoadingSuccess(deviations))
        dispatch(DepartmentSlice.actions.setSelectDeviation(deviation))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectDeviation = (deviation: IDeviation) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectDeviation(deviation))
}

export const getChildrenMovementRegister = () => async (dispatch: AppDispatch) => {
    try{
        dispatch(DepartmentSlice.actions.dataLoading())
        const response = await ChildrenMovementRegisterService.getChildrenMovementRegister().then(res => res.data)
        dispatch(DepartmentSlice.actions.childrenMovementRegisterLoadingSuccess(response))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}
export const addChildrenMovementRegister = (value: object) => async (dispatch: AppDispatch) => {
    try {
        await ChildrenMovementRegisterService.addChildrenMovementRegister(value);
        const childrenMovementRegister = await ChildrenMovementRegisterService.getChildrenMovementRegister().then(res => res.data)
        dispatch(DepartmentSlice.actions.childrenMovementRegisterLoadingSuccess(childrenMovementRegister))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const updateChildrenMovementRegister = (value: object, Id: number) => async (dispatch: AppDispatch) => {
    try {
        await ChildrenMovementRegisterService.updateChildrenMovementRegister(value, Id);
        const childrenMovementRegister = await ChildrenMovementRegisterService.getChildrenMovementRegister().then(res => res.data)
        const childMovementRegister = childrenMovementRegister.find(childMR => childMR.id === Id) as IChildrenMovementRegister;
        dispatch(DepartmentSlice.actions.childrenMovementRegisterLoadingSuccess(childrenMovementRegister))
        dispatch(DepartmentSlice.actions.setSelectChildrenMovementRegister(childMovementRegister))
    } catch (e: any) {
        dispatch(DepartmentSlice.actions.dataLoadingError(e.message))
    }
}

export const setSelectChildrenMovementRegister = (childMR: IChildrenMovementRegister) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.dataLoading())
    dispatch(DepartmentSlice.actions.setSelectChildrenMovementRegister(childMR))
}

export const setSelectOrganizations = (selectOrganizations: {value: string, label: string, checked: boolean}[]) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.setSelectOrganizations(selectOrganizations))
}

export const setDataSelectOrganizations = (dataSelectOrganizations: any[]) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.setDataSelectOrganizations(dataSelectOrganizations))
}

export const setCountSelectOrganizations = (value: {value: string, label: string}) => async (dispatch: AppDispatch) => {
    dispatch(DepartmentSlice.actions.setCountSelectOrganizations(value))
}
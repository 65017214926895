import { Component, useEffect, useState } from 'react'
import DataTable from "react-data-table-component"
import { Card, CardBody, CardHeader } from "reactstrap"
import WidgetService from "../../../api/services/widgetService/WidgetService"
import { useAppDispatch, useAppSelector } from "../../../hooks/redux"
import { IAllDeviationGroup } from "../../../models/widgets/IAllDeviationGroup"
import { setToUpdateGraphMainPageDo } from "../../../store/reducers/widgets/WidgetsSlice"

const WidgetTableAllDeviationsGroup = () => {
    const {groupAllDeviationsDO, date, toUpdateGraphMainPageDo} = useAppSelector(state => state.WidgetReducer)
    const dispatch = useAppDispatch();
    const [data, setData] = useState<IAllDeviationGroup[]>([]);

    useEffect(() => {
        toUpdateGraphMainPageDo &&
        WidgetService.getAllDeviationGroup(date, "True")
            .then(res => {
                setData(res.data)
                dispatch(setToUpdateGraphMainPageDo(false))
            })
    }, [toUpdateGraphMainPageDo])

    useEffect(() => {
        WidgetService.getAllDeviationGroup(date, "True")
            .then(res => setData(res.data))
    }, [date])

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Отклонение</span>,
            selector: (row: IAllDeviationGroup) => row.deviation_name,
            sortable: true,
        },
        {
            id: "count",
            name: <span className='font-weight-bold fs-13'>Количество</span>,
            selector: (row: IAllDeviationGroup) => row.count,
            sortable: true,
            center: true,
        }
    ];


    return (
        <>
            <Card>
                <CardHeader>
                    Таблица всех отклонений ДО
                </CardHeader>
                <CardBody className='d-flex align-items-center'>
                    <DataTable
                        columns={columns}
                        data={data.filter(item => item.group_id === groupAllDeviationsDO)}
                        noDataComponent={'В данной группе нет отклонений'}
                        defaultSortFieldId={"count"}
                        defaultSortAsc={false}
                        highlightOnHover
                        customStyles={{
                            rows: {
                                highlightOnHoverStyle: {
                                    backgroundColor: "#ffebb5"
                                }
                            }
                        }}
                    />
                </CardBody>
            </Card>
        </>
    );
};

export default WidgetTableAllDeviationsGroup;
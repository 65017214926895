import {AxiosResponse} from "axios";
import $api from "../../AxiosClient";
import {ILimitHealth} from "../../../models/ILimitHealth";

class LimitHealthService {
    static async getLimitsHealth(): Promise<AxiosResponse<ILimitHealth[]>>{
        return $api.post<ILimitHealth[]>("/limit-healths");
    }

    static async addLimitHealth(value: object): Promise<AxiosResponse<void>>{
        return $api.post<void>("/limit-health/add", value);
    }

    static async updateLimitHealth(value: object, id: number): Promise<AxiosResponse<void>>{
        return $api.post<void>(`/limit-health/update/${id}`, value);
    }
}

export default LimitHealthService;
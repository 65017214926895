import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import PortfolioEvents from "../../components/Kindergarten/PortfolioEvents";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getGroups} from "../../store/reducers/widgets/ActionCreator";
import AxiosClient from "../../api/AxiosClient";
import {setSuccessDeletePortfolio, setSuccessPortfolios} from "../../store/reducers/widgets/WidgetsSlice";
import {Helmet} from "react-helmet";

const KindergartenChildrenPortfolio = () => {
    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    const {groups, portfolios, successDeletePortfolio, selectDeletePortfolio} = useAppSelector(state => state.WidgetReducer);
    const [group, setGroup] = useState('');

    useEffect(() => {
        dispatch(getGroups([new Date(), new Date()])).then()
    }, [dispatch]);

    useEffect(() => {
        setGroup(groups[0]?.group_id)
    }, [groups]);


    useEffect(() => {
        if (group){
            const params = {
                group: group
            }
            AxiosClient.get('/portfolios', {params}).then(
                r => dispatch(setSuccessPortfolios(r.data))
            )
        }
    }, [group, dispatch]);

    const toggle = () => dispatch(setSuccessDeletePortfolio(!successDeletePortfolio))
    const toggleDelete = () => {
        const params = {
            group: group
        }
        dispatch(setSuccessDeletePortfolio(!successDeletePortfolio))
        AxiosClient.post(`/portfolio/delete/${selectDeletePortfolio}`).then(
            () => {
                AxiosClient.get('/portfolios', {params}).then(
                    r => dispatch(setSuccessPortfolios(r.data))
                )
            }
        )
    }

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Портфолио</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col className="col-auto">
                        <Button color="primary" onClick={() => navigate("/children/portfolio/add")}><i className="ri-add-fill me-1 align-bottom"></i>Добавить портфолио</Button>
                    </Col>
                    <Col sm={5} lg={4} xl={3} xxl={2} className="mt-2 mt-sm-0 mt-lg-0 mt-xl-0 mt-xxl-0">
                        <select name="" id="" className="form-select" value={group} onChange={(e) => setGroup(e.target.value)} >
                            {
                                groups.map(group => <option key={group.group_id} value={group.group_id}>{group.group_name}</option>)
                            }
                        </select>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PortfolioEvents portfolios={portfolios}/>
                    </Col>
                </Row>
            </Container>
            <Modal toggle={toggle} isOpen={successDeletePortfolio}
                   modalClassName="zoomIn" tabIndex={-1} centered
                >
                <ModalHeader toggle={toggle} className="p-3 bg-soft-primary" >Удаление портфолио</ModalHeader>
                <ModalBody>
                    <h3>Удалить портфолио?</h3>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>
                        Нет
                    </Button>{' '}
                    <Button color="secondary" onClick={toggleDelete}>
                        Удалить
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default KindergartenChildrenPortfolio;

import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "reactstrap";
import {ParentSlice} from "../../store/reducers/parent/ParentSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getChildren} from "../../store/reducers/parent/ActionCreator";
import AxiosClient from "../../api/AxiosClient";
import DesctopNews from "../../components/parent/DesctopNews";
import MobileNews from "../../components/parent/MobileNews";
import {Helmet} from "react-helmet";
import useWidth from "../../hooks/useWidth";

const ParentNews = () => {
    const {width} = useWidth()

    const dispatch = useAppDispatch()
    const {children, child} = useAppSelector(state => state.ParentReducer)
    const [news, setNews] = useState<any[]>([]);

    useEffect(() => {
        dispatch(getChildren()).then()
    }, [dispatch]);

    useEffect(() => {
        let group = '';
        children.forEach(i => {
            if (i.account_uid === child){
                group = i.group_code
            }
        })
        group &&
        AxiosClient.get('/parent/news', {params: {group}})
            .then(r => setNews(r.data))

    }, [child, children]);

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Новости</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col sm={5} xs={12} xl={3} xxl={3}>
                        <select className="form-select mt-1 fs-14" value={child}
                                onChange={(event) => dispatch(ParentSlice.actions.setChild(event.target.value))}>
                            {
                                children.map(child =>
                                    (
                                        <option key={child.account_uid} value={child.account_uid}
                                                className="fs-14">{child.account_name}</option>
                                    )
                                )
                            }
                        </select>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <div>
                            <h5 className="mb-4">Новости</h5>
                            {
                                width > 450 ?
                                    <DesctopNews news={news}/>
                                    :
                                    <MobileNews news={news}/>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ParentNews;

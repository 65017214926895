import React, {FC} from 'react';
import {Row} from "reactstrap";
import {convertDateTimeFull} from "../../functions/ConvertDate";
import {Link} from "react-router-dom";

interface IItemEvent {
    title: string,
    date: Date,
    teacher: string
}

const ItemEvent: FC<IItemEvent> = ({title, date, teacher}) => {
    return (
        <div className="list-group-item">
            <Row className="align-items-center g-3">
                <div className="col-auto">
                    <div className="avatar-sm m-1 h-auto bg-light rounded-3">
                        <div className="text-center">
                            <i className="ri-newspaper-line fs-24"></i>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <h4 className="text-muted mt-0 mb-1 fs-12">{convertDateTimeFull(date)}</h4>
                    <h5 className="text-muted mt-0 mb-1 fs-14">{title}</h5>
                    <Link to="#" className="text-reset fs-15 mb-0">{teacher}</Link>
                </div>
            </Row>
        </div>
    );
};

export default ItemEvent;

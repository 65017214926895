import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const ChildrenTypeOvzPart2 = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState([
        {
            org: "Организация 1",
            deaf: '23',
            small_deaf: '123',
            blind: '1',
            small_blind: '4',
        },
        {
            org: "Организация 2",
            deaf: '23',
            small_deaf: '123',
            blind: '1',
            small_blind: '4',
        },
        {
            org: "Организация 3",
            deaf: '23',
            small_deaf: '123',
            blind: '1',
            small_blind: '4',
        },
        {
            org: "Организация 4",
            deaf: '23',
            small_deaf: '123',
            blind: '1',
            small_blind: '4',
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);


    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true,
            compact: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Глухие</span>,
            selector: (row: any) => row.deaf,
        },
        {
            name: <span className='font-weight-bold fs-13'>Слабослышащие</span>,
            selector: (row: any) => row.small_deaf,
            compact: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Слепые</span>,
            selector: (row: any) => row.blind,
        },
        {
            name: <span className='font-weight-bold fs-13'>Слабовидящие</span>,
            selector: (row: any) => row.small_blind,
            wrap: true,
            compact: true
        },
    ]

    return (
        <Card>
            <CardHeader>
                Дети с ОВЗ
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default ChildrenTypeOvzPart2;

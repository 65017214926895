import React, {FC, useEffect, useState} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

interface ITableGroupHealth {
    years: any[]
}

const TableGroupHealth: FC<ITableGroupHealth> = ({years}) => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)


    const [data, setData] = useState([
        {
            year: 2023,
            data: [
                {
                    org: "Организация 1",
                    year: 2023,
                    one: '23',
                    two: '23',
                    three: '64',
                    four: '3',
                    five: '2',
                },
                {
                    org: "Организация 2",
                    year: 2023,
                    one: '23',
                    two: '23',
                    three: '64',
                    four: '3',
                    five: '2',
                },
                {
                    org: "Организация 3",
                    year: 2023,
                    one: '23',
                    two: '23',
                    three: '64',
                    four: '3',
                    five: '2',
                },
                {
                    org: "Организация 4",
                    year: 2023,
                    one: '23',
                    two: '23',
                    three: '64',
                    four: '3',
                    five: '2',
                },
            ]
        },
        {
            year: 2022,
            data: [
                {
                    org: "Организация 1",
                    year: 2022,
                    one: '23',
                    two: '23',
                    three: '64',
                    four: '3',
                    five: '2',
                },
                {
                    org: "Организация 2",
                    year: 2022,
                    one: '23',
                    two: '23',
                    three: '64',
                    four: '3',
                    five: '2',
                },
                {
                    org: "Организация 3",
                    year: 2022,
                    one: '23',
                    two: '23',
                    three: '64',
                    four: '3',
                    five: '2',
                },
                {
                    org: "Организация 4",
                    year: 2022,
                    one: '23',
                    two: '23',
                    three: '64',
                    four: '3',
                    five: '2',
                },
            ]
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        const selOrg = selectOrganizations.map(s => s.label)
        let year = years.map(s => s.value)
        let d = data.filter(b => year.includes(b.year))
        let newD2: any[] = []
        d.forEach((e) => newD2.push(...e.data))
        if (newD2.length > 0)
            setOutData(newD2.filter(a => selOrg.includes(a?.org)))
    }, [selectOrganizations, years]);


    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Год</span>,
            selector: (row: any) => row.year,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>I</span>,
            selector: (row: any) => row.one,
        },
        {
            name: <span className='font-weight-bold fs-13'>II</span>,
            selector: (row: any) => row.two,
        },
        {
            name: <span className='font-weight-bold fs-13'>III</span>,
            selector: (row: any) => row.three,
        },
        {
            name: <span className='font-weight-bold fs-13'>IV</span>,
            selector: (row: any) => row.four,
        },
        {
            name: <span className='font-weight-bold fs-13'>V</span>,
            selector: (row: any) => row.five,
        }
    ]

    return (
        <Card>
            <CardHeader>
                Распределение по группам здоровья
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableGroupHealth;

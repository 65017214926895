import React, {FC, useEffect, useState} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {Russian} from "flatpickr/dist/l10n/ru";
import Flatpickr from "react-flatpickr";
import {setGroupAllDeviations, setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getGroups} from "../../../store/reducers/widgets/ActionCreator";
import AxiosClient from "../../../api/AxiosClient";
import UserService from "../../../api/services/userService/UserService";
import classNames from "classnames";
import moment from "moment";
import {Field, Form, Formik} from "formik";
import DataTable from "react-data-table-component";
import * as Yup from "yup";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import {toast} from "react-toastify";
import {convertDateFullCalendar} from "../../../functions/ConvertDate";

interface IModalAddLevelEvolutionChildren {
    open: boolean,
    setOpen: (value: boolean) => void
    updateList: () => void,
    dateMonth: Date,
    listLevelEvolutionChildren: any[]
}

const ModalAddLevelEvolutionChildren: FC<IModalAddLevelEvolutionChildren> = ({
                                                                                 open,
                                                                                 setOpen,
                                                                                 updateList,
                                                                                 dateMonth,
                                                                                 listLevelEvolutionChildren
                                                                             }) => {
    const [date, setDate] = useState(new Date());
    const [selectEvolutionDirectionList, setSelectEvolutionDirectionList] = useState('');
    const [evolutionDirectionList, setEvolutionDirectionList] = useState<any[]>([]);
    const [selectEvolutionDirectionOfChildList, setSelectEvolutionDirectionOfChildList] = useState('');
    const [listPeriodTest, setListPeriodTest] = useState<any[]>([]);
    const [listTypeTest, setListTypeTest] = useState<any[]>([]);
    const [listEvolutionProgram, setListEvolutionProgram] = useState<any[]>([]);
    const [selectListEvolutionProgram, setSelectListEvolutionProgram] = useState('');
    const [selectTypeTest, setSelectTypeTest] = useState('');
    const [selectPeriodTest, setSelectPeriodTest] = useState('');
    const [users, setUsers] = useState<any[]>([]);
    const [selectUser, setSelectUser] = useState('');
    const [childEvolutionStudentsList, setChildEvolutionStudentsList] = useState<any>(null);
    const [selectChildEvolutionStudentsList, setSelectChildEvolutionStudentsList] = useState('');
    const dispatch = useAppDispatch()
    const {groups, groupAllDeviations} = useAppSelector(state => state.WidgetReducer);
    const [activeTab, setActiveTab] = useState("1");
    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    useEffect(() => {
        dispatch(getGroups([date, date])).then()
    }, [date, dispatch]);

    useEffect(() => {
        groupAllDeviations &&
        AxiosClient.get('/get_evolution_direction_list', {params: {group_uid: groupAllDeviations}})
            .then(r => setEvolutionDirectionList(r.data))
            .catch(() => console.log('error'))
    }, [groupAllDeviations]);

    useEffect(() => {
        (groupAllDeviations && selectEvolutionDirectionList && date) &&
        AxiosClient.get('/get_evolution_program_list', {
            params: {
                group_uid: groupAllDeviations,
                direction_uid: selectEvolutionDirectionList,
                date: moment(date).format('DD.MM.YYYY')
            }
        })
            .then(r => {
                setListEvolutionProgram(r.data)
                setSelectListEvolutionProgram('')
            })
            .catch(() => console.log('error'))
    }, [groupAllDeviations, selectEvolutionDirectionList, date]);

    useEffect(() => {
        (groupAllDeviations && selectListEvolutionProgram) &&
        AxiosClient.get('/child_evolution_students_list', {
            params: {
                group_uid: groupAllDeviations,
                program_uid: selectListEvolutionProgram,
                date: moment(date).format('DD.MM.YYYY')
            }
        })
            .then(r => {
                setChildEvolutionStudentsList(r.data)
                setSelectChildEvolutionStudentsList('')
            })
            .catch(() => console.log('error'))
    }, [groupAllDeviations, selectListEvolutionProgram, date]);

    useEffect(() => {
        AxiosClient.get('/get_test_period_list')
            .then(r => setListPeriodTest(r.data))
            .catch(() => console.log('error'))
        AxiosClient.get('/get_test_type_list')
            .then(r => setListTypeTest(r.data))
            .catch(() => console.log('error'))
        UserService.getUsersOrganization()
            .then(r => setUsers(r.data))
            .catch(() => console.log('error'))
    }, []);

    const onCheck = () => {
        const doc = listLevelEvolutionChildren.find(el => moment(date).month() === moment(convertDateFullCalendar(el.data)).month() && el.group_uid === groupAllDeviations && el.program_uid === selectListEvolutionProgram)
        if (doc) {
            toast.error(
                <div>Документ уже существует, отредактируйте его.
                    <br/> Дата: {doc.data}
                    <br/> Группа: {doc.group_name}
                    <br/> Программа образования: {doc.program}
                </div>, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            return false
        } else {
            return true
        }

    }

    useEffect(() => {
        setDate(moment(dateMonth).startOf("month").toDate())
    }, [dateMonth]);


    const columns: any = [
        {
            name: <span className='font-weight-bold fs-13'>ФИО ребенка</span>,
            selector: (row: any) => row.child_name,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Оценка</span>,
            selector: (row: any) => (
                <select name="" id="" value={row.mark_name} className="form-select"
                        onChange={(event) => checkChild(event.target.value, row.child_uid)}>
                    {
                        childEvolutionStudentsList?.marks.map((elem: any, index: number) => <option key={index}
                                                                                                    value={elem.name}>{elem.name}</option>)
                    }
                </select>
            ),
            center: true,
            style: {padding: '0'}
        }
    ];

    const checkChild = (mark: string, child_uid: string) => {
        if (selectChildEvolutionStudentsList) {
            setChildEvolutionStudentsList({
                ...childEvolutionStudentsList,
                indicators: childEvolutionStudentsList.indicators.map((indicator: any) => {
                    if (indicator.uid === selectChildEvolutionStudentsList) {
                        return {
                            ...indicator,
                            children: indicator.children.map((child: any) => {
                                if (child.child_uid === child_uid) {
                                    return {
                                        ...child,
                                        mark_name: mark
                                    }
                                } else return child
                            })
                        }
                    } else return indicator
                })
            })
        }
    }

    const checkAll = () => {
        if (selectEvolutionDirectionOfChildList && selectChildEvolutionStudentsList) {
            setChildEvolutionStudentsList({
                ...childEvolutionStudentsList,
                indicators: childEvolutionStudentsList.indicators.map((indicator: any) => {
                    if (indicator.uid === selectChildEvolutionStudentsList) {
                        return {
                            ...indicator,
                            children: indicator.children.map((child: any) => ({
                                ...child,
                                mark_name: selectEvolutionDirectionOfChildList
                            }))
                        }
                    } else return indicator
                })
            })
        }
    }

    return (
        <Modal id="createProjectModal" isOpen={open} toggle={() => setOpen(!open)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setOpen(!open)} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Создание Уровень развития ребенка
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={{
                        type_evolution: selectEvolutionDirectionList,
                        period_test: selectPeriodTest,
                        type_test: selectTypeTest,
                        controler: selectUser,
                        programm: selectListEvolutionProgram,
                        pokaz: selectChildEvolutionStudentsList
                    }}
                    validateSchema={
                        Yup.object({
                            type_evolution: Yup.string().required('Обязательное поле'),
                            period_test: Yup.string().required('Обязательное поле'),
                            selectTypeTest: Yup.string().required('Обязательное поле'),
                            controler: Yup.string().required('Обязательное поле'),
                            programm: Yup.string().required('Обязательное поле'),
                            pokaz: Yup.string().required('Обязательное поле'),
                        })
                    }
                    onSubmit={() => {
                        console.log(123)
                        if (onCheck()) {
                            dispatch(setIsSend(true))
                            const newData = {
                                uid: childEvolutionStudentsList.uid,
                                create_date: moment(date).format('DD-MM-YYYY'),
                                inspector_uid: selectUser,
                                group_uid: groupAllDeviations,
                                start_process: "0",
                                program_uid: selectListEvolutionProgram,
                                period_uid: selectPeriodTest,
                                type_name: selectTypeTest,
                                children: [].concat(...childEvolutionStudentsList?.indicators.map((ind: any) => ind.children.map((el: any) => ({
                                    uid: el.child_uid,
                                    mark: el.mark_name,
                                    indicator_uid: ind.uid
                                }))))
                            }

                            AxiosClient.post('/save_evolution_child', {data: newData})
                                .then(r => {
                                    dispatch(setIsSend(false))
                                    setOpen(false)
                                    updateList()
                                    setSelectUser('')
                                    setSelectListEvolutionProgram('')
                                    setSelectPeriodTest('')
                                    setSelectTypeTest('')
                                    setSelectEvolutionDirectionList('')
                                    setSelectEvolutionDirectionOfChildList('')
                                    setSelectChildEvolutionStudentsList('')
                                })
                                .catch(() => {
                                    dispatch(setIsSend(false))
                                    console.log('error')
                                })
                        }
                    }}
                >
                    {
                        ({touched}) => (
                            <Form>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "1", "p-2": true})}
                                            onClick={() => {
                                                tabChange("1");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Основное
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "2", "p-2": true})}
                                            onClick={() => {
                                                tabChange("2");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Дети
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="mt-2">
                                    <TabPane tabId="1" className="align-items-center">
                                        <Row>
                                            <Col>
                                                <label htmlFor="">Дата</label>
                                                <Flatpickr
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    value={date}
                                                    options={{
                                                        locale: Russian, dateFormat: "d M Y",
                                                        maxDate: moment(dateMonth).endOf("month").toDate(),
                                                        minDate: moment(dateMonth).startOf("month").toDate()
                                                    }}
                                                    onChange={(dates) => setDate(dates[0])}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <label htmlFor="">Группа:</label>
                                                <select className="form-select shadow" value={groupAllDeviations}
                                                        onChange={(event) => dispatch(setGroupAllDeviations(event.target.value))}
                                                >
                                                    {
                                                        groups.map((group) => {
                                                            return <option key={group.group_id}
                                                                           value={group.group_id}>{group.group_name}</option>
                                                        })
                                                    }
                                                </select>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <label htmlFor="">Направление развития:</label>
                                                <Field as="select" name="type_evolution"
                                                       className={`form-select shadow ${!selectEvolutionDirectionList && touched.type_evolution && 'border-danger'}`}
                                                       value={selectEvolutionDirectionList}
                                                       onChange={(event: any) => setSelectEvolutionDirectionList(event.target.value)}
                                                >
                                                    <option value="">Не выбрано</option>
                                                    {
                                                        evolutionDirectionList.map((group) => {
                                                            return <option key={group.uid}
                                                                           value={group.uid}>{group.name}</option>
                                                        })
                                                    }

                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <label htmlFor="">Период тестирования:</label>
                                                <Field as="select" name="period_test"
                                                       className={`form-select shadow ${!selectPeriodTest && touched.period_test && 'border-danger'}`}
                                                       value={selectPeriodTest}
                                                       onChange={(event: any) => setSelectPeriodTest(event.target.value)}
                                                >
                                                    <option value="">Не выбрано</option>
                                                    {
                                                        listPeriodTest.map((group) => {
                                                            return <option key={group.uid}
                                                                           value={group.uid}>{group.name}</option>
                                                        })
                                                    }
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <label htmlFor="">Вид тестирования:</label>
                                                <Field as="select" name="type_test"
                                                       className={`form-select shadow ${!selectTypeTest && touched.type_test && 'border-danger'}`}
                                                       value={selectTypeTest}
                                                       onChange={(event: any) => setSelectTypeTest(event.target.value)}
                                                >
                                                    <option value="">Не выбрано</option>
                                                    {
                                                        listTypeTest.map((group) => {
                                                            return <option key={group.name}
                                                                           value={group.name}>{group.name}</option>
                                                        })
                                                    }
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <label htmlFor="">Контролер:</label>
                                                <Field as="select" name="controler"
                                                       className={`form-select shadow ${!selectUser && touched.controler && 'border-danger'}`}
                                                       value={selectUser}
                                                       onChange={(event: any) => setSelectUser(event.target.value)}>
                                                    <option value="">Не выбрано</option>
                                                    {
                                                        users.map((elem, index) => <option key={index}
                                                                                           value={elem.user1c}>{`${elem.name} ${elem.middle_name}`}</option>)
                                                    }
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <label htmlFor="">Программа образования:</label>
                                                <Field as="select" name="programm"
                                                       className={`form-select shadow ${!selectListEvolutionProgram && touched.programm && 'border-danger'}`}
                                                       value={selectListEvolutionProgram}
                                                       onChange={(event: any) => setSelectListEvolutionProgram(event.target.value)}>
                                                    <option value="">Не выбрано</option>
                                                    {
                                                        listEvolutionProgram.map((elem, index) => <option key={index}
                                                                                                          value={elem.uid}>{elem.name}</option>)
                                                    }
                                                </Field>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2" className="align-items-center">
                                        <Row className="mt-2">
                                            <Col>
                                                <label htmlFor="">Показатель:</label>
                                                <Field as="select" name="pokaz"
                                                       className={`form-select shadow ${!selectChildEvolutionStudentsList && touched.pokaz && 'border-danger'}`}
                                                       value={selectChildEvolutionStudentsList}
                                                       onChange={(event: any) => setSelectChildEvolutionStudentsList(event.target.value)}>
                                                    <option value="">Не выбрано</option>
                                                    {
                                                        childEvolutionStudentsList?.indicators.map((elem: any, index: number) =>
                                                            <option key={index}
                                                                    value={elem.uid}>{elem.name}</option>)
                                                    }
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <label htmlFor="">Значение по умолчанию:</label>
                                                <Row>
                                                    <Col>
                                                        <select className="form-select shadow"
                                                                value={selectEvolutionDirectionOfChildList}
                                                                onChange={(event) => setSelectEvolutionDirectionOfChildList(event.target.value)}>
                                                            <option value="">Не выбрано</option>
                                                            {
                                                                childEvolutionStudentsList?.marks.map((elem: any, index: number) =>
                                                                    <option key={index}
                                                                            value={elem.name}>{elem.name}</option>)
                                                            }
                                                        </select>
                                                    </Col>
                                                    <Col xxl={3} xl={3}>
                                                        <button type="button"
                                                                className="btn btn-primary w-100 mt-xl-0 mt-2"
                                                                onClick={() => checkAll()}>Для всех
                                                        </button>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col>
                                                        <DataTable
                                                            columns={columns}
                                                            data={childEvolutionStudentsList?.indicators.find((el: any) => el.uid === selectChildEvolutionStudentsList)?.children}
                                                            className="custom-scroll-news"
                                                            fixedHeader={true}
                                                            fixedHeaderScrollHeight={'400px'}
                                                            noDataComponent={'В данной группе нет детей'}
                                                            highlightOnHover
                                                            customStyles={{
                                                                rows: {
                                                                    highlightOnHoverStyle: {
                                                                        backgroundColor: "#ffebb5"
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <SubmitWithLoading text={"Сохранить"}/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddLevelEvolutionChildren;

import React, {FC, useEffect, useState} from 'react';
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment";
import AxiosClient from "../../../api/AxiosClient";

interface  IUpdateObjectDesinfectinGeneralCleaning{
    selectSchedule: any,
    selectScheduleDate: any,
    updateObjectsDisinfection: any,
    setUpdateObjectsDisinfection(value: any): void
}

const UpdateObjectDesinfectinGeneralCleaning: FC <IUpdateObjectDesinfectinGeneralCleaning> = ({selectSchedule, selectScheduleDate, updateObjectsDisinfection, setUpdateObjectsDisinfection}) => {

    const [objectsDisinfection, setObjectsDisinfection] = useState<any[]>([]);

    useEffect(() => {
        if(selectScheduleDate){
            const params = {
                schedule: selectSchedule,
                dateSchedule: selectScheduleDate
            }
            AxiosClient.get(`haccp/getObjectsDisinfection`, {params})
                .then((r: any) => {
                    const originalData = r.data;
                    setObjectsDisinfection(originalData);

                    const updatedData = originalData.map((item: any) => ({
                        ...item,
                        timeOfDisinfection: "00:00"
                    }));
                    setUpdateObjectsDisinfection(updatedData);
                })
                .catch((error) => console.log(error))
        }
    }, [selectScheduleDate]);

    return (
        <>
            {updateObjectsDisinfection.map((room: any, index: number) => (
                <div className="mb-3" key={index}>
                    <label className="w-100">{room.object_name}
                        <Flatpickr
                            className="form-control border-0 dash-filter-picker shadow"
                            value={room.timeOfDisinfection !== "00:00" ? room.timeOfDisinfection : undefined} // значение по умолчанию
                            options={{
                                locale: Russian,
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i",
                                time_24hr: true,
                                disableMobile: true,
                                mode: 'single'
                            }}
                            onChange={(time) => {
                                const newTime = moment(time[0]).format('HH:mm');
                                const updatedRooms: any = [...updateObjectsDisinfection];
                                updatedRooms[index].timeOfDisinfection = newTime;
                                setUpdateObjectsDisinfection(updatedRooms);
                            }}
                            onOpen={() => {
                                if (room.timeOfDisinfection === "00:00") {
                                    const originalTime = objectsDisinfection[index]?.timeOfDisinfection;
                                    if (originalTime) {
                                        const updatedRooms: any = [...updateObjectsDisinfection];
                                        updatedRooms[index].timeOfDisinfection = originalTime;
                                        setUpdateObjectsDisinfection(updatedRooms);
                                    }
                                }
                            }}
                        />
                    </label>
                </div>

            ))}
        </>
    );
};

export default UpdateObjectDesinfectinGeneralCleaning;

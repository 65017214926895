import React, {useEffect} from 'react';
import {useAppDispatch} from "../hooks/redux";
import {
    setChildren
} from "../store/reducers/widgets/WidgetsSlice";
import AxiosClient from "../api/AxiosClient";
import {Col, Container, Row} from "reactstrap";
import {Helmet} from "react-helmet";
import WidgetRequiredFlatpikerPeriod from "../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import WidgetRequiredSelectGroupDO from "../components/widgets/requireds/WidgetRequiredSelectGroupDO";
import WidgetTableAllDeviationsGroupDO from "../components/widgets/tables/WidgetTableAllDeviationsGroupDO";
import WidgetGraphProjectOverviewDO from "../components/widgets/graphs/WidgetGraphProjectOverviewDO";
import WidgetTableAllInfoGroupDO from "../components/widgets/tables/WidgetTableAllInfoGroupDO";
import WidgetGraphRoundDO from "../components/widgets/graphs/WidgetGraphRoundDO";

const MainPaidService = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        AxiosClient.post('/childrens')
            .then(
                res => {
                    dispatch(setChildren(res.data))
                }
            )
    }, [dispatch])

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Табель по платным услугам</title>
        </Helmet>
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Row>
                        <Col xxl={3}>
                            <WidgetRequiredFlatpikerPeriod/>
                            <WidgetRequiredSelectGroupDO/>
                            <WidgetGraphRoundDO/>
                            <WidgetTableAllDeviationsGroupDO/>
                        </Col>
                        <Col xxl={9}>
                            <WidgetGraphProjectOverviewDO/>
                            <WidgetTableAllInfoGroupDO/>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </div>
    </>)
};

export default MainPaidService;

import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import Widget from "../../components/department/DeviationsChildrenInOrganization/Widget";
import WidgetRequiredFlatpikerPeriod from "../../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import TableCountChildrenDeviations
    from "../../components/department/DeviationsChildrenInOrganization/TableCountChildrenDeviations";
import GraphCountChildrenOrganization
    from "../../components/department/AttendanceInOrganization/GraphCountChildrenOrganization";
import GraphCountChildrenYear from "../../components/department/AttendanceInOrganization/GraphCountChildrenYear";
import TableCountChildrenRecomendedLessons
    from "../../components/department/DeviationsChildrenInOrganization/TableCountChildrenRecomendedLessons";
import PhoneOrPC from "../../components/department/PhoneOrPC";
import TableCountChildrenDeviationsPart1
    from "../../components/department/DeviationsChildrenInOrganization/TablePhone/TableCountChildrenDeviationsPart1";
import TableCountChildrenDeviationsPart2
    from "../../components/department/DeviationsChildrenInOrganization/TablePhone/TableCountChildrenDeviationsPart2";
import TableCountChildrenRecomendedLessonsPart1
    from "../../components/department/DeviationsChildrenInOrganization/TablePhone/TableCountChildrenRecomendedLessonsPart1";
import TableCountChildrenRecomendedLessonsPart2
    from "../../components/department/DeviationsChildrenInOrganization/TablePhone/TableCountChildrenRecomendedLessonsPart2";
import SelectOrganization from "../../components/department/SelectOrganization";

const DeviationsChildrenInOrganization = () => {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Отклонения в организациях</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xxl={3}>
                            <WidgetRequiredFlatpikerPeriod/>
                            <SelectOrganization/>
                            <GraphCountChildrenOrganization/>
                            <GraphCountChildrenYear/>
                        </Col>
                        <Col xxl={9}>
                            <Widget/>
                            <PhoneOrPC phone={[<TableCountChildrenDeviationsPart1/>, <TableCountChildrenDeviationsPart2/>,
                                                <TableCountChildrenRecomendedLessonsPart1/>, <TableCountChildrenRecomendedLessonsPart2/>]}
                                       pc={[<TableCountChildrenDeviations/>, <TableCountChildrenRecomendedLessons/>]}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default DeviationsChildrenInOrganization;

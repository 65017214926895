import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const TableMethodologicalWorkTwoPart1 = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState([
        {
            org: "Организация 1",
            individualPlanTutor: '3',
            twoTutorPrfEvolution: '3',
            tutors: '3',
            attendants: '23',
            attendantsFive: '23',
            teacherOYFive: '23',
        },
        {
            org: "Организация 2",
            individualPlanTutor: '3',
            twoTutorPrfEvolution: '3',
            tutors: '3',
            attendants: '23',
            attendantsFive: '23',
            teacherOYFive: '23',
        },
        {
            org: "Организация 3",
            individualPlanTutor: '3',
            twoTutorPrfEvolution: '3',
            tutors: '3',
            attendants: '23',
            attendantsFive: '23',
            teacherOYFive: '23',
        },
        {
            org: "Организация 4",
            individualPlanTutor: '3',
            twoTutorPrfEvolution: '3',
            tutors: '3',
            attendants: '23',
            attendantsFive: '23',
            teacherOYFive: '23',
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns: any = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: typeof data[0]) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Индивидуальных планов наставничества</span>,
            selector: (row: typeof data[0]) => row.individualPlanTutor,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Наставнечиских пар по проф. развитию педагогов</span>,
            selector: (row: typeof data[0]) => row.twoTutorPrfEvolution,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Тьюторов</span>,
            selector: (row: typeof data[0]) => row.tutors,
        }
    ]

    return (
        <Card>
            <CardHeader>
                Наставничество
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableMethodologicalWorkTwoPart1;

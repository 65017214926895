import {Field} from "formik";
import {FC, useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import AxiosClient from "../../../../api/AxiosClient";

interface ITableAddDayliReportNutrationSecondBreakfast {
    studentsDayliReport: any[]
}
const TableAddDayliReportNutrationSecondBreakfast: FC<ITableAddDayliReportNutrationSecondBreakfast> = ({studentsDayliReport}) => {


    const [breakfast, setBreakfast] = useState<any[]>([]);

    useEffect(() => {
        AxiosClient.get('/second_brunch').then(
            r => setBreakfast(r.data)
        )
    }, []);


    const columns: any = [
        {
            name: <span className='font-weight-bold fs-13'>ФИО ребенка</span>,
            selector: (row: any) => row.name,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Второй ужин</span>,
            selector: (row: any, i: number) => (
                <Field as="select" className="form-select table-add-ceil" name={`second_brunch[${i}].${row.children_id}`}>
                    {
                        breakfast.map(br => <option key={br.id} value={br.id}>{br.name}</option>)
                    }
                </Field>
            ),
            center: true,
            style: {padding: '0'}
        }
    ];

    return (
        <DataTable
            columns={columns}
            data={studentsDayliReport}
            className="custom-scroll-news"
            fixedHeader
            fixedHeaderScrollHeight={'400px'}
            noDataComponent={'В данной группе нет детей'}
            highlightOnHover
            customStyles={{
                rows: {
                    highlightOnHoverStyle: {
                        backgroundColor: "#ffebb5"
                    }
                }
            }}
        />
    );
}

export default TableAddDayliReportNutrationSecondBreakfast;
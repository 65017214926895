import {AxiosResponse} from "axios";
import {ITypeCabinet} from "../../../models/ITypeCabinet";
import $api from "../../AxiosClient";

class TypeCabinetService {
    static async getTypeCabinets(): Promise<AxiosResponse<ITypeCabinet[]>>{
        return $api.post<ITypeCabinet[]>("/type-cabinets");
    }
    static async addTypeCabinets(value: object): Promise<AxiosResponse<void>>{
        return $api.post<void>("/type-cabinet/add", value);
    }
    static async updateTypeCabinets(value: object, typeCabinetId: number): Promise<AxiosResponse<void>>{
        return $api.post<void>(`/type-cabinet/update/${typeCabinetId}`, value);
    }
}

export default TypeCabinetService
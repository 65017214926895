import {AxiosResponse} from "axios";
import {IGroup} from "../../../models/IGroup";
import $api from "../../AxiosClient";

class GroupService {
    static async getGroup(): Promise<AxiosResponse<IGroup[]>>{
        return $api.post<IGroup[]>("/groups");
    }

    static async addGroup(value: object): Promise<AxiosResponse<void>>{
        return $api.post<void>("/group/add", value);
    }

    static async updateGroup(value: object, groupId: number): Promise<AxiosResponse<void>>{
        return $api.post<void>(`/group/update/${groupId}`, value);
    }
}

export default GroupService
import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import WidgetRequiredFlatpikerPeriod from "../../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import GraphCountChildrenOrganization
    from "../../components/department/AttendanceInOrganization/GraphCountChildrenOrganization";
import SelectOrganization from "../../components/department/SelectOrganization";
import GraphCountChildrenYear from "../../components/department/AttendanceInOrganization/GraphCountChildrenYear";
import PhoneOrPc from "../../components/department/PhoneOrPC";
import TableCountChildrenOVZDisabledPersons
    from "../../components/department/OVZDisabledPersonPMPK/TableCountChildrenOVZDisabledPersons";
import TableCountChildrenSpecialGroup
    from "../../components/department/OVZDisabledPersonPMPK/TableCountChildrenSpecialGroup";
import TableCountChildrenSpecialGroupDeviation
    from "../../components/department/OVZDisabledPersonPMPK/TableCountChildrenSpecialGroupDeviation";
import Widgets from "../../components/department/OVZDisabledPersonPMPK/Widgets";
import TableCountChildrenOvzDisabledPersonsPart1
    from "../../components/department/OVZDisabledPersonPMPK/TablePhone/TableCountChildrenOVZDisabledPersonsPart1";
import TableCountChildrenOvzDisabledPersonsPart2
    from "../../components/department/OVZDisabledPersonPMPK/TablePhone/TableCountChildrenOVZDisabledPersonsPart2";
import TableCountChildrenSpecialGroupPart1
    from "../../components/department/OVZDisabledPersonPMPK/TablePhone/TableCountChildrenSpecialGroupPart1";
import TableCountChildrenSpecialGroupPart2
    from "../../components/department/OVZDisabledPersonPMPK/TablePhone/TableCountChildrenSpecialGroupPart2";
import TableCountChildrenSpecialGroupDeviationPart1
    from "../../components/department/OVZDisabledPersonPMPK/TablePhone/TableCountChildrenSpecialGroupDeviationPart1";
import TableCountChildrenSpecialGroupDeviationPart2
    from "../../components/department/OVZDisabledPersonPMPK/TablePhone/TableCountChildrenSpecialGroupDeviationPart2";

const OVZDisabledPersonPMPK = () => {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ОВЗ, Инвалиды, ПМПК</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xxl={3}>
                            <WidgetRequiredFlatpikerPeriod/>
                            <SelectOrganization/>
                            <GraphCountChildrenOrganization/>
                            <GraphCountChildrenYear/>
                        </Col>
                        <Col xxl={9} >
                            <Widgets/>
                            <PhoneOrPc phone={[<TableCountChildrenOvzDisabledPersonsPart1/>, <TableCountChildrenOvzDisabledPersonsPart2/>,
                                            <TableCountChildrenSpecialGroupPart1/>, <TableCountChildrenSpecialGroupPart2/>,
                                            <TableCountChildrenSpecialGroupDeviationPart1/>, <TableCountChildrenSpecialGroupDeviationPart2/>]}
                                       pc={[<TableCountChildrenOVZDisabledPersons/>, <TableCountChildrenSpecialGroup/>, <TableCountChildrenSpecialGroupDeviation/>]}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default OVZDisabledPersonPMPK;

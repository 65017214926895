import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {ProjectsOverviewCharts} from "../ProjectStatusCharts";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {IDeviationGroupMonth} from "../../../models/widgets/IDeviationGroupMonth";
import {setToUpdateGraphMainPage} from "../../../store/reducers/widgets/WidgetsSlice";

const WidgetGraphProjectOverview = () => {
    const {groupAllDeviations, toUpdateGraphMainPage, date} = useAppSelector(state => state.WidgetReducer)
    const dispatch = useAppDispatch();
    const [chartData, setChartData] = useState<IDeviationGroupMonth[]>([]);
    const [data, setData] = useState<IDeviationGroupMonth[]>([]);

    useEffect(() => {
        toUpdateGraphMainPage &&
        WidgetService.getDeviationGroupMonth(null, date)
            .then(res => {
                setChartData(res.data)
                dispatch(setToUpdateGraphMainPage(false))
            })
    }, [toUpdateGraphMainPage, date, dispatch])

    useEffect(() => {
        WidgetService.getDeviationGroupMonth(null, date)
            .then(res => setChartData(res.data))
    }, [date])

    useEffect(() => {
        if (groupAllDeviations !== "" && chartData.length > 0)
            setData(chartData.filter((item: any) => item.group_id === groupAllDeviations))
        else
            setData([{
                year: 0,
                mounth: '',
                group_name: '',
                group_id: '',
                present: 0,
                no_present: 0,
                zabolevanie: 0
            }])
    }, [groupAllDeviations, chartData])

    return (
        <>
            <Card>
                <CardHeader className="border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Присутствует/Отсутствует/Болеет</h4>
                </CardHeader>

                <CardHeader className="p-0 border-0 bg-soft-light">
                    <Row className="g-0 text-center">
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1"><span className="counter-value" data-target="9851">
                                        {data.length > 0 ? data.map((item: any) => item.present).reduce((a: number, b: number) => a + b) : 'Загрузка'}
                                    </span></h5>
                                <p className="text-muted mb-0">Присутствует</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1"><span className="counter-value">
                                        {data.length > 0 ? data.map((item: any) => item.no_present).reduce((a: number, b: number) => a + b) : 'Загрузка'}
                                    </span></h5>
                                <p className="text-muted mb-0">Отсутствует</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1"><span className="counter-value">
                                        {data.length > 0 ? data.map((item: any) => item.zabolevanie).reduce((a: number, b: number) => a + b) : 'Загрузка'}
                                    </span></h5>
                                <p className="text-muted mb-0">Болеет</p>
                            </div>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className="p-0 pb-2">
                    <div>
                        {
                            data && <ProjectsOverviewCharts series={data}
                                                            dataColors='["--vz-primary", "--vz-danger", "--vz-warning"]'/>
                        }
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default WidgetGraphProjectOverview;

import React, {FC, useEffect, useState} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Russian} from "flatpickr/dist/l10n/ru";
import Flatpickr from "react-flatpickr";
import AxiosClient from "../../../api/AxiosClient";
import {Field, Form, Formik} from "formik";
import UserService from "../../../api/services/userService/UserService";
import moment from "moment";

interface IModalAddOperationControlPointControl {
    open: boolean,
    setOpen: (value: boolean) => void,
    point: null | any,
    listPointControlNew: any[],
    setListPointControlNew: (value: any[]) => void
    listMark: any[]
}

const ModalAddOperationControlPointControl: FC<IModalAddOperationControlPointControl> = ({point, open, listMark, setOpen, setListPointControlNew, listPointControlNew}) => {
    const [date, setDate] = useState(new Date());
    const [users, setUsers] = useState<any[]>([]);
    const [file, setFile] = useState<null | any>(null);
    useEffect(() => {
        UserService.getUsersOrganization()
            .then(r => setUsers(r.data))
    }, []);

    function addRezultFile(files: File[] | null) {

        let data: any[] = []
        if (files){
            files = [...files]
            files.forEach(file => {
                const fileElem = {
                    filename: '',
                    filetype: '',
                    base64: ''
                }
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    fileElem.base64 = (reader.result as string)!.split(',')[1].toString()
                };
                fileElem.filename = `${file.name}`
                fileElem.filetype = file.type
                data.push(fileElem)
            })
        }

        return data
    }

    return (
        <Modal id="createProjectModal" isOpen={open}
               toggle={() => setOpen(!open)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setOpen(!open)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Пункт контроля
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        mark: point?.mark,
                        controller: users[0] && users[0].user1c,
                        comment: point?.message
                    }}
                    onSubmit={(values) => {
                        setListPointControlNew(
                            listPointControlNew.map((elem: any) => {
                                if (elem.subject_uid === point.subject_uid){
                                    return {
                                        number: elem.number,
                                        subject_name: point.subject_name,
                                        subject_uid: point.subject_uid,
                                        mark: values.mark,
                                        message: values.comment,
                                        responsible: values.controller,
                                        base64: '',
                                        deadline_date: moment(date).format('D.M.Y'),
                                        files: addRezultFile(file),
                                    }
                                }
                                return elem
                            })
                        )

                        setOpen(false)
                    }}
                >
                    <Form>
                        <div className="col-auto mb-2">
                            <label>Номер пункта: {point && point.number}</label>
                        </div>
                        <div className="col-auto mb-2">
                            <label>Предмет проверки: {point && point.subject_name}</label>
                        </div>
                        <div className="col-auto mb-2">
                            <label>Оценка:</label>
                            <div className="col">
                                <div className="input-group">
                                    <Field as="select" name="mark" className="form-control">
                                        {
                                            listMark.map((elem, index) => <option key={index}
                                                                                  value={elem.name}>{elem.synonim}</option>)
                                        }
                                    </Field>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto mb-2">
                            <label>Комментарий:</label>
                            <div className="col">
                                <div className="input-group">
                                    <Field as="textarea" name="comment" className="form-control" rows={4}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto mb-2">
                            <label>Файл:</label>
                            <div className="col">
                                <div className="input-group">
                                    <input type="file" multiple className="form-control" onChange={event => {
                                        setFile(event.target.files && event.target.files)
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto mb-2">
                            <label>Ответственный:</label>
                            <div className="col">
                                <div className="input-group">
                                    <Field as="select" name="responsible" className="form-control">
                                        {
                                            users.map((elem, index) => <option key={index}
                                                                               value={elem.user1c}>{`${elem.name} ${elem.middle_name}`}</option>)
                                        }
                                    </Field>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto mb-2">
                            <label>Срок:</label>
                            <div className="col">
                                <div className="input-group">
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker shadow"
                                        value={date}
                                        options={{
                                            locale: Russian, mode: "single", dateFormat: "d m Y",
                                            minDate: "today"
                                        }}
                                        onChange={(date) => setDate(date[0])}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="hstack gap-2 d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary" id="addNewProject">ОК</button>
                        </div>
                    </Form>
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddOperationControlPointControl;

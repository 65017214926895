import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import {Link} from "react-router-dom";

const RegulatoryLegalBase = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState<any[]>([
        {
            org: "Организация 1",
            isDocs: 'Да',
            link: 'https://vk.ru',
            isDocsOVZ: 'Нет',
            linkOVZ: 'https://ok.ru'
        },
        {
            org: "Организация 2",
            isDocs: 'Да',
            link: 'https://vk.ru',
            isDocsOVZ: 'Нет',
            linkOVZ: 'https://ok.ru'
        },
        {
            org: "Организация 3",
            isDocs: 'Да',
            link: 'https://vk.ru',
            isDocsOVZ: 'Нет',
            linkOVZ: 'https://ok.ru'
        },
        {
            org: "Организация 4",
            isDocs: 'Да',
            link: 'https://vk.ru',
            isDocsOVZ: 'Нет',
            linkOVZ: 'https://ok.ru'
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Наличие док-ов регламентирующие работу</span>,
            selector: (row: any) => row.isDocs,
        },
        {
            name: <span className='font-weight-bold fs-13'>Сслыка на документы</span>,
            selector: (row: any) => (<Link target="_blank"  to={row.link}>Сслыка на док-ты</Link>),
        },
        {
            name: <span className='font-weight-bold fs-13'>Наличие док-ов регламентирующие работу(ОВЗ)</span>,
            selector: (row: any) => row.isDocsOVZ,
        },
        {
            name: <span className='font-weight-bold fs-13'>Сслыка на документы(ОВЗ)</span>,
            selector: (row: any) => (<Link target="blank" to={row.linkOVZ}>Сслыка на док-ты</Link>),
        }
    ]

    return (
        <Card>
            <CardHeader>
                Учет талантливых детей
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default RegulatoryLegalBase;

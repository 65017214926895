import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import Select from "react-select";
import TableCountChildrenPhysicalEducation
    from "../../components/department/CountChildrenPhysicalEducation/TableCountChildrenPhysicalEducation";
import TableAllCountInDOY from "../../components/department/CountChildrenPhysicalEducation/TableAllCountInDOY";
import TableAllCountInAPPG from "../../components/department/CountChildrenPhysicalEducation/TableAllCountInAPPG";
import GraphDOYPhysicalEducation
    from "../../components/department/CountChildrenPhysicalEducation/GraphDOYPhysicalEducation";
import GraphAllPhysicalEducation
    from "../../components/department/CountChildrenPhysicalEducation/GraphAllPhysicalEducation";
import GraphAPPGPhysicalEducation
    from "../../components/department/CountChildrenPhysicalEducation/GraphAPPGPhysicalEducation";
import Widget from "../../components/department/CountChildrenPhysicalEducation/Widget";

const CountChildrenPhysicalEducation = () => {

    const [years, setYears] = useState<any[]>([{value: new Date().getFullYear(), label: new Date().getFullYear()}]);

    const customStyles = {
        control: (ds: any) => ({
            ...ds,
            backgroundColor: "#fff",
        }),
        option: (ds: any, state: any) => ({
            ...ds,
            backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
            color: state.isFocused ? "#fff" : "#000"
        })
    }

    function yearsLab(data: number) {
        let currentYear = new Date().getFullYear(), years: any[] = [];
        data = 1980;
        while ( data <= currentYear ) {
            const d = data++
            years.push({value: d, label: d});
        }
        return years;
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Физическая культура</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <Select options={yearsLab(new Date().getFullYear())}
                                        value={years}
                                        onChange={(newValue) => {
                                            setYears(newValue as any[])
                                        }}
                                        closeMenuOnSelect={false}
                                        name="stock"
                                        styles={customStyles}
                                        placeholder="Поиск"
                                        maxMenuHeight={500}
                                        minMenuHeight={200}
                                        isMulti
                                        className="mb-2"/>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Widget/>
                            </Row>
                            <Row>
                                <Col xxl={4} xl={6}>
                                    <GraphDOYPhysicalEducation/>
                                </Col>
                                <Col xxl={4} xl={6}>
                                    <GraphAPPGPhysicalEducation/>
                                </Col>
                                <Col xxl={4}>
                                    <GraphAllPhysicalEducation/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <TableCountChildrenPhysicalEducation years={years}/>
                            <TableAllCountInDOY/>
                            <TableAllCountInAPPG/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default CountChildrenPhysicalEducation;

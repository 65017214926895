import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {ISettingsShot, IShot} from "../../models/game/IShot";
import {IGame} from "../../models/game/IGame";
import {validateGame} from "./validateGame";
import AxiosClient from "../../api/AxiosClient";
import {Col, Container, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import cn from "classnames";
import ModalNameGame from "./constructorUpdate/ModalNameGame";
import ListShots from "./constructorUpdate/ListShots";
import FullShot from "./constructorUpdate/FullShot";
import SettingsShot from "./constructorUpdate/SettingsShot";
import ModalAddShot from "./constructorUpdate/ModalAddShot";
import Notification from "../widgets/Notification";
import {ISettingsTexture} from "../../models/game/ISettingsTexture";

const GameUpdate = () => {
    const {id} = useParams()

    const [isHoverCreate, setIsHoverCreate] = useState(false);
    const [isHoverSave, setIsHoverSave] = useState(false);
    const [isHoverAddShot, setIsHoverAddShot] = useState(false);
    const [modalGameName, setModalGameName] = useState(false);
    const [openSettings, setOpenSettings] = useState(false);
    const [openFullShot, setOpenFullShot] = useState(false);
    const [modalAddShot, setModalAddShot] = useState(false);
    const [selectSettingsShot, setSelectSettingsShot] = useState<IShot | null>(null);
    const [game, setGame] = useState<IGame | null>(null);
    const [savingGame, setSavingGame] = useState(false);
    const [error, setError] = useState('');
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState(false);
    const [delShot, setDelShot] = useState<IShot | null>(null);
    const [selectCopyShot, setSelectCopyShot] = useState<IShot | null>(null);
    const [isOpenCopy, setIsOpenCopy] = useState(false);
    const navigate = useNavigate()
    const toggle = () => setModalGameName(!modalGameName);
    const toggleSave = (game: IGame) => {
        setGame(game)
        setModalGameName(!modalGameName)
    };

    const handleOpenSettings = () => {
        setOpenSettings(!openSettings)
        setOpenFullShot(true)
    }

    const toggleAddShot = () => setModalAddShot(!modalAddShot);
    const toggleAddShotSave = (name: string) => {
        const minId = game?.shots.reduce((min, item) => (item.id < min ? item.id : min), 0) ?? 0;
        setGame(game => {
            if (game) {
                return {
                    ...game,
                    shots: [...game.shots, {
                        id: minId > 0 ? 0 : minId - 1,
                        index: game.shots.length - 1,
                        useSoundBack: false,
                        widthTextures: 300,
                        heightTextures: 300,
                        name: name,
                        pathHint: '',
                        text: '',
                        hint: '',
                        personages: [],
                        textures: [],
                        backgroundImage: null,
                        backgroundSound: null,
                        isGPTChat: false,
                        settings: null,
                        defaultAnimate: 'none',
                        settingsAutoPlay: {
                            play: false,
                            time: 1
                        }
                    }]
                }
            }
            return null
        })
        setModalAddShot(!modalAddShot)
    };

    const handleCloseShot = (shot: IShot) => {
        setGame((game) => {
            if (game) {
                return {
                    ...game,
                    shots: game?.shots.map(el => el.id === shot.id ? shot : el)
                }
            }
            return null
        })
        setOpenFullShot(false)
    }

    const saveSettingsShot = (shot: IShot) => {
        setGame((game) => {
            if (game) {
                return {
                    ...game,
                    shots: game.shots.map((oldShot) => {
                        if (oldShot.id === shot.id) {
                            return shot
                        }
                        return oldShot
                    })
                }
            }
            return null
        })
        setSelectSettingsShot(shot)
    }

    const saveGame = () => {
        if (game) {
            const validError = validateGame(game)
            if (validError) {
                setError(validError)
                setShowNotification(true)
            } else {
                setSavingGame(true)
                const formData = new FormData()
                if (game?.file) {
                    formData.append('file', game.file)
                    formData.append('data', JSON.stringify(game))
                } else {
                    formData.append('data', JSON.stringify(game))
                }
                AxiosClient.post(`/game/update/${game.id}`, formData, {headers: {"Content-Type": "multipart/form-data"}})
                    .then(r => {
                        setSavingGame(false)
                        navigate('/games/teacher')
                    })
                    .catch((reason) => {
                        setError(reason.response.data.message ?? 'Ошибка создания игры')
                        setShowNotification(true)
                        setSavingGame(false)
                    })
            }
        }
    }

    useEffect(() => {
        id &&
        AxiosClient.get<IGame>(`/game/game/${id}`)
            .then((r) => {
                const convertGame: IGame = {
                    ...r.data,
                    classes: JSON.parse(r.data.classes as any) as any[],
                    shots: r.data.shots.map((shot, index) => {
                        const shotSettings = JSON.parse(shot.settings as any) as ISettingsShot
                        let settingsAutoPlay = JSON.parse(shot.settingsAutoPlay as any)
                        if (!settingsAutoPlay || settingsAutoPlay === 'null') {
                            settingsAutoPlay = {
                                play: false,
                                time: 1,
                            }
                        }
                        return {
                            ...shot,
                            index: index,
                            settings: shotSettings ? shotSettings : null,
                            settingsAutoPlay: settingsAutoPlay,
                            backgroundSound: shot.background_sound ? shot.background_sound : null,
                            backgroundImage: shot.background ? shot.background : null,
                            widthTextures: 300,
                            heightTextures: 300,
                            text: (shot as any).startText,
                            textures: shot.textures.map((texture) => {
                                const textureSettings = JSON.parse(texture.pivot.settings as any) as ISettingsTexture;
                                return {
                                    ...texture,
                                    pivot: {
                                        ...texture.pivot,
                                        description: texture.pivot.description ? texture.pivot.description : texture.name,
                                        settings: {
                                            ...textureSettings
                                        }
                                    }
                                }
                            }),
                        }
                    })
                }
                setGame(convertGame)
            })
    }, [id]);

    const deleteShot = (shot: IShot) => {
        setIsOpen(true)
        setDelShot(shot)
    }



    const copyShot = (shot: IShot) => {
        setIsOpenCopy(true)
        setSelectCopyShot(shot)
    }

    const copy = () => {
        if (selectCopyShot) {
            const minId = game?.shots.reduce((min, item) => (item.id < min ? item.id : min), 0) ?? 0;
            setGame(game => {
                if (game) {
                    return {
                        ...game,
                        shots: [...game.shots, {
                            ...selectCopyShot,
                            id: minId > 0 ? 0 : minId - 1,
                            index: game.shots.length - 1,
                            name: selectCopyShot.name + ' - копия'
                        }]
                    }
                }
                return null
            })
            setIsOpenCopy(false)
            setSelectCopyShot(null)
        }
    }
    const removeShot = () => {
        delShot &&
        setGame((game) => {
            if (game) {
                return {
                    ...game,
                    shots: game.shots.filter((oldShot) => oldShot.id !== delShot.id)
                }
            }
            return null
        })
        setIsOpen(false)
    }

    const toggleDelete = () => {
        setIsOpen((value) => !value)
    }

    const toggleCopy = () => {
        setIsOpenCopy((value) => !value)
    }

    return (
        <div className="page-content" style={{height: '100vh'}}>
            <Helmet>
                <meta charSet="utf-8"/>  
                <title>Обновление игры</title>
            </Helmet>
            <Container fluid className="h-100">
                <div className="h-100 w-100 bg-primary bg-opacity-10 position-relative p-2" id="window">
                    <Row>
                        <Col sm={12}>
                            <Row>
                                <Col xxl={3}>
                                    <div className="bg-white fs-1 d-flex align-items-center gap-2 rounded shadow">
                                        <i className={cn("bx bx-folder-plus cursor-pointer", {
                                            'text-primary': isHoverCreate
                                        })}
                                           onMouseEnter={() => setIsHoverCreate(true)}
                                           onMouseLeave={() => setIsHoverCreate(false)}
                                           onClick={toggle}
                                        ></i>
                                        <i className={cn("bx bx-save cursor-pointer", {
                                            'text-primary': isHoverSave
                                        })}
                                           onMouseEnter={() => setIsHoverSave(true)}
                                           onMouseLeave={() => setIsHoverSave(false)}
                                           onClick={() => saveGame()}
                                        ></i>
                                    </div>
                                </Col>
                            </Row>
                            <ModalNameGame toggle={toggle} toggleSave={toggleSave} isOpen={modalGameName} dataGame={game}/>
                        </Col>
                        <Col sm={12} className="my-5">
                            <ListShots handleOpenSettings={handleOpenSettings} listShot={game?.shots ?? []}
                                       selectShot={(shot) => setSelectSettingsShot(shot)}
                                       copyShot={copyShot}
                                       deleteShot={(shot) => deleteShot(shot)}
                                       onListShot={(shots) => {
                                           setGame((game) => {
                                               if (game) {
                                                   return {...game, shots: shots}
                                               }
                                               return null
                                           })
                                       }}
                            />
                        </Col>
                    </Row>
                    {
                        savingGame && <div
                            className="d-flex justify-content-center align-items-center bg-dark bg-opacity-50 position-absolute top-0 start-0 w-100 h-100"
                            style={{zIndex: 9999}}>
                            <div className="text-center">
                                <div className="text-light fs-3 mb-3">Сохранение</div>
                                <Spinner className="text-light" style={{width: '3rem', height: '3rem'}}/>
                            </div>
                        </div>
                    }
                    {
                        selectSettingsShot &&
                        <FullShot shot={selectSettingsShot} openFullShot={openFullShot} handleCloseShot={handleCloseShot}
                                  openSettings={openSettings}/>
                    }
                    {
                        selectSettingsShot && game &&
                        <SettingsShot game={game} setGame={game => setGame(game)} openSettings={openSettings}
                                      handleOpenSettings={handleOpenSettings} selectedShot={selectSettingsShot}
                                      saveSettingsShot={(shot) => saveSettingsShot(shot)}/>
                    }
                    {
                        !openSettings && !openFullShot && game &&
                        <div
                            className="position-absolute bottom-0 mb-2 end-0 bg-white fs-1 d-flex align-items-center gap-2 rounded mx-2 col-xxl-3 shadow">
                            <i className={cn("bx bx-plus cursor-pointer", {
                                'text-primary': isHoverAddShot
                            })}
                               onMouseEnter={() => setIsHoverAddShot(true)}
                               onMouseLeave={() => setIsHoverAddShot(false)}
                               onClick={toggleAddShot}
                            ></i>
                            <ModalAddShot toggle={toggleAddShot} isOpen={modalAddShot}
                                          toggleSave={(name) => toggleAddShotSave(name)}/>
                        </div>
                    }
                    {showNotification && <Notification type={'error'} message={error}
                                                       setShowNotification={(value: boolean) => setShowNotification(value)}/>}
                </div>
            </Container>
            <Modal isOpen={isOpen} toggle={toggleDelete} centered>
                <ModalHeader toggle={toggleDelete} className="p-3 bg-soft-primary">Удаление кадра</ModalHeader>
                <ModalBody>
                    Вы уверены, что хотите удалить кадр?
                    <div className="mt-3 gap-2 d-flex">
                        <button className="btn btn-primary" type="button" onClick={() => removeShot()}>
                            Удалить
                        </button>
                        <button className="btn btn-primary" type="button" onClick={toggleDelete}>
                            Отмена
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={isOpenCopy} toggle={toggleCopy} centered>
                <ModalHeader toggle={toggleCopy} className="p-3 bg-soft-primary">Копирование кадра</ModalHeader>
                <ModalBody>
                    Вы уверены, что хотите скопировать кадр?
                    <div className="mt-3 gap-2 d-flex">
                        <button className="btn btn-primary" type="button" onClick={() => copy()}>
                            Копировать
                        </button>
                        <button className="btn btn-primary" type="button" onClick={toggleCopy}>
                            Отмена
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default GameUpdate;

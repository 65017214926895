import React, {FC} from 'react';
import {Alert, Modal, ModalBody, ModalHeader} from "reactstrap";
import {useAppSelector} from "../../../hooks/redux";
import Mood from "./FormDailyReport/Mood";

interface IModalAddDayliReportNutrition {
    modalAdd: boolean,
    setModalAdd: (modalAdd: boolean) => void
}
const ModalAddDayliReportMood: FC<IModalAddDayliReportNutrition> = ({modalAdd, setModalAdd}) => {

    const {isError, successUpdate} = useAppSelector(state => state.WidgetReducer);


    return (
        <Modal id="createProjectModal" isOpen={modalAdd} toggle={() => setModalAdd(!modalAdd)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setModalAdd(!modalAdd)} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Отчет за день(Настроение)
            </ModalHeader>
            <ModalBody>
                {successUpdate && <Alert>{successUpdate}</Alert>}
                {isError && <Alert color="danger">{isError}</Alert>}
                <Mood setModalAdd={(value) => setModalAdd(value)}/>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddDayliReportMood;

import React, {FC, useState} from 'react';
import {Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classNames from "classnames";
import UpdateDataSubdivisionForm from "./UpdateDataSubdivisionForm";

const SubdivisionUpdate: FC = () => {

    const [activeTab, setActiveTab] = useState("1");
    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <>
            <Card className="mx-n3">
                <CardHeader>
                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                         role="tablist">
                        <NavItem>
                            <NavLink
                                className={classNames({active: activeTab === "1"})}
                                onClick={() => {
                                    tabChange("1");
                                }}
                                type="button">
                                <i className="fas fa-home"></i>
                                Обновление вида подразделения
                            </NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
                <CardBody className="p-4">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="align-items-center">
                            <UpdateDataSubdivisionForm/>
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
        </>
    );
};

export default SubdivisionUpdate;

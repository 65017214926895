import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import TableTeachersInDouCategory from "../../components/department/VSK/TableTeachersInDouCategory";
import PhoneOrPc from "../../components/department/PhoneOrPC";
import TableTeachersInDouCategoryPart1
    from "../../components/department/VSK/TablePhone/TableTeachersInDouCategoryPart1";
import TableTeachersInDouCategoryPart2
    from "../../components/department/VSK/TablePhone/TableTeachersInDouCategoryPart2";
import TableTeachersVskAttendance from "../../components/department/VSK/TableTeachersVSKAttendance";
import TableTeachersVskAttendancePart1
    from "../../components/department/VSK/TablePhone/TableTeachersVSKAttendancePart1";
import TableTeachersVskAttendancePart2
    from "../../components/department/VSK/TablePhone/TableTeachersVSKAttendancePart2";
import TableTeachersVSKAttendanceSeniorTeacherPart1
    from "../../components/department/VSK/TablePhone/TableTeachersVSKAttendanceSeniorTeacherPart1";
import TableTeachersVskAttendanceSeniorTeacherPart2
    from "../../components/department/VSK/TablePhone/TableTeachersVSKAttendanceSeniorTeacherPart2";
import TableTeachersVSKAttendanceSeniorTeacher
    from "../../components/department/VSK/TableTeachersVSKAttendanceSeniorTeacher";
import TableTeachersVSKAttendancePsychologyTeacherPart1
    from "../../components/department/VSK/TablePhone/TableTeachersVSKAttendancePsychologyTeacherPart1";
import TableTeachersVskAttendancePsychologyTeacherPart2
    from "../../components/department/VSK/TablePhone/TableTeachersVSKAttendancePsychologyTeacherPart2";
import TableTeachersVSKAttendancePsychologyTeacher
    from "../../components/department/VSK/TableTeachersVSKAttendancePsychologyTeacher";
import TableTeachersVSKAttendanceSpeechTherapist
    from "../../components/department/VSK/TableTeachersVSKAttendanceSpeechTherapist";
import TableTeachersVSKAttendanceSpeechTherapistPart1
    from "../../components/department/VSK/TablePhone/TableTeachersVSKAttendanceSpeechTherapistPart1";
import TableTeachersVskAttendanceSpeechTherapistPart2
    from "../../components/department/VSK/TablePhone/TableTeachersVSKAttendanceSpeechTherapistPart2";
import TableTeachersVSKAttendanceEducationActivites
    from "../../components/department/VSK/TableTeachersVSKAttendanceEducationActivites";
import TableTeachersVSKAttendanceEducationActivitesPart1
    from "../../components/department/VSK/TablePhone/TableTeachersVSKAttendanceEducationActivitesPart1";
import TableTeachersVskAttendanceEducationActivitesPart2
    from "../../components/department/VSK/TablePhone/TableTeachersVSKAttendanceEducationActivitesPart2";
import TableTeachersVSKAttendanceAll from "../../components/department/VSK/TableTeachersVSKAttendanceAll";
import TableTeachersVSKAttendanceAllPart1
    from "../../components/department/VSK/TablePhone/TableTeachersVSKAttendanceAllPart1";
import TableTeachersVSKAttendanceAllPart2
    from "../../components/department/VSK/TablePhone/TableTeachersVSKAttendanceAllPart2";
import GraphTeachersVSKAttendanceAll from "../../components/department/VSK/GraphTeachersVSKAttendanceAll";
import Widget from "../../components/department/VSK/Widget";

const Vsk = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ВСК</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Widget/>
                            </Row>
                            <Row>
                                <Col>
                                    <GraphTeachersVSKAttendanceAll/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <PhoneOrPc phone={[<TableTeachersInDouCategoryPart1/>, <TableTeachersInDouCategoryPart2/>,
                                            <TableTeachersVskAttendancePart1/>, <TableTeachersVskAttendancePart2/>,
                                            <TableTeachersVSKAttendanceSeniorTeacherPart1/>, <TableTeachersVskAttendanceSeniorTeacherPart2/>,
                                            <TableTeachersVSKAttendancePsychologyTeacherPart1/>, <TableTeachersVskAttendancePsychologyTeacherPart2/>,
                                            <TableTeachersVSKAttendanceSpeechTherapistPart1/>, <TableTeachersVskAttendanceSpeechTherapistPart2/>,
                                            <TableTeachersVSKAttendanceEducationActivitesPart1/>, <TableTeachersVskAttendanceEducationActivitesPart2/>,
                                            <TableTeachersVSKAttendanceAllPart1/>, <TableTeachersVSKAttendanceAllPart2/>]}
                                       pc={[<TableTeachersInDouCategory/>, <TableTeachersVskAttendance/>,
                                            <TableTeachersVSKAttendanceSeniorTeacher/>, <TableTeachersVSKAttendancePsychologyTeacher/>,
                                            <TableTeachersVSKAttendanceSpeechTherapist/>, <TableTeachersVSKAttendanceEducationActivites/>,
                                            <TableTeachersVSKAttendanceAll/>]}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Vsk;

import React, {FC, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {ProjectsOverviewChartsOperationalControl} from "../ProjectStatusCharts";
import 'moment/locale/ru';

interface IWidgetGraphProjectOverviewOperationalControl {
    data: any[]
}

 const WidgetGraphProjectOverviewOperationalControl: FC<IWidgetGraphProjectOverviewOperationalControl> = ({data}) => {

    return (
        <>
            <Card>
                <CardHeader className="border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Замечания/исправлено</h4>
                </CardHeader>

                <CardHeader className="p-0 border-0 bg-soft-light">
                    <Row className="g-0 text-center">
                        <Col xs={6} sm={6}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1"><span className="counter-value" data-target="9851">
                                        {data.length > 0 ? data.map(item => item.hard).reduce((a, b) => a + b) : 'Загрузка'}
                                    </span></h5>
                                <p className="text-muted mb-0">Грубые</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={6}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1"><span className="counter-value">
                                        {data.length > 0 ? data.map(item => item.all).reduce((a, b) => a + b) : 'Загрузка'}
                                    </span></h5>
                                <p className="text-muted mb-0">Всего</p>
                            </div>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className="p-0 pb-2">
                    <div>
                        <ProjectsOverviewChartsOperationalControl series={data}
                                                                  dataColors='["--vz-primary", "--vz-danger", "--vz-warning"]'/>
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default WidgetGraphProjectOverviewOperationalControl;

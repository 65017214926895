import {AxiosResponse} from "axios";
import {ITypeDeviation} from "../../../models/ITypeDeviation";
import $api from "../../AxiosClient";

class TypeDeviationService {
    static async getTypeDeviation(): Promise<AxiosResponse<ITypeDeviation[]>>{
        return $api.post<ITypeDeviation[]>("/type-deviations");
    }
    static async addTypeDeviation(value: object): Promise<AxiosResponse<void>>{
        return $api.post<void>("/type-deviation/add", value);
    }
    static async updateTypeDeviation(value: object, typeDeviationId: number): Promise<AxiosResponse<void>>{
        return $api.post<void>(`/type-deviation/update/${typeDeviationId}`, value);
    }
}

export default TypeDeviationService;
import React, {FC, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import AxiosClient from "../../../api/AxiosClient";
import {IMonthPotencySumm} from "../../../models/widgets/IMonthPotencySumm";
import {useAppSelector} from "../../../hooks/redux";
import moment from "moment/moment";

interface WidgetGraphProjectStatusEmployeePerformanceProps {
    date: Date
}
const WidgetGraphProjectStatusEmployeePerformance: FC<WidgetGraphProjectStatusEmployeePerformanceProps> = ({date}) => {

    const [state, setState] = useState<IMonthPotencySumm[]>([]);
    const [active, setActive] = useState('month');

    useEffect(() => {
        const params = {
            active,
            date: moment(date).endOf('month').toDate()
        }
        AxiosClient.get<IMonthPotencySumm[]>('/get_month_static_employee', {params})
            .then(r => setState(r.data))
    }, [active, date]);


    const options: any = {
        chart: {
            type: 'line',
            background: '#fff',
            toolbar: {
                show: false
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0],
            width: [0, 0],
        },
        fill: {
            opacity: [1, 1]
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: false,
                }
            },
            yaxis: {
                lines: {
                    show: true,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 20,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '50%',
                barHeight: '70%'
            }
        },
        xaxis: {
            categories: state.map(elem => elem.mounth)
        }
    }


    const data = [
        {
            name: "Всего",
            type: 'bar',
            data: state.length > 0 ? state.map(elem => elem.score) : []
        },
        {
            name: "Зависимые",
            type: 'bar',
            data: state.length > 0 ? state.map(elem => elem.depend_score_person) : []
        },
        {
            name: "Независимые",
            type: 'bar',
            data: state.length > 0 ? state.map(elem => elem.independ_score_person) : []
        },
        {
            name: "Принятые зависимые",
            type: 'bar',
            data: state.length > 0 ? state.map(elem => elem.depend_score_org) : []
        },
        {
            name: "Принятые не зависимые",
            type: 'bar',
            data: state.length > 0 ? state.map(elem => elem.independ_score_org) : []
        }
    ]
    return (
        <Card>
            <CardHeader className="border-0 align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Сумма баллов/Принятые баллы</h4>
                <div className="d-flex gap-1">
                    <button type="button" className="btn btn-soft-success btn-sm"
                            onClick={() => setActive('month')}>
                        1М
                    </button>
                    <button type="button" className="btn btn-soft-secondary btn-sm"
                            onClick={() => setActive('halfYear')}>
                        6М
                    </button>
                    <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => setActive('year')}>
                        1Г
                    </button>
                </div>
            </CardHeader>
            <CardHeader className="p-0 border-0 bg-soft-light">
                <Row className="g-0 text-center">
                    <Col xs={6} sm={6}>
                        <div className="p-3 border border-dashed border-start-0">
                            <h5 className="mb-1"><span className="counter-value">
                                    {state.length > 0 ? state.map(elem => elem.score).reduce((a: number, b: number) => a + b) : 'Нет данных'}
                                </span></h5>
                            <p className="text-muted mb-0">Сумма баллов</p>
                        </div>
                    </Col>
                    <Col xs={6} sm={6}>
                        <div className="p-3 border border-dashed border-start-0">
                            <h5 className="mb-1"><span className="counter-value">
                                    {state.length > 0 ? state.map(elem => elem.depend_score_org + elem.independ_score_org).reduce((a, b) => a + b) : 'Нет данных'}
                                </span></h5>
                            <p className="text-muted mb-0">Принятые баллы</p>
                        </div>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody className="p-0 pb-2">
                <div>
                    <ReactApexChart
                        options={options}
                        series={data}
                        type="line"
                        height={370}
                    />
                </div>
            </CardBody>
        </Card>
    )
};

export default WidgetGraphProjectStatusEmployeePerformance;

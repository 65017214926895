import React, {useEffect, useState} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap";
import {ProjectStatusChartsEmployee} from "../ProjectStatusCharts";
import AxiosClient from "../../../api/AxiosClient";


const WidgetGraphRoundEmployee = () => {

    const [selectedMonth, setSelectedMonth] = useState<string>("30 дней");

    const [dataGroup, setDataGroup] = useState<any>([]);

    useEffect(() => {
        AxiosClient.get('/get_rezult_employee_graph', {params: {selectedMonth}})
            .then((r) => setDataGroup(r.data))
    }, [selectedMonth]);


    const onChangeChartPeriod = (pType: string) => {
        switch (pType) {
            case 'month':
                setSelectedMonth('30 дней');
                break;
            case 'quarter':
                setSelectedMonth('90 дней');
                break;
            default:
                break;
        }
    };



    return (
        <>
            <Card>
                <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Результаты сотрудника</h4>
                    <div className="flex-shrink-0">
                        <UncontrolledDropdown className="card-header-dropdown">
                            <DropdownToggle tag="a" className="dropdown-btn text-muted" role="button">
                                {selectedMonth.charAt(0).toUpperCase() + selectedMonth.slice(1)} <i
                                className="mdi mdi-chevron-down ms-1"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem onClick={() => {
                                    onChangeChartPeriod("month");
                                }} className={selectedMonth === "30 дней" ? "active" : ""}>Последние 30
                                    дней</DropdownItem>
                                <DropdownItem onClick={() => {
                                    onChangeChartPeriod("quarter");
                                }} className={selectedMonth === "90 дней" ? "active" : ""}>Последние 90
                                    дней</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </CardHeader>

                <CardBody>
                    <ProjectStatusChartsEmployee series={dataGroup}/>
                    {
                        dataGroup.length > 0 ?
                            <div className="mt-3">
                                <div className="d-flex justify-content-center align-items-center mb-4">
                                    <div>
                                        <p className="text-muted mb-0">Всего баллов</p>
                                    </div>
                                    <h2 className="mx-3 ff-secondary mb-0">{dataGroup.find((el: any) => el.name === 'Всего баллов')?.count ?? 0}</h2>

                                </div>

                                {
                                    (dataGroup).map((rez: any, id: any) => {
                                        return (
                                            <div key={id}
                                                 className="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                                                <p className="fw-medium mb-0">{rez.name}</p>
                                                <div>
                                                        <span
                                                            className="text-muted pe-5">{rez.count} </span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            :
                            <div className="d-flex justify-content-center align-items-center mb-4">
                                <div>
                                    <p className="text-muted mb-0">Данных нет</p>
                                </div>
                            </div>
                    }
                </CardBody>
            </Card>
        </>
    )
};

export default WidgetGraphRoundEmployee;

import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const TableTeachersVskAttendanceSeniorTeacher = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState<any[]>([
        {
            org: "Организация 1",
            attend: '42',
            vkk: '12',
            IKV: '1',
            SZD: '4',
            NATwo: '5',
            NATwoFive: '6',
            NAFive: '6'
        },
        {
            org: "Организация 2",
            attend: '42',
            vkk: '12',
            IKV: '1',
            SZD: '4',
            NATwo: '5',
            NATwoFive: '6',
            NAFive: '6'
        },
        {
            org: "Организация 3",
            attend: '42',
            vkk: '12',
            IKV: '1',
            SZD: '4',
            NATwo: '5',
            NATwoFive: '6',
            NAFive: '6'
        },
        {
            org: "Организация 4",
            attend: '42',
            vkk: '12',
            IKV: '1',
            SZD: '4',
            NATwo: '5',
            NATwoFive: '6',
            NAFive: '6'
        }

    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Посещено в рамках ВСК занятий ст. препод.</span>,
            selector: (row: any) => row.attend,
        },
        {
            name: <span className='font-weight-bold fs-13'>Имеют ВКК</span>,
            selector: (row: any) => row.vkk,
        },
        {
            name: <span className='font-weight-bold fs-13'>Имеют I КВ</span>,
            selector: (row: any) => row.IKV,
        },
        {
            name: <span className='font-weight-bold fs-13'>Имеют СЗД</span>,
            selector: (row: any) => row.SZD,
        },
        {
            name: <span className='font-weight-bold fs-13'>Не Ат. со стажем до 2 лет</span>,
            selector: (row: any) => row.NATwo,
        },
        {
            name: <span className='font-weight-bold fs-13'>Не Ат. со стажем 2-5 лет</span>,
            selector: (row: any) => row.NATwoFive,
        },
        {
            name: <span className='font-weight-bold fs-13'>Не Ат. со стажем 5+ лет</span>,
            selector: (row: any) => row.NAFive,
        },
    ]

    return (
        <Card>
            <CardHeader>
                Посещено в рамках ВСК занятий ст. препод.
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableTeachersVskAttendanceSeniorTeacher;

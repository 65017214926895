import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    setDateAdd, setGroupModalAdd,
    setIsSend,
    setModalAdd,
    WidgetsSlice
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import AxiosClient from "../../../api/AxiosClient";
import {getJournalHaccpTemperature} from "../../../store/reducers/widgets/ActionCreator";

interface IModalAddReportJournalHaccp{
    updateData: any
}

const ModalAddReportJournalHaccp: FC<IModalAddReportJournalHaccp> = ({updateData}) => {
    const dispatch = useAppDispatch();
    const {modalAdd, groupAllDeviations, journalHaccpTemperature, date, groupModalAdd, groups, dateAdd, successUpdate} = useAppSelector(state => state.WidgetReducer);
    const [roomTemperatures, setRoomTemperatures] = useState<any[]>([]);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(WidgetsSlice.actions.successUpdate(""))
        }, 5000)
        return () => clearTimeout(timer)
    }, [successUpdate, dispatch]);

    useEffect(() => {
        if (journalHaccpTemperature && journalHaccpTemperature.type_group) {
            const newRoomTemperatures = journalHaccpTemperature.type_group.map((roomType: string) => {
                let minTemp: any = '', maxTemp: any = '', factTemp: any = '';

                if (journalHaccpTemperature.dates && journalHaccpTemperature.dates.length > 0) {
                    const updateData = journalHaccpTemperature.dates.find((value: any) => moment(value.date).isSame(dateAdd, 'day'));
                    if (updateData) {
                        const roomData = updateData.data.find((room: any) => room.name === roomType);
                        if (roomData) {
                            minTemp = roomData.min_temperature;
                            maxTemp = roomData.max_temperature;
                            factTemp = roomData.temperature || '';
                        }
                    } else {
                        // Если updateData нет, берем данные из первой записи journalHaccpTemperature.dates
                        const firstDateData = journalHaccpTemperature.dates[0].data.find((room: any) => room.name === roomType);
                        if (firstDateData) {
                            minTemp = firstDateData.min_temperature;
                            maxTemp = firstDateData.max_temperature;
                        }
                    }
                }

                return {
                    name: roomType,
                    min_temperature: minTemp || '',
                    max_temperature: maxTemp || '',
                    temperature: factTemp || '',
                };
            });

            setRoomTemperatures(newRoomTemperatures);
        }
    }, [dateAdd, journalHaccpTemperature]);

    const handleTemperatureChange = (index: number, field: string, value: string) => {
        const updatedRooms = [...roomTemperatures];
        updatedRooms[index][field] = value;
        setRoomTemperatures(updatedRooms);
    };

    return (
        <Modal id="createProjectModal" isOpen={modalAdd}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => dispatch(setModalAdd(!modalAdd))} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление параметров температуры
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        group: `${groupModalAdd}`,
                        date: dateAdd,

                    }}
                    onSubmit={(value) => {
                        dispatch(setIsSend(true));

                        let params = {
                            group_id : groupAllDeviations,
                            doc_id: updateData? updateData.doc_id : '',
                            date: moment(dateAdd, "DD.MM.YYYY H:mm:ss").format("DD.MM.YYYY"),
                            data: roomTemperatures,
                        }

                        AxiosClient.post('haccp/saveJournalTemperatureRoom', params)
                            .then((r: any) => {
                                dispatch(getJournalHaccpTemperature(date, groupAllDeviations)).then();
                                dispatch(setIsSend(false));
                                dispatch(setModalAdd(false));
                            })
                            .catch((error) => {
                                dispatch(setIsSend(false))
                            })
                    }}
                >
                    <Form>
                        <div className="col-auto">
                            <label>Дата:</label>
                            <div className="col mb-3">
                                <div className="input-group col-auto">
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker shadow"
                                        value={moment(dateAdd, "DD.MM.YYYY H:mm:ss").toDate()}
                                        options={{
                                            locale: Russian, dateFormat: "d M Y", disableMobile: true, mode: 'single'
                                        }}
                                        onChange={(date) => {
                                            dispatch(setDateAdd(moment(date[0]).toDate()))
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Группа:</label>
                            <Field as="select" className="form-select shadow" value={groupModalAdd}
                                   onChange={(e: any) => dispatch(setGroupModalAdd(e.target.value))} name="group">
                                {groups.map((group) => {
                                    return <option key={group.group_id}
                                                   value={group.group_id}>{group.group_name}</option>
                                })}
                            </Field>
                        </div>
                        {roomTemperatures.map((room, index) => (
                            <div className="mb-3" key={index}>
                                <Row><label className="w-100">{room.name}</label></Row>
                                <Row>
                                    <Col>
                                            <label className="w-100">
                                                План мин:
                                                <Field
                                                    name={`plan_min_${room.name}`}
                                                    value={room.min_temperature}
                                                    className="form-control"
                                                    onChange={(e: any) => handleTemperatureChange(index, 'min_temperature', e.target.value)}
                                                    disabled={true}
                                                />
                                            </label>
                                        </Col>
                                        <Col>
                                            <label className="w-100">
                                                План макс:
                                                <Field
                                                    name={`plan_max_${room.name}`}
                                                    value={room.max_temperature}
                                                    className="form-control"
                                                    onChange={(e: any) => handleTemperatureChange(index, 'max_temperature', e.target.value)}
                                                    disabled={true}
                                                />
                                            </label>
                                        </Col>
                                    <Col>
                                        <label className="w-100">
                                            Факт:
                                            <Field
                                                name={`fact_${room.name}`}
                                                value={room.temperature}
                                                className="form-control"
                                                onChange={(e: any) => handleTemperatureChange(index, 'temperature',  e.target.value)}
                                            />
                                        </label>
                                    </Col>
                                </Row>
                            </div>
                        ))}

                        <div className="d-flex gap-5 justify-content-between">
                            <div></div>
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddReportJournalHaccp;

import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import TableChildrenAttendOO from "../../components/department/Social/TableChildrenAttendOO";
import PhoneOrPc from "../../components/department/PhoneOrPC";
import TableChildrenAttendOoPart1 from "../../components/department/Social/TablePhone/TableChildrenAttendOOPart1";
import TableChildrenAttendOoPart2 from "../../components/department/Social/TablePhone/TableChildrenAttendOOPart2";
import TableFamalySocialDanger from "../../components/department/Social/TableFamalySocialDanger";
import TablePrevention from "../../components/department/Social/TablePrevention";
import GraphChildrenAttendOO from "../../components/department/Social/GraphChildrenAttendOO";
import Widget from "../../components/department/Social/Widget";

const Social = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Социальность</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Widget/>
                            </Row>
                            <Row>
                                <Col>
                                    <GraphChildrenAttendOO/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <PhoneOrPc phone={[<TableChildrenAttendOoPart1/>, <TableChildrenAttendOoPart2/>]}
                                       pc={[<TableChildrenAttendOO/>]}/>
                            <TableFamalySocialDanger/>
                            <TablePrevention/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Social;

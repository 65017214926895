import React, {FC, useEffect, useState} from 'react';
import {Row} from "reactstrap";
import Shot from "./Shot";
import {IShot} from "../../../models/game/IShot";

interface IProps {
    handleOpenSettings: () => void
    listShot: any[],
    selectShot: (shot: any) => void
    onListShot: (shots: any[]) => void
    copyShot: (shot: IShot) => void
}

const ListShots: FC<IProps> = ({handleOpenSettings, listShot, selectShot, onListShot, copyShot}) => {
    const [shots, setShots] = useState(listShot);
    const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);
    const [targetIndex, setTargetIndex] = useState<number | null>(null); // индекс элемента для выделения

    useEffect(() => {
        setShots(listShot)
    }, [listShot]);

    const onDragStart = (index: number) => {
        setDraggedItemIndex(index);
        setTargetIndex(null); // сброс выделения при начале перетаскивания
    };

    const onDragEnter = (index: number) => {
        if (index !== draggedItemIndex) {
            setTargetIndex(index); // устанавливаем новый индекс для выделения
        }
    };

    const onDrop = () => {
        if (draggedItemIndex !== null && targetIndex !== null && draggedItemIndex !== targetIndex) {
            // Обновляем порядок в массиве только после отпускания
            const updatedShots = [...shots];
            const [movedItem] = updatedShots.splice(draggedItemIndex, 1);
            updatedShots.splice(targetIndex, 0, movedItem);

            setShots(updatedShots);
            onListShot(updatedShots.map((el, index) => ({...el, index})))
        }
        setDraggedItemIndex(null);
        setTargetIndex(null); // сброс после завершения перемещения
    };

    return (
        <Row>
            {
                shots.map((shot, index) => <Shot copyShot={() => copyShot(shot)} key={index} selectShot={() => selectShot(shot)}
                                                    handleOpenSettings={handleOpenSettings} shotName={shot.name}
                                                    index={index}
                                                    onDragStart={onDragStart}
                                                    onDragEnter={onDragEnter}
                                                    onDrop={onDrop}
                                                    isHighlighted={index === targetIndex}
                />)
            }
        </Row>
    );
};

export default ListShots;

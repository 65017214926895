import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import {Link} from "react-router-dom";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const FormSupportTalentedChildren = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState<any[]>([
        {
            org: "Организация 1",
            linkSystemMentor: 'https://vk.ru',
            childrenWithMentor: '23',
            linkCart: 'https://ok.ru',
            countCartSuccess: '23',
            linkPsychical: 'https://ok.ru',
            countPsychical: '12',
            formSupport: 'Выставки, конкурсы'
        },
        {
            org: "Организация 2",
            linkSystemMentor: 'https://vk.ru',
            childrenWithMentor: '23',
            linkCart: 'https://ok.ru',
            countCartSuccess: '23',
            linkPsychical: 'https://ok.ru',
            countPsychical: '12',
            formSupport: 'Выставки, конкурсы'
        },
        {
            org: "Организация 3",
            linkSystemMentor: 'https://vk.ru',
            childrenWithMentor: '23',
            linkCart: 'https://ok.ru',
            countCartSuccess: '23',
            linkPsychical: 'https://ok.ru',
            countPsychical: '12',
            formSupport: 'Выставки, конкурсы'
        },
        {
            org: "Организация 4",
            linkSystemMentor: 'https://vk.ru',
            childrenWithMentor: '23',
            linkCart: 'https://ok.ru',
            countCartSuccess: '23',
            linkPsychical: 'https://ok.ru',
            countPsychical: '12',
            formSupport: 'Выставки, конкурсы'
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Ссылка на док. по орг-ции наставничества</span>,
            selector: (row: any) => (<Link target="_blank"  to={row.linkSystemMentor}>Сслыка на док-ты</Link>),
        },
        {
            name: <span className='font-weight-bold fs-13'>Имеют наставника</span>,
            selector: (row: any) => row.childrenWithMentor,
        },
        {
            name: <span className='font-weight-bold fs-13'>Ссылка на регламент по маршрутам</span>,
            selector: (row: any) => (<Link target="blank" to={row.linkCart}>Сслыка на док-ты</Link>),
        },
        {
            name: <span className='font-weight-bold fs-13'>Кол-во реалезуемых маршрутов</span>,
            selector: (row: any) => row.countCartSuccess,
        },
        {
            name: <span className='font-weight-bold fs-13'>Ссылка на регламент психо.-педагог. сопр.</span>,
            selector: (row: any) => (<Link target="blank" to={row.linkPsychical}>Сслыка на док-ты</Link>),
        },
        {
            name: <span className='font-weight-bold fs-13'>Имеют психо.-педагог. сопровождение</span>,
            selector: (row: any) => row.countPsychical,
        },
        {
            name: <span className='font-weight-bold fs-13'>Применяемые формы поддержки</span>,
            selector: (row: any) => row.formSupport,
        }
    ]

    return (
        <Card>
            <CardHeader>
                Формы поддержки талантливых детей
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default FormSupportTalentedChildren;

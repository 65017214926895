import React, {FC} from 'react';
import {Card, CardBody, CardImg} from "reactstrap";
interface IOtherImage {
    url: {url: string, name?: string, baseUrl: string}
}
const OtherImage: FC<IOtherImage> = ({url}) => {
    return (
        <Card className="card-height-100">
            <CardBody className="d-flex align-items-center">
                <CardImg src={url.url}/>
            </CardBody>
        </Card>
    );
};

export default OtherImage;

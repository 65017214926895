import {AxiosResponse} from "axios";
import {IGroupHealth} from "../../../models/IGroupHealth";
import $api from "../../AxiosClient";

class GroupHealthService {
    static async getGroupsHealth(): Promise<AxiosResponse<IGroupHealth[]>>{
        return $api.post<IGroupHealth[]>("/group-healths");
    }

    static async addGroupHealth(value: object): Promise<AxiosResponse<void>>{
        return $api.post<void>("/group-health/add", value);
    }

    static async updateGroupHealth(value: object, id: number): Promise<AxiosResponse<void>>{
        return $api.post<void>(`/group-health/update/${id}`, value);
    }
}

export default GroupHealthService;
import React, {FC, useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";

interface IAddLinen {
    modalUpdateLinen: boolean;
    setModalUpdateLinen: (value: boolean) => void;
    linen: any[];
    setLinen: (value: any[]) => void;
    selectLinen: any;
    selectClothesSetting: any
}

const AddLinen: FC <IAddLinen> = ({modalUpdateLinen, setModalUpdateLinen, linen, setLinen, selectLinen, selectClothesSetting}) => {
    const [clothesSettingList, setClothesSettingList] = useState<any[]>([]);

    useEffect(() => {
        if(modalUpdateLinen){
            AxiosClient.get(`haccp/getClothesSetting/${selectClothesSetting}`)
                .then((r: any) => setClothesSettingList(r.data))
                .catch((error) => console.log(error));
        }
    }, [modalUpdateLinen]);

    return (
        <Modal
            id="createProjectModal"
            isOpen={modalUpdateLinen}
            toggle={() => setModalUpdateLinen(!modalUpdateLinen)}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
        >
            <ModalHeader
                toggle={() => setModalUpdateLinen(!modalUpdateLinen)}
                className="p-3 bg-soft-primary"
                id="createProjectModalLabel"
            >
                Обновление списка постельного белья
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        uid: selectLinen?.uid || '',
                        units: selectLinen?.units || ''
                    }}
                    validationSchema={
                        Yup.object({
                            uid: Yup.string().required('Требуется выбрать постельное'),
                            units: Yup.string().required('Требуется указать количество')
                        })}
                    onSubmit={(values) => {
                        const newObject = {
                            uid: values.uid,
                            name: clothesSettingList.find(value => value.tl_uid === values.uid)?.tl_name,
                            units: values.units +"",
                            remove: selectLinen ? selectLinen.remove : "0"
                        };

                        setLinen(
                            Array.isArray(linen)
                                ? linen.some(item => item.uid === values.uid)
                                    ? linen.map(item =>
                                        item.uid === values.uid ? { ...item, ...newObject } : item
                                    )
                                    : [...linen, newObject]
                                : [newObject]
                        );

                        setModalUpdateLinen(false);
                    }}
                >
                    {({ errors, touched , setFieldValue}) => (
                        <Form>
                            <div className="mb-3">
                                <label className="w-100">
                                    Название:
                                    <Field
                                        as="select"
                                        name="uid"
                                        className={`form-select ${errors.uid && touched.uid && "border border-2 border-danger"}`}
                                        onChange={(e: { target: { value: any; checked: any; }; }) => {
                                            setFieldValue('uid', e.target.value);
                                            setFieldValue('units', clothesSettingList.find(value => value.tl_uid == e.target.value)?.units);
                                        }}
                                    >
                                        <option value="">Не выбрано</option>
                                        {clothesSettingList.map(el => (
                                            <option key={el.tl_uid} value={el.tl_uid}>{el.tl_name}</option>
                                        ))}
                                    </Field>
                                </label>
                            </div>
                            <div className="mb-3">
                                <label className="w-100">
                                    Количество:
                                    <Field name="units" className="form-control" />
                                </label>
                            </div>
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default AddLinen;

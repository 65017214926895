import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import TableCoursesOne from "../../components/department/Courses/TableCoursesOne";
import TableCourseTwo from "../../components/department/Courses/TableCourseTwo";
import TableCourseThree from "../../components/department/Courses/TableCourseThree";
import PhoneOrPc from "../../components/department/PhoneOrPC";
import TableCourseOnePart1 from "../../components/department/Courses/TablePhone/TableCourseOnePart1";
import TableCourseOnePart2 from "../../components/department/Courses/TablePhone/TableCourseOnePart2";
import TableCourseTwoPart1 from "../../components/department/Courses/TablePhone/TableCourseTwoPart1";
import TableCourseTwoPart2 from "../../components/department/Courses/TablePhone/TableCourseTwoPart2";
import TableCourseThreePart1 from "../../components/department/Courses/TablePhone/TableCourseThreePart1";
import TableCourseThreePart2 from "../../components/department/Courses/TablePhone/TableCourseThreePart2";
import GraphOne from "../../components/department/Courses/GraphOne";
import GraphTwo from "../../components/department/Courses/GraphTwo";
import Widget from "../../components/department/Courses/Widget";

const Courses = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Курсы</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Widget/>
                            </Row>
                            <Row>
                                <Col xxl={6}>
                                    <GraphOne/>
                                </Col>
                                <Col xxl={6}>
                                    <GraphTwo/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <PhoneOrPc phone={[<TableCourseOnePart1/>, <TableCourseOnePart2/>,
                                                <TableCourseTwoPart1/>, <TableCourseTwoPart2/>,
                                                <TableCourseThreePart1/>, <TableCourseThreePart2/>]}
                                       pc={[<TableCoursesOne/>,
                                           <TableCourseTwo/>,
                                           <TableCourseThree/>]}/>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Courses;

import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import WidgetRequiredFlatpikerPeriod from "../../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import SelectOrganization from "../../components/department/SelectOrganization";
import Widget from "../../components/department/NutritionDiet/Widget";
import GraphKKal from "../../components/department/NutritionDiet/GraphKKal";
import GraphSSNP from "../../components/department/NutritionDiet/GraphSSNP";
import TableSSNP from "../../components/department/NutritionDiet/TableSSNP";
import TableSSNPThreeSeven from "../../components/department/NutritionDiet/TableSSNPThreeSeven";
import TableKKal from "../../components/department/NutritionDiet/TableKKal";
import TableKKalThreeSeven from "../../components/department/NutritionDiet/TableKKalThreeSeven";

const NutritionDiet = () => {
    return  (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Питание по диетам</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <WidgetRequiredFlatpikerPeriod/>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Widget/>
                            </Row>
                            <Row>
                                <Col xxl={6} xl={6} sm={12}>
                                    <GraphSSNP/>
                                </Col>
                                <Col xxl={6} xl={6} sm={12}>
                                    <GraphKKal/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <TableKKal/>
                            <TableKKalThreeSeven/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
};

export default NutritionDiet;

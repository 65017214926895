import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import WidgetRequiredFlatpikerPeriod from "../../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import SelectOrganization from "../../components/department/SelectOrganization";
import ControlRatingSubjectCheckOrganizationEmployee
    from "../../components/department/MedicalControl/controlRatingSubjectCheckOrganizationEmployee/ControlRatingSubjectCheckOrganizationEmployee";
import TableMedicalControlRatings
    from "../../components/department/MedicalControl/controlRating/TableMedicalControlRatings";
import TableMedicalControlSubjectCheck
    from "../../components/department/MedicalControl/subjectCheck/TableMedicalControlSubjectCheck";
import TableMedicalControlRatingSubjectCheckOrganization
    from "../../components/department/MedicalControl/controlRatingSubjectCheckOrganization/TableMedicalControlRatingSubjectCheckOrganization";
import GraphMedicalControlRatings from "../../components/department/MedicalControl/GraphMedicalControlRatings";
import GraphMedicalControlTask from "../../components/department/MedicalControl/GraphMedicalControlTask";

const MedicalControl = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Медицинский контроль</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <WidgetRequiredFlatpikerPeriod/>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Col xxl={6} xl={6}>
                                    <GraphMedicalControlRatings/>
                                </Col>
                                <Col xxl={6} xl={6}>
                                    <GraphMedicalControlTask/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <TableMedicalControlRatings/>
                            <TableMedicalControlSubjectCheck/>
                            <TableMedicalControlRatingSubjectCheckOrganization/>
                            <ControlRatingSubjectCheckOrganizationEmployee/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default MedicalControl;

import {AxiosResponse} from "axios";
import $api from "../../AxiosClient";

class FromMyTaskService {
    static async getBusinessProcess(): Promise<AxiosResponse<any>>{
        return $api.get<any>("/business_process");
    }

    static async getWaypoints(bp: string): Promise<AxiosResponse<any>>{
        const params =  {
            bp: bp
        }
        return $api.get<any>("/get_waypoints", {params});
    }

    static async getAllUsers(): Promise<AxiosResponse<any[]>>{
        return $api.get<any[]>("/get_all_users");
    }

    static async addTask(data: { uid: string, enddate: Date, process_uid: string, waypoint_uid: string[], waypoint_performer: string[], name: string, description: string}): Promise<AxiosResponse<any>>{
        return $api.post<any>("/add_task", {data});
    }

    static async getFromMyTask(): Promise<AxiosResponse<any>>{
        return $api.get<any>("/get_from_my_task");
    }
}

export default FromMyTaskService;
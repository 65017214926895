import React from 'react';
import {Container} from "reactstrap";
import FormUpdatePortfolio from "../../components/Kindergarten/FormUpdatePortfolio";
import {Helmet} from "react-helmet";

const ChildrenPortfolioMore = () => {

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Обновить портфолио</title>
            </Helmet>
            <Container fluid>
                <FormUpdatePortfolio/>
            </Container>
        </div>
    );
};

export default ChildrenPortfolioMore;

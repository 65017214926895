import React, {FC, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {useAppSelector} from "../../hooks/redux";
import AxiosClient from "../../api/AxiosClient";
import {IDailyReport} from "../../models/parent/IDailyReport";

interface IDailyReportComponent {
    date: Date
}
const DailyReport: FC<IDailyReportComponent> = ({date}) => {

    const {child} = useAppSelector(state => state.ParentReducer)
    const [dailyReport, setDailyReport] = useState<IDailyReport | null>(null);

    useEffect(() => {
        child &&
        AxiosClient.get<IDailyReport>('/get_deily_reports_parent', {params: {child, date}})
            .then((r) => setDailyReport(r.data))
    }, [child, date]);

    function emotion(emotion: string | undefined) {
        if (emotion)
            switch (emotion) {
                case 'Отличное':
                    return <i className="ri-emotion-happy-line fs-24"></i>
                case 'Не веселый':
                    return  <i className="ri-emotion-normal-line fs-24"></i>
                case 'Плохое':
                    return  <i className="ri-emotion-unhappy-line fs-24"></i>
            }
        else
            return <i className="ri-emotion-happy-line fs-24"></i>
    }


    return (
        <Card className="">
            <CardHeader>
                <h4 className="card-title mb-0 flex-grow-1">Отчет за день</h4>
            </CardHeader>
            <CardBody>
                <div className="list-group list-group-flush border-dashed custom-scroll-news" style={{overflow: 'auto', height: "22.5rem"}}>
                    <div className="list-group-item">
                        <Row className="align-items-center g-3">
                            <div className="col-auto">
                                <div className="avatar-sm m-1 h-auto bg-light rounded-3">
                                    <div className="text-center">
                                        <i className="mdi mdi-food-apple-outline fs-24"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <h5 className="text-muted mt-0 mb-1 fs-14">Завтрак</h5>
                                <Link to="#" className="text-reset fs-15 mb-0">{dailyReport?.breakfast?.name ?? '-'}</Link>
                            </div>
                        </Row>
                    </div>
                    <div className="list-group-item">
                        <Row className="align-items-center g-3">
                            <div className="col-auto">
                                <div className="avatar-sm m-1 h-auto bg-light rounded-3">
                                    <div className="text-center">
                                        <i className="mdi mdi-food-croissant fs-24"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <h5 className="text-muted mt-0 mb-1 fs-14">Второй завтрак</h5>
                                <Link to="#" className="text-reset fs-15 mb-0">{dailyReport?.second_breakfast?.name ?? '-'}</Link>
                            </div>
                        </Row>
                    </div>
                    <div className="list-group-item">
                        <Row className="align-items-center g-3">
                            <div className="col-auto">
                                <div className="avatar-sm m-1 h-auto bg-light rounded-3">
                                    <div className="text-center">
                                        <i className="mdi mdi-pot-steam fs-24"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <h5 className="text-muted mt-0 mb-1 fs-14">Обед</h5>
                                <Link to="#" className="text-reset fs-15 mb-0">{dailyReport?.dinner?.name ?? '-'}</Link>
                            </div>
                        </Row>
                    </div>
                    <div className="list-group-item">
                        <Row className="align-items-center g-3">
                            <div className="col-auto">
                                <div className="avatar-sm m-1 h-auto bg-light rounded-3">
                                    <div className="text-center">
                                        <i className="ri-moon-clear-line fs-24"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <h5 className="text-muted mt-0 mb-1 fs-14">Дневной сон</h5>
                                <Link to="#" className="text-reset fs-15 mb-0">{dailyReport?.sleep?.name ?? '-'}</Link>
                            </div>
                        </Row>
                    </div>
                    <div className="list-group-item">
                        <Row className="align-items-center g-3">
                            <div className="col-auto">
                                <div className="avatar-sm m-1 h-auto bg-light rounded-3">
                                    <div className="text-center">
                                        <i className="mdi mdi-cookie-outline fs-24"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <h5 className="text-muted mt-0 mb-1 fs-14">Полдник</h5>
                                <Link to="#" className="text-reset fs-15 mb-0">{dailyReport?.snack?.name ?? '-'}</Link>
                            </div>
                        </Row>
                    </div>
                    <div className="list-group-item">
                        <Row className="align-items-center g-3">
                            <div className="col-auto">
                                <div className="avatar-sm m-1 h-auto bg-light rounded-3">
                                    <div className="text-center">
                                        <i className="mdi mdi-food-steak fs-24"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <h5 className="text-muted mt-0 mb-1 fs-14">Ужин</h5>
                                <Link to="#" className="text-reset fs-15 mb-0">{dailyReport?.brunch?.name ?? '-'}</Link>
                            </div>
                        </Row>
                    </div>
                    <div className="list-group-item">
                        <Row className="align-items-center g-3">
                            <div className="col-auto">
                                <div className="avatar-sm m-1 h-auto bg-light rounded-3">
                                    <div className="text-center">
                                        <i className="mdi mdi-food-hot-dog fs-24"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <h5 className="text-muted mt-0 mb-1 fs-14">Второй ужин</h5>
                                <Link to="#" className="text-reset fs-15 mb-0">{dailyReport?.second_brunch?.name ?? '-'}</Link>
                            </div>
                        </Row>
                    </div>
                    <div className="list-group-item">
                        <Row className="align-items-center g-3">
                            <div className="col-auto">
                                <div className="avatar-sm m-1 h-auto bg-light rounded-3">
                                    <div className="text-center">
                                        {
                                            emotion(dailyReport?.mood?.name)
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <h5 className="text-muted mt-0 mb-1 fs-14">Настроение</h5>
                                <Link to="#" className="text-reset fs-15 mb-0">{dailyReport?.mood?.name ?? '-'}</Link>
                            </div>
                        </Row>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default DailyReport;

import React, {useEffect, useState} from 'react';
import { Formik, Field, Form } from 'formik';
import {Link, useParams} from "react-router-dom";
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import {Container, Button, Card, CardBody, Col, Row, Spinner} from "reactstrap";
import {Helmet} from "react-helmet";
import DateTimeSubmissionDocuments from "../components/submissionDocuments/DateTimeSubmissionDocuments";
import AxiosClient from "../api/AxiosClient";
import SubmissionDocumentsSuccess from "../components/submissionDocuments/SubmissionDocumentsSuccess";
import { AddressSuggestions, DaDataAddress, DaDataSuggestion } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import SubmitWithLoading from "../components/layouts/UI/SubmitWithLoading";
import {useAppDispatch} from "../hooks/redux";
import {setIsSend} from "../store/reducers/widgets/WidgetsSlice";

const SubmissionDocuments = () => {
    const {id, number} = useParams();
    const dispatch = useAppDispatch()
    const [toggle, setToggle] = useState(false);
    const [date, setDate] = useState({dateRecord: '', reservationId: '',  time: {id: '', time: '', human_time: '', title:'', open_place:''}});
    const [modalActive, setModalActive] = useState(false);
    const [period, setPeriod] = useState<any[]>([]);
    const [success, setSuccess] = useState(false);
    const [time, setTime] = useState<any[]>([]);
    const [nameOrg, setNameOrg] = useState('');
    const [address, setAddress] = useState<any>('');
    const [addressFull, setAddressFull] = useState<any>({});
    const [addressFather, setAddressFather] = useState<any>('');
    const [addressFullFather, setAddressFullFather] = useState<any>({});
    const [send, setSend] = useState(false);
    const handleSelect = (suggestion: DaDataSuggestion<DaDataAddress> | undefined) => {
        if (suggestion) {
            setAddress(suggestion.unrestricted_value);
            setAddressFull(suggestion.data);
        }
    }
    const handleSelectFather = (suggestion: DaDataSuggestion<DaDataAddress> | undefined) => {
        if (suggestion) {
            setAddressFather(suggestion.unrestricted_value);
            setAddressFullFather(suggestion.data);
        }
    }
    useEffect(() => {
        AxiosClient.get('/getPeriodsSubmissionDocuments', {params: {idOrg: id}})
            .then((r) => {
                setPeriod(r.data['periods']);
            })
            .catch(() => console.log('error'))
        AxiosClient.get('/getOrgName', {params: {idOrg: id, number: number}})
            .then(r => setNameOrg(r.data))
            .catch(() => console.log('error'))
    }, [id, number]);

    const DisplayingErrorMessagesSchema = Yup.object({
        'fullName': Yup.string().required(),
        'birthdate': Yup.string().required(),
        'certificateBirth': Yup.string().required(),
        'dateCertificate': Yup.string().required(),
        'whoCertificate': Yup.string().required(),
        // 'MHIPolicy': Yup.string().required(),
        'snils': Yup.string().required(),
        //'index': Yup.string().required(),
        //'area': Yup.string().required(),
        //'city': Yup.string().required(),
        //'street': Yup.string().required(),
        //'house': Yup.string().required(),
        // 'citizenship': Yup.string().required(),
        // 'birthCountry': Yup.string().required(),
        //'email': Yup.string().required(),

        'matherFullName': Yup.string(),
        //matherBirthdate: Yup.string(),
        /*matherBirthdate: Yup.string().when('matherFullName', {
            is: (value) => value && value.length > 0,
            then: Yup.string().required(),
            otherwise: Yup.string()
        }),*/
        'matherBirthdate': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'matherSeriesPassport': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'matherNumberPassport': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'matherDateIssuePass': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'matherWhoIssuePass': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'matherPassportCodePodr': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'matherPhone': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'matherPlaceWork': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'matherPosition': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'email': Yup.string()
            .when('matherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),

        'fatherFullName': Yup.string(),
        'fatherBirthdate': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherSeriesPassport': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherNumberPassport': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherDateIssuePass': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherWhoIssuePass': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherPassportCodePodr': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherPhone': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherPlaceWork': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherPosition': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'fatherEmail': Yup.string()
            .when('fatherFullName', {
                is: (value:any) => value && value.length > 0,
                then: (schema:any) => schema.required()
            }),
        'code': Yup.string().required()
    });

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Форма подачи документов</title>
            </Helmet>
            <Container fluid>
                <Formik
                    enableReinitialize
                    initialValues={{
                        fullName: '',
                        birthdate: '',
                        gender: 'male',
                        certificateBirth: '',
                        dateCertificate: '',
                        whoCertificate: '',
                        snils: '',
                        // MHIPolicy: '',
                        //index: '',
                        address: '',
                        //area: '',
                        //city: '',
                        //street: '',
                        //house: '',
                        //building: '',
                        //apartment: '',
                        // citizenship: '',
                        // birthCountry: '',
                        // birthRegion: '',
                        // birthDistrict: '',
                        // birthTown: '',
                        email: '',
                        matherFullName: '',
                        matherBirthdate: '',
                        matherSeriesPassport: '',
                        matherNumberPassport: '',
                        matherDateIssuePass: '',
                        matherWhoIssuePass: '',
                        matherPassportCodePodr: '',
                        matherPhone: '',
                        matherPlaceWork: '',
                        matherPosition: '',
                        fatherFullName: '',
                        fatherBirthdate: '',
                        fatherSeriesPassport: '',
                        fatherNumberPassport: '',
                        fatherDateIssuePass: '',
                        fatherWhoIssuePass: '',
                        fatherPassportCodePodr: '',
                        fatherPhone: '',
                        fatherPlaceWork: '',
                        fatherPosition: '',
                        fatherAddress: '',
                        fatherEmail: '',
                        institution: '',
                        reservationId: '',
                        dateTimeRecord: '',
                        toggle: false,
                        code: ''
                    }}
                    validationSchema={DisplayingErrorMessagesSchema}
                    onSubmit={(values, {resetForm}) => {
                        dispatch(setIsSend(true))
                        values.reservationId = date.reservationId;
                        values.dateTimeRecord = date.time['time'] ? new Intl.DateTimeFormat('ru', {day: '2-digit',  month: '2-digit', year: 'numeric'}).format(new Date(date.dateRecord)) + " "+ date.time['human_time'][0].split(' ')[1].slice(0, -3) : ''
                       AxiosClient.post('/addRegistrationAtInstitution', {...values, address: address, idOrg: id, addressFull: addressFull, addressFather: addressFather, addressFullFather: addressFullFather})
                            .then((r) => {
                                setTime(r.data)
                                dispatch(setIsSend(false))
                                //resetForm()
                                setDate({dateRecord: '', reservationId: '',  time: {id: '', time: '', human_time: '', title:'', open_place:''}})
                                setSuccess(true)
                            })
                            .catch(() => {
                                console.log('error')
                                dispatch(setIsSend(false))
                            })
                    }}
                >
                    {({errors, touched}) => (
                        <Form>
                            <Row>
                                <Col xxl={12}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <h4 className="text-center mt-sm-3 mt-lg-3 mt-xl-3 mt-xll-3">Данные по ребенку</h4>
                                                <Row>
                                                    <Col xxl={6}>
                                                        <Field
                                                            name='fullName'
                                                            placeholder="ФИО ребенка"
                                                            className={touched.fullName && errors.fullName ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field name="birthdate">
                                                            {({
                                                                  field,
                                                                  form: {touched, errors},
                                                              }:any) => (
                                                                // eslint-disable-next-line react/jsx-no-undef
                                                                <MaskedInput
                                                                    name="birthdate"
                                                                    mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                    placeholder="Дата рождения"
                                                                    {...field}
                                                                    className={touched.birthdate && errors.birthdate ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Col>
                                                    <Col>
                                                        <Field
                                                            as="select"
                                                            name="gender"
                                                            className="mt-2 form-select mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0"
                                                        >
                                                            <option value="male">Мужской</option>
                                                            <option value="female">Женский</option>
                                                        </Field>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            name="certificateBirth"
                                                            placeholder="Св-во о рождении"
                                                            className={touched.certificateBirth && errors.certificateBirth ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field name="dateCertificate">
                                                            {({
                                                                  field,
                                                                  form: {touched, errors},
                                                              }:any) => (
                                                                <MaskedInput
                                                                    name="dateCertificate"
                                                                    mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                    placeholder="Дата выдачи"
                                                                    {...field}
                                                                    className={touched.dateCertificate && errors.dateCertificate ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Col>
                                                    <Col xxl={6}>
                                                        <Field
                                                            name="whoCertificate"
                                                            placeholder="Кем выдано"
                                                            className={touched.whoCertificate && errors.whoCertificate ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            name="snils"
                                                            placeholder="СНИЛС"
                                                            className={touched.snils && errors.snils ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    {/*<Col>*/}
                                                    {/*    <Field*/}
                                                    {/*        name="MHIPolicy"*/}
                                                    {/*        placeholder="Полис ОМС"*/}
                                                    {/*        className={touched.MHIPolicy && errors.MHIPolicy ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}*/}
                                                    {/*    />*/}
                                                    {/*</Col>*/}
                                                </Row>

                                                {/*
                                                <Row>
                                                    <Col xxl={2}>
                                                        <Field
                                                            name="index"
                                                            placeholder="Индекс"
                                                            className={touched.index && errors.index ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>


                                                    <Col>
                                                        <Field
                                                            name="area"
                                                            placeholder="Область"
                                                            className={touched.area && errors.area ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xxl={4}>
                                                        <Field
                                                            name="city"
                                                            placeholder="Город"
                                                            className={touched.city && errors.city ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field
                                                            name="street"
                                                            placeholder="Улица"
                                                            className={touched.street && errors.street ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            name="house"
                                                            placeholder="Дом"
                                                            className={touched.house && errors.house ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field
                                                            name="building"
                                                            placeholder="Корпус"
                                                            className='mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field
                                                            name="apartment"
                                                            placeholder="Квартира"
                                                            className='mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2'
                                                        />
                                                    </Col>
                                                </Row>
                                                */}
                                            {/*    <Row>*/}
                                            {/*        <Col>*/}
                                            {/*            <Field*/}
                                            {/*                name="citizenship"*/}
                                            {/*                placeholder="Гражданство "*/}
                                            {/*                className={touched.citizenship && errors.citizenship ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}*/}
                                            {/*            />*/}
                                            {/*        </Col>*/}
                                            {/*        <Col>*/}
                                            {/*            <Field*/}
                                            {/*                name="birthCountry"*/}
                                            {/*                placeholder="Страна места рождения"*/}
                                            {/*                className={touched.birthCountry && errors.birthCountry ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}*/}
                                            {/*            />*/}
                                            {/*        </Col>*/}
                                            {/*    </Row><Row>*/}
                                            {/*    <Col>*/}
                                            {/*        <Field*/}
                                            {/*            name="birthRegion"*/}
                                            {/*            placeholder="Регион места рождения"*/}
                                            {/*            className={"mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}*/}
                                            {/*        />*/}
                                            {/*    </Col>*/}
                                            {/*    <Col>*/}
                                            {/*        <Field*/}
                                            {/*            name="birthDistrict"*/}
                                            {/*            placeholder="Район места рождения"*/}
                                            {/*            className={"mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}*/}
                                            {/*        />*/}
                                            {/*    </Col>*/}
                                            {/*    <Col>*/}
                                            {/*        <Field*/}
                                            {/*            name="birthTown"*/}
                                            {/*            placeholder="Город места рождения"*/}
                                            {/*            className={"mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}*/}
                                            {/*        />*/}
                                            {/*    </Col>*/}
                                            {/*</Row>*/}
                                                <h4 className="text-center mt-sm-3 mt-lg-3 mt-xl-3 mt-xll-3">Данные мамы ребенка</h4>
                                                <Row>
                                                    <Col xxl={8}>
                                                        <Field
                                                            name="matherFullName"
                                                            placeholder="ФИО мамы"
                                                            className={touched.matherFullName && errors.matherFullName ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field name="matherBirthdate">
                                                            {({
                                                                  field
                                                              }:any) => (
                                                                <MaskedInput
                                                                    name="matherBirthdate"
                                                                    mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                    placeholder="Дата рождения"
                                                                    {...field}
                                                                    className={touched.matherBirthdate && errors.matherBirthdate ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                        name="matherSeriesPassport"
                                                        placeholder="Серия паспорта"
                                                        className={touched.matherSeriesPassport && errors.matherSeriesPassport ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field
                                                            name="matherNumberPassport"
                                                            placeholder="Номер паспорта"
                                                            className={touched.matherNumberPassport && errors.matherNumberPassport ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field name="matherDateIssuePass">
                                                            {({
                                                                  field
                                                              }:any) => (
                                                                <MaskedInput
                                                                    name="matherDateIssuePass"
                                                                    mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                    placeholder="Дата выдачи"
                                                                    {...field}
                                                                    className={touched.matherDateIssuePass && errors.matherDateIssuePass ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col >
                                                        <Field
                                                            name="matherWhoIssuePass"
                                                            placeholder="Кем выдан"
                                                            className={touched.matherWhoIssuePass && errors.matherWhoIssuePass ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field
                                                            name="matherPassportCodePodr"
                                                            placeholder="Код подразделения"
                                                            className={touched.matherPassportCodePodr && errors.matherPassportCodePodr ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            type="phone"
                                                            name="matherPhone"
                                                            placeholder="Мобильный телефон"
                                                            className={touched.matherPhone && errors.matherPhone ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            name="matherPlaceWork"
                                                            placeholder="Место работы"
                                                            className={touched.matherPlaceWork && errors.matherPlaceWork ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field
                                                            name="matherPosition"
                                                            placeholder="Должность"
                                                            className={touched.matherPosition && errors.matherPosition ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field name="address">
                                                            {({
                                                                  field
                                                              }:any) => (
                                                                <AddressSuggestions
                                                                    token="ef14be653a9a2c47d5cb2e29d1b6b140161a6d4b"
                                                                    value={address}
                                                                    inputProps={{
                                                                        'placeholder': 'Адрес',
                                                                        'className' : 'mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2',
                                                                    }}
                                                                    {...field}
                                                                    onChange={(address)=>handleSelect(address)}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            name="email"
                                                            placeholder="Электронная почта"
                                                            className={touched.email && errors.email ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                </Row>

                                                <h4 className="text-center mt-sm-3 mt-lg-3 mt-xl-3 mt-xll-3">Данные папы ребенка</h4>
                                                <Row>
                                                    <Col xxl={8}>
                                                        <Field
                                                            name="fatherFullName"
                                                            placeholder="ФИО папы"
                                                            className={touched.fatherFullName && errors.fatherFullName ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field name="fatherBirthdate">
                                                            {({
                                                                  field
                                                              }:any) => (
                                                                <MaskedInput
                                                                    name="fatherBirthdate"
                                                                    mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                    placeholder="Дата рождения"
                                                                    {...field}
                                                                    className={touched.fatherBirthdate && errors.fatherBirthdate ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            name="fatherSeriesPassport"
                                                            placeholder="Серия паспорта"
                                                            className={touched.fatherSeriesPassport && errors.fatherSeriesPassport ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field
                                                            name="fatherNumberPassport"
                                                            placeholder="Номер паспорта"
                                                            className={touched.fatherNumberPassport && errors.fatherNumberPassport ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field name="fatherDateIssuePass">
                                                            {({
                                                                  field
                                                              }:any) => (
                                                                <MaskedInput
                                                                    name="fatherDateIssuePass"
                                                                    mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                    placeholder="Дата выдачи"
                                                                    {...field}
                                                                    className={touched.fatherDateIssuePass && errors.fatherDateIssuePass ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            name="fatherWhoIssuePass"
                                                            placeholder="Кем выдан"
                                                            className={touched.fatherWhoIssuePass && errors.fatherWhoIssuePass ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field
                                                            name="fatherPassportCodePodr"
                                                            placeholder="Код подразделения"
                                                            className={touched.fatherPassportCodePodr && errors.fatherPassportCodePodr ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            type="phone"
                                                            name="fatherPhone"
                                                            placeholder="Мобильный телефон"
                                                            className={touched.fatherPhone && errors.fatherPhone ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            name="fatherPlaceWork"
                                                            placeholder="Место работы"
                                                            className={touched.fatherPlaceWork && errors.fatherPlaceWork ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field
                                                            name="fatherPosition"
                                                            placeholder="Должность"
                                                            className={touched.fatherPosition && errors.fatherPosition ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field name="fatherAddress">
                                                            {({
                                                                  field
                                                              }:any) => (
                                                                <AddressSuggestions
                                                                    token="ef14be653a9a2c47d5cb2e29d1b6b140161a6d4b"
                                                                    value={addressFather}
                                                                    inputProps={{
                                                                        'placeholder': 'Адрес',
                                                                        'className' : 'mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2',
                                                                    }}
                                                                    {...field}
                                                                    onChange={(address)=>handleSelectFather(address)}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            name="fatherEmail"
                                                            placeholder="Электронная почта"
                                                            className={touched.fatherEmail && errors.fatherEmail ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <h4 className="text-center mt-sm-3 mt-lg-3 mt-xl-3 mt-xll-3">Контрольные данные</h4>
                                                <span className="mt-1 mb-2 fw-medium">Контрольные данные</span>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            name='code'
                                                            placeholder="PIN код"
                                                            type="password"
                                                            className={touched.code && errors.code ? "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2 border-danger" : "mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            name="institution"
                                                            placeholder={nameOrg}
                                                            className='mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2'
                                                            disabled
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <input
                                                            name="dateTimeRecord"
                                                            defaultValue={date.time['time'] && date.time['human_time'].length > 0 ? new Intl.DateTimeFormat('ru', {day: '2-digit',  month: '2-digit', year: 'numeric'}).format(new Date(date.dateRecord)) + " "+ date.time['human_time'][0].split(' ')[1].slice(0, -3) : ''}
                                                            placeholder="Время и дата приема"
                                                            className='mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0 mb-2'
                                                            disabled
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <label className='consentProcessing'>
                                                        <input
                                                            type="checkbox"
                                                            name="toggle"
                                                            checked={toggle}
                                                            onChange={(e) => setToggle(e.target.checked)}
                                                        />
                                                        &nbsp;&nbsp;Я даю согласие на обработку моих&nbsp;
                                                        <Link to="https://static3.banki.ru/cfe/consent1.pdf" target="_blank">персональных
                                                            данных.</Link>
                                                    </label>
                                                </Row>
                                                <Row >
                                                    <Col xxl={3}>
                                                        <Button type="button" color="primary"  className="mt-1 w-100 btn  bg-primary"
                                                                onClick={() =>
                                                                    {
                                                                        setSend(true)
                                                                        AxiosClient.get('/getDatesSubmissionDocuments', {params: {periods: period, idOrg: id}})
                                                                            .then((r) => {
                                                                                setSend(false)
                                                                                setPeriod(r.data)
                                                                                setModalActive(true)
                                                                            })
                                                                            .catch(() => {
                                                                                setSend(false)
                                                                                console.log('error')
                                                                            })
                                                                    }
                                                                }
                                                                disabled={!toggle || send}>
                                                                    {
                                                                        send ?
                                                                            <Spinner size={'sm'}>
                                                                                Loading...
                                                                            </Spinner>
                                                                            :
                                                                            "Получить талон"
                                                                    }
                                                        </Button>
                                                    </Col>
                                                    <Col xxl={3}>
                                                        <SubmitWithLoading text={"Отправить"} noActive={!toggle} className="mt-1 btn btn-primary w-100"/>
                                                        {/*<Button type="submit" color="primary" className="mt-1 w-100 btn bg-primary text-white" disabled={!toggle}>Отправить</Button>*/}
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    )}

                </Formik>
            </Container>
            <DateTimeSubmissionDocuments active={modalActive} setActive={setModalActive} date={date} setDate={(values)=>setDate(values)} period={period}></DateTimeSubmissionDocuments>
            <SubmissionDocumentsSuccess success={success} setSuccess={value => setSuccess(value)} time={time}/>
        </div>
    );
};

export default SubmissionDocuments;
function downloadPDF(data, filename){
    let URL = window.URL || window.webkitURL,
        byteChars = atob(data),
        bytes = [],
        i = 0;
    for (; i < byteChars.length; i++)
        bytes[i] = byteChars.charCodeAt(i);

    const blob = new Blob([new Uint8Array(bytes)], {type: 'application/pdf'});
    const downloadUrl = URL.createObjectURL(blob);

    if(window.navigator && window.navigator.msSaveOrOpenBlob)
    window.navigator.msSaveOrOpenBlob(blob, filename);
else
    {
        const a = document.createElement('a');
        document.body.appendChild(a);
        //window.open(downloadUrl, '_blank');
        a.href = downloadUrl;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);
    }
}

export default downloadPDF;
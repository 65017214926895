import {IGame} from "../../models/game/IGame";
import {TypeSettingsShot} from "../../models/game/typesSettings/TypeSettingsShot";
import {ISettingsAnswerShot, ISettingsCountTextures} from "../../models/game/IShot";
import {TypeSettings} from "../../models/game/typesSettings/TypeSettings";
import {ISettingsCollect, ISettingsSelectAnswer} from "../../models/game/ISettingsTexture";

export const validateGame = (game: Omit<IGame,'id'>): string => {
    let error = ''
    if (!game.name) {
        error =  'Введите имя игры'
    }

    game.shots.forEach((shot) => {
        const settings = shot.settings
        if (!shot.name) {
            error = 'У одного из кадров нет имени'
            return
        }
        if (!shot.backgroundSound){
            error = `Добавьте музыку заднего фона в кадр "${shot.name}"`
            return
        }
        if (!shot.backgroundImage){
            error = `Добавьте задний фон в кадр "${shot.name}"`
            return
        }
        if (settings) {
            if (settings.type === TypeSettingsShot.COUNT_TEXTURE) {
                const settingsCountTexture = settings.settings as ISettingsCountTextures
                if (!settingsCountTexture.textTrue) {
                    error = `Добавьте текст верного ответа в кадре "${shot.name}" для настроек "Посчитать кол-во текстур"`
                    return
                }
                if (!settingsCountTexture.textFalse) {
                    error = `Добавьте текст не верного ответа в кадре "${shot.name}" для настроек "Посчитать кол-во текстур"`
                    return
                }
                if (!settingsCountTexture.answer) {
                    error = `Добавьте ответ в кадре "${shot.name}" для настроек "Посчитать кол-во текстур"`
                    return
                }
            }
            if (settings.type === TypeSettingsShot.ANSWER) {
                const settingsAnswerTexture = settings.settings as ISettingsAnswerShot
                if (!settingsAnswerTexture.text) {
                    error = `Добавьте текст верного ответа в кадре "${shot.name}" для настроек "Решить пример"`
                    return
                }
                if (!settingsAnswerTexture.textFalse) {
                    error = `Добавьте текст не верного ответа в кадре "${shot.name}" для настроек "Решить пример"`
                    return
                }
                if (!settingsAnswerTexture.textAnswer) {
                    error = `Добавьте ответ в кадре "${shot.name}" для настроек "Решить пример"`
                    return
                }
                if (!settingsAnswerTexture.html) {
                    error = `Добавьте текст в кадре "${shot.name}" для настроек "Решить пример"`
                    return
                }
            }
        }

        shot.textures.forEach((texture) => {
            if (!texture.pivot.name) {
                error = `У одной из текстур в кадре "${shot.name}" нет названия. Обозначение текстуры "${texture.name}"`
                return
            }
            if (!texture.pivot.description) {
                error = `У одной из текстур в кадре "${shot.name}" нет описания. Обозначение текстуры "${texture.name}"`
                return
            }
            const settings = texture.pivot.settings
            if (settings.type === TypeSettings.SELECT_ANSWER) {
                const settingsSelectAnswer = settings.settings as ISettingsSelectAnswer
                if (!settingsSelectAnswer.text) {
                    error = `У одной из текстур в кадре "${shot.name}" нет текста в настройках "Выбор ответа"`
                    return
                }
            }
            if (settings.type === TypeSettings.COLLECT) {
                const settingsCollect = settings.settings as ISettingsCollect
                if (!settingsCollect.textTrue) {
                    error = `У одной из текстур в кадре "${shot.name}" нет верного текста перемещения в настройках "Собрать в кучку"`
                    return
                }
                if (!settingsCollect.textFalse) {
                    error = `У одной из текстур в кадре "${shot.name}" нет не верного текста перемещения в настройках "Собрать в кучку"`
                    return
                }
            }
        })
    })

    return error
}
import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import ModalMyTaskInfo from "../modal/ModalMyTaskInfo";
import MyTaskService from "../../../../api/services/documentsFlowService/MyTaskService";
import {convertDateLineInDot} from "../../../../functions/ConvertDate";

const TableMyTask = () => {
    const [openModalMyTaskInfo, setOpenModalMyTaskInfo] = useState<boolean>(false);
    const [myTasks, setMyTasks] = useState<any[]>([]);
    const [myTaskInfo, setMyTaskInfo] = useState<any | null>(null);
    useEffect(() => {
        MyTaskService.getMyTask().then(r => setMyTasks(r.data))
    }, []);


    const columns: any = [
        {
            id: "date",
            name: <span className='font-weight-bold fs-13'>Дата</span>,
            selector: (row: any) => convertDateLineInDot(row.data),
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Наименование</span>,
            selector: (row: any) => row.name,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Контрагент</span>,
            selector: (row: any) => row.partner,
        },
    ];

    return (
        <Card>
            <CardHeader>
                Список задач мне
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={myTasks}
                    noDataComponent={'Нет задач мне'}
                    defaultSortFieldId={"date"}
                    defaultSortAsc={false}
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно актов:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                    onRowClicked={
                        (row) => {
                            if(window.innerWidth <= 768){
                                setMyTaskInfo(row)
                                setOpenModalMyTaskInfo(true)
                            }
                        }
                    }
                    onRowDoubleClicked={
                        (row) => {
                            if(window.innerWidth > 768){
                                setMyTaskInfo(row)
                                setOpenModalMyTaskInfo(true)
                            }
                        }
                    }
                />
            </CardBody>
            {
                openModalMyTaskInfo &&
                <ModalMyTaskInfo myTaskInfo={myTaskInfo} openModalMyTaskInfo={openModalMyTaskInfo} setOpenMyTaskInfo={(value) => setOpenModalMyTaskInfo(value)}/>
            }
        </Card>
    );
};

export default TableMyTask;

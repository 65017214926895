import {AxiosResponse} from "axios";
import $api from "../../AxiosClient";
class MyTaskService {
    static async getMyTask(): Promise<AxiosResponse<any>>{
        return $api.get<any>("/get_my_task");
    }

    static async nextWaypoint(data: any): Promise<AxiosResponse<any>>{
        return $api.post<any>("/next_waypoint", {data});
    }
}

export default MyTaskService;
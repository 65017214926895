import React, {FC, memo, useEffect} from 'react';
import DataTable from "react-data-table-component";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getDeviationChild} from "../../../store/reducers/parent/ActionCreator";
import {IDeviationChild} from "../../../models/parent/IDeviationChild";

interface ITableAllAttendanceChildren {
    date: Date
}
const TableAllAttendanceChildren: FC<ITableAllAttendanceChildren> = ({date}) => {

    const dispatch = useAppDispatch();
    const {deviationChildPaid, child} = useAppSelector(state => state.ParentReducer)

    useEffect(() => {
        dispatch(getDeviationChild(date, child, "True")).then()
    }, [child, date, dispatch])


    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Отсутствие</span>,
            selector: (row: IDeviationChild) => row.prichina ? row.prichina : "Присутствовал",
            sortable: true,
            style: {
                fontSize: '14px'
            },
        },
        {
            id: "count",
            name: <span className='font-weight-bold fs-13'>Колличество</span>,
            selector: (row: IDeviationChild) => row.count,
            sortable: true,
            center: true,
        }
    ];


    return (
        <DataTable
            columns={columns}
            data={deviationChildPaid}
            noDataComponent={'Нет данных'}
            defaultSortFieldId={"count"}
            defaultSortAsc={false}
            highlightOnHover
            customStyles={{
                rows: {
                    highlightOnHoverStyle: {
                        backgroundColor: "#ffebb5"
                    }
                }
            }}
        />
    );
};

export default memo(TableAllAttendanceChildren);

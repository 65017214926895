import React, {FC, useEffect, useState} from 'react';
import {Badge, Card, CardBody, Col, Row} from "reactstrap";
import bgImage from "../../assets/images/small/img-5.jpg";
import avatar1 from "../../assets/images/users/avatarSeminar.jpg";
import {Link} from "react-router-dom";
import ModelRegister from "./ModelRegister";
import AxiosClient from "../../api/AxiosClient";
import {useStateContext} from "../../hooks/useStateContext";
import moment from "moment";

interface IElemSeminar {
    seminar: { id: number, name: string, dateStart: Date, link: string }
}

const ElemSeminar: FC<IElemSeminar> = ({seminar}) => {

    const {user} = useStateContext()
    const [register, setRegister] = useState(false);

    const [registerModal, setRegisterModal] = useState(false);

    const [userSeminar, setUserSeminar] = useState<any[]>([]);
    const [countUser, setCountUser] = useState(0);

    useEffect(() => {
        AxiosClient.get('/user_seminar')
            .then(r => setUserSeminar(r.data))
        AxiosClient.get('/seminar_count_user', {params: {id: seminar.id}}).then(r => {
            setCountUser(r.data)
        })
    }, []);

    const badge = (register: boolean, participate: boolean) => {
        if (register || participate) {
            if (register && !participate) {
                return <Badge color="warning" className="position-absolute mt-2 mx-2 fs-16">Записан</Badge>
            }
            return <Badge color="success" className="position-absolute mt-2 mx-2 fs-16">Учавствуете</Badge>
        }
    }

    const btnParticipate = (status: string) => {
        AxiosClient.post('/update_user_seminar_participant', {status: status, user_id: user.id, seminar_id: seminar.id})
            .then(() => {
                //setParticipate(!participate)
                AxiosClient.get('/user_seminar')
                    .then(r => setUserSeminar(r.data))
                AxiosClient.get('/seminar_count_user', {params: {id: seminar.id}}).then(r => setCountUser(r.data))
            })
    }

    const checkRecord = (idSeminar: number) => {
        const userRecord = userSeminar.find((u: any) => u.user_id === user.id && u.status === 'Записан' && u.seminar_id === idSeminar)
        const userParticipation = userSeminar.find((u: any) => u.user_id === user.id && u.status === 'Участие' && u.seminar_id === idSeminar)
        if (userParticipation)
            return !!userParticipation
        return !!userRecord
    }

    const checkParticipation = (idSeminar: number) => {
        const userParticipation = userSeminar.find((u: any) => u.user_id === user.id && u.status === 'Участие' && u.seminar_id === idSeminar)
        return !!userParticipation
    }

    const isStart = () => {
        let myTime = moment().toDate();
        let offset = myTime.getTimezoneOffset();
        let myTimeForMoscow = new Date(myTime.getTime() + (offset * 60 * 1000) + (3 * 60 * 60 * 1000));
        if (myTimeForMoscow >= moment(seminar.dateStart).subtract('7', 'd').toDate()) {
            return checkRecord(seminar.id) ?
                <div>
                    {
                        checkParticipation(seminar.id) ? (
                                <div>
                                    {
                                        seminar.link &&
                                        <Link to={seminar.link}
                                              className="btn btn-light view-btn mt-3" target="_blank">Перейти на семинар</Link>
                                    }

                                    <Link to={`#`}
                                          className="btn btn-light view-btn mt-3"
                                          onClick={() => {
                                              //setParticipate(!participate)
                                              btnParticipate('Записан')
                                          }}>Отменить
                                        участие</Link>
                                </div>
                            )
                            :
                            <button className="btn btn-light view-btn"
                                    onClick={() => btnParticipate('Участие')}>Учавствовать</button>
                    }
                </div>
                :
                <button className="btn btn-light view-btn"
                        onClick={() => {
                            setRegisterModal(true)
                        }}>Записаться</button>
        } else {
            return <button className="btn btn-light view-btn" disabled>Регистрация будет доступна с {moment(seminar.dateStart).subtract('7', 'd').format('DD.MM.YYYY HH:mm')}</button>
        }
    }

    return (
        <Col lg={6} xl={4} xxl={3}>
            <Card className="team-box card-height-100">
                <div className="team-cover">
                    {
                        <img src={bgImage} alt="" className="img-fluid"/>
                    }
                </div>
                {
                    badge(checkRecord(seminar.id), checkParticipation(seminar.id))
                }
                <CardBody className="p-4">
                    <Row className="align-items-center team-row">
                        <Col lg={4} className="col">
                            <div className="team-profile-img">
                                <div
                                    className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                    <img src={avatar1} alt=""
                                         className="img-fluid d-block rounded-circle"/>
                                </div>
                                <div className="team-content">
                                    <Link to="#"><h5 className="fs-16 mb-1">{seminar.name}</h5></Link>
                                    {/*<p className="text-muted mb-0">Описание семинар 1</p>*/}
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} className="col">
                            <Row className="text-muted text-center justify-content-center">
                                <Col xs={12}>
                                    <h5 className="mb-1">{moment(seminar.dateStart).format('DD.MM.YYYY')}</h5>
                                    <p className="text-muted mb-0">Дата</p>
                                </Col>
                                <Row className="d-flex justify-content-center mt-1">
                                    <Col xs={6} className="border-end border-end-dashed">
                                        <h5 className="mb-1">{countUser}</h5>
                                        <p className="text-muted mb-0">Участников</p>
                                    </Col>
                                    <Col xs={6}>
                                        <h5 className="mb-1">{moment(seminar.dateStart).format('HH:mm')} МСК</h5>
                                        <p className="text-muted mb-0">Время</p>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                        <Col lg={2} className="col">
                            <div className="text-end">
                                {
                                    isStart()
                                }
                            </div>
                        </Col>
                    </Row>
                    <ModelRegister registerModal={registerModal}
                                   setRegisterModal={(value) => setRegisterModal(value)}
                                   setRegister={(values) => setRegister(values)} id={seminar.id}/>
                </CardBody>
            </Card>
        </Col>
    );
};

export default ElemSeminar;

import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const CountChildrenAdditionalEducationInPreSchoolThreeFivePart2 = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState<any[]>([
        {
            org: "Организация 1",
            tech: {
                group: '2',
                children: '23'
            },
            naturalScience: {
                group: "3",
                children: '56'
            },
            physicalEducation:{
                group: "5",
                children: "23"
            },
        },
        {
            org: "Организация 2",
            tech: {
                group: '2',
                children: '23'
            },
            naturalScience: {
                group: "3",
                children: '56'
            },
            physicalEducation:{
                group: "5",
                children: "23"
            },
        },
        {
            org: "Организация 3",
            tech: {
                group: '2',
                children: '23'
            },
            naturalScience: {
                group: "3",
                children: '56'
            },
            physicalEducation:{
                group: "5",
                children: "23"
            },
        },
        {
            org: "Организация 4",
            tech: {
                group: '2',
                children: '23'
            },
            naturalScience: {
                group: "3",
                children: '56'
            },
            physicalEducation:{
                group: "5",
                children: "23"
            },
        }

    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Техн. (группы/дети)</span>,
            selector: (row: any) => (`${row.tech.group}/${row.tech.children}`),
        },
        {
            name: <span className='font-weight-bold fs-13'>Ест. науч. (группы/дети)</span>,
            selector: (row: any) => (`${row.naturalScience.group}/${row.naturalScience.children}`),
        },
        {
            name: <span className='font-weight-bold fs-13'>Физ. спорт. (группы/дети)</span>,
            selector: (row: any) => (`${row.physicalEducation.group}/${row.physicalEducation.children}`),
        }
    ]

    return (
        <Card>
            <CardHeader>
                Дополнительное образование в ДОУ(3 - 5 лет)
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default CountChildrenAdditionalEducationInPreSchoolThreeFivePart2;

import React, {useEffect, useState} from 'react';
import {Alert, Col, Container, Row} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import SelectToUpdateCabinet from "../../components/department/cabinet/SelectToUpdateCabinet";
import CabinetAdd from "../../components/department/cabinet/CabinetAdd";
import CabinetUpdate from "../../components/department/cabinet/CabinetUpdate";
import {getCabinets, getOrganizations, getTypeCabinets} from "../../store/reducers/department/ActionCreator";

const SettingsCabinet = () => {

    const dispatch = useAppDispatch();

    const {isError, cabinets} = useAppSelector(state => state.DepartmentReducer)

    const [addCabinet, setAddCabinet] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getCabinets())
        dispatch(getTypeCabinets())
        dispatch(getOrganizations())
    }, [dispatch]);


    return (
        <div className="page-content">
            {isError && <Alert className="alert-danger mb-4">{isError}</Alert>}
            <Container fluid>
                <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                    <SelectToUpdateCabinet
                        setAddCabinet={(val) => setAddCabinet(val)}
                        cabinets={cabinets}/>
                    <div className="w-100 overflow-hidden">
                        <div className="d-lg-flex">
                            <div className="w-100">
                                <div>
                                    <div className="mt-1 mt-sm-1 mt-lg-0 mt-xl-0 mt-xxl-0" id="users-chat">
                                        <Row >
                                            <Col>
                                                {
                                                    addCabinet ? <CabinetAdd /> : <CabinetUpdate/>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SettingsCabinet;

import React, {FC} from 'react';
import IconFile from "./IconFile";
interface IAddOtherDoc {
    url: {url: string, name: string}
}
const AddOtherDoc: FC<IAddOtherDoc> = ({url}) => {


    return (
        <div className="d-flex border border-dashed p-2 rounded position-relative">
            <div className="flex-shrink-0 avatar-xs">
                <div className="avatar-title bg-white rounded">
                    <IconFile name={url.name}/>
                </div>
            </div>
            <div className="flex-shrink-0">

            </div>
            <div className="flex-grow-1 overflow-hidden ms-2">
                <h6 className="text-truncate mb-0"><a href={url.url} className="stretched-link" download={url.name}>{url.name}</a></h6>
            </div>
        </div>
    );
};

export default AddOtherDoc;

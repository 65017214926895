import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import WidgetRequiredFlatpikerPeriod from "../../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import TableRecommendedLessons
    from "../../components/department/RecomendedLessonsInOrganizations/TableRecommendedLessons";
import GraphCountChildrenOrganization
    from "../../components/department/AttendanceInOrganization/GraphCountChildrenOrganization";
import GraphCountChildrenYear from "../../components/department/AttendanceInOrganization/GraphCountChildrenYear";
import PhoneOrPC from "../../components/department/PhoneOrPC";
import TableRecommendedLessonsPart1
    from "../../components/department/RecomendedLessonsInOrganizations/TablePhone/TableRecommendedLessonsPart1";
import TableRecommendedLessonsPart2
    from "../../components/department/RecomendedLessonsInOrganizations/TablePhone/TableRecommendedLessonsPart2";
import TableRecommendedLessonsPart3
    from "../../components/department/RecomendedLessonsInOrganizations/TablePhone/TableRecommendedLessonsPart3";
import Widgets from "../../components/department/RecomendedLessonsInOrganizations/Widgets";
import SelectOrganization from "../../components/department/SelectOrganization";
import TableCountTeacherInDOY
    from "../../components/department/RecomendedLessonsInOrganizations/TableCountTeacherInDOY";
import TableCountTeacherInPPK
    from "../../components/department/RecomendedLessonsInOrganizations/TableCountTeacherInPPK";
import TableCountTeacherInDoyPart1
    from "../../components/department/RecomendedLessonsInOrganizations/TablePhone/TableCountTeacherInDOYPart1";
import TableCountTeacherInDoyPart2
    from "../../components/department/RecomendedLessonsInOrganizations/TablePhone/TableCountTeacherInDOYPart2";
import TableCountTeacherInPpkPart1
    from "../../components/department/RecomendedLessonsInOrganizations/TablePhone/TableCountTeacherInPPKPart1";
import TableCountTeacherInPpkPart2
    from "../../components/department/RecomendedLessonsInOrganizations/TablePhone/TableCountTeacherInPPKPart2";

const RecommendedLessonsInOrganizations = () => {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Рекомендованные занятия в организациях</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <WidgetRequiredFlatpikerPeriod/>
                                    <SelectOrganization/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Widgets />
                                    <Row>
                                        <Col>
                                            <GraphCountChildrenYear/>
                                        </Col>
                                        <Col>
                                            <GraphCountChildrenOrganization/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <PhoneOrPC phone={[<TableRecommendedLessonsPart1/>, <TableRecommendedLessonsPart2/>, <TableRecommendedLessonsPart3/>,
                                                    <TableCountTeacherInDoyPart1/>, <TableCountTeacherInDoyPart2/>,
                                                    <TableCountTeacherInPpkPart1/>, <TableCountTeacherInPpkPart2/>]}
                                               pc={[<TableRecommendedLessons/>, <TableCountTeacherInDOY/>, <TableCountTeacherInPPK/>]}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default RecommendedLessonsInOrganizations;

import React, {FC, useEffect, useState} from 'react';
import Select from "react-select";
import {Input} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
    setCountSelectOrganizations,
    setDataSelectOrganizations,
    setSelectOrganizations
} from "../../store/reducers/department/ActionCreator";

const SelectOrganization = () => {

    const dispatch = useAppDispatch()
    const {dataSelectOrganizations, countSelectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [elems, setElems] = useState<{value: string, label: string, checked: boolean}[]>([]);
    useEffect(() => {
        dataSelectOrganizations.length === 0 &&
        dispatch(setDataSelectOrganizations([{label: 'Ленинский р-н', checked: false, options: [{value: '1', label: 'Организация 1', checked: false}, {value: '2', label: 'Организация 2', checked: false}]}, {label: 'Восточный р-н', checked: false, options: [{value: '3', label: 'Организация 3', checked: false}, {value: '4', label: 'Организация 4', checked: false}]}]))
    }, []);

    const customStyles = {
        control: (ds: any) => ({
            ...ds,
            backgroundColor: "#fff",
        }),
        option: (ds: any, state: any) => ({
            ...ds,
            backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
            color: state.isFocused ? "#fff" : "#000"
        })
    }
    const checked = (checked: boolean, elemId: string) => {
        let count = +countSelectOrganizations.label.split(" ")[1];
        let isAll = false
        const newData = dataSelectOrganizations.map(lab => {
            return {
                ...lab,
                checked: isAll,
                options: lab.options.map((option: any) => {
                    if (option.value === elemId){
                        if (checked){
                            count += 1
                            dispatch(setSelectOrganizations([...elems, {...option, checked: checked}])).then()
                            setElems([...elems, {...option, checked: checked}])
                        }
                        else{
                            count -= 1
                            dispatch(setSelectOrganizations(elems.filter(el => el.value !== elemId))).then()
                            setElems(elems.filter(el => el.value !== elemId))
                        }
                        return {...option, checked: checked}
                    }
                    else {
                        return option
                    }
                })
            }
        })
        dispatch(setCountSelectOrganizations({...countSelectOrganizations, label: `Выбрано ${count}`})).then()
        dispatch(setDataSelectOrganizations(newData)).then()
    }

    const checkedAll = (checked: boolean, elemId: string) => {
        let count = +countSelectOrganizations.label.split(" ")[1];
        let el: any[] = elems
        const newData = dataSelectOrganizations.map(lab => {
            if (lab.label === elemId){
                return {
                    ...lab,
                    checked: checked,
                    options: lab.options.map((option: any) => {
                        if (checked){
                            count += 1
                            el = [...el, {...option, checked: checked}]
                        }
                        else{
                            count -= 1
                            el = el.filter(ele => ele.value !== option.value)
                        }
                        return {...option, checked: checked}
                    })
                }
            }
            return lab
        })
        setElems(el)
        dispatch(setSelectOrganizations(el)).then()
        dispatch(setCountSelectOrganizations({...countSelectOrganizations, label: `Выбрано ${count}`})).then()
        dispatch(setDataSelectOrganizations(newData))
    }



    const formatOptionLabel = (data: any) => (
        <div className="mx-3 ">
            <div className="d-flex align-items-center">
                <Input type="checkbox" style={{width: 20, height: 20}} defaultChecked={data.checked} onClick={() => checked(!data.checked, data.value)}/>
                <span className="mx-2">{data.label}</span>
            </div>
        </div>
    );

    const formatGroupLabel = (data: any) => (
        <div>
                <Input style={{width: 20, height: 20}} type="checkbox" className="mb-2 mt-n1" defaultChecked={data.checked} onClick={() => checkedAll(!data.checked, data.label)}/>
                <span className="mx-1">{data.label}</span>
        </div>
    );

    return (
        <Select options={dataSelectOrganizations}
                value={countSelectOrganizations}
                closeMenuOnSelect={false}
                name="stock"
                styles={customStyles}
                placeholder="Поиск"
                maxMenuHeight={500}
                minMenuHeight={200}
                formatGroupLabel={formatGroupLabel}
                formatOptionLabel={formatOptionLabel}
                className="mb-2"/>
    );
};

export default SelectOrganization;

import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import TableCashAccountingTotal from "../../components/parent/table/TableCashAccountingTotal";
import {Card, CardBody, CardFooter, Col, Container, Row} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import TableCashAccounting from "../../components/parent/table/TableCashAccounting";
import {getChildren} from "../../store/reducers/parent/ActionCreator";
import {ParentSlice} from "../../store/reducers/parent/ParentSlice";
import GraphDonutCashAccounting from "../../components/parent/graph/GraphDonutCashAccounting";
import {Helmet} from "react-helmet";
import ModalGetReceipt from "../../components/widgets/modal/ModalGetReceipt";
import {Link} from "react-router-dom";
import payVTB from '../../assets/images/payments/schoolAndKindergartenVTBpay.jpg'

const ChildrenCashAccounting = () => {

    const [date, setDate] = useState([new Date(new Date().setDate(1)), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)]);
    const dispatch = useAppDispatch();
    const [getReceipt, setGetReceipt] = useState(false);
    const [typeReceipt, setTypeReceipt] = useState<"receipt" | "compare">('receipt');
    const {child, children} = useAppSelector(state => state.ParentReducer)

    useEffect(() => {
        dispatch(getChildren()).then()
    }, [dispatch]);


    return (
        <div className="page-content">
            <Container fluid>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Баланс учета денежных средств</title>
                </Helmet>
                <Row>
                    <Col xxl={3}>
                        <div className="col-auto mb-3">
                            <div className="input-group">
                                <Flatpickr
                                    className="form-control border-0 dash-filter-picker shadow"
                                    value={date}
                                    options={{
                                        locale: Russian, mode: "range", dateFormat: "d m Y", disableMobile: true
                                    }}
                                    onClose={(date) => {
                                        setDate(date)
                                    }}
                                />
                            </div>
                        </div>
                        <Card>
                            <CardBody className="fs-18 ">
                                Ребенок:
                                <select className="form-select mt-1" value={child}
                                        onChange={event => dispatch(ParentSlice.actions.setChild(event.target.value))}>
                                    {
                                        children.map(child =>
                                            (<option key={child.account_uid} value={child.account_uid}>{child.account_name}</option>)
                                        )
                                    }
                                </select>
                                Лиц. счет: <br />
                                {
                                    children.find((children : any) => children.account_uid === child)?.personal_account
                                }
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Link to="http://online.vtb.ru/i/pay">
                                    <img src={payVTB} width='100%' height={'70%'} className="mb-3" alt="payVTB"/>
                                </Link>
                            </CardBody>
                        </Card>
                        <GraphDonutCashAccounting dateStart={date} child={child}/>
                    </Col>
                    <Col xxl={9}>
                        <div>
                            <Card>
                                <CardBody>
                                    <TableCashAccountingTotal date={date}/>
                                </CardBody>
                                <CardFooter>
                                    <Row>
                                        <Col xxl={3} lg={4}>
                                            <button className="btn btn-primary w-100 mt-1" onClick={() => {
                                                setGetReceipt(true)
                                                setTypeReceipt("receipt")
                                            }}>Получить квитанцию</button>
                                        </Col>
                                        <Col xxl={3} lg={4}>
                                            <button className="btn btn-primary w-100 mt-1" onClick={() => {
                                                setGetReceipt(true)
                                                setTypeReceipt("compare")
                                            }}>Получить акт сверки</button>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </div>

                        <div>
                            <Card>
                                <TableCashAccounting date={date}/>
                            </Card>
                        </div>
                    </Col>
                </Row>
                <ModalGetReceipt dateSlice={date[0]} modalSign={getReceipt} setModalSign={(value) => setGetReceipt(value)} type={typeReceipt}/>
            </Container>
        </div>
    );
};

export default ChildrenCashAccounting;

import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import * as Yup from "yup";
import {Col, Label, Row} from "reactstrap";
import {updateGroup} from "../../../store/reducers/department/ActionCreator";

const UpdateDataGroupForm = () => {
    const dispatch = useAppDispatch();
    const {selectGroup, typesGroup, regions, organizations, cabinets, subdivisions} = useAppSelector(state => state.DepartmentReducer)

    return (
        <Formik
            enableReinitialize
            initialValues={{
                name: `${selectGroup?.name}`,
                game_arena: `${selectGroup?.game_arena}`,
                children: `${selectGroup?.children}`,
                cabinet_id: selectGroup?.cabinet_id,
                organization_id: selectGroup?.organization_id,
                type_group_id: selectGroup?.type_group_id,
                type_subdivision_id: selectGroup?.type_subdivision_id,
                region_id: selectGroup?.region_id
            }}
            validationSchema={
                Yup.object({
                    name: Yup.string().required('Введите название группы'),
                    game_arena: Yup.string().required('Обязательное поле'),
                    children: Yup.number().required('Обязательное поле'),
                    cabinet_id: Yup.string().required('Обязательное поле'),
                    organization_id: Yup.string().required('Обязательное поле'),
                    type_group_id: Yup.string().required('Обязательное поле'),
                    type_subdivision_id: Yup.string().required('Обязательное поле'),
                    region_id: Yup.string().required('Обязательное поле')
                })
            }
            onSubmit={(value) => {
                dispatch(updateGroup(value, selectGroup!.id))
            }}
        >
            <Form>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="nameInput" className="form-label">Название
                            </Label>
                            <Field type="text" className="form-control" id="nameInput"
                                   name="name"
                                   placeholder="Введите название группы"/>
                            <ErrorMessage name="name">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="game_arenaInput" className="form-label">Игровая
                                площадь
                            </Label>
                            <Field type="text" className="form-control" id="game_arenaInput"
                                   name="game_arena"
                                   placeholder="Введите игровую площадь"/>
                            <ErrorMessage name="game_arena">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="cabinet_idInput"
                                   className="form-label">Кабинет</Label>
                            <Field as="select" className="form-select" id="cabinet_idInput"
                                   name="cabinet_id">
                                {
                                    cabinets.map(item => (
                                        <option key={item.id}
                                                value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Field>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="childrenInput" className="form-label">Колличество
                                детей по СанПину</Label>
                            <Field type="text" className="form-control" id="childrenInput"
                                   name="children"
                                   placeholder="Введите колличество детей"/>
                            <ErrorMessage name="children">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="organization_idInput"
                                   className="form-label">Организация</Label>
                            <Field as="select" className="form-select"
                                   id="organization_idInput"
                                   name="organization_id">
                                {
                                    organizations.map(item => (
                                        <option key={item.id}
                                                value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Field>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="type_group_idInput"
                                   className="form-label">Тип группы</Label>
                            <Field as="select" className="form-select"
                                   id="type_group_idInput"
                                   name="type_group_id">
                                {
                                    typesGroup.map(item => (
                                        <option key={item.id}
                                                value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Field>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="type_subdivision_idInput"
                                   className="form-label">Тип подразделения</Label>
                            <Field as="select" className="form-select"
                                   id="type_subdivision_idInput"
                                   name="type_subdivision_id">
                                {
                                    subdivisions.map(item => (
                                        <option key={item.id}
                                                value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Field>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="region_idInput"
                                   className="form-label">Регион</Label>
                            <Field as="select" className="form-select" id="region_idInput"
                                   name="region_id">
                                {
                                    regions.map(item => (
                                        <option key={item.id}
                                                value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Field>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="mt-3">
                        <div className="hstack gap-2 justify-content-end">
                            <button type="submit"
                                    className="btn btn-primary">Обновить
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Formik>
    );
};

export default UpdateDataGroupForm;

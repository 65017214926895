import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import {useAppSelector} from "../../../hooks/redux";

const GraphCountChildrenYear = () => {

    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)
    const [selectedMonth, setSelectedMonth] = useState<string[]>(['year', 'Текущий год']);

    const [data, setData] = useState<any[]>([
        {
            year: 'year',
            data: [
                {
                    label: 'Организация 1',
                    options: {
                        oneThree: 240,
                        threeSeven: 250,
                        sevenEight: 230
                    }
                },
                {
                    label: 'Организация 2',
                    options: {
                        oneThree: 240,
                        threeSeven: 250,
                        sevenEight: 230
                    }
                },
                {
                    label: 'Организация 3',
                    options: {
                        oneThree: 240,
                        threeSeven: 250,
                        sevenEight: 230
                    }
                },
                {
                    label: 'Организация 4',
                    options: {
                        oneThree: 240,
                        threeSeven: 250,
                        sevenEight: 230
                    }
                }
            ]
        },
        {
            year: 'yearOld',
            data: [
                {
                    label: 'Организация 1',
                    options: {
                        oneThree: 223,
                        threeSeven: 214,
                        sevenEight: 236
                    }
                },
                {
                    label: 'Организация 2',
                    options: {
                        oneThree: 145,
                        threeSeven: 232,
                        sevenEight: 534
                    }
                },
                {
                    label: 'Организация 3',
                    options: {
                        oneThree: 235,
                        threeSeven: 123,
                        sevenEight: 321
                    }
                },
                {
                    label: 'Организация 4',
                    options: {
                        oneThree: 222,
                        threeSeven: 312,
                        sevenEight: 543
                    }
                }
            ]
        }
    ]);
    const [outData, setOutData] = useState<any[]>([
        {name: '1-3 лет', type: "bar", data: [0]},
        {name: '3-7 лет', type: "bar", data: [0]},
        {name: '7-8 лет', type: "bar", data: [0]}
    ]);

    useEffect(() => {
        let selOrg: string[] = selectOrganizations?.map(s => s.label)
        let newData = data.find(d => d.year === selectedMonth[0])?.data
        if (newData) {
            newData = newData.filter((d: any) => selOrg?.includes(d.label))
            const oneThree: number[] = newData.map((r: any) => r.options.oneThree)
            const threeSeven: number[] = newData.map((r: any) => r.options.threeSeven)
            const sevenEight: number[] = newData.map((r: any) => r.options.sevenEight)

            setOutData([
                {name: '1-3 лет', type: "bar", data: [oneThree.length > 0 ? oneThree.reduce((a, b) => a + b) : 0]},
                {name: '3-7 лет', type: "bar", data: [threeSeven.length > 0 ? threeSeven.reduce((a, b) => a + b) : 0]},
                {name: '7-8 лет', type: "bar", data: [sevenEight.length > 0 ? sevenEight.reduce((a, b) => a + b) : 0]}
            ])
        } else {
            setOutData([
                {name: '1-3 лет', type: "bar", data: [0]},
                {name: '3-7 лет', type: "bar", data: [0]},
                {name: '7-8 лет', type: "bar", data: [0]}
            ])
        }


    }, [selectOrganizations, selectedMonth]);

    const options: any = {
        chart: {
            height: '500px',
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 3],
            width: [0, 0, 2],
        },
        fill: {
            opacity: [1, 1, 0.3]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 4,
            }
        },
        xaxis: {
            categories: ['Всего'],
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        }
    };

    const onChangeChartPeriod = (pType: string[]) => {
        setSelectedMonth(pType)
    };

    return (
        <Card>
            <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Дети в организации</h4>
                <div className="flex-shrink-0">
                    <UncontrolledDropdown className="card-header-dropdown">
                        <DropdownToggle tag="a" className="dropdown-btn text-muted" role="button">
                            {selectedMonth[1]} <i className="mdi mdi-chevron-down ms-1"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end overflow-auto">
                            <DropdownItem onClick={() => {
                                onChangeChartPeriod(['year', 'Текущий год']);
                            }} className={selectedMonth[0] === "year" ? "active" : ""}>Текущий год</DropdownItem>
                            <DropdownItem onClick={() => {
                                onChangeChartPeriod(['yearOld', 'Прошлый год']);
                            }} className={selectedMonth[0] === "yearOld" ? "active" : ""}>Прошлый год</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </CardHeader>
            <CardBody>
                <ReactApexChart
                    options={options}
                    series={outData}
                    type="line"
                    height="400"
                    className="apex-charts"
                />
            </CardBody>
        </Card>
    );
};

export default GraphCountChildrenYear;

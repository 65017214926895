import React, {MouseEvent, useEffect, useState} from 'react';
import {Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import AxiosClient from "../../api/AxiosClient";
import CardGameTeacher from "./CardGameTeacher";
import {Link} from "react-router-dom";

const ListGames = () => {
    const [games, setGames] = useState<{ id: number, name: string, file: string | null }[]>([]);
    const [selectGameId, setSelectGameId] = useState<number | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        AxiosClient.get('/game')
            .then((r) => setGames(r.data))
    }, []);

    const removeConfirmGame = (event: MouseEvent<HTMLElement, globalThis.MouseEvent>, id: number) => {
        event.stopPropagation()
        event.preventDefault()
        setSelectGameId(id)
        setIsOpen(true)
    }

    const removeGame = () => {
        selectGameId &&
        AxiosClient.post(`/game/delete/${selectGameId}`)
            .then((r) => {
                setGames((games) => games.filter((game) => game.id !== selectGameId))
                setSelectGameId(null)
                setIsOpen(false)
            })
    }

    const toggle = () => {
        setIsOpen((value) => !value)
        setSelectGameId(null)
    }

    return (
        <div className="page-content">
            <Container fluid>
                <div>
                    <Row>
                        <Col>
                            <Link to={'/games/create'} className="btn btn-primary">Создать игру</Link>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        {
                            games.map(el => (
                                <Col xxl={3} xl={4} lg={6} key={el.id}>
                                    <CardGameTeacher id={el.id} name={el.name} path={el.file} removeGame={(event, id) => removeConfirmGame(event, id)}/>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </Container>
            <Modal isOpen={isOpen} toggle={toggle} centered>
                <ModalHeader toggle={toggle} className="p-3 bg-soft-primary">Удаление игры</ModalHeader>
                <ModalBody>
                    Вы уверены, что хотите удалить игру?
                    <div className="mt-3 gap-2 d-flex">
                        <button className="btn btn-primary" type="button" onClick={() => removeGame()}>
                            Удалить
                        </button>
                        <button className="btn btn-primary" type="button" onClick={toggle}>
                            Отмена
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default ListGames;

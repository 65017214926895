import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    setDateAdd, setGroupModalAdd,
    setIsSend,
    setModalAdd,
    setToUpdateGraphMainPage,
    WidgetsSlice
} from "../../../store/reducers/widgets/WidgetsSlice";
import {Alert, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import {convertDate} from "../../../functions/ConvertDate";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import moment from "moment/moment";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import ModalSelectDeviationAll from "../../widgets/modal/ModalSelectDeviationAll";
import AxiosClient from "../../../api/AxiosClient";
import {getJournalHaccpTemperature, getJournalHaccpVentilationRoom} from "../../../store/reducers/widgets/ActionCreator";

interface IModalAddReportJournalHaccp{
    updateData: any
}

const ModalAddReportJournalVentilationRoom: FC<IModalAddReportJournalHaccp> = ({updateData}) => {
    const dispatch = useAppDispatch();
    const {modalAdd, groupAllDeviations, date, groupModalAdd, students, groups, dateAdd, successUpdate} = useAppSelector(state => state.WidgetReducer);
    const [timeStart, setTimeStart] = useState(moment().format('HH:mm'));
    const [timeEnd, setTimeEnd] = useState(moment().format('HH:mm'));

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(WidgetsSlice.actions.successUpdate(""))
        }, 5000)
        return () => clearTimeout(timer)
    }, [successUpdate, dispatch]);

    return (
        <Modal id="createProjectModal" isOpen={modalAdd}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => dispatch(setModalAdd(!modalAdd))} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Добавление параметров температуры
            </ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        group: `${groupModalAdd}`,
                        date: moment(dateAdd).format("DD-MM-YYYY"),
                        timeStart: updateData? updateData.timeStart : '',
                        timeEnd: updateData? updateData.timeEnd : '',
                        temperature: updateData? updateData.temperature : '',
                    }}
                    onSubmit={(value) => {
                        dispatch(setIsSend(true));

                        let prop = {
                            group_id : groupAllDeviations,
                            uid: updateData? updateData.uid : '',
                            number: updateData? updateData.number : '',
                            data: moment(dateAdd).format("DD-MM-YYYY"),
                            time: timeStart,
                            endtime: timeEnd,
                            temperature: value.temperature,
                        }

                        AxiosClient.post('haccp/saveJournalVentilationRoom', prop)
                            .then((r: any) => {
                                dispatch(getJournalHaccpVentilationRoom(date, groupAllDeviations)).then();
                                dispatch(setIsSend(false));
                                dispatch(setModalAdd(false));
                            })
                            .catch((error) => {
                                dispatch(setIsSend(false))
                            })
                    }}
                >
                    <Form>
                        <div className="col-auto">
                            <label>Дата:</label>
                            <div className="col mb-3">
                                <div className="input-group col-auto">
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker shadow"
                                        value={dateAdd}
                                        options={{
                                            locale: Russian, dateFormat: "d M Y", disableMobile: true, mode: 'single'
                                        }}
                                        onChange={(date) => {
                                            dispatch(setDateAdd(moment(date[0]).toDate()))
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Группа:</label>
                            <Field as="select" className="form-select shadow" value={groupModalAdd}
                                   onChange={(e: any) => dispatch(setGroupModalAdd(e.target.value))} name="group">
                                {groups.map((group) => {
                                    return <option key={group.group_id}
                                                   value={group.group_id}>{group.group_name}</option>
                                })}
                            </Field>
                        </div>

                        <div className="mb-3">
                            <label>Время начала проветривания:</label>
                            <div className="col mb-3">
                                <div className="input-group col-auto">
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker shadow"
                                        value={timeStart}
                                        options={{
                                            locale: Russian,
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                            disableMobile: true,
                                            mode: 'single'
                                        }}
                                        onChange={(time) => setTimeStart(moment(time[0]).format('HH:mm'))}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label>Время окончания проветривания:</label>
                            <div className="col mb-3">
                                <div className="input-group col-auto">
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker shadow"
                                        value={timeEnd}
                                        options={{
                                            locale: Russian,
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                            disableMobile: true,
                                            mode: 'single'
                                        }}
                                        onChange={(time) => setTimeEnd(moment(time[0]).format('HH:mm'))}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="w-100">
                                Наружная температура:
                                <Field name="temperature" className={`form-control`}/>
                            </label>
                        </div>

                        <div className="d-flex gap-5 justify-content-between">
                            <div></div>
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddReportJournalVentilationRoom;

import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import TableAchievementsAll from "../../components/department/AchievementsChildren/TableAchievementsAll";
import TableAchievementsInDOY from "../../components/department/AchievementsChildren/TableAchievementsInDOY";
import GraphIntellect from "../../components/department/AchievementsChildren/GraphIntellect";
import GraphCreation from "../../components/department/AchievementsChildren/GraphCreation";
import GraphSport from "../../components/department/AchievementsChildren/GraphSport";
import GraphParticipant from "../../components/department/AchievementsChildren/GraphParticipant";
import Widget from "../../components/department/AchievementsChildren/Widget";

const AchievementsChildren = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Достижения детей</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Widget/>
                            </Row>
                            <Row>
                                <Col xxl={6} lg={6}>
                                    <GraphIntellect/>
                                </Col>
                                <Col xxl={6} lg={6}>
                                    <GraphCreation/>
                                </Col>
                                <Col xxl={6} lg={6}>
                                    <GraphSport/>
                                </Col>
                                <Col xxl={6} lg={6}>
                                    <GraphParticipant/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <TableAchievementsAll/>
                            <TableAchievementsInDOY/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default AchievementsChildren;

import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import GraphCountChildrenOrganization
    from "../../components/department/AttendanceInOrganization/GraphCountChildrenOrganization";
import GraphCountChildrenYear from "../../components/department/AttendanceInOrganization/GraphCountChildrenYear";
import Widgets from "../../components/department/OVZDisabledPersonPMPKYear/Widgets";
import Select from "react-select";
import TableCountChildrenYear from "../../components/department/OVZDisabledPersonPMPKYear/TableCountChildrenYear";

const OvzDisabledPersonPmpkYear = () => {
    const [years, setYears] = useState<any[]>([{value: new Date().getFullYear(), label: new Date().getFullYear()}]);

    const customStyles = {
        control: (ds: any) => ({
            ...ds,
            backgroundColor: "#fff",
        }),
        option: (ds: any, state: any) => ({
            ...ds,
            backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
            color: state.isFocused ? "#fff" : "#000"
        })
    }

    function yearsLab(data: number) {
        let currentYear = new Date().getFullYear(), years: any[] = [];
        data = 1980;
        while ( data <= currentYear ) {
            const d = data++
            years.push({value: d, label: d});
        }
        return years;
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Посещаемость в организациях</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xxl={3}>
                            <Select options={yearsLab(new Date().getFullYear())}
                                    value={years}
                                    onChange={(newValue) => {
                                        setYears(newValue as any[])
                                    }}
                                    closeMenuOnSelect={false}
                                    name="stock"
                                    styles={customStyles}
                                    placeholder="Поиск"
                                    maxMenuHeight={500}
                                    minMenuHeight={200}
                                    isMulti
                                    className="mb-2"/>
                            <SelectOrganization/>
                            <GraphCountChildrenOrganization/>
                            <GraphCountChildrenYear/>
                        </Col>
                        <Col xxl={9} >
                            <Widgets/>
                            <TableCountChildrenYear years={years}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default OvzDisabledPersonPmpkYear;

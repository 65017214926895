import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../hooks/redux";
import {Card, CardBody} from "reactstrap";
import DataTable from "react-data-table-component";

const TableAprEmplyeesPart1 = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState<any[]>([
        {
            org: "Организация 1",
            employee: '12',
            vkk: ['12', '3'],
            OneKK: ['12', '3'],
            SZD: ['12', '3'],
            NA: '12',
            Attest: '1',
            AttestNext: "100"
        },
        {
            org: "Организация 2",
            employee: '12',
            vkk: ['12', '3'],
            OneKK: ['12', '3'],
            SZD: ['12', '3'],
            NA: '12',
            Attest: '1',
            AttestNext: "100"
        },
        {
            org: "Организация 3",
            employee: '12',
            vkk: ['12', '3'],
            OneKK: ['12', '3'],
            SZD: ['12', '3'],
            NA: '12',
            Attest: '1',
            AttestNext: "100"
        },
        {
            org: "Организация 4",
            employee: '12',
            vkk: ['12', '3'],
            OneKK: ['12', '3'],
            SZD: ['12', '3'],
            NA: '12',
            Attest: '1',
            AttestNext: "100"
        }

    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Пед-х работников в ДОУ</span>,
            selector: (row: any) => row.employee,
        },
        {
            name: <span className='font-weight-bold fs-13'>Аттестованы ВКК чел/%</span>,
            selector: (row: any) => (`${row.vkk[0]}/${row.vkk[1]}%`),
        },
        {
            name: <span className='font-weight-bold fs-13'>Аттестованы 1КК чел/%</span>,
            selector: (row: any) => (`${row.OneKK[0]}/${row.OneKK[1]}%`),
        },
        {
            name: <span className='font-weight-bold fs-13'>Аттестованы СЗД чел/%</span>,
            selector: (row: any) => (`${row.SZD[0]}/${row.SZD[1]}%`),
        }
    ]

    return (
        <Card>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableAprEmplyeesPart1;

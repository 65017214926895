import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import CountChildrenMaleFemaleInGroupFocuses
    from "../../components/department/GroupFocuses/CountChildrenMaleFemaleInGroupFocuses";
import CountChildrenGroupFocuses from "../../components/department/GroupFocuses/CountChildrenGroupFocuses";
import PhoneOrPc from "../../components/department/PhoneOrPC";
import CountChildrenMaleFemaleInGroupFocusesPart1
    from "../../components/department/GroupFocuses/TablePhone/CountChildrenMaleFemaleInGroupFocusesPart1";
import CountChildrenMaleFemaleInGroupFocusesPart2
    from "../../components/department/GroupFocuses/TablePhone/CountChildrenMaleFemaleInGroupFocusesPart2";
import CountChildrenGroupFocusesPart1
    from "../../components/department/GroupFocuses/TablePhone/CountChildrenGroupFocusesPart1";
import CountChildrenGroupFocusesPart2
    from "../../components/department/GroupFocuses/TablePhone/CountChildrenGroupFocusesPart2";
import GraphCountChildrenGroupFocuses from "../../components/department/GroupFocuses/GraphCountChildrenGroupFocuses";
import GraphCountChildrenMaleFemaleGroupFocuses
    from "../../components/department/GroupFocuses/GraphCountChildrenMaleFemaleGroupFocuses";
import Widget from "../../components/department/GroupFocuses/Widget";

const GroupFocuses = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Направленность групп</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Widget/>
                            </Row>
                            <Row>
                                <Col xl={6} xxl={6}><GraphCountChildrenGroupFocuses/></Col>
                                <Col xl={6} xxl={6}><GraphCountChildrenMaleFemaleGroupFocuses/></Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <PhoneOrPc phone={[<CountChildrenMaleFemaleInGroupFocusesPart1/>, <CountChildrenMaleFemaleInGroupFocusesPart2/>,
                                            <CountChildrenGroupFocusesPart1/>, <CountChildrenGroupFocusesPart2/>]}
                                       pc={[<CountChildrenMaleFemaleInGroupFocuses/>, <CountChildrenGroupFocuses/>]}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default GroupFocuses;

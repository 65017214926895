import React, {useState} from 'react';
import {Col, Container, Row} from "reactstrap";
import {Helmet} from "react-helmet";
import WidgetTableAllScoreEmployee from "../components/widgets/tables/WidgetTableAllScoreEmployee";
import WidgetTableScoreEmployee from "../components/widgets/tables/WidgetTableScoreEmployee";
import WidgetGraphRoundEmployee from "../components/widgets/graphs/WidgetGraphRoundEmployee";
import WidgetGraphProjectStatusEmployeePerformance
    from "../components/widgets/graphs/WidgetGraphProjectStatusEmployeePerformance";
import WidgetRequiredSelectGroupDO from "../components/widgets/requireds/WidgetRequiredSelectGroupDO";
import WidgetRequiredSelectGroup from "../components/widgets/requireds/WidgetRequiredSelectGroup";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import {Russian} from "flatpickr/dist/l10n/ru";
import Flatpickr from "react-flatpickr";
import WidgetRequiredSelectPost from "../components/widgets/requireds/WidgetRequiredSelectPost";

const MainPaidService = () => {
    const [date, setDate] = useState(new Date());
    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Результативность сотрудника</title>
        </Helmet>
        <div className="page-content">
            <Container fluid>
                <Row >
                    <Col xxl={3}>
                        <Flatpickr
                            name="dateRecord"
                            className="form-control border-0 dash-filter-picker shadow mb-2"
                            value={date}
                            options={{
                                plugins: [
                                    monthSelectPlugin({
                                        shorthand: true,
                                        dateFormat: "F Y",
                                        altFormat: "F Y",
                                        theme: "light"
                                    })
                                ],
                                locale: Russian, disableMobile: true
                            }}

                            onChange={ (date) => setDate(date[0])}
                        />
                        <WidgetRequiredSelectPost />
                        <WidgetGraphRoundEmployee/>
                        <WidgetTableScoreEmployee date={date}/>
                    </Col>
                    <Col xxl={9}>
                        <WidgetGraphProjectStatusEmployeePerformance date={date}/>
                        <WidgetTableAllScoreEmployee date={date}/>
                    </Col>
                </Row>
            </Container>
        </div>
    </>)
};

export default MainPaidService;

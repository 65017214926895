import React, {FC, Fragment} from 'react';
import {Col, Row} from "reactstrap";
import NewsDocs from "../news/NewsDocs";
import {Link} from "react-router-dom";
import moment from "moment";

interface IDesctopNews {
    news: any[],
}
const DesctopNews: FC<IDesctopNews> = ({news}) => {

    function badge(id: string){
        switch (id) {
            case '1':
                return <span className="badge bg-soft-success text-primary align-middle">Не срочно</span>
            case '2':
                return <span className="badge bg-soft-warning text-primary align-middle">Требует внимание</span>
            case '3':
                return <span className="badge bg-soft-danger text-primary align-middle">Срочно</span>
        }
    }
    
    function NewNews(date: Date) {
        const dateNews = moment(date).add(1, "days")
        const dateNew = moment();
        if (moment(dateNews).isSameOrAfter(dateNew)){
            return <span className="badge bg-success align-middle">Новая</span>
        }
    }


    return (
        <div className="timeline-2">
            {
                news.map((newses: any, i) => {
                    return <Fragment key={i}>
                        <Row>
                            <Col xs={3}>
                                <div className="timeline-year">
                                    <p>{newses.date}</p>
                                </div>
                            </Col>
                        </Row>
                        {
                            newses.news.map((i: any) => {
                                return <Row key={i.id} className="timeline-right">
                                                <Col xs={12}>
                                                    <p className="timeline-date">
                                                        {i.date}
                                                    </p>
                                                </Col>
                                                <Col xs={12}>
                                                    <div className="timeline-box">
                                                        <div className="timeline-text">
                                                            <Link to={`/parent/news/more/${i.id}`}><h5>{i.theme} <span>{badge(i.rang)} {NewNews(new Date(i.origDate))}</span></h5></Link>
                                                            <p className="text-muted mb-0">{i.description}</p>
                                                            {
                                                                i.documents.map((doc: any) => (<NewsDocs key={doc.url} url={doc}/>))
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                            })
                        }
                    </Fragment>
                })
            }
        </div>
    );
};

export default DesctopNews;

import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getJournalHaccpGeneralCleaning} from "../../store/reducers/widgets/ActionCreator";
import {Helmet} from "react-helmet";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import WidgetRequiredFlatpikerPeriod from "../../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import WidgetRequiredSelectGroup from "../../components/widgets/requireds/WidgetRequiredSelectGroup";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import {Russian} from "flatpickr/dist/l10n/ru";
import {ParentSlice} from "../../store/reducers/parent/ParentSlice";
import WidgetTableGeneralCleaning from "../../components/journalHaccp/GeneralCleaning/WidgetTableGeneralCleaning";
import WidgetGraphGeneralCleaning from "../../components/journalHaccp/GeneralCleaning/WidgetGraphGeneralCleaning";
import AxiosClient from "../../api/AxiosClient";

const JournalGeneralCleaning = () => {
    const dispatch = useAppDispatch()
    const {journalHaccpGeneralCleaning, groupAllDeviations, date} = useAppSelector(state => state.WidgetReducer);
    const [scheduleDate, setScheduleDate] = useState<any[]>([]);

    useEffect(() => {
        if(groupAllDeviations && date){
            dispatch(getJournalHaccpGeneralCleaning(date, groupAllDeviations)).then()
        }
        AxiosClient.get('haccp/getScheduleList')
            .then((r: any) => {
                let schedule: any[] = Array.from(new Set(r.data.flatMap((item: any) => item.data_list)));
                const sortedDates: any[] = schedule
                    .map(dateStr => moment(dateStr, "DD.MM.YYYY")) // Преобразуем строки в объекты moment
                    .filter(dateMoment => dateMoment.isBetween(moment(date[0]), moment(date[1]), undefined, '[]')) // Оставляем даты, не раньше первой даты в `date`
                    .sort((a, b) => a.valueOf() - b.valueOf()) // Сортируем по возрастанию
                    .map(dateMoment => dateMoment.format("DD.MM.YYYY")); // Преобразуем обратно в строки
                setScheduleDate(sortedDates);
            })
            .catch((error) => console.log(error))
    }, [dispatch, date, groupAllDeviations]);

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Журнал генеральных уборок</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col xxl={3}>
                        <WidgetRequiredFlatpikerPeriod/>
                        <WidgetRequiredSelectGroup/>
                        <div className="col">
                            <Card>
                                <CardBody>
                                    <Flatpickr
                                        className="form-control border-0 dash-filter-picker d-none"
                                        value={[moment(date[0]).toDate(), moment(date[1]).toDate()]}
                                        options={{
                                            locale: Russian,
                                            disableMobile: true,
                                            inline: true,
                                            maxDate: new Date(),
                                        }}
                                        onMonthChange={(dates, currentDateString, self, data) => {
                                            dispatch(ParentSlice.actions.setDate(moment().month(self.currentMonth).year(self.currentYear).startOf('month').toDate()));
                                        }}
                                        onDayCreate={(dates, currentDateString, self, data) => {
                                            const dateString = moment(data.dateObj).format('DD.MM.YYYY');
                                            const hasTemperatureData = journalHaccpGeneralCleaning && journalHaccpGeneralCleaning.some((el: any) => el.data === dateString);
                                            const isScheduledDate = scheduleDate.includes(dateString);

                                            data.classList.add('border-2', 'border-white', 'rounded-1', 'shadow-none');

                                            if (hasTemperatureData) {
                                                data.style.backgroundColor = 'rgba(75, 56, 179, 0.5)';
                                                data.classList.add('text-white');
                                            }else if (isScheduledDate) {
                                                data.style.backgroundColor = 'rgba(255, 0, 0, 0.5)';
                                                data.classList.add('text-white');
                                            }

                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </div>

                    </Col>
                    <Col>
                        <WidgetGraphGeneralCleaning scheduleDate={scheduleDate}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="card-height-100">
                            <CardBody >
                                <WidgetTableGeneralCleaning />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default JournalGeneralCleaning;

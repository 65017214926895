import {Field} from "formik";
import {FC, useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {IDeviation} from "../../../../models/widgets/IDeviation";
import {IStudent} from "../../../../models/widgets/IStudent";
import WidgetService from "../../../../api/services/widgetService/WidgetService";
import {getStudents} from "../../../../store/reducers/widgets/ActionCreator";
import {setLoadingStudents} from "../../../../store/reducers/widgets/WidgetsSlice";

interface ITableAdd{
    presentAndNoPresent: () => void
}
const TableAdd:FC<ITableAdd> = ({presentAndNoPresent}) => {

    const {students, groupModalAdd, dateAdd, selectDeviationAll} = useAppSelector(state => state.WidgetReducer)
    const dispatch = useAppDispatch();

    const [deviations, setDeviations] = useState<IDeviation[]>([])
    useEffect(() => {
        WidgetService.getDeviations()
            .then(res => setDeviations(res.data))
    }, [])

    useEffect(() => {
        if (groupModalAdd !== undefined) {
            dispatch(getStudents(dateAdd, groupModalAdd)).then()
        }
    }, [dateAdd, groupModalAdd, dispatch])

    useEffect(() => {
        dispatch(setLoadingStudents(
            students.map(s => ({...s, deviation_code: selectDeviationAll}))
        ))
    }, [selectDeviationAll]);


    const columns: any = [
        {
            name: <span className='font-weight-bold fs-13'>ФИО ребенка</span>,
            selector: (row: IStudent) => row.fio,
        },
        {
            name: <span className='font-weight-bold fs-13'>Отсутствие</span>,
            selector: (row: IStudent, i: number) => (
                <Field as="select" className="form-select table-add-ceil selectStudentsDev" name={`deviations[${i}].${row.code}`} onClick={presentAndNoPresent}>
                    <option value={''}>Явка</option>
                    {
                        (deviations || []).map((item) => (
                            <option key={item.code} value={item.code}>{item.symbol + " (" + item.name + ")"}</option>
                        ))
                    }
                </Field>
            ),
            center: true,
            style: {padding: '0'}
        }
    ];

    return (
        <DataTable
            columns={columns}
            data={students}
            className="custom-scroll-news"
            fixedHeader={true}
            fixedHeaderScrollHeight={'400px'}
            noDataComponent={'В данной группе нет детей'}
            highlightOnHover
            customStyles={{
                rows: {
                    highlightOnHoverStyle: {
                        backgroundColor: "#ffebb5"
                    }
                }
            }}
        />
    );
}

export default TableAdd;
import React, {FC, useEffect, useState} from 'react';
import {Badge, Col, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {IGame} from "../../../models/game/IGame";
import AxiosClient from "../../../api/AxiosClient";
import {Russian} from "flatpickr/dist/l10n/ru";
import Flatpickr from "react-flatpickr";

interface IProps {
    toggle: () => void
    isOpen: boolean,
    dataGame: Omit<IGame, 'id'> | null,
    toggleSave: (game: Omit<IGame, 'id'>) => void
}
const ModalNameGame: FC<IProps> = ({toggle, isOpen, toggleSave, dataGame}) => {
    const [game, setGame] = useState(dataGame);
    const [addClass, setAddClass] = useState(false);
    const [nameClass, setNameClass] = useState('');
    const [listClass, setListClass] = useState<any[]>([]);
    useEffect(() => {
        setGame(dataGame)
    }, [dataGame]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
            <ModalHeader toggle={toggle}>Название игры</ModalHeader>
            <ModalBody>
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: game?.name ?? '',
                        fio: game?.fio ?? '',
                        ageGroup: game?.ageGroup ?? '',
                        objective: game?.objective ?? '',
                        integrationEducationRegion: game?.integrationEducationRegion ?? '',
                        educationTask: game?.educationTask ?? '',
                        evolution: game?.evolution ?? '',
                        education: game?.education ?? '',
                        gameTask: game?.gameTask ?? '',
                        wordsWork: game?.wordsWork ?? '',
                        planResult: game?.planResult ?? '',
                        preparatoryWork: game?.preparatoryWork ?? '',
                        file: null,
                        private: !!game?.private,
                        password: game?.password ?? '',
                        timer: game?.timer ?? false,
                        closeTimer: game?.closeTimer ?? false,
                        time: game?.time ?? '00:00:00'
                    }}
                    onSubmit={(values, {setFieldError}) => {
                        if (values.private) {
                            AxiosClient.post('/game/check_password', {password: values.password})
                                .then(() => {
                                    const element = document.getElementById('window')
                                    if (element) {
                                        const width = element?.offsetWidth
                                        const height = element?.offsetHeight

                                        toggleSave({...game, ...values, width, height, shots: game?.shots ?? [], classes: listClass})
                                    }
                                })
                                .catch(() => {
                                    setFieldError('password', 'Создайте другой пароль доступа к игре')
                                })
                        } else {
                            const element = document.getElementById('window')
                            if (element) {
                                const width = element?.offsetWidth
                                const height = element?.offsetHeight

                                toggleSave({...game, ...values, width, height, shots: game?.shots ?? [], classes: [], password: ''})
                            }
                        }
                    }}
                >
                    {
                        ({setFieldValue, values}) => (
                            <Form>
                                <Row>
                                    <Col sm={12}>
                                        <Row>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="name" className="form-label">Название игры</label>
                                                <Field type="text" id="name" name="name" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="fio" className="form-label">ФИО</label>
                                                <Field type="text" id="fio" name="fio" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="ageGroup" className="form-label">Возрастная группа</label>
                                                <Field as="textarea" id="ageGroup" name="ageGroup" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="objective" className="form-label">Цель игры</label>
                                                <Field as="textarea" id="objective" name="objective" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="integrationEducationRegion" className="form-label">Интеграция образовательных областей</label>
                                                <Field as="textarea" id="integrationEducationRegion" name="integrationEducationRegion" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="educationTask" className="form-label">Образовательная задача</label>
                                                <Field as="textarea" id="educationTask" name="educationTask" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="evolution" className="form-label">Развивающая задача</label>
                                                <Field as="textarea" id="evolution" name="evolution" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="education" className="form-label">Воспитательная задача</label>
                                                <Field as="textarea" id="education" name="education" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="gameTask" className="form-label">Игровая задача</label>
                                                <Field as="textarea" id="gameTask" name="gameTask" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="wordsWork" className="form-label">Словарная работа</label>
                                                <Field as="textarea" id="wordsWork" name="wordsWork" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="planResult" className="form-label">Планируемый результат</label>
                                                <Field as="textarea" id="planResult" name="planResult" className="form-control"/>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <label htmlFor="preparatoryWork" className="form-label">Подготовительная работа</label>
                                                <Field as="textarea" id="preparatoryWork" name="preparatoryWork" className="form-control"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label htmlFor="textureLabel"
                                                   className="form-label">Изображение игры</Label>
                                            <div>
                                                <input id="textureLabel" name="file" type="file" className="form-control"
                                                       onChange={(event) => {
                                                           setFieldValue("file", event.currentTarget.files ? event.currentTarget.files[0] : null);
                                                       }}/>
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col sm={12} lg={6} className="mt-2">
                                                <label htmlFor="private" className="form-label m-0">Приватная игра</label>
                                                <Field type="checkbox" id="private" name="private" className="form-check-input fs-3 m-0 mx-2"/>
                                            </Col>
                                        </Row>
                                        {
                                            values.private &&
                                            <>
                                                <Row>
                                                    <Col sm={12} className="mt-2">
                                                        <label htmlFor="password" className="form-label m-0">Пароль доступа</label>
                                                        <Field type="text" id="password" name="password" className="form-control"/>
                                                        <ErrorMessage name="password">{(msg) => (<p className="m-0 text-danger">{msg}</p>)}</ErrorMessage>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col>
                                                        <div className="d-flex gap-2">
                                                            {
                                                                listClass.map((el) => (
                                                                    <Badge className="fs-5">
                                                                        <div className="d-flex gap-3 justify-content-center align-items-center">
                                                                            <p className="m-0">{el}</p>
                                                                            <i className="bx bx-x fs-3 m-0 cursor-pointer" onClick={() => {
                                                                                setListClass((prevState) => prevState.filter(prev => prev !== el))
                                                                            }}></i>
                                                                        </div>
                                                                    </Badge>
                                                                ))
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {
                                                    addClass &&
                                                    <Row>
                                                        <Col sm={12}>
                                                            <label htmlFor="nameClass" className="form-label">Название класса</label>
                                                            <input type="text" id="nameClass" className="form-control" value={nameClass} onChange={(e) => setNameClass(e.target.value)}/>
                                                        </Col>
                                                    </Row>
                                                }
                                                <Row>
                                                    <Col>
                                                        {
                                                            addClass ?
                                                                <button type="button" className="btn btn-primary mt-2" onClick={() => {
                                                                    setListClass((prevState) => [...prevState, nameClass])
                                                                    setNameClass('')
                                                                    setAddClass(false)
                                                                }}>
                                                                    Сохранить класс
                                                                </button>
                                                                :
                                                                <button type="button" className="btn btn-primary mt-2" onClick={() => setAddClass(true)}>
                                                                    Добавить класс
                                                                </button>
                                                        }
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        <Row>
                                            <Col sm={12} lg={6} className="mt-2">
                                                <label htmlFor="timer" className="form-label m-0">Таймер</label>
                                                <Field type="checkbox" id="timer" name="timer" className="form-check-input fs-3 m-0 mx-2"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} lg={6} className="mt-2">
                                                <label htmlFor="closeTimer" className="form-label m-0">Обратный отсчет</label>
                                                <Field type="checkbox" id="closeTimer" name="closeTimer" className="form-check-input fs-3 m-0 mx-2"/>
                                            </Col>
                                        </Row>
                                        {
                                            values.closeTimer &&
                                            <Row>
                                                <Col sm={12} lg={6} className="mt-2">
                                                    <label htmlFor="time" className="form-label m-0">Время</label>
                                                    <Field
                                                        type="time"
                                                        name="time"
                                                        id="time"
                                                        className="form-control"
                                                        step="1"
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="mt-1 gap-2 d-flex">
                                            <button className="btn btn-primary" type="submit">
                                                Сохранить
                                            </button>
                                            <button className="btn btn-primary" type="button" onClick={toggle}>
                                                Отмена
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalNameGame;

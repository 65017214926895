import React, {FC} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import CardPortfolioEvent from "./CardPortfolioEvent";
import {IMonth} from "../../models/kindergarten/IPortfolio";

interface ICardPortfolioAllEvents {
    months: IMonth[]
}

const CardPortfolioAllEvents: FC<ICardPortfolioAllEvents> = ({months}) => {
    return (
        <>
            {
                months.map(i => {
                    return (
                        <Card key={i.month}>
                            <CardHeader>
                                <h5>{i.month}</h5>
                            </CardHeader>
                            <CardBody>
                                <Row className="team-list grid-view-filter">
                                    {
                                        i.elem.map((portf) => (
                                            <Col lg={6} xl={4} xxl={3} key={portf.id}>
                                                <CardPortfolioEvent portf={portf}/>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    )
                })
            }
        </>
    );
};

export default CardPortfolioAllEvents;

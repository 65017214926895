import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import TableAttendanceInDOY
    from "../../components/department/AttendanceInOrganizationDOYKindergarten/TableAttendanceInDOY";
import TableAttendanceInKindergarten
    from "../../components/department/AttendanceInOrganizationDOYKindergarten/TableAttendanceInKindergarten";
import TableAttendanceInNursery
    from "../../components/department/AttendanceInOrganizationDOYKindergarten/TableAttendanceInNursery";
import TableAttendanceInDOYWithAPPK
    from "../../components/department/AttendanceInOrganizationDOYKindergarten/TableAttendanceInDOYWithAPPK";
import TableAttendanceInKindergartenWithAPPK
    from "../../components/department/AttendanceInOrganizationDOYKindergarten/TableAttendanceInKindergartenWithAPPK";
import TableAttendanceInNurseryWithAPPK
    from "../../components/department/AttendanceInOrganizationDOYKindergarten/TableAttendanceInNurseryWithAPPK";
import GraphTableAttendanceInDOY
    from "../../components/department/AttendanceInOrganizationDOYKindergarten/GraphTableAttendanceInDOY";
import GraphTableAttendanceInKindergarten
    from "../../components/department/AttendanceInOrganizationDOYKindergarten/GraphTableAttendanceInKindergarten";
import GraphTableAttendanceInNursery
    from "../../components/department/AttendanceInOrganizationDOYKindergarten/GraphTableAttendanceInNursery";
import Widget from "../../components/department/AttendanceInOrganizationDOYKindergarten/Widget";

const AttendanceInOrganizationDoyKindergarten = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Посещаемость</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Widget/>
                            </Row>
                            <Row>
                                <Col xxl={4} xl={6}><GraphTableAttendanceInDOY/></Col>
                                <Col xxl={4} xl={6}><GraphTableAttendanceInKindergarten/></Col>
                                <Col xxl={4} xl={12}><GraphTableAttendanceInNursery/></Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <TableAttendanceInDOY/>
                            <TableAttendanceInKindergarten/>
                            <TableAttendanceInNursery/>
                            <TableAttendanceInDOYWithAPPK/>
                            <TableAttendanceInKindergartenWithAPPK/>
                            <TableAttendanceInNurseryWithAPPK/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default AttendanceInOrganizationDoyKindergarten;

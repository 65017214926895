import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import * as Yup from "yup";
import {Col, Label, Row} from "reactstrap";
import {updateEmployee} from "../../../store/reducers/department/ActionCreator";

const UpdateDataEmployeeForm = () => {
    const dispatch = useAppDispatch();
    const {selectEmployee, positions, organizations} = useAppSelector(state => state.DepartmentReducer)
    const birthday = selectEmployee?.birthday.split('.')[2] + '-' + selectEmployee?.birthday.split('.')[1] + '-' + selectEmployee?.birthday.split('.')[0]

    return (
        <Formik
            enableReinitialize
            initialValues={{
                name: `${selectEmployee?.name}`,
                birthday: birthday,
                position: selectEmployee?.position_id,
                organization_id: selectEmployee?.organization_id
            }}
            validationSchema={Yup.object({
                name: Yup.string().required('Введите ФИО'),
                birthday: Yup.string().required('Выберите ату рождения'),
                position: Yup.string().required('Выберите должность'),
                organization_id: Yup.string().required('Обязательное поле'),
            })}
            onSubmit={(value) => {
                dispatch(updateEmployee(value, selectEmployee!.id))
            }}
        >
            <Form>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="nameInput" className="form-label">ФИО</Label>
                            <Field type="text" className="form-control" id="nameInput"
                                   name="name"
                                   placeholder="Введите ФИО"/>
                            <ErrorMessage name="name">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="birthdayInput" className="form-label">Дата
                                рождения</Label>
                            <Field type="date" className="form-control" id="birthdayInput"
                                   name="birthday"
                                   placeholder="Введите дату рождения"/>
                            <ErrorMessage name="birthday">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="positionInput"
                                   className="form-label">Должность</Label>
                            <Field as="select" className="form-select" id="positionInput"
                                   name="position"
                                   placeholder="Введите тип кабинета">
                                {
                                    positions.map(item => (
                                        <option key={item.id}
                                                value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Field>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="organization_idInput"
                                   className="form-label">Организация</Label>
                            <Field as="select" className="form-select"
                                   id="organization_idInput"
                                   name="organization_id">
                                {
                                    organizations.map(item => (
                                        <option key={item.id}
                                                value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Field>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="mt-3">
                        <div className="hstack gap-2 justify-content-end">
                            <button type="submit"
                                    className="btn btn-primary">Обновить
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Formik>
    );
};

export default UpdateDataEmployeeForm;

import React, {FC, Fragment, useEffect, useState} from 'react';
import {Badge, Button, Col, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classNames from "classnames";
import {ITexture} from "../../../models/game/ITexture";
import {IShot} from "../../../models/game/IShot";
import {TypeSettings} from "../../../models/game/typesSettings/TypeSettings";
import {TypeSettingsSelectAnswer} from "../../../models/game/typesSettings/TypeSettingsSelectAnswer";
import {
    ISettingsAppearance,
    ISettingsCollect,
    ISettingsSelectAnswer,
    ISettingsTexture
} from "../../../models/game/ISettingsTexture";
import {TypeSettingsShot} from "../../../models/game/typesSettings/TypeSettingsShot";
import {TypeSettingsAppearance} from "../../../models/game/typesSettings/TypeSettingsAppearance";

interface IProps {
    openSettingsTexture: boolean,
    handleOpenSettingsTexture: () => void,
    selectTexture: ITexture
    saveSettingsTexture: (texture: ITexture) => void,
    shot: IShot
}

const SettingsTexture: FC<IProps> = ({
                                         openSettingsTexture,
                                         handleOpenSettingsTexture,
                                         selectTexture,
                                         saveSettingsTexture,
                                         shot
                                     }) => {
    const [activeTab, setActiveTab] = useState('1');
    const [activeTabSettings, setActiveTabSettings] = useState('1');
    const [selectedOption, setSelectedOption] = useState(0);
    const [texture, setTexture] = useState(selectTexture);
    const [oldSettings, setOldSettings] = useState<ISettingsTexture | null>(null);

    useEffect(() => {
        setTexture(selectTexture)
        setOldSettings(selectTexture.pivot.settings)
        if (selectTexture.pivot.settings.type === TypeSettings.NO_SETTINGS) {
            setActiveTabSettings("0")
        }
        if (selectTexture.pivot.settings.type === TypeSettings.SELECT_ANSWER) {
            setSelectedOption((selectTexture.pivot.settings.settings as ISettingsSelectAnswer).select)
            setActiveTabSettings("1")
        } else {
            setSelectedOption(0)
        }
        if (selectTexture.pivot.settings.type === TypeSettings.COLLECT) {
            setActiveTabSettings("2")
        }
        if (selectTexture.pivot.settings.type === TypeSettings.APPEARANCE) {
            setActiveTabSettings("3")
        }
        setActiveTab('1')
    }, [selectTexture]);

    const handleChange = (event: any) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        select: +event.target.value
                    }
                }
            }
        }))
        setSelectedOption(+event.target.value);
    };

    const handleActiveTabSettings = (id: string) => {
        const defaultSettingsCollect = {
            type: TypeSettings.COLLECT,
            settings: {
                id: texture.id,
                name: texture.pivot.name,
                path: texture.path,
                show: 'up',
                textTrue: '',
                textFalse: '',
                pathTextTrue: '',
                pathTextFalse: '',
                textures: []
            },
            width: texture.pivot.settings.width,
            height: texture.pivot.settings.height,
        }

        const defaultSettingsSelectAnswer = {
            type: TypeSettings.SELECT_ANSWER,
            settings: {
                select: TypeSettingsSelectAnswer.TRUE,
                text: '',
                path: ''
            },
            width: texture.pivot.settings.width,
            height: texture.pivot.settings.height,
        }

        const defaultSettingsAppearance = {
            type: TypeSettings.APPEARANCE,
            settings: {
                text: '',
                path: '',
                voice: TypeSettingsAppearance.WOMAN,
                showAfterVoice: false,
                timeShow: 0,
                hide: false,
                timeHide: 0
            },
            width: texture.pivot.settings.width,
            height: texture.pivot.settings.height,
        }

        const defaultSettingsNoSettings = {
            type: TypeSettings.NO_SETTINGS,
            settings: {
                select: TypeSettingsSelectAnswer.TRUE,
                text: '',
                path: ''
            },
            width: texture.pivot.settings.width,
            height: texture.pivot.settings.height,
        }

        if (id === '0') {
            if (oldSettings?.type === TypeSettings.NO_SETTINGS) {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: oldSettings
                    }
                }))
            } else {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: {...defaultSettingsNoSettings, typeAnimation: texture.pivot.settings.typeAnimation}
                    }
                }))
            }

        }
        if (id === '1') {
            if (oldSettings?.type === TypeSettings.SELECT_ANSWER) {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: oldSettings
                    }
                }))
            } else {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: {...defaultSettingsSelectAnswer, typeAnimation: texture.pivot.settings.typeAnimation}
                    }
                }))
            }

        }
        if (id === '2') {
            if (oldSettings?.type === TypeSettings.COLLECT) {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: oldSettings
                    }
                }))
            } else {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: {...defaultSettingsCollect, typeAnimation: texture.pivot.settings.typeAnimation}
                    }
                }))
            }

        }
        if (id === '3') {
            if (oldSettings?.type === TypeSettings.COLLECT) {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: oldSettings
                    }
                }))
            } else {
                setTexture((texture) => ({
                    ...texture,
                    pivot: {
                        ...texture.pivot,
                        settings: {...defaultSettingsAppearance, typeAnimation: texture.pivot.settings.typeAnimation}
                    }
                }))
            }
        }
        setActiveTabSettings(id)
    }

    const save = () => {
        saveSettingsTexture(texture)
        handleOpenSettingsTexture()
    }

    const BadgeShow = (textureForShow: ITexture) => {
        if (texture.pivot.settings.type === TypeSettings.COLLECT) {
            if ((texture.pivot.settings.settings as ISettingsCollect).textures.find((el) => el.id === textureForShow.id)) {
                return <Badge color="success" style={{width: 30, height: 30}}
                              className="rounded-circle d-flex justify-content-center align-items-center position-absolute top-0 start-100 translate-middle"><i
                    className="bx bx-check fs-3"></i></Badge>
            }
        }

        return <></>
    }

    const handleSelectCollectTexture = (texture1: ITexture) => {
        if ((texture.pivot.settings.settings as ISettingsCollect).textures.find(el => el.id === texture1.id)) {
            setTexture((texture) => ({
                ...texture,
                pivot: {
                    ...texture.pivot,
                    settings: {
                        ...texture.pivot.settings,
                        settings: {
                            ...texture.pivot.settings?.settings,
                            textures: (texture.pivot.settings.settings as ISettingsCollect).textures.filter(el => el.id !== texture1.id)
                        }
                    }
                }
            }))
        } else {
            setTexture((texture) => ({
                ...texture,
                pivot: {
                    ...texture.pivot,
                    settings: {
                        ...texture.pivot.settings,
                        settings: {
                            ...texture.pivot.settings.settings,
                            textures: [...(texture.pivot.settings.settings as ISettingsCollect).textures, texture1]
                        }
                    }
                }
            }))
        }
    }

    const onChangeTextTrueCollect = (value: string) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        textTrue: value
                    }
                }
            }
        }))
    }

    const onChangeTextFalseCollect = (value: string) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        textFalse: value
                    }
                }
            }
        }))
    }

    const onChangeTypeAnimate = (value: 'shadow' | 'pulse' | 'none') => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    typeAnimation: value
                }
            }
        }))
    }

    const onChangeShow = (value: 'up' | 'down') => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        show: value
                    }
                }
            }
        }))
    }


    const onChangeTextShow = (value: string) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        text: value
                    }
                }
            }
        }))
    }

    const onChangeAppearance = (voice: TypeSettingsAppearance) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        voice: voice
                    }
                }
            }
        }))
    }

    const onChangeTimeShow = (time: string) => {
        if (!isNaN(+time))
            setTexture((texture) => ({
                ...texture,
                pivot: {
                    ...texture.pivot,
                    settings: {
                        ...texture.pivot.settings,
                        settings: {
                            ...texture.pivot.settings.settings,
                            timeShow: +time
                        }
                    }
                }
            }))
    }

    const onChangeTimeHide = (time: string) => {
        if (!isNaN(+time))
            setTexture((texture) => ({
                ...texture,
                pivot: {
                    ...texture.pivot,
                    settings: {
                        ...texture.pivot.settings,
                        settings: {
                            ...texture.pivot.settings.settings,
                            timeHide: +time
                        }
                    }
                }
            }))
    }

    const onChangeHide = (hide: boolean) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        hide: hide
                    }
                }
            }
        }))
    }

    const onChangeShowAfterVoice = (showAfterVoice: boolean) => {
        setTexture((texture) => ({
            ...texture,
            pivot: {
                ...texture.pivot,
                settings: {
                    ...texture.pivot.settings,
                    settings: {
                        ...texture.pivot.settings.settings,
                        showAfterVoice: showAfterVoice
                    }
                }
            }
        }))
    }

    return (
        openSettingsTexture ?
            <div className="position-absolute top-0 start-0 h-100 w-100 bg-white col-xxl-3">
                <div>
                    <i className="bx bx-x fs-1 cursor-pointer"
                       onClick={handleOpenSettingsTexture}>
                    </i>
                </div>
                <div className="mt-3">
                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                         role="tablist">
                        <NavItem>
                            <NavLink
                                className={classNames('p-2', {active: activeTab === "1"})}
                                onClick={() => {
                                    setActiveTab("1");
                                }}
                                type="button">
                                <i className="fas fa-home"></i>
                                Основная информация
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classNames('p-2', {active: activeTab === "2"})}
                                onClick={() => {
                                    shot.settings?.type !== TypeSettingsShot.COUNT_TEXTURE &&
                                    setActiveTab("2");
                                }}
                                type="button">
                                <i className="fas fa-home"></i>
                                Настройки
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classNames('p-2', {active: activeTab === "3"})}
                                onClick={() => {
                                    setActiveTab("3");
                                }}
                                type="button">
                                <i className="fas fa-home"></i>
                                Анимация
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <div>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div className="p-2">
                                <div className="mt-2">
                                    <Row>
                                        <Col sm={12}>
                                            <Row>
                                                <Col sm={12}>
                                                    <label htmlFor="name" className="form-label">Название
                                                        текстуры</label>
                                                    <input type="text" id="name" disabled value={texture.pivot.name}
                                                           onChange={(event) => setTexture((texture) => ({
                                                               ...texture,
                                                               pivot: {...texture.pivot, name: event.target.value}
                                                           }))} className="form-control"/>
                                                </Col>
                                                <Col sm={12}>
                                                    <label htmlFor="description" className="form-label">Описание</label>
                                                    <input type="text" id="description"
                                                           value={texture.pivot.description ?? ''}
                                                           onChange={(event) => setTexture((texture) => ({
                                                               ...texture,
                                                               pivot: {
                                                                   ...texture.pivot,
                                                                   description: event.target.value
                                                               }
                                                           }))} className="form-control"/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="p-2">
                                <div className="mt-2">
                                    <div className="d-flex gap-2">
                                        <Button
                                            color="primary"
                                            outline={activeTabSettings !== "0"}
                                            onClick={() => handleActiveTabSettings("0")}
                                        >
                                            Нет настроек
                                        </Button>
                                        <Button
                                            color="primary"
                                            outline={activeTabSettings !== "1"}
                                            onClick={() => handleActiveTabSettings("1")}
                                        >
                                            Выбор ответа
                                        </Button>
                                        <Button
                                            color="primary"
                                            outline={activeTabSettings !== "2"}
                                            onClick={() => handleActiveTabSettings("2")}
                                        >
                                            Собрать в кучку
                                        </Button>
                                        <Button
                                            color="primary"
                                            outline={activeTabSettings !== "3"}
                                            onClick={() => handleActiveTabSettings("3")}
                                        >
                                            Появление
                                        </Button>
                                    </div>
                                    <TabContent activeTab={activeTabSettings} className="mt-2">
                                        <TabPane tabId="1">
                                            <div className="d-flex gap-5 fs-16">
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="radioOptions"
                                                        value={0}
                                                        checked={selectedOption === 0}
                                                        onChange={handleChange}
                                                    />{' '}
                                                    Правильный ответ
                                                </Label>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="radioOptions"
                                                        value={1}
                                                        checked={selectedOption === 1}
                                                        onChange={handleChange}
                                                    />{' '}
                                                    Не правильный ответ
                                                </Label>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="radioOptions"
                                                        value={2}
                                                        checked={selectedOption === 2}
                                                        onChange={handleChange}
                                                    />{' '}
                                                    Не активный
                                                </Label>
                                            </div>
                                            <div className="mt-2">
                                                <label htmlFor="text" className="form-label">Текст</label>
                                                <textarea rows={5} id="text"
                                                          value={(texture.pivot.settings.settings as ISettingsSelectAnswer).text}
                                                          onChange={(event) => {
                                                              setTexture((texture) => (
                                                                  {
                                                                      ...texture,
                                                                      pivot: {
                                                                          ...texture.pivot,
                                                                          settings: {
                                                                              ...texture.pivot.settings,
                                                                              settings: {
                                                                                  ...texture.pivot.settings.settings,
                                                                                  text: event.target.value
                                                                              }
                                                                          }
                                                                      }
                                                                  }
                                                              ))
                                                          }}
                                                          className="form-control"/>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <h4>Выберите объекты, которые нужно соотнести с настраеваемым объектом</h4>
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <label htmlFor="trueOverlap" className="form-label">Текст
                                                                    при верном перемещении</label>
                                                                <input type="text" id="trueOverlap"
                                                                       value={(texture.pivot.settings.settings as ISettingsCollect).textTrue}
                                                                       onChange={(event) => onChangeTextTrueCollect(event.target.value)}
                                                                       className="form-control"/>
                                                            </Col>
                                                            <Col sm={12}>
                                                                <label htmlFor="falseOverlap" className="form-label">Текст
                                                                    при не верном перемещении</label>
                                                                <input type="text" id="falseOverlap"
                                                                       value={(texture.pivot.settings.settings as ISettingsCollect).textFalse}
                                                                       onChange={(event) => onChangeTextFalseCollect(event.target.value)}
                                                                       className="form-control"/>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                        <div className="p-2 mt-2">
                                                            <div className="d-flex gap-5 fs-16">
                                                                <Label check>
                                                                    <Input
                                                                        type="radio"
                                                                        name="radioOptionsShowTexture"
                                                                        value={'up'}
                                                                        checked={(texture.pivot.settings.settings as ISettingsCollect).show === 'up'}
                                                                        onChange={(e) => onChangeShow(e.target.value as any)}
                                                                    />{' '}
                                                                    Текстуры над
                                                                </Label>
                                                                <Label check>
                                                                    <Input
                                                                        type="radio"
                                                                        name="radioOptionsShowTexture"
                                                                        value={'down'}
                                                                        checked={(texture.pivot.settings.settings as ISettingsCollect).show === 'down'}
                                                                        onChange={(e) => onChangeShow(e.target.value as any)}
                                                                    />{' '}
                                                                    Текстуры под
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row className="mt-4">
                                                {
                                                    shot.textures.map((texture1) => {
                                                        if (texture1.id === texture.id) {
                                                            return <Fragment key={texture1.id}></Fragment>
                                                        }
                                                        return <Col xxl={1} lg={2} sm={3} xs={4} key={texture1.id}
                                                                    className="position-relative d-flex justify-content-center">
                                                            <div className="position-relative"
                                                                 onClick={() => handleSelectCollectTexture(texture1)}>
                                                                {
                                                                    BadgeShow(texture1)
                                                                }
                                                                <img
                                                                    src={process.env.REACT_APP_API_BASE_URL + texture1.path}
                                                                    style={{height: 100, width: 100}}
                                                                    alt="user"
                                                                />
                                                            </div>
                                                        </Col>
                                                    })
                                                }
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <div>
                                                <Row>
                                                    <Col sm={12}>
                                                        <label htmlFor="textShow" className="form-label">Текст после появления текстуры</label>
                                                        <input type="text" value={(texture.pivot.settings.settings as ISettingsAppearance).text} onChange={(e) => onChangeTextShow(e.target.value)} id="textShow" className="form-control"/>
                                                    </Col>
                                                    <Col sm={12} className="mt-2">
                                                        <label className="form-label">Голос озвучки</label>
                                                        <div>
                                                            <Label check>
                                                                <Input
                                                                    type="radio"
                                                                    name="radioOptionsVoice"
                                                                    value={TypeSettingsAppearance.MAN}
                                                                    checked={(texture.pivot.settings.settings as ISettingsAppearance).voice === TypeSettingsAppearance.MAN}
                                                                    onChange={(e) => onChangeAppearance(+e.target.value as TypeSettingsAppearance)}
                                                                />{' '}
                                                                Мужской
                                                            </Label>
                                                            <Label check className="mx-2">
                                                                <Input
                                                                    type="radio"
                                                                    name="radioOptionsVoice"
                                                                    value={TypeSettingsAppearance.WOMAN}
                                                                    checked={(texture.pivot.settings.settings as ISettingsAppearance).voice === TypeSettingsAppearance.WOMAN}
                                                                    onChange={(e) => onChangeAppearance(+e.target.value as TypeSettingsAppearance)}
                                                                />{' '}
                                                                Женский
                                                            </Label>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} className="mt-2">
                                                        <label htmlFor="showAfterVoice" className="form-label">Появление после озвучки основного текста</label>
                                                        <div>
                                                            <input type="checkbox" checked={(texture.pivot.settings.settings as ISettingsAppearance).showAfterVoice} id="showAfterVoice" className="form-check-input fs-21"
                                                                   onChange={(e) => onChangeShowAfterVoice(e.target.checked)}
                                                            />
                                                        </div>
                                                    </Col>
                                                    {
                                                        !(texture.pivot.settings.settings as ISettingsAppearance).showAfterVoice &&
                                                        <Col sm={12} className="mt-2">
                                                            <label htmlFor="showTime" className="form-label">Время до появления (0 - появится сразу после открытия кадра)</label>
                                                            <input type="text" id="showTime" onChange={(e) => onChangeTimeShow(e.target.value)} value={(texture.pivot.settings.settings as ISettingsAppearance).timeShow} placeholder="0 - появится сразу после озвучки текста кадра" className="form-control"/>
                                                        </Col>
                                                    }
                                                    <Col sm={12} className="mt-2">
                                                        <label htmlFor="hide" className="form-label">Исчезновение</label>
                                                        <div>
                                                            <input type="checkbox" checked={(texture.pivot.settings.settings as ISettingsAppearance).hide} id="hide" className="form-check-input fs-21"
                                                                   onChange={(e) => onChangeHide(e.target.checked)}
                                                            />
                                                        </div>
                                                    </Col>
                                                    {
                                                        (texture.pivot.settings.settings as ISettingsAppearance).hide &&
                                                        <Col sm={12} className="mt-2">
                                                            <label htmlFor="hideTime" className="form-label">Время исчезновения (0 - исчезнет сразу после озвучки своего текста)</label>
                                                            <input type="text" onChange={(e) => onChangeTimeHide(e.target.value)} value={(texture.pivot.settings.settings as ISettingsAppearance).timeHide} placeholder="0 - исчезнет сразу после озвучки своего текста" id="hideTime" className="form-control"/>
                                                        </Col>
                                                    }
                                                </Row>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="3">
                            <div className="p-2 mt-2">
                                <div className="d-flex gap-5 fs-16">
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="radioOptionsAnimate"
                                            value={'shadow'}
                                            checked={texture.pivot.settings.typeAnimation === 'shadow'}
                                            onChange={(e) => onChangeTypeAnimate(e.target.value as any)}
                                        />{' '}
                                        Тень
                                    </Label>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="radioOptionsAnimate"
                                            value={'pulse'}
                                            checked={texture.pivot.settings.typeAnimation === 'pulse'}
                                            onChange={(e) => onChangeTypeAnimate(e.target.value as any)}
                                        />{' '}
                                        Пульсация
                                    </Label>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="radioOptionsAnimate"
                                            value={'none'}
                                            checked={texture.pivot.settings.typeAnimation === 'none'}
                                            onChange={(e) => onChangeTypeAnimate(e.target.value as any)}
                                        />{' '}
                                        Без анимации
                                    </Label>
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>

                    <div className="mx-2">
                        <button className="btn btn-primary" onClick={save}>Сохранить</button>
                    </div>
                </div>
            </div> : <></>
    )
};

export default SettingsTexture;

import React, {FC} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import TableSSNPThreeSeven from "./TableSSNPThreeSeven";

interface IModalKKalAllThreeSeven {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void
}
const ModalKKalAllThreeSeven:FC<IModalKKalAllThreeSeven> = ({setIsOpen, isOpen}) => {
    return (
        <Modal id="createProjectModal" isOpen={isOpen}
               toggle={() => setIsOpen(!isOpen)}
               modalClassName="zoomIn" tabIndex={-1} centered size={"xl"}>
            <ModalHeader toggle={() => setIsOpen(!isOpen)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Подробнее
            </ModalHeader>
            <ModalBody>
                <TableSSNPThreeSeven/>
            </ModalBody>
        </Modal>
    );
};

export default ModalKKalAllThreeSeven;

import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import {useAppSelector} from "../../../../hooks/redux";


const TableCountChildrenSpecialGroupDeviationPart2 = () => {

    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState([
        {
            org: "Организация 1",
            special_group_children: '23',
            isHouse: "2"
        },
        {
            org: "Организация 2",
            special_group_children: '6',
            isHouse: "5"
        },
        {
            org: "Организация 3",
            special_group_children: '1',
            isHouse: "7"
        },
        {
            org: "Организация 4",
            special_group_children: '23',
            isHouse: "1"
        },
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);


    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Кол-во детей в спец-х группах</span>,
            selector: (row: any) => row.special_group_children,
        },
        {
            name: <span className='font-weight-bold fs-13'>Дошкольное образование на дому</span>,
            selector: (row: any) => row.isHouse,
        }
    ]

    return (
        <Card>
            <CardHeader>
                Число детей в специализированных группах
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableCountChildrenSpecialGroupDeviationPart2;

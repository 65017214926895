import {AxiosResponse} from "axios";
import $api from "../../AxiosClient";
import {ICategoryOvz} from "../../../models/ICategoryOvz";

class CategoryOvzService {
    static async getCategoriesOvz(): Promise<AxiosResponse<ICategoryOvz[]>>{
        return $api.post<ICategoryOvz[]>("/category-ovzs");
    }

    static async addCategoryOvz(value: object): Promise<AxiosResponse<void>>{
        return $api.post<void>("/category-ovz/add", value);
    }

    static async updateCategoryOvz(value: object, categoryOvzId: number): Promise<AxiosResponse<void>>{
        return $api.post<void>(`/category-ovz/update/${categoryOvzId}`, value)
    }
}
export default CategoryOvzService;
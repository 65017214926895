import React, {FC, useEffect, useState} from 'react';
import AxiosClient from "../../../api/AxiosClient";
import downloadPDF from "../../../functions/downloadPDF";
import {Alert, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import MonthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import moment from "moment";
import {useAppSelector} from "../../../hooks/redux";

interface IModalGetReceipt {
    modalSign: boolean,
    setModalSign: (value: boolean) => void,
    type: 'receipt' | 'compare',
    dateSlice: Date
}

const ModalGetReceipt: FC<IModalGetReceipt> = ({modalSign, setModalSign, type, dateSlice}) => {
    const [date, setDate] = useState(new Date());
    const [dateAct, setDateAct] = useState([moment().subtract(2, "month").startOf('month').toDate(), moment().endOf('month').toDate()]);
    const [error, setError] = useState([false, '']);
    const [loading, setLoading] = useState(false);
    const [typeAct, setTypeAct] = useState('НаОбучение');
    const [checkedAllInfo, setCheckedAllInfo] = useState(false);
    const {child} = useAppSelector(state => state.ParentReducer)

    async function getTabel() {
        setLoading(true)
        const params = {
            date: type === "receipt" ? date : dateAct,
            type,
            typeAct,
            children_uid: child,
            checkedAllInfo
        }
        AxiosClient.get('/get_file_receipt', {params})
            .then(d => {
                setLoading(false)
                downloadPDF(d.data.base64, d.data.filename)
            })
            .catch((reason) => {
                setError([true, reason.response.data])
                setLoading(false)
            })
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setError([false, ''])
        }, 3000)

        return () => clearTimeout(timer)
    }, [error]);

    useEffect(() => {
        setDate(dateSlice)
    }, [dateSlice]);


    return (
        <Modal id="createProjectModal" isOpen={modalSign}
               toggle={() => {
                   setModalSign(!modalSign)
               }}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => {
                setModalSign(!modalSign)
            }} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Получить {type === "receipt" ? 'квитанцию' : 'акт сверки'}
            </ModalHeader>
            <ModalBody>
                <div>
                    {error[0] && <Alert color="danger">{error[1]}</Alert>}
                    {
                        loading ?
                            <div className="d-sm-flex text-center align-items-center justify-content-center">
                                <Spinner
                                    color="success">
                                    Loading...
                                </Spinner>
                                <div className="mx-2 fs-5">Подождите. Скоро начнется
                                    загрузка {type === "receipt" ? 'квитанции' : 'акта сверки'}.
                                </div>
                            </div>
                            :
                            <div className="col-auto mb-2">

                                {
                                    type === "compare" ?
                                        <>
                                            <div>
                                                <label className="w-100">Дата:
                                                <div className="col mb-2">
                                                    <div className="input-group">
                                                        <Flatpickr
                                                            className="form-control border-0 dash-filter-picker shadow"
                                                            value={dateAct}
                                                            options={{
                                                                locale: Russian, mode: "range", dateFormat: "d.m.Y", disableMobile: true
                                                            }}
                                                            onChange={(date) => setDateAct(date)}
                                                        />
                                                    </div>
                                                </div>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="w-100">Тип акта:
                                                <select name="type" id="type" value={typeAct}
                                                        onChange={e => setTypeAct(e.target.value)} className="form-select">
                                                    <option value="НаОбучение">На обучение</option>
                                                    <option value="НаДопОбразование">Доп. образование</option>
                                                    <option value="ПлатныеУслуги">Платные услуги</option>
                                                </select>
                                                </label>
                                            </div>
                                        </>
                                        :
                                        <div>
                                            <div>
                                                <label className="w-100">Дата:
                                                <div className="col mb-2">
                                                    <div className="input-group">
                                                        <Flatpickr
                                                            className="form-control border-0 dash-filter-picker shadow"
                                                            value={date}
                                                            options={{
                                                                locale: Russian,
                                                                dateFormat: "m Y",
                                                                plugins: [MonthSelectPlugin({
                                                                    shorthand: true, //defaults to false
                                                                    dateFormat: "F y", //defaults to "F Y"
                                                                    altFormat: "F Y", //defaults to "F Y"
                                                                })],
                                                                disableMobile: true
                                                            }}
                                                            onChange={(date) => setDate(date[0])}
                                                        />
                                                    </div>
                                                </div>
                                                </label>
                                            </div>
                                            {/*<div className="mt-2">*/}
                                            {/*    <label htmlFor="">Получить общую квитанцию по всем услугам </label>*/}
                                            {/*    <input type="checkbox" checked={checkedAllInfo} onChange={(event) => setCheckedAllInfo(event.target.checked)} className="form-check-input fs-18 mx-2"/>*/}
                                            {/*</div>*/}
                                        </div>
                                }
                            </div>
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="hstack gap-2 justify-content-end mt-2">
                    {
                        loading
                            ?
                            <button type="button" className="btn btn-primary" id="addNewProject">
                                <div className="d-flex align-items-center">
                                    <Spinner
                                        size="sm"
                                        className="mx-2"
                                    >
                                        Loading...
                                    </Spinner>
                                    Подождите
                                </div>
                            </button>
                            :
                            <button type="submit" className="btn btn-primary" id="addNewProject"
                                    onClick={getTabel}>Получить {type === "receipt" ? 'квитанцию' : 'акт сверки'}</button>
                    }
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ModalGetReceipt;

import React, {FC} from 'react';
import {Card, CardBody, CardHeader, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {convertDateFull, convertDateGetMonthName} from "../../functions/ConvertDate";
import moment from "moment";

interface IItemsEvent {
    events: any[]
}
const ItemsEvent: FC<IItemsEvent> = ({events}) => {

    return (
        <div>
            <Card className="card-height-100">
                <CardHeader>
                    <h4 className="card-title mb-0 flex-grow-1">Темы занятий</h4>
                </CardHeader>
                <CardBody>
                    <div className="list-group list-group-flush border-dashed custom-scroll-news" style={{overflow: 'auto', height: "22.5rem"}}>
                        {
                            events.map((row, i) => (
                                <div className="list-group-item" key={i}>
                                    <Row className="align-items-center g-3">
                                        <div className="col-auto">
                                            <div className="avatar-sm m-1 h-auto bg-light rounded-3">
                                                <div className="text-center">
                                                    <h5 className="mb-0">{moment(convertDateFull(row.data)).toDate().getDate().toString()}</h5>
                                                    <div className="text-muted">{convertDateGetMonthName(moment(convertDateFull(row.data)).toDate())}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <h5 className="text-muted mt-0 mb-1 fs-13">{row.vremya_begin.split(' ')[1].slice(0, -3)}</h5>
                                            <Link to="#" className="text-reset fs-14 mb-0">{row.event}</Link>
                                        </div>
                                    </Row>
                                </div>
                            ))
                        }
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default ItemsEvent;

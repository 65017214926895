import React, {FC, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {setToUpdateGraphMainPage} from "../../../store/reducers/widgets/WidgetsSlice";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {ProjectsJournalHaccpGeneralCleaning} from "../../widgets/ProjectStatusCharts";

interface IWidgetGraphGeneralCleaning{
    scheduleDate: any[]
}
const WidgetGraphGeneralCleaning: FC <IWidgetGraphGeneralCleaning> = ({scheduleDate}) => {
    const {toUpdateGraphMainPage, date, journalHaccpGeneralCleaning} = useAppSelector(state => state.WidgetReducer)
    const dispatch = useAppDispatch();


    useEffect(() => {
        toUpdateGraphMainPage &&
        WidgetService.getDeviationGroupMonth(null, date)
            .then(res => {
                dispatch(setToUpdateGraphMainPage(false))
            })
    }, [toUpdateGraphMainPage, date, dispatch])

    return (
        <>
            <Card>
                <CardHeader className="border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Журнал генеральных уборок</h4>
                </CardHeader>

                <CardHeader className="p-0 border-0 bg-soft-light">
                    <Row className="g-0 text-center">
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {scheduleDate ? scheduleDate.length : 'Загрузка'}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Количество уборок (план)</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {journalHaccpGeneralCleaning ? journalHaccpGeneralCleaning.length : 'Загрузка'}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Количество уборок (факт)</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {scheduleDate && journalHaccpGeneralCleaning ?
                                            scheduleDate.length - journalHaccpGeneralCleaning.length : 'Загрузка'}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Отклонения</p>
                            </div>
                        </Col>
                    </Row>
                </CardHeader>

                <CardBody className="p-0 pb-2">
                    <div>
                        {
                            journalHaccpGeneralCleaning && <ProjectsJournalHaccpGeneralCleaning series={journalHaccpGeneralCleaning} dataPlan={scheduleDate}
                                                                                                dataColors='["--vz-green", "--vz-warning", "--vz-danger"]'/>
                        }
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default WidgetGraphGeneralCleaning;

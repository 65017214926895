import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import TableTeachersOther from "../../components/department/Teachers/TableTeachersOther";
import TableWorkExperience from "../../components/department/Teachers/TableWorkExperience";
import TableTeacherEducation from "../../components/department/Teachers/TableTeacherEducation";
import PhoneOrPc from "../../components/department/PhoneOrPC";
import TableTeacherEducationPart1 from "../../components/department/Teachers/TablePhone/TableTeacherEducationPart1";
import TableTeacherEducationPart2 from "../../components/department/Teachers/TablePhone/TableTeacherEducationPart2";
import TableAllTeachers from "../../components/department/Teachers/TableAllTeachers";
import TableAllWorkExperience from "../../components/department/Teachers/TableAllWorkExperience";
import GraphTableWorkExperience from "../../components/department/Teachers/GraphTableWorkExperience";
import GraphTableTeachersOther from "../../components/department/Teachers/GraphTableTeachersOther";
import Widget from "../../components/department/Teachers/Widget";

const Teachers = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Педагоги</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Widget/>
                            </Row>
                            <Row>
                                <Col>
                                    <GraphTableWorkExperience/>
                                </Col>
                                <Col>
                                    <GraphTableTeachersOther/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <TableTeachersOther/>
                            <TableWorkExperience/>
                            <PhoneOrPc phone={[<TableTeacherEducationPart1/>, <TableTeacherEducationPart2/>]} pc={[<TableTeacherEducation/>]}/>
                            <TableAllTeachers/>
                            <TableAllWorkExperience/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Teachers;

import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import ItemEvent from "./ItemEvent";
import AxiosClient from "../../api/AxiosClient";
import {useAppSelector} from "../../hooks/redux";

const ItemNews = () => {

    const [news, setNews] = useState<any[]>([]);

    const {children, child} = useAppSelector(state => state.ParentReducer)


    useEffect(() => {
        let group = '';
        children.forEach(i => {
            if (i.account_uid === child){
                group = i.group_code
            }
        })
        group &&
        AxiosClient.get('/parent/news/calendar', {params: {group}})
            .then(r => setNews(r.data))

    }, [child, children]);

    return (
        <Card className="card-height-100">
            <CardHeader>
                <h4 className="card-title mb-0 flex-grow-1"> Лента новостей</h4>
            </CardHeader>
            <CardBody>
                <div style={{overflow: 'auto', height: "22.5rem"}}
                     className="list-group list-group-flush border-dashed custom-scroll-news">
                    {
                        news.map((event: any, i: number) => {
                            return <ItemEvent key={i} title={event.theme} date={new Date(event.date)} teacher={`${event.user?.name} ${event.user?.last_name ?? ''}`}/>
                        })
                    }
                </div>
            </CardBody>
        </Card>
    );
};

export default ItemNews;

import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {setToUpdateGraphMainPage} from "../../../store/reducers/widgets/WidgetsSlice";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {ProjectsJournalHaccpMorningFilterChildren} from "../../widgets/ProjectStatusCharts";

const WidgetGraphMorningFilterChildren = () => {
    const {groupAllDeviations, toUpdateGraphMainPage, date, journalHaccpMorningFilterChildren} = useAppSelector(state => state.WidgetReducer)
    const dispatch = useAppDispatch();

    useEffect(() => {
        toUpdateGraphMainPage &&
        WidgetService.getDeviationGroupMonth(null, date)
            .then(res => {
                dispatch(setToUpdateGraphMainPage(false))
            })
    }, [toUpdateGraphMainPage, date, dispatch])

    return (
        <>
            <Card>
                <CardHeader className="border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Журнал утреннего фильтра детей</h4>
                </CardHeader>

                <CardHeader className="p-0 border-0 bg-soft-light">
                    <Row className="g-0 text-center">
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {journalHaccpMorningFilterChildren.length > 0 ? (
                                            (journalHaccpMorningFilterChildren.reduce((totalAdmitted, entry) => {
                                                const admittedCount = entry.deti.filter((child: any) => child.conclusion === "Допущен").length;
                                                return totalAdmitted + admittedCount;
                                            }, 0) / journalHaccpMorningFilterChildren.length).toFixed()
                                        ) : 0}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Среднее количество здоровых</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {journalHaccpMorningFilterChildren.length > 0 ? (
                                            (journalHaccpMorningFilterChildren.reduce((totalAdmitted, entry) => {
                                                const admittedCount = entry.deti.filter((child: any) => child.conclusion !== "Допущен").length;
                                                return totalAdmitted + admittedCount;
                                            }, 0) / journalHaccpMorningFilterChildren.length).toFixed()
                                        ) : 0}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Среднее количество заболевших</p>
                            </div>
                        </Col>

                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {journalHaccpMorningFilterChildren.length > 0 ? (
                                            (
                                                journalHaccpMorningFilterChildren.reduce((totalSick, entry) => {
                                                    const sickCount = entry.deti.filter((child: any) => child.conclusion !== "Допущен").length;
                                                    return totalSick + sickCount;
                                                }, 0) / journalHaccpMorningFilterChildren.reduce((totalChildren, entry) => {
                                                    return totalChildren + entry.deti.length;
                                                }, 0)
                                                * 100).toFixed() + "%"
                                        ) : 0}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Процент заболевших</p>
                            </div>
                        </Col>

                    </Row>
                </CardHeader>

                <CardBody className="p-0 pb-2">
                    <div>
                        {
                            journalHaccpMorningFilterChildren && <ProjectsJournalHaccpMorningFilterChildren series={journalHaccpMorningFilterChildren}
                                                            dataColors='["--vz-green", "--vz-warning", "--vz-danger"]'/>
                        }
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default WidgetGraphMorningFilterChildren;

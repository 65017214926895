import React, {FC} from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import NewsDocs from "../news/NewsDocs";
import {Link} from "react-router-dom";
import moment from "moment/moment";

interface IMobileNews {
    news: any[],
}
const MobileNews: FC<IMobileNews> = ({news}) => {

    function badge(id: string){
        switch (id) {
            case '1':
                return <span className="badge bg-soft-success text-primary align-middle">Не срочно</span>
            case '2':
                return <span className="badge bg-soft-warning text-primary align-middle">Требует внимание</span>
            case '3':
                return <span className="badge bg-soft-danger text-primary align-middle">Срочно</span>
        }
    }

    function NewNews(date: Date) {
        const dateNews = moment(date).add(1, "days")
        const dateNew = moment();
        if (moment(dateNews).isSameOrAfter(dateNew)){
            return <span className="badge bg-success align-middle">Новая</span>
        }
    }

    return (
        <>
            {
                news.map((newses: any) => {
                    return newses.news.map((i: any) => {
                                return <Link key={i.id} to={`/parent/news/more/${i.id}`}>
                                            <Card key={i.id}>
                                                <CardBody>
                                                    <Row className="timeline-right">
                                                        <Col xs={12}>
                                                            <div className="timeline-box">
                                                                <div className="timeline-text">
                                                                    <p className="text-muted">{newses.date} {i.date}</p>
                                                                    <h5>{i.theme} <span>{badge(i.rang)} {NewNews(new Date(i.origDate))}</span></h5>
                                                                    <p className="text-muted mb-0">{i.description}</p>
                                                                    {
                                                                        i.documents.map((doc: any) => (<NewsDocs key={doc.url} url={doc}/>))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Link>
                            })
                })
            }
        </>
    );
};

export default MobileNews;

import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import PhoneOrPc from "../../components/department/PhoneOrPC";
import TableSafety from "../../components/department/Safety/TableSafety";
import TableSafetyPart1 from "../../components/department/Safety/PhoneTable/TableSafetyPart1";
import TableSafetyPart2 from "../../components/department/Safety/PhoneTable/TableSafetyPart2";

const Safety = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Безопасность</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <SelectOrganization/>
                        </Col>
                        <Col xxl={12}>
                            <PhoneOrPc phone={[<TableSafetyPart1/>, <TableSafetyPart2/>]} pc={[<TableSafety/>]}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Safety;

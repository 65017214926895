import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardImg,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Row, Spinner, UncontrolledDropdown
} from "reactstrap";
import bgImage from "../../assets/images/small/img-5.jpg";
import Flatpickr from "react-flatpickr";
import UpdateOtherImage from "./UpdateOtherImage";
import {useNavigate, useParams} from "react-router-dom";
import {getGroups, getStudents} from "../../store/reducers/widgets/ActionCreator";
import {getEmployees} from "../../store/reducers/department/ActionCreator";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import AxiosClient from "../../api/AxiosClient";
import {convertDateFull} from "../../functions/ConvertDate";
import TableChildrenParty from "./TableChildrenParty";
import {Russian} from "flatpickr/dist/l10n/ru";
import {useStateContext} from "../../hooks/useStateContext";

const  FormUpdatePortfolio = () => {

    const {id} = useParams();
    const redirect = useNavigate()
    const dispatch = useAppDispatch();
    const {groups, students} = useAppSelector(state => state.WidgetReducer)
    const {user} = useStateContext()
    const [image, setImage] = useState('');
    const [otherImage, setOtherImage] = useState<any[]>([]);
    const [formImage, setFormImage] = useState('');
    const [date, setDate] = useState<Date>(new Date());
    const [formOtherImages, setFormOtherImages] = useState<any[]>([]);
    const [group, setGroup] = useState('');
    const [portfolio, setPortfolio] = useState<any>({});
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [send, setSend] = useState(false);


    useEffect(() => {
        dispatch(getGroups([date, date])).then()
        dispatch(getEmployees()).then()
    }, [date, dispatch])

    useEffect(() => {
        dispatch(getStudents(date, group)).then()
    }, [date, group, dispatch])

    useEffect(() => {
        setGroup(groups[0]?.group_id)
    }, [groups])

    useEffect(() => {
        const params = {
            id: id
        }
        AxiosClient.get('/portfolio', {params}).then(r => setPortfolio(r.data))
    }, [id]);

    useEffect(() => {
        if (portfolio.date)
            setDate(new Date(convertDateFull(portfolio.date)))
    }, [portfolio]);

    const uploadMainImage = () => {
        document.getElementById('mainImageInput')?.click();
    }

    const uploadOtherImage = () => {
        document.getElementById('otherImageInput')?.click();
    }

    const showMainImage = (e: any) => {
        if (e.target.files){
            const [file] = e.target.files;
            setImage(URL.createObjectURL(file))
            setFormImage(file);
        }
    }
    const showOtherImage = (e: any) => {
        if (e.target.files){
            const files: File[] = [...e.target.files];
            setOtherImage(otherImage.concat(files.map((i) => ({url: URL.createObjectURL(i), name: i.name}))))
            setFormOtherImages(formOtherImages.concat(files))
        }
    }

    const deleteImage = (url: string) => {
        setPortfolio({...portfolio, otherImage: JSON.stringify(JSON.parse(portfolio.otherImage).filter((img: any) => url !== img))})
    }
    const deleteImageNew = (url: string, name: string) => {
        setOtherImage(otherImage.filter(img => img !== url))
        setFormOtherImages(formOtherImages.filter(i => (i as File).name !== name))
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    id: `${portfolio?.id}`,
                    theme: `${portfolio?.theme}`,
                    description: `${portfolio?.description}`,
                    checkbox: checkAll ? portfolio?.children?.map((i: any) => ({[i.children_id]: "true"})) : portfolio?.children?.map((i: any) => ({[i.children_id]: i.value === "true"})),
                    date: new Date(),
                    place: `${portfolio?.place}`,
                    type: `${portfolio?.type}`,
                    level: `${portfolio?.level}`,
                    employee: `${user.id}`,
                    group: `${portfolio?.group}`,
                    rang: `${portfolio?.rang}`,
                    mainImages: '',
                    image: '',
                    group_name: ''
                }}
                onSubmit={(values) =>{
                    setSend(true)
                    values.date = date
                    values.mainImages = portfolio.otherImage
                    values.image = portfolio.mainImage
                    values.group_name = groups.find(group => group.group_id === values.group)?.group_name as string
                    const data = new FormData();
                    if (formImage !== '') {
                        data.append('mainImage', formImage)
                    }
                    formOtherImages.forEach(file =>{
                        data.append('otherImage[]', file)
                    })
                    data.append('values', JSON.stringify(values))
                    AxiosClient.post('/portfolio/update', data, {headers: {"Content-Type": 'multipart/form-data'}})
                        .then(() => {
                            setSend(false)
                            redirect("/children/portfolio")
                        })
                        .catch(() => setSend(false))
                }}
            >
                <Form>
                    <Row>
                        <Col xxl={8}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={6} xl={5} xxl={5}>
                                            <Card className="card-height-100">
                                                <CardBody className="d-flex align-items-center">
                                                    <UncontrolledDropdown direction='start' className="col text-end settings-image">
                                                        <DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
                                                            <i className="ri-more-fill fs-21 text-light"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem className="dropdown-item edit-list" onClick={() => uploadMainImage()}>
                                                                <i className="ri-add-fill me-1 align-bottom"></i>Обновить главное фото
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    {
                                                        portfolio?.mainImage && !image ?
                                                            <CardImg id="mainImage" src={process.env.REACT_APP_API_BASE_URL + portfolio.mainImage} />
                                                            :
                                                            <CardImg id="mainImage" src={image ? image : bgImage} />
                                                    }
                                                    {/*<CardImg id="mainImage" src={image ? image : bgImage} />*/}
                                                    <Input color="success" id="mainImageInput" accept="image/*" defaultValue={image} className="mt-1" type="file" hidden onChange={(e) => showMainImage(e)}/>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card className="card-height-100">
                                                <CardBody>
                                                    <Field name="theme"
                                                           className="mt-2 form-control mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0"
                                                           placeholder="Тема"/>
                                                    <ErrorMessage name='theme'>{(msg) => <Alert className="mt-1" color="danger">{msg}</Alert>}</ErrorMessage>
                                                    <Field as="textarea" name="description" className="form-control mt-2"
                                                           rows={10}
                                                           style={{resize: "none"}} placeholder="Описание"/>
                                                    <ErrorMessage name='description'>{(msg) => <Alert className="mt-1" color="danger">{msg}</Alert>}</ErrorMessage>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h4 className="text-center mt-sm-3 mt-lg-3 mt-xl-3 mt-xll-3">Доп. информация</h4>
                                    <Row className="fs-5">
                                        <div className="mt-1"><span
                                            className="fw-medium">Дата:</span>
                                            <Flatpickr
                                                value={date}
                                                name='date'
                                                className="form-control border-1"
                                                options={{
                                                    mode: "single",
                                                    dateFormat: "d m Y",
                                                    defaultDate: new Date(),
                                                    locale: Russian,
                                                }}
                                                onClose={(date) => setDate(date[0])}
                                            />
                                        </div>
                                        <div className="mt-1"><span
                                            className="fw-medium">Место проведения:</span>
                                            <Field name="place" className="form-control" />
                                        </div>
                                        <div className="mt-1"><span
                                            className="fw-medium">Тип мероприятия:</span>
                                            <Field as="select" name="type" id="" className="form-select ">
                                                <option value="1">Классический</option>
                                            </Field>
                                        </div>
                                        <div className="mt-1 "><span
                                            className="fw-medium">Уровень организации:</span>
                                            <Field as="select" name="level" id="" className="form-select ">
                                                <option value="1">Высокий</option>
                                            </Field>
                                        </div>
                                        <div className="mt-1"><span
                                            className="fw-medium">Сотрудник:</span>
                                            <Field as="select" name="employee"
                                                   className="form-control" disabled>
                                                {
                                                    <option value={user.id}>{user.name}</option>
                                                }
                                            </Field>
                                        </div>
                                        <div className="mt-1"><span
                                            className="fw-medium">Группа:</span>
                                            <Field as="select" name="group" id="" className="form-select">
                                                {
                                                    groups.map(group => <option key={group.group_id} value={group.group_id}>{group.group_name}</option>)
                                                }
                                            </Field>
                                        </div>
                                        <div className="mt-1 mb-2"><span
                                            className="fw-medium">Занял место:</span>
                                            <Field className="form-control" name="rang"/>
                                            <ErrorMessage name='rang'>{(msg) => <Alert className="mt-1 fs-6" color="danger">{msg}</Alert>}</ErrorMessage>
                                        </div>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <TableChildrenParty students={students} setCheckAll={(check) => setCheckAll(check)}/>
                        </Col>
                    </Row>
                    <Row className="mt-3 mt-sm-3 mt-lg-3 mt-xl-3 mt-xxl-0">
                        <Col>
                            <Card>
                                <CardHeader>
                                    <Input color="success" id="otherImageInput" accept="image/*" multiple className="mt-1" type="file" hidden onChange={(e) => showOtherImage(e)}/>
                                    <Row>
                                        <Col xxl={3} lg={4}>
                                            <Button color="primary" className="mt-1 w-100" onClick={() => uploadOtherImage()}><i className="ri-add-fill me-1 align-bottom"></i>Добавить новые фото</Button>
                                        </Col>
                                        <Col xxl={3} lg={4}>
                                            <Button color="primary" className="mt-1 w-100" type="submit" disabled={send}><i className="ri-add-fill me-1 align-bottom"></i>{send ? <Spinner size={'sm'}>
                                                Loading...
                                            </Spinner> : "Сохранить"}</Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        {
                                            portfolio.otherImage &&
                                                JSON.parse(portfolio.otherImage)?.map((url: any) => (
                                                    <Col lg={6} xl={4} xxl={3} className="mt-1" key={url}>
                                                        <UpdateOtherImage url={{url: process.env.REACT_APP_API_BASE_URL + url, baseUrl: url}} deleteImage={(url) => deleteImage(url)}/>
                                                    </Col>
                                                ))
                                        }
                                        {
                                            otherImage && otherImage.map(url => (
                                                <Col lg={6} xl={4} xxl={3} className="mt-1" key={url}>
                                                    <UpdateOtherImage deleteImage={(url, name) => deleteImageNew(url, name as string)} url={url.baseUrl = url}/>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Formik>
        </>
    );
};

export default FormUpdatePortfolio;

import React, {useEffect, useState} from 'react';
import {
    Card,
    CardBody
} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getEvolutionChild} from "../../../store/reducers/parent/ActionCreator";
import {IEvolutionChild} from "../../../models/parent/IEvolutionChild";

const GraphDonutChildrenEvolution = () => {
    const dispatch = useAppDispatch();
    const {evolutionChild, child, selectDatesEvolution} = useAppSelector(state => state.ParentReducer)
    const [evoChild, setEvoChild] = useState<IEvolutionChild[]>([]);

    useEffect(() => {
        child &&
            dispatch(getEvolutionChild(selectDatesEvolution, child)).then()
    }, [child, dispatch])

    useEffect(() => {
        setEvoChild(evolutionChild.filter(i => selectDatesEvolution.find(k => k.period === i.period) && true))
    }, [selectDatesEvolution, evolutionChild])

    const options: any = {
        labels: (evoChild || []).map((evo) => evo.grade),
        chart: {
            type: "donut",
            height: 230,
        },
        plotOptions: {
            pie: {
                size: 100,
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: "80%",
                    labels: {
                        show: false,
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        stroke: {
            lineCap: "round",
            width: 0
        }
    }

    return (
        <>
            <Card>
                <CardBody>
                    <ReactApexChart
                        options={options}
                        series={(evoChild || []).map((dev) => dev.points)}
                        type="donut"
                        height="150"
                        className="apex-charts"
                    />
                    {
                        evoChild.length > 0 ?
                            <div className="mt-3">
                                <div className="d-flex justify-content-center align-items-center mb-4">
                                    <div>
                                        <p className="text-muted mb-0">Всего показателей</p>
                                    </div>
                                    <h2 className="mx-3 ff-secondary mb-0">{evoChild.map(evo => evo.points).reduce((a, b) => a + b)}</h2>

                                </div>

                                {
                                    (evoChild).map((evo, id) => {
                                        return (
                                            <div key={id}
                                                 className="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                                                <p className="fw-medium mb-0">{evo.grade}</p>
                                                <div>
                                                        <span
                                                            className="text-muted pe-5">{evo.points} показателей</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            :
                            <div className="d-flex justify-content-center align-items-center mb-4">
                                <div>
                                    <p className="text-muted mb-0">Данных нет</p>
                                </div>
                            </div>
                    }
                </CardBody>
            </Card>
        </>
    )
};

export default GraphDonutChildrenEvolution;

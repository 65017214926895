import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import * as Yup from "yup";
import {Col, Label, Row} from "reactstrap";
import {updateDoGroup} from "../../../store/reducers/department/ActionCreator";

const UpdateDataDoGroupForm = () => {
    const dispatch = useAppDispatch();
    const {selectDoGroup, employees, typesGroup, regions, organizations} = useAppSelector(state => state.DepartmentReducer)

    return (
        <Formik
            enableReinitialize
            initialValues={{
                name: `${selectDoGroup?.name}`,
                employee_id: selectDoGroup?.id,
                children: `${selectDoGroup?.children}`,
                organization_id: selectDoGroup?.organization_id,
                type_group_id: selectDoGroup?.type_group_id,
                region_id: selectDoGroup?.region_id
            }}
            validationSchema={Yup.object({
                name: Yup.string().required('Введите название группы ДО'),
                employee_id: Yup.string().required('Обязательное поле'),
                children: Yup.number().required('Обязательное поле'),
                organization_id: Yup.string().required('Обязательное поле'),
                type_group_id: Yup.string().required('Обязательное поле'),
                region_id: Yup.string().required('Обязательное поле')
            })}
            onSubmit={(value) => {
                dispatch(updateDoGroup(value, selectDoGroup!.id))
            }}
        >
            <Form>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="nameInput" className="form-label">Название
                                ДО</Label>
                            <Field type="text" className="form-control" id="nameInput"
                                   name="name"
                                   placeholder="Введите ДО"/>
                            <ErrorMessage name="name">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="employee_idInput"
                                   className="form-label">Сотрудник</Label>
                            <Field as="select" className="form-select" id="employee_idInput"
                                   name="employee_id">
                                {
                                    employees.map(item => (
                                        <option key={item.id}
                                                value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Field>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="childrenInput" className="form-label">Колличество
                                детей по СанПину</Label>
                            <Field type="text" className="form-control" id="childrenInput"
                                   name="children"
                                   placeholder="Введите колличество детей"/>
                            <ErrorMessage name="children">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="organization_idInput"
                                   className="form-label">Организация</Label>
                            <Field as="select" className="form-select"
                                   id="organization_idInput"
                                   name="organization_id">
                                {
                                    organizations.map(item => (
                                        <option key={item.id}
                                                value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Field>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="type_group_idInput"
                                   className="form-label">Тип группы</Label>
                            <Field as="select" className="form-select"
                                   id="type_group_idInput"
                                   name="type_group_id">
                                {
                                    typesGroup.map(item => (
                                        <option key={item.id}
                                                value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Field>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="region_idInput"
                                   className="form-label">Регион</Label>
                            <Field as="select" className="form-select" id="region_idInput"
                                   name="region_id">
                                {
                                    regions.map(item => (
                                        <option key={item.id}
                                                value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Field>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="mt-3">
                        <div className="hstack gap-2 justify-content-end">
                            <button type="submit"
                                    className="btn btn-primary">Обновить
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Formik>
    );
};

export default UpdateDataDoGroupForm;

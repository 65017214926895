import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import {Card, CardBody, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import ReactApexChart from "react-apexcharts";

const GraphSsnp = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)


    const [selectedMonth, setSelectedMonth] = useState<string[]>([]);

    const [data, setData] = useState([
        {
            label: 'Организация 1',
            options: {
                metal: '100',
                appg: '80',
                electric: '3',
                all: '23',
            }
        },
        {
            label: 'Организация 2',
            options: {
                metal: '100',
                appg: '90',
                electric: '3',
                all: '23',
            }
        },
        {
            label: 'Организация 3',
            options: {
                metal: '100',
                appg: '90',
                electric: '3',
                all: '23',
            }
        },
        {
            label: 'Организация 4',
            options: {
                metal: '100',
                appg: '100',
                electric: '3',
                all: '23',
            }
        }
    ]);
    const [outData, setOutData] = useState<any[]>([
        {name: 'План', type: "bar", data: [0]},
        {name: 'Факт', type: "bar", data: [0]}
    ]);

    useEffect(() => {
        let selOrg: string[] = selectOrganizations?.map(s => s.label)
        const newData = data.filter(d => selOrg?.includes(d.label))
        const metal: number[] = newData.map(r => +r.options.metal)
        const appg: number[] = newData.map(r => +r.options.appg)


        setOutData([
            {name: 'План', type: "bar", data: [metal.length > 0 ? (metal.reduce((a, b) => a + b)/selOrg.length).toFixed(1) : 0]},
            {name: 'Факт', type: "bar", data: [appg.length > 0 ? (appg.reduce((a, b) => a + b)/selOrg.length).toFixed(1) : 0]}
        ])

    }, [selectOrganizations]);


    const options: any = {
        chart: {
            height: '500px',
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0, 3],
            width: [0, 0, 0],
        },
        fill: {
            opacity: [1, 1, 0.3]
        },
        markers: {
            size: [0, 0, 4],
            strokeWidth: 2,
            hover: {
                size: 4,
            }
        },
        xaxis: {
            categories: ['Всего'],
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        }
    };

    const onChangeChartPeriod = (pType: string[]) => {
        setSelectedMonth(pType)
    };

    return (
        <>
            <Card>
                <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">План/Факт ССНП</h4>
                    <div className="flex-shrink-0">
                        <UncontrolledDropdown className="card-header-dropdown">
                            <DropdownToggle tag="a" className="dropdown-btn text-muted" role="button">
                                {selectedMonth[1]} <i
                                className="mdi mdi-chevron-down ms-1"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end overflow-auto" style={{maxHeight: 200}}>
                                <DropdownItem onClick={() => {
                                    onChangeChartPeriod(['1', 'org 1']);
                                }} className={selectedMonth[0] === "1" ? "active" : ""}>Последние 30
                                    дней</DropdownItem>
                                <DropdownItem onClick={() => {
                                    onChangeChartPeriod(['2', 'org 2']);
                                }} className={selectedMonth[0] === "2" ? "active" : ""}>Последние 90
                                    дней</DropdownItem>
                                <DropdownItem onClick={() => {
                                    onChangeChartPeriod(['3', 'org 3']);
                                }} className={selectedMonth[0] === "3" ? "active" : ""}>Последние 90
                                    дней</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </CardHeader>
                <CardBody>
                    <ReactApexChart
                        options={options}
                        series={outData}
                        type="line"
                        height="400"
                        className="apex-charts"
                    />
                </CardBody>
            </Card>
        </>
    );
};

export default GraphSsnp;

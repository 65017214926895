import React from 'react';
import {Col, Container, Row} from "reactstrap";
import {Helmet} from "react-helmet";
import WidgetRequiredFlatpikerPeriod from "../../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import GraphMyTask from "../../components/DocumentsFlow/MyTask/graphs/GraphMyTask";
import TableMyTask from "../../components/DocumentsFlow/MyTask/table/TableMyTask";

const MyTask = () => {
    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Задачи мне</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col xxl={4}>
                        <WidgetRequiredFlatpikerPeriod/>
                        {/*<GraphMyTask/>*/}
                    </Col>
                    <Col>
                        <TableMyTask/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default MyTask;

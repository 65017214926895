import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {setToUpdateGraphMainPage} from "../../../store/reducers/widgets/WidgetsSlice";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {ProjectsJournalHaccp} from "../../widgets/ProjectStatusCharts";
import {IDeviationGroupMonth} from "../../../models/widgets/IDeviationGroupMonth";

const WidgetGraphJournalHaccp = () => {
    const {groupAllDeviations, toUpdateGraphMainPage, date, journalHaccpTemperature} = useAppSelector(state => state.WidgetReducer)
    const dispatch = useAppDispatch();
    const [chartData, setChartData] = useState<IDeviationGroupMonth[]>([]);
    const [data, setData] = useState<IDeviationGroupMonth[]>([]);

    useEffect(() => {
        toUpdateGraphMainPage &&
        WidgetService.getDeviationGroupMonth(null, date)
            .then(res => {
                setChartData(res.data)
                dispatch(setToUpdateGraphMainPage(false))
            })
    }, [toUpdateGraphMainPage])

    useEffect(() => {
        if (groupAllDeviations !== "")
            setData(chartData.filter((item: any) => item.group_id === groupAllDeviations))
        else
            setData([{
                year: 0,
                mounth: '',
                group_name: '',
                group_id: '',
                present: 0,
                no_present: 0,
                zabolevanie: 0
            }])
    }, [groupAllDeviations, chartData])

    return (
        <>
            <Card>
                <CardHeader className="border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Журнал замеров температуры</h4>
                </CardHeader>

                <CardHeader className="p-0 border-0 bg-soft-light">
                    <Row className="g-0 text-center">
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                <span className="counter-value">
                                    {journalHaccpTemperature?.dates && journalHaccpTemperature.dates.length > 0 ? journalHaccpTemperature.dates.reduce((total, item) => {
                                        return total + item.data.filter(
                                            tempData => tempData.temperature < tempData.min_temperature
                                        ).length;
                                    }, 0) : 'Загрузка'}
                                </span>
                                </h5>
                                <p className="text-muted mb-0">Пониженная</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                        {journalHaccpTemperature?.dates && journalHaccpTemperature.dates.length > 0 ? journalHaccpTemperature.dates.reduce((total, item) => {
                            return total + item.data.filter(
                                tempData => tempData.temperature >= tempData.min_temperature && tempData.temperature <= tempData.max_temperature
                            ).length;
                        }, 0) : 'Загрузка'}
                    </span>

                                </h5>
                                <p className="text-muted mb-0">В пределах нормы</p>
                            </div>
                        </Col>

                        <Col xs={6} sm={4}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1">
                    <span className="counter-value">
                        {journalHaccpTemperature?.dates && journalHaccpTemperature.dates.length > 0 ? journalHaccpTemperature.dates.reduce((total, item) => {
                            return total + item.data.filter(
                                tempData => tempData.temperature > tempData.max_temperature
                            ).length;
                        }, 0) : 'Загрузка'}
                    </span>
                                </h5>
                                <p className="text-muted mb-0">Повышенная</p>
                            </div>
                        </Col>
                    </Row>
                </CardHeader>

                <CardBody className="p-0 pb-2">
                    <div>
                        {
                            journalHaccpTemperature && journalHaccpTemperature.dates && <ProjectsJournalHaccp series={journalHaccpTemperature}
                                                            dataColors='["--vz-green", "--vz-warning", "--vz-danger"]'/>
                        }
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default WidgetGraphJournalHaccp;

import {AxiosResponse} from "axios";
import $api from "../../AxiosClient";
import {IDeviation} from "../../../models/IDeviation";

class DeviationService {
    static async getDeviation(): Promise<AxiosResponse<IDeviation[]>>{
        return $api.post<IDeviation[]>("/deviations");
    }
    static async addDeviation(value: object): Promise<AxiosResponse<void>>{
        return $api.post<void>("/deviation/add", value);
    }
    static async updateDeviation(value: object, Id: number): Promise<AxiosResponse<void>>{
        return $api.post<void>(`/deviation/update/${Id}`, value);
    }
}

export default DeviationService;
import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Card, CardBody, CardHeader, Col, Label, Nav, NavItem, NavLink, Row} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {addTypeDeviation} from "../../../store/reducers/department/ActionCreator";

const TypeDeviationAdd = () => {

    const dispatch = useAppDispatch();
    const {organizations} = useAppSelector(state => state.DepartmentReducer)

    return (
        <>
            <Formik
                initialValues={
                    {
                        name: '',
                        organization_id: organizations[0].id
                    }
                }
                validationSchema={
                    Yup.object(
                        {
                            name: Yup.string().required('Введите тип отклонения'),
                            organization_id: Yup.string().required('Обязательное поле')
                        }
                    )
                }
                onSubmit={
                    (value, {resetForm}) => {
                        dispatch(addTypeDeviation(value));
                        resetForm()
                    }
                }
            >
                <Form>
                    <Card className="mx-n3">
                        <CardHeader>
                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                 role="tablist">
                                <NavItem>
                                    <NavLink
                                        className="active">
                                        <i className="fas fa-home"></i>
                                        Данные о типе отклонения
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody className="p-4">
                            <Row>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="roleInput" className="form-label">Тип
                                            отклонения</Label>
                                        <Field type="text" className="form-control" id="roleInput"
                                               name="name"
                                               placeholder="Введите тип отклонения"/>
                                        <ErrorMessage name="name">{(msg) => <div
                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                    </div>
                                    <div className="mb-3">
                                        <Label htmlFor="organization_idInput"
                                               className="form-label">Организация</Label>
                                        <Field as="select" className="form-select"
                                               id="organization_idInput"
                                               name="organization_id"
                                               placeholder="Введите организацию">
                                            {
                                                organizations.map(item => (
                                                    <option key={item.id}
                                                            value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Field>
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col lg={12} className="mt-3">
                                    <div className="hstack gap-2 justify-content-end">
                                        <button type="submit"
                                                className="btn btn-primary">Добавить
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Form>
            </Formik>
        </>
    );
};

export default TypeDeviationAdd;

import {AxiosResponse} from "axios";
import {ISubdivision} from "../../../models/ISubdivision";
import $api from "../../AxiosClient";

class SubdivisionService {

    static async getSubdivisions(): Promise<AxiosResponse<ISubdivision[]>>{
        return $api.post<ISubdivision[]>("/subdivisions");
    }

    static async addSubdivision(value: object): Promise<AxiosResponse<void>>{
        return $api.post<void>("/subdivision/add", value);
    }

    static async updateSubdivision(value: object, subdivisionId: number): Promise<AxiosResponse<void>>{
        return $api.post<void>(`/subdivision/update/${subdivisionId}`, value);
    }

}

export default SubdivisionService;
import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const CountChildrenAdditionalEducationInPreSchoolThreeFive = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState<any[]>([
        {
            org: "Организация 1",
            license: 'Да',
            countFiveSeven: '23',
            tech: {
                group: '2',
                children: '23'
            },
            naturalScience: {
                group: "3",
                children: '56'
            },
            physicalEducation:{
                group: "5",
                children: "23"
            },
            artistic: {
                group: "5",
                children: "23"
            },
            touristHistory: {
                group: "5",
                children: "23"
            },
            socialPedagogical: {
                group: "5",
                children: "23"
            },
            all: {
                group: '25',
                children: '234',
                fiveSevenPercent: '26'
            },
            appg: {
                group: "5",
                children: "23"
            }
        },
        {
            org: "Организация 2",
            license: 'Да',
            countFiveSeven: '23',
            tech: {
                group: '2',
                children: '23'
            },
            naturalScience: {
                group: "3",
                children: '56'
            },
            physicalEducation:{
                group: "5",
                children: "23"
            },
            artistic: {
                group: "5",
                children: "23"
            },
            touristHistory: {
                group: "5",
                children: "23"
            },
            socialPedagogical: {
                group: "5",
                children: "23"
            },
            all: {
                group: '25',
                children: '234',
                fiveSevenPercent: '26'
            },
            appg: {
                group: "5",
                children: "23"
            }
        },
        {
            org: "Организация 3",
            license: 'Да',
            countFiveSeven: '23',
            tech: {
                group: '2',
                children: '23'
            },
            naturalScience: {
                group: "3",
                children: '56'
            },
            physicalEducation:{
                group: "5",
                children: "23"
            },
            artistic: {
                group: "5",
                children: "23"
            },
            touristHistory: {
                group: "5",
                children: "23"
            },
            socialPedagogical: {
                group: "5",
                children: "23"
            },
            all: {
                group: '25',
                children: '234',
                fiveSevenPercent: '26'
            },
            appg: {
                group: "5",
                children: "23"
            }
        },
        {
            org: "Организация 4",
            license: 'Да',
            countFiveSeven: '23',
            tech: {
                group: '2',
                children: '23'
            },
            naturalScience: {
                group: "3",
                children: '56'
            },
            physicalEducation:{
                group: "5",
                children: "23"
            },
            artistic: {
                group: "5",
                children: "23"
            },
            touristHistory: {
                group: "5",
                children: "23"
            },
            socialPedagogical: {
                group: "5",
                children: "23"
            },
            all: {
                group: '25',
                children: '234',
                fiveSevenPercent: '26'
            },
            appg: {
                group: "5",
                children: "23"
            }
        }

    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Лицензия</span>,
            selector: (row: any) => row.license,
        },
        {
            name: <span className='font-weight-bold fs-13'>Детей 3-5</span>,
            selector: (row: any) => row.countFiveSeven,
        },
        {
            name: <span className='font-weight-bold fs-13'>Техн. (группы/дети)</span>,
            selector: (row: any) => (`${row.tech.group}/${row.tech.children}`),
        },
        {
            name: <span className='font-weight-bold fs-13'>Ест. науч. (группы/дети)</span>,
            selector: (row: any) => (`${row.naturalScience.group}/${row.naturalScience.children}`),
        },
        {
            name: <span className='font-weight-bold fs-13'>Физ. спорт. (группы/дети)</span>,
            selector: (row: any) => (`${row.physicalEducation.group}/${row.physicalEducation.children}`),
        },
        {
            name: <span className='font-weight-bold fs-13'>Художств. (группы/дети)</span>,
            selector: (row: any) => (`${row.artistic.group}/${row.artistic.children}`),
        },
        {
            name: <span className='font-weight-bold fs-13'>Турист. краев. (группы/дети)</span>,
            selector: (row: any) => (`${row.touristHistory.group}/${row.touristHistory.children}`),
        },
        {
            name: <span className='font-weight-bold fs-13'>Соц. пед. (группы/дети)</span>,
            selector: (row: any) => (`${row.socialPedagogical.group}/${row.socialPedagogical.children}`),
        },
        {
            name: <span className='font-weight-bold fs-13'>Всего в ДОУ (группы/дети/охват 5-7%)</span>,
            selector: (row: any) => (`${row.all.group}/${row.all.children}/${row.all.fiveSevenPercent}%`),
        },
        {
            name: <span className='font-weight-bold fs-13'>АППГ (группы/дети/)</span>,
            selector: (row: any) => (`${row.appg.group}/${row.appg.children}`),
        },
    ]

    return (
        <Card>
            <CardHeader>
                Дополнительное образование в ДОУ(3-5 лет)
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default CountChildrenAdditionalEducationInPreSchoolThreeFive;

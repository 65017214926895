import React, {FC, useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import AxiosClient from "../../../api/AxiosClient";
import {Col, Collapse, Row, Table} from "reactstrap";
import classNames from "classnames";
import {setGroupModalAdd} from "../../../store/reducers/widgets/WidgetsSlice";
import {Field} from "formik";

interface ITableEmployee {
    employees: any,
    setEmployees(value: any): void
}

const TableEmployee: FC <ITableEmployee> = ({employees, setEmployees}) => {
    const [expandedRow, setExpandedRow] = useState<string | null>(null);

    const toggleRow = (uid: string) => {
        setExpandedRow(expandedRow === uid ? null : uid);
    };

    const handleChange = (uid: string, field: string, value: any) => {
        const updatedEmployee = employees.map((employee: any) =>
            employee.uid === uid ? { ...employee, [field]: value } : employee
        );
        setEmployees(updatedEmployee);
    };

    return (
        <div className="accordion accordion-border-box custom-scroll-news" style={{ height: '38rem', overflow: 'auto' }}>
            {employees.map((employee: any) => (
                <div className="accordion-item shadow position-relative" key={employee.uid}>
                    <div
                        className={classNames('position-absolute rounded-start h-100', 'bg-primary')}
                        style={{ width: 5 }}
                    ></div>
                    <h2 className="accordion-header">
                        <button
                            className={classNames(
                                "accordion-button",
                                "fw-medium",
                                { collapsed: expandedRow !== employee.uid }
                            )}
                            type="button"
                            onClick={() => toggleRow(employee.uid)}
                            style={{ cursor: "pointer" }}
                        >
                            {employee.name}
                        </button>
                    </h2>
                    <Collapse isOpen={expandedRow === employee.uid} className="accordion-collapse">
                        <Col className="mx-3">
                            <Row>
                                <Col>
                                    <label>
                                        Температура
                                        <input type="text" className="form-control custom-input" value={employee.temperature}
                                               onChange={(e) => handleChange(employee.uid, 'temperature', e.target.value)}/>
                                    </label>
                                </Col>
                            </Row>
                            <Row>
                                <label className="form-label">
                                    Симптомы РЛС:
                                    <Field as="select" className="form-select shadow" value={employee.rls}
                                           onChange={(e: { target: { value: any; }; }) => handleChange(employee.uid, 'rls', e.target.value)}
                                           name="rls">
                                        <option key={0} value="Есть">Есть</option>
                                        <option key={1} value="Отсутствуют">Отсутствуют</option>
                                    </Field>
                                </label>
                            </Row>
                            <Row>
                                <label className="form-label">
                                    Симптомы ОРВИ:
                                    <Field as="select" className="form-select shadow" value={employee.orvi}
                                           onChange={(e: { target: { value: any; }; }) => handleChange(employee.uid, 'orvi', e.target.value)}
                                           name="orvi">
                                        <option key={0} value="Есть">Есть</option>
                                        <option key={1} value="Отсутствуют">Отсутствуют</option>
                                    </Field>
                                </label>
                            </Row>
                            <Row>
                                <label className="form-label">
                                    Симптомы:
                                    <textarea rows={3} className="form-control" name="symptoms" value={employee.symptoms}
                                              onChange={(e) => handleChange(employee.uid, 'symptoms', e.target.value)}/>
                                </label>
                            </Row>
                        </Col>
                    </Collapse>
                </div>
            ))}
        </div>
    );
};

export default TableEmployee;

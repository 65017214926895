import React, {FC, memo, useEffect} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Col
} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import {getMoneySpent} from "../../../store/reducers/parent/ActionCreator";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";

interface IGraphDonutCash {
    child: string
}
const GraphDonutCash: FC<IGraphDonutCash> = ({child}) => {
    const dispatch = useAppDispatch();
    const {moneySpent, dateMonth} = useAppSelector(state => state.ParentReducer)

    useEffect(() => {
        if (child)
            dispatch(getMoneySpent(dateMonth, child)).then()
    }, [dispatch, child, dateMonth])


    const options: any = {
        labels: (moneySpent || []).map((dev) => dev.type_education),
        chart: {
            type: "donut",
            height: 230,
        },
        plotOptions: {
            pie: {
                size: 100,
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: "80%",
                    labels: {
                        show: false,
                    }
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        stroke: {
            lineCap: "round",
            width: 0
        },
    }

    return (
        <>
            <Col xxl={3}>
                <Card className="card-height-100">
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Денежные средства</h4>
                    </CardHeader>

                    <CardBody>
                        <ReactApexChart
                            options={options}
                            series={(moneySpent || []).map((dev) => dev.summa)}
                            type="donut"
                            height="150"
                            className="apex-charts"
                        />
                        {
                            moneySpent.length > 0 ?
                                <div className="mt-3">
                                    <div className="d-flex justify-content-center align-items-center mb-4">
                                        <div>
                                            <p className="text-muted mb-0">Всего затрат</p>
                                        </div>
                                        <h2 className="mx-3 ff-secondary mb-0">{moneySpent.map(dev => dev.summa).reduce((a, b) => a + b)}</h2>

                                    </div>

                                    {
                                        (moneySpent).map((type, id) => {
                                            return (
                                                <div key={id}
                                                     className="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                                                    <p className="fw-medium mb-0">{type.type_education}</p>
                                                    <div>
                                                        <span
                                                            className="text-muted pe-5">{type.summa} руб. </span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                :
                                <div className="d-flex justify-content-center align-items-center">
                                    <div>
                                        <p className="text-muted mb-0">Данных нет</p>
                                    </div>
                                </div>
                        }
                    </CardBody>
                </Card>
            </Col>
        </>
    )
};

export default memo(GraphDonutCash);

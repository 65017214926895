import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const CountChildrenAdditionalEducationInPreSchoolThreeFivePart3 = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState<any[]>([
        {
            org: "Организация 1",
            artistic: {
                group: "5",
                children: "23"
            },
            touristHistory: {
                group: "5",
                children: "23"
            },
            socialPedagogical: {
                group: "5",
                children: "23"
            }
        },
        {
            org: "Организация 2",
            artistic: {
                group: "5",
                children: "23"
            },
            touristHistory: {
                group: "5",
                children: "23"
            },
            socialPedagogical: {
                group: "5",
                children: "23"
            }
        },
        {
            org: "Организация 3",
            artistic: {
                group: "5",
                children: "23"
            },
            touristHistory: {
                group: "5",
                children: "23"
            },
            socialPedagogical: {
                group: "5",
                children: "23"
            }
        },
        {
            org: "Организация 4",
            artistic: {
                group: "5",
                children: "23"
            },
            touristHistory: {
                group: "5",
                children: "23"
            },
            socialPedagogical: {
                group: "5",
                children: "23"
            }
        }

    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Художств. (группы/дети)</span>,
            selector: (row: any) => (`${row.artistic.group}/${row.artistic.children}`),
        },
        {
            name: <span className='font-weight-bold fs-13'>Турист. краев. (группы/дети)</span>,
            selector: (row: any) => (`${row.touristHistory.group}/${row.touristHistory.children}`),
        },
        {
            name: <span className='font-weight-bold fs-13'>Соц. пед. (группы/дети)</span>,
            selector: (row: any) => (`${row.socialPedagogical.group}/${row.socialPedagogical.children}`),
        }
    ]

    return (
        <Card>
            <CardHeader>
                Дополнительное образование в ДОУ (3 - 5 лет)
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default CountChildrenAdditionalEducationInPreSchoolThreeFivePart3;

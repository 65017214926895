import React, {FC, useState} from 'react';
import {Alert, Modal, ModalBody, ModalHeader} from "reactstrap";
import * as Yup from "yup";
import AxiosClient from "../../../api/AxiosClient";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import {setDateSign} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";

interface IModalSignEmployee {
    modalSign: boolean;
    setModalSign: (modalSign: boolean) => void;
}
const ModalSignEmployee: FC<IModalSignEmployee> = ({modalSign, setModalSign}) => {
    const dispatch = useAppDispatch();
    const {dateSign} = useAppSelector(state => state.WidgetReducer)
    const [codeValid, setCodeValid] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    return (
        <Modal id="createProjectModal" isOpen={modalSign}
               toggle={() => {
                   setModalSign(!modalSign)
                   setError('')
               }}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => {
                setModalSign(!modalSign)
                setError('')
            }} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Подписать результаты сотрудника
            </ModalHeader>
            <ModalBody>
                    <Alert>Код отправлен в Telegram</Alert>
                <Formik
                    initialValues={
                        {
                            code: ''
                        }
                    }
                    validationSchema={
                        Yup.object({
                            code: Yup.number().typeError('Введите числа').required('Введите код подтверждения')
                        })
                    }
                    onSubmit={
                        (values) => {
                            AxiosClient.post('/check_code_sign_employee', {code: values.code})
                                .then(() => AxiosClient.post('/set_sign_employee', {date: dateSign})
                                    .then(() => {
                                        setModalSign(false)
                                        setError('')
                                    })
                                    .catch((reason) => {
                                        setError(reason.response.data)
                                    })
                                )
                                .catch(() => setCodeValid(false))
                        }
                    }
                >
                    <Form>
                        {
                            error &&
                            <Alert color="danger" className="mt-2">{error}</Alert>
                        }
                        <div>
                            <div className="col-auto mb-2">
                                <label>Период:</label>
                                <div className="col mb-2">
                                    <div className="input-group">
                                        <Flatpickr
                                            className="form-control border-0 dash-filter-picker shadow"
                                            value={dateSign}
                                            options={{
                                                locale: Russian, mode: "range", dateFormat: "d m Y",
                                            }}
                                            onChange={(date) => dispatch(setDateSign(date))}
                                        />
                                    </div>
                                </div>
                                <label>Код:</label>
                                <div className="col">
                                    <Field name="code" className="form-control shadow"/>
                                    <ErrorMessage name="code">{(msg) => <Alert color="danger" className="mt-2">{msg}</Alert>}</ErrorMessage>
                                    {
                                        !codeValid &&
                                        <Alert color="danger" className="mt-2">Не правильно введен код</Alert>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="hstack gap-2 justify-content-end mt-2">
                            <button type="submit" className="btn btn-primary" id="addNewProject">Подтвердить</button>
                        </div>
                    </Form>
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalSignEmployee;

import React, {useState} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";
import TableMedicalControlRatingsShow from "./TableMedicalControlRatingsShow";

const TableMedicalControlRatings = () => {

    const [data, setData] = useState([
        {
            org: "Организация 2",
            name: 'Отсутствует',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        },
        {
            org: "Организация 3",
            name: 'Незначительные',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        },
        {
            org: "Организация 4",
            name: 'Грубые',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        }
    ]);

    const columns: any[] = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Нарушение</span>,
            selector: (row: any) => row.name,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Всего</span>,
            selector: (row: any) => row.all,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Завершено</span>,
            selector: (row: any) => row.zav,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Просрочено</span>,
            selector: (row: any) => row.pros,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Выполнено</span>,
            selector: (row: any) => row.vip,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Вып. с задержкой</span>,
            selector: (row: any) => <span className="mx-2">{row.vipzad}</span>,
        }
    ]

    return (
        <Card>
            <CardHeader>
                Нарушения в разрезе оценок
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    expandableRows
                    expandOnRowClicked
                    expandableRowsComponent={
                        () => {
                            return <TableMedicalControlRatingsShow/>
                        }
                    }
                    data={data}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно нарушений:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        },
                        expanderRow:{
                            style:{
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableMedicalControlRatings;

import Immutable from 'immutable';
import {DefaultDraftBlockRenderMap} from 'draft-js';

export enum BlockType {
    /* Заголовки */
    h1 = 'header-one',
    h2 = 'header-two',
    h3 = 'header-three',
    h4 = 'header-four',
    h5 = 'header-five',
    h6 = 'header-six',
    /* Цитата */
    blockquote = 'blockquote',
    /* Блок с кодом */
    code = 'code-block',
    /* Список */
    list = 'unordered-list-item',
    /* Нумерованный список */
    orderList = 'ordered-list-item',
    /* Сноска */
    cite = 'cite',
    /* Простой текст */
    default = 'unstyled',
}

const CUSTOM_BLOCK_RENDER_MAP = Immutable.Map({
    [BlockType.cite]: {
        element: 'cite',
    },
});

export enum InlineStyle {
    BOLD = 'BOLD',
    ITALIC = 'ITALIC',
    UNDERLINE = 'UNDERLINE',
    ACCENT = 'ACCENT', // код нашего произвольного стиля

    // цвета

    RED = 'RED',
    GREEN = 'GREEN',
    BLUE = 'BLUE',
    ORANGE = 'ORANGE',
    PURPLE = 'PURPLE',
    YELLOW = 'YELLOW',
    BLACK = 'BLACK',
    WHITE = 'WHITE',
    GRAY = 'GRAY',
    PINK = 'PINK'
}

export enum ColorInline {
    RED = 'RED',
    GREEN = 'GREEN',
    BLUE = 'BLUE',
    ORANGE = 'ORANGE',
    PURPLE = 'PURPLE',
    YELLOW = 'YELLOW',
    BLACK = 'BLACK',
    WHITE = 'WHITE',
    GRAY = 'GRAY',
    PINK = 'PINK'
}

export const CUSTOM_STYLE_MAP = {
    [InlineStyle.ACCENT]: {
        backgroundColor: '#F7F6F3',
        color: '#A41E68',
    },
    RED: { color: 'red' },
    GREEN: { color: 'green' },
    BLUE: { color: 'blue' },
    ORANGE: { color: 'orange' },
    PURPLE: { color: 'purple' },
    YELLOW: { color: 'yellow' },
    BLACK: { color: 'black' },
    WHITE: { color: 'white' },
    GRAY: { color: 'gray' },
    PINK: { color: 'pink' },
};

export const COLORS = [
    { label: 'Красный', style: 'RED', color: 'red', inline: InlineStyle.RED },
    { label: 'Зеленый', style: 'GREEN', color: 'green', inline: InlineStyle.GREEN },
    { label: 'Синий', style: 'BLUE', color: 'blue', inline: InlineStyle.BLUE },
    { label: 'Оранжевый', style: 'ORANGE', color: 'orange', inline: InlineStyle.ORANGE },
    { label: 'Фиолетовый', style: 'PURPLE', color: 'purple', inline: InlineStyle.PURPLE },
    { label: 'Желтый', style: 'YELLOW', color: 'yellow', inline: InlineStyle.YELLOW },
    { label: 'Черный', style: 'BLACK', color: 'black', inline: InlineStyle.BLACK },
    { label: 'Белый', style: 'WHITE', color: 'white', inline: InlineStyle.WHITE },
    { label: 'Серый', style: 'GRAY', color: 'gray', inline: InlineStyle.GRAY },
    { label: 'Розовый', style: 'PINK', color: 'pink', inline: InlineStyle.PINK },
];

export const BLOCK_RENDER_MAP = DefaultDraftBlockRenderMap.merge(CUSTOM_BLOCK_RENDER_MAP);
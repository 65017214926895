import React, {FC, memo, useEffect, useRef} from 'react';
import {FormRoutes, PrivateRoutes, PublicRoutes} from "../routes/routes";
import {Routes, Route, Navigate, useLocation} from "react-router-dom";
import {ConstRoutes} from "../routes/constRoutes";
import {useStateContext} from "../hooks/useStateContext";
import AuthService from "../api/services/authService/AuthService";
import SideBarMain from "./layouts/sideBar/SideBarMain";
import Lockscreen from "../pages/Lockscreen";

const AppRoutes: FC = () => {

    const {token, setUser, lockscreen, user} = useStateContext()
    const location = useLocation()
    const prevLocation = useRef(location.pathname);

    useEffect(() => {
        if (token)
            AuthService.getUser()
                .then(({data}) => {
                    setUser(data)
                })
    }, [setUser, token])

    useEffect(() => {
        const regex = /\/game\/parent\/\d+/
        const regex2 = /\/game\/\d+/;
        //game/play/private/161
        const regex3 = /\/game\/play\/private\/\d+/
        if (!regex.test(location.pathname) && !regex2.test(location.pathname) && !regex3.test(location.pathname)){
            if ((regex.test(prevLocation.current) || regex2.test(prevLocation.current) || regex3.test(prevLocation.current)) && prevLocation.current !== location.pathname) {
                window.location.reload()
            }
        }
        return () => {
            prevLocation.current = location.pathname;
        };
    }, [location.pathname]);

    return (
        <>
            {
                !token ?
                    <Routes>
                        {
                            FormRoutes.map(route => {
                                return <Route key={route.path} path={route.path} element={route.component}/>
                            })
                        }
                        {
                            PublicRoutes.map(route => {
                                return <Route key={route.path} path={route.path} element={route.component}/>
                            })
                        }
                        <Route path="*" element={<Navigate to={ConstRoutes.LOGIN}/>}/>
                    </Routes>
                    :
                    lockscreen === "true" ?
                    <Lockscreen/> :
                    <Routes>
                        {
                            PrivateRoutes.map(route => {
                                return user.term?.pages?.map((routeUser) => {
                                    if (routeUser.path === route.path){
                                        return <Route key={route.path} path={route.path} element={
                                            <SideBarMain>
                                                {route.component}
                                            </SideBarMain>
                                        }/>
                                    }
                                    return null
                                })
                            })
                        }
                         <Route path="*" element={<Navigate to={user?.term?.base_url}/>}/>
                    </Routes>
            }
        </>


    );
};

export default memo(AppRoutes);

import React, {FC} from 'react';

interface IIconFile {
    name: string
}
const IconFile: FC<IIconFile> = ({name}) => {

    const icon = (name: string) => {
        if(name.indexOf(".docx") > 1){
            return <i className="ri-file-word-2-line text-info fs-24"></i>;
        }
        if (name.indexOf(".xlsx") > 1){
            return <i className="ri-file-excel-2-line text-success fs-24"></i>;
        }
        if (name.indexOf(".pptx") > 1){
            return <i className="ri-file-ppt-2-line text-danger fs-24"></i>;
        }
        if (name.indexOf(".jpg") > 1){
            return <i className="ri-image-2-line fs-24"></i>;
        }
        if (name.indexOf(".png") > 1){
            return <i className="ri-image-2-line fs-24"></i>;
        }
    }

    return (
        <>
            {icon(name)}
        </>
    );
};

export default IconFile;

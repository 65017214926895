import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const TableChildrenAttendOoPart2 = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState([
        {
            org: "Организация 1",
            guardianship: '3',
            childrenHouse: '3',
            lowIncomeFamily: '5',
            manyChildrenFamily: '1',
            familyMigration: '1',
            singleParentFamily: '2',
            oneParentFather: '2'
        },
        {
            org: "Организация 2",
            guardianship: '3',
            childrenHouse: '3',
            lowIncomeFamily: '5',
            manyChildrenFamily: '1',
            familyMigration: '1',
            singleParentFamily: '2',
            oneParentFather: '2'
        },
        {
            org: "Организация 3",
            guardianship: '3',
            childrenHouse: '3',
            lowIncomeFamily: '5',
            manyChildrenFamily: '1',
            familyMigration: '1',
            singleParentFamily: '2',
            oneParentFather: '2'
        },
        {
            org: "Организация 4",
            guardianship: '3',
            childrenHouse: '3',
            lowIncomeFamily: '5',
            manyChildrenFamily: '1',
            familyMigration: '1',
            singleParentFamily: '2',
            oneParentFather: '2'
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: typeof data[0]) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Семья мигрантов</span>,
            selector: (row: typeof data[0]) => row.lowIncomeFamily,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Неполная семья</span>,
            selector: (row: typeof data[0]) => row.singleParentFamily,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Воспитывается только отцом</span>,
            selector: (row: typeof data[0]) => row.oneParentFather,
        }
    ]

    return (
        <Card>
            <CardHeader>
                Предметно - развивающая среда
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableChildrenAttendOoPart2;

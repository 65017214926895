import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import WidgetRequiredFlatpikerPeriod from "../../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import Widget from "../../components/department/Nutrition/Widget";
import GraphSSNP from "../../components/department/Nutrition/GraphSSNP";
import GraphKKal from "../../components/department/Nutrition/GraphKKal";
import TableSSNP from "../../components/department/Nutrition/TableSSNP";
import TableKKal from "../../components/department/Nutrition/TableKKal";
import TableSSNPThreeSeven from "../../components/department/Nutrition/TableSSNPThreeSeven";
import TableKKalThreeSeven from "../../components/department/Nutrition/TableKKalThreeSeven";

const Nutrition = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Питание</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <WidgetRequiredFlatpikerPeriod/>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Widget/>
                            </Row>
                            <Row>
                                <Col xxl={6} xl={6} sm={12}>
                                    <GraphSSNP/>
                                </Col>
                                <Col xxl={6} xl={6} sm={12}>
                                    <GraphKKal/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                                <TableKKal/>
                                <TableKKalThreeSeven/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Nutrition;

import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import TableMTBComputers from "../../components/department/MTB/TableMTBComputers";
import TableMtbMultimedia from "../../components/department/MTB/TableMTBMultimedia";
import TableMtbConstruct from "../../components/department/MTB/TableMTBConstruct";
import TableMtbEvolutionEnvOne from "../../components/department/MTB/TableMTBEvolutionEnvOne";
import TableMtbEvolutionEnvTwo from "../../components/department/MTB/TableMTBEvolutionEnvTwo";
import PhoneOrPc from "../../components/department/PhoneOrPC";
import TableMtbComputersPart1 from "../../components/department/MTB/PhoneTable/TableMTBComputersPart1";
import TableMtbComputersPart2 from "../../components/department/MTB/PhoneTable/TableMTBComputersPart2";
import TableMtbEvolutionEnvOnePart1 from "../../components/department/MTB/PhoneTable/TableMtbEvolutionEnvOnePart1";
import TableMtbEvolutionEnvOnePart2 from "../../components/department/MTB/PhoneTable/TableMtbEvolutionEnvOnePart2";
import TableMtbEvolutionEnvTwoPart1 from "../../components/department/MTB/PhoneTable/TableMtbEvolutionEnvTwoPart1";
import TableMtbEvolutionEnvTwoPart2 from "../../components/department/MTB/PhoneTable/TableMtbEvolutionEnvTwoPart2";
import GraphPC from "../../components/department/MTB/GraphPC";
import GraphConstuct from "../../components/department/MTB/GraphConstuct";
import GraphMultimedia from "../../components/department/MTB/GraphMultimedia";
import Widget from "../../components/department/MTB/Widget";

const Mtb = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Курсы</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Widget/>
                            </Row>
                            <Row>
                                <Col xxl={6} xl={6} lg={6} sm={12}>
                                    <GraphConstuct/>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} sm={12}>
                                    <GraphMultimedia/>
                                </Col>
                                <Col>
                                    <GraphPC/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <PhoneOrPc phone={[<TableMtbComputersPart1/>, <TableMtbComputersPart2/>,
                                                <TableMtbMultimedia/>,
                                                <TableMtbConstruct/>,
                                                <TableMtbEvolutionEnvOnePart1/>, <TableMtbEvolutionEnvOnePart2/>,
                                                <TableMtbEvolutionEnvTwoPart1/>, <TableMtbEvolutionEnvTwoPart2/>]}
                                       pc={[<TableMTBComputers/>, <TableMtbMultimedia/>, <TableMtbConstruct/>, <TableMtbEvolutionEnvOne/>, <TableMtbEvolutionEnvTwo/>]}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
};

export default Mtb;

import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import Select from "react-select";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import PhoneOrPc from "../../components/department/PhoneOrPC";
import TableOvzInvGroups from "../../components/department/OvzInv/TableOvzInvGroups";
import TableOvzInvGroupsPart1 from "../../components/department/OvzInv/TablePhone/TableOvzInvGroupsPart1";
import TableOvzInvGroupsPart2 from "../../components/department/OvzInv/TablePhone/TableOvzInvGroupsPart2";
import TableOvzInvYear from "../../components/department/OvzInv/TableOvzInvYear";
import Widget from "../../components/department/OvzInv/Widget";
import GraphAllDeviations from "../../components/department/OvzInv/GraphAllDeviations";
import GraphOvzInvSex from "../../components/department/OvzInv/GraphOvzInvSex";

const OvzInv = () => {
    const [years, setYears] = useState<any[]>([{value: new Date().getFullYear(), label: new Date().getFullYear()}]);

    const customStyles = {
        control: (ds: any) => ({
            ...ds,
            backgroundColor: "#fff",
        }),
        option: (ds: any, state: any) => ({
            ...ds,
            backgroundColor: state.isFocused ? "#4b38b3" : "#fff",
            color: state.isFocused ? "#fff" : "#000"
        })
    }

    function yearsLab(data: number) {
        let currentYear = new Date().getFullYear(), years: any[] = [];
        data = 1980;
        while ( data <= currentYear ) {
            const d = data++
            years.push({value: d, label: d});
        }
        return years;
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Инвалиды, ОВЗ</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <Select options={yearsLab(new Date().getFullYear())}
                                        value={years}
                                        onChange={(newValue) => {
                                            setYears(newValue as any[])
                                        }}
                                        closeMenuOnSelect={false}
                                        name="stock"
                                        styles={customStyles}
                                        placeholder="Поиск"
                                        maxMenuHeight={500}
                                        minMenuHeight={200}
                                        isMulti
                                        className="mb-2"/>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Widget/>
                            </Row>
                            <Row>
                                <Col xxl={6} xl={6} sm={12} xs={12}>
                                    <GraphAllDeviations/>
                                </Col>
                                <Col xxl={6} xl={6} sm={12} xs={12}>
                                    <GraphOvzInvSex/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <PhoneOrPc phone={[<TableOvzInvGroupsPart1/>, <TableOvzInvGroupsPart2/>, <TableOvzInvYear years={years}/>]} pc={[<TableOvzInvGroups/>, <TableOvzInvYear years={years}/>]}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
};

export default OvzInv;

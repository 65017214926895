import React, {FC, useEffect} from 'react';
import DataTable from "react-data-table-component";
import {IPFCFoodRation} from "../../../models/parent/IPFCFoodRation";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getPFCFoodRation} from "../../../store/reducers/parent/ActionCreator";

interface ITablePfcFoodRation {
    date: Date
}
const TablePfcFoodRation: FC<ITablePfcFoodRation> = ({date}) => {

    const dispatch = useAppDispatch()
    const {child, pfcFoodRation} = useAppSelector(state => state.ParentReducer)


    useEffect(() => {
        dispatch(getPFCFoodRation(date, child)).then()
    }, [dispatch, child, date]);


    const columnsPFC: any = [
        {
            name: <span className='font-weight-bold fs-18'>БЖУ</span>,
            selector: (row: IPFCFoodRation) => <span className="fs-13">{row.name}</span>,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold fs-18'>Кол-во</span>,
            selector: (row: IPFCFoodRation) => (
                row.count
            ),
            center: true
        }
    ];


    return (
        <DataTable
            columns={columnsPFC}
            data={pfcFoodRation}
            noDataComponent={'Нет данных'}
            highlightOnHover
            customStyles={{
                rows: {
                    highlightOnHoverStyle: {
                        backgroundColor: "#ffebb5"
                    }
                }
            }}
        />
    );
};

export default TablePfcFoodRation;

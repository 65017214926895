import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardImg, Col, Container, Row} from "reactstrap";
import bgImage from "../../assets/images/small/img-5.jpg";
import {useParams} from "react-router-dom";
import AxiosClient from "../../api/AxiosClient";
import {IElem} from "../../models/kindergarten/IPortfolio";
import {Helmet} from "react-helmet";

const ChildrenPortfolioMore = () => {

    const {id} = useParams();

    const [portfolio, setPortfolio] = useState<IElem>();

    useEffect(() => {
        AxiosClient.get<IElem>('/portfolio', {params: {id}})
            .then(r => setPortfolio(r.data))
    }, [id]);

    return (
        <div className="page-content">
            <Container fluid>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{portfolio?.theme}</title>
                </Helmet>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={6} xl={5} xxl={5} className="d-flex align-items-center">
                                        {
                                            portfolio?.mainImage ?
                                                <img src={process.env.REACT_APP_API_BASE_URL + portfolio?.mainImage}
                                                     alt="" className="img-fluid"/>
                                                :
                                                <img src={bgImage} alt="" className="img-fluid"/>
                                        }
                                    </Col>
                                    <Col className="mt-sm-2 mt-lg-0 mt-xl-0 mt-xll-0">
                                        <h4>{portfolio?.theme}</h4>
                                        <div className="hstack gap-2 flex-wrap">
                                            <div className="text-muted">
                                                Сотрудник :{" "}
                                                <span className="text-body fw-medium">
                                                {portfolio?.user?.name}
                                                </span>
                                            </div>
                                            <div className="vr"></div>
                                            <div className="text-muted">
                                                Дата :{" "}
                                                <span className="text-body fw-medium">
                                                {portfolio?.date}
                                            </span>
                                            </div>
                                            <div className="vr"></div>
                                            <div className="text-muted">
                                                Группа :{" "}
                                                <span className="text-body fw-medium">
                                                {portfolio?.group_name}
                                            </span>
                                            </div>
                                        </div>
                                        <Row className="mt-2">
                                            <Row>
                                                <Col lg={12} sm={6} xl={6} xxl={6}>
                                                    <div className="p-0 border border-dashed rounded">
                                                        <div className="d-flex align-items-center">
                                                            <div className="avatar-sm me-2">
                                                                <div className="avatar-title rounded bg-transparent text-success fs-24">
                                                                    <i className="ri-community-line"></i>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <p className="text-muted mb-1">Место проведения :</p>
                                                                <h5 className="mb-0">{portfolio?.place ? portfolio.place : "-"}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={12} sm={6} xl={6} xxl={6}>
                                                    <div className="p-0 border border-dashed rounded">
                                                        <div className="d-flex align-items-center">
                                                            <div className="avatar-sm me-2">
                                                                <div className="avatar-title rounded bg-transparent text-success fs-24">
                                                                    <i className="mdi mdi-format-list-bulleted"></i>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <p className="text-muted mb-1">Тип мероприятия :</p>
                                                                <h5 className="mb-0">{portfolio?.type}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} sm={6} xl={6} xxl={6}>
                                                    <div className="p-0 border border-dashed rounded">
                                                        <div className="d-flex align-items-center">
                                                            <div className="avatar-sm me-2">
                                                                <div className="avatar-title rounded bg-transparent text-success fs-24">
                                                                    <i className="ri-line-chart-line"></i>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <p className="text-muted mb-1">Уровень организации :</p>
                                                                <h5 className="mb-0">{portfolio?.level}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={12} sm={6} xl={6} xxl={6}>
                                                    <div className="p-0 border border-dashed rounded">
                                                        <div className="d-flex align-items-center">
                                                            <div className="avatar-sm me-2">
                                                                <div className="avatar-title rounded bg-transparent text-success fs-24">
                                                                    <i className="ri-medal-line"></i>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <p className="text-muted mb-1">Занял место :</p>
                                                                <h5 className="mb-0">{portfolio?.rang}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Row>
                                        <div className="mt-4 text-muted">
                                            <h5 className="fs-14">Описание :</h5>
                                            <p>
                                                {portfolio?.description}
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-3 mt-sm-3 mt-lg-3 mt-xl-3 mt-xxl-0">
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    {
                                        portfolio?.otherImage &&
                                        JSON.parse(portfolio?.otherImage).map((url: string) => (
                                            <Col lg={6} xl={4} xxl={3} className="mt-1" key={url}>
                                                <CardImg src={process.env.REACT_APP_API_BASE_URL + url}/>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ChildrenPortfolioMore;

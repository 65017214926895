import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import WidgetRequiredFlatpikerPeriod from "../../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import TableFromMyTask from "../../components/DocumentsFlow/FromMyTask/Table/TableFromMyTask";
import GraphFromMyTask from "../../components/DocumentsFlow/FromMyTask/graphs/GraphFromMyTask";

const FromMyTask = () => {
    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Задачи от меня</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col xxl={4}>
                        <WidgetRequiredFlatpikerPeriod/>
                        {/*<GraphFromMyTask/>*/}
                    </Col>
                    <Col>
                        <TableFromMyTask/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FromMyTask;

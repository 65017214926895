import React, {FC, useState} from 'react';
import DataTable from "react-data-table-component";
import AddLinen from "./AddLinen";

interface ITableLinen {
    linen: any,
    setLinen(value: any): void,
    selectClothesSetting: any
}

const TableLinen: FC <ITableLinen> = ({linen, setLinen, selectClothesSetting}) => {
    const [modalUpdateLinen, setModalUpdateLinen] = useState(false);
    const [selectLinen, setSelectLinen] = useState<any | null>(null);

    const columns : any = [
        {
            id: "name",
            name: <span className='font-weight-bold fs-13'>Наименование</span>,
            selector: (row: any) => row.name,
            wrap: true,
        },
        {
            id: "concentration",
            name: <span className='font-weight-bold fs-13'>Количество</span>,
            selector: (row: any) => row.units,
            wrap: true,
        },
        {
            name: <span className='font-weight-bold fs-13'></span>,
            selector: (row: any) => <i className="bx bx-trash fs-24 cursor-pointer" onClick={() => {removeLinen(row.uid)}}></i>,
            width: '60px'
        }
    ]

    const removeLinen = (uid: string) => {
        setLinen(linen.filter((item: any) => item.uid !== uid));
    }

    return (
        <>
            <DataTable
                columns={columns}
                data={linen}
                noDataComponent={'Нет данных'}
                pagination
                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                paginationComponentOptions={
                    {
                        rowsPerPageText: 'Видно льгот:',
                        rangeSeparatorText: 'из'
                    }
                }
                highlightOnHover
                customStyles={{
                    rows: {
                        highlightOnHoverStyle: {
                            backgroundColor: "#ffebb5"
                        },
                    },
                    expanderRow: {
                        style: {
                            backgroundColor: "#ffebb5",
                            paddingTop: '5px',
                            paddingBottom: '5px'
                        },
                    },
                }}
                onRowClicked={(row) => {
                    setSelectLinen(row);
                    setModalUpdateLinen(true);
                }}
            />
            <button className="btn btn-primary" type="button"
                    onClick={() => {
                        setSelectLinen(null)
                        setModalUpdateLinen(true)}}>Добавить
            </button>
            <AddLinen modalUpdateLinen={modalUpdateLinen} setModalUpdateLinen={(value) => setModalUpdateLinen(value)} linen={linen} setLinen={(value) => setLinen(value)} selectLinen={selectLinen} selectClothesSetting={selectClothesSetting}/>
        </>

    );
};

export default TableLinen;

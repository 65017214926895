import React, {useState} from 'react';
import DataTable from "react-data-table-component";
import TableShowOrganization from "./TableShowOrganization";
import {Card, CardBody} from "reactstrap";

const TableShowSubjectCheck = () => {
    const [data, setData] = useState([
        {
            org: "Организация 2",
            name: 'Планирование',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        },
        {
            org: "Организация 3",
            name: 'Организация и проведения',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        },
        {
            org: "Организация 4",
            name: 'Условия для самостоятельной и двигательной деятельности детей',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        },
        {
            org: "Организация 3",
            name: 'Организация и проведения подвижных игр',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        },
        {
            org: "Организация 4",
            name: 'В самостоятельной деятельности',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        },
        {
            org: "Организация 3",
            name: 'Организация спортивных соревнований и развлечений',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        },
        {
            org: "Организация 4",
            name: 'Картотечное сопровождение',
            all: '12',
            zav: '12',
            pros: '3',
            vip: '23',
            vipzad: '23',
        }
    ]);

    const columns: any[] = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Нарушение</span>,
            selector: (row: any) => row.name,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Всего</span>,
            selector: (row: any) => row.all,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Завершено</span>,
            selector: (row: any) => row.zav,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Просрочено</span>,
            selector: (row: any) => row.pros,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Выполнено</span>,
            selector: (row: any) => row.vip,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Вып. с задержкой</span>,
            selector: (row: any) => row.vipzad,
        }
    ]

    return (
        <Card className="my-1">
            <CardBody>
                <DataTable
                    columns={columns}
                    className="my-1"
                    expandableRows
                    noTableHead
                    expandOnRowClicked
                    expandableRowsComponent={
                        () => {
                            return <TableShowOrganization/>
                        }
                    }
                    data={data}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно нарушений:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        },
                        expanderRow:{
                            style:{
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableShowSubjectCheck;

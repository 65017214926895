import React, {useEffect} from 'react';
import {Card, CardHeader} from "reactstrap";
import {setPostSelect} from "../../../store/reducers/widgets/WidgetsSlice";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getAllInfoPost} from "../../../store/reducers/widgets/ActionCreator";

const WidgetRequiredSelectPost = () => {
    const dispatch = useAppDispatch()

    const {posts, postSelect, date} = useAppSelector(state => state.WidgetReducer);

    useEffect(() => {
        dispatch(getAllInfoPost(date)).then()
    }, [date, dispatch]);

    return (
        <div className="col">
            <Card>
                <CardHeader className="fs-18 shadow">
                    Должность:
                    <select className="form-select mt-1 shadow" value={postSelect}
                            onChange={(event) => dispatch(setPostSelect(event.target.value))}
                    >
                        {
                            posts.map((post) => {
                                return <option key={post.uid}
                                               value={post.uid}>{post.name}</option>
                            })
                        }
                    </select>
                </CardHeader>
            </Card>
        </div>
    );
};

export default WidgetRequiredSelectPost;

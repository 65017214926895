import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const TableMethodologicalWorkOnePart2 = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState([
        {
            org: "Организация 1",
            all: '3',
            profDefOO: '3',
            profDefOther: '3',
            searchPlanProfDef: '23',
            recommendedProfDef: '23',
            programIndividualProfEvolution: '23',
        },
        {
            org: "Организация 2",
            all: '3',
            profDefOO: '3',
            ProfDefOther: '3',
            searchPlanProfDef: '23',
            recommendedProfDef: '23',
            programIndividualProfEvolution: '23',
        },
        {
            org: "Организация 3",
            all: '3',
            profDefOO: '3',
            ProfDefOther: '3',
            searchPlanProfDef: '23',
            recommendedProfDef: '23',
            programIndividualProfEvolution: '23',
        },
        {
            org: "Организация 4",
            all: '3',
            profDefOO: '3',
            ProfDefOther: '3',
            searchPlanProfDef: '23',
            recommendedProfDef: '23',
            programIndividualProfEvolution: '23',
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns: any = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: typeof data[0]) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>План по выявлению проф. деф</span>,
            selector: (row: typeof data[0]) => row.searchPlanProfDef,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Адресных рекменд. по итогам диагн. проф. деф.</span>,
            selector: (row: typeof data[0]) => row.recommendedProfDef,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Программ индивид-го проф. развтития </span>,
            selector: (row: typeof data[0]) => row.programIndividualProfEvolution,
        },
    ]

    return (
        <Card>
            <CardHeader>
                Диагностика
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableMethodologicalWorkOnePart2;

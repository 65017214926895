import React from 'react';
import {Field, Form, Formik} from "formik";
import {Card, CardBody, CardHeader, Col, Label, Nav, NavItem, NavLink, Row} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {addChildrenMovementRegister} from "../../../store/reducers/department/ActionCreator";

const ChildrenMovementRegisterAdd = () => {

    const dispatch = useAppDispatch();
    const {children, groups} = useAppSelector(state => state.DepartmentReducer)
    return (
        <>
            <Formik
                initialValues={
                    {
                        data_begin: new Date(),
                        data_end: new Date(),
                        children_id: children[0].id,
                        group_id: groups[0].id
                    }
                }
                onSubmit={
                    (value, {resetForm}) => {
                        dispatch(addChildrenMovementRegister(value))
                        resetForm()
                    }
                }
            >
                <Form>
                    <Card className="mx-n3">
                        <CardHeader>
                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                 role="tablist">
                                <NavItem>
                                    <NavLink
                                        className="active">
                                        <i className="fas fa-home"></i>
                                        Данные о перемещении
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody className="p-4">
                            <Row>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="nameInput" className="form-label">Начальная
                                            дата</Label>
                                        <Field type="date" className="form-control" id="nameInput"
                                               name="data_begin"/>
                                    </div>
                                    <div className="mb-3">
                                        <Label htmlFor="nameInput" className="form-label">Конечная
                                            дата</Label>
                                        <Field type="date" className="form-control" id="nameInput"
                                               name="data_end"/>
                                    </div>
                                    <div className="mb-3">
                                        <Label htmlFor="typeCabinetInput"
                                               className="form-label">Ребенок</Label>
                                        <Field as="select" className="form-select" id="typeCabinetInput"
                                               name="children_id">
                                            {
                                                children.map(item => (
                                                    <option key={item.id}
                                                            value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Field>
                                    </div>
                                    <div className="mb-3">
                                        <Label htmlFor="organization_idInput"
                                               className="form-label">Группа</Label>
                                        <Field as="select" className="form-select"
                                               id="organization_idInput"
                                               name="group_id">
                                            {
                                                groups.map(item => (
                                                    <option key={item.id}
                                                            value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Field>
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col lg={12} className="mt-3">
                                    <div className="hstack gap-2 justify-content-end">
                                        <button type="submit"
                                                className="btn btn-primary">Добавить
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Form>
            </Formik>
        </>
    );
};

export default ChildrenMovementRegisterAdd;

import React, {FC, memo, useEffect} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getAllAttendance} from "../../../store/reducers/parent/ActionCreator";

interface IGraphAttendanceChildren {
    child: string
}

const GraphAttendanceChildren: FC<IGraphAttendanceChildren> = ({child}) => {

    const dispatch = useAppDispatch();
    const {allAttendancePaid, dateMonth} = useAppSelector(state => state.ParentReducer)



    const present: any = {
        name: "Всего посещал",
        type: 'bar',
        data: allAttendancePaid.length > 0 && allAttendancePaid.map(i => i.present)
    }
    const no_present: any = {
        name: "Всего отсутствовал",
        type: 'bar',
        data: allAttendancePaid.length > 0 && allAttendancePaid.map(i => i.no_present)
    }

    useEffect(() => {
        if (child)
            dispatch(getAllAttendance(dateMonth ,child, "True")).then()
    }, [dispatch, child, dateMonth]);

    const options: any = {
        chart: {
            type: 'bar',
            background: '#fff',
            toolbar: {
                show: false
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 0],
            width: [0, 0],
        },
        fill: {
            opacity: [1, 1]
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: false,
                }
            },
            yaxis: {
                lines: {
                    show: true,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 20,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '50%',
                barHeight: '70%'
            }
        },
        xaxis: {
            categories: allAttendancePaid.length > 0 && allAttendancePaid.map(i => i.month)
        }
    }

    return (
        <Col xxl={9}>
            <Card className="card-height-100">
                <CardHeader className="border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Всего посещал/Всего отсутствовал</h4>
                </CardHeader>
                <CardHeader className="p-0 border-0 bg-soft-light">
                    <Row className="g-0 text-center">
                        <Col xs={6} sm={6}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1"><span className="counter-value" data-target="9851">
                                    {present.data.length > 0 && present.data.reduce((a: number, b: number) => a + b)}
                                </span></h5>
                                <p className="text-muted mb-0">Всего посещал</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={6}>
                            <div className="p-3 border border-dashed border-start-0">
                                <h5 className="mb-1"><span className="counter-value">
                                    {no_present.data.length > 0 && no_present.data.reduce((a: number, b: number) => a + b)}
                                </span></h5>
                                <p className="text-muted mb-0">Всего отсутствовал</p>
                            </div>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className="p-0 pb-2">
                    <div>
                        {
                            (present.data.length > 0 || no_present.data.length > 0)
                                ?
                                <ReactApexChart
                                    options={options}
                                    series={[present, no_present]}
                                    type="line"
                                    height={370}
                                    width='98%'
                                />
                                :
                                <div className="d-flex justify-content-center align-items-center mb-4 my-5">
                                    <div>
                                        <p className="text-muted mb-0">Данных нет</p>
                                    </div>
                                </div>
                        }

                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default memo(GraphAttendanceChildren);

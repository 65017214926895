import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import * as Yup from "yup";
import {Col, Label, Row} from "reactstrap";
import {updateTypeCabinet} from "../../../store/reducers/department/ActionCreator";

const UpdateDataTypeCabinetForm = () => {
    const dispatch = useAppDispatch();
    const {selectTypeCabinet, organizations} = useAppSelector(state => state.DepartmentReducer)

    return (
        <Formik
            enableReinitialize
            initialValues={{
                typeCabinet: `${selectTypeCabinet?.name}`,
                organization_id: selectTypeCabinet?.organization_id
            }}
            validationSchema={Yup.object({
                typeCabinet: Yup.string().required('Обязательное поле'),
                organization_id: Yup.string().required('Обязательное поле')
            })}
            onSubmit={(value) => {
                dispatch(updateTypeCabinet(value, selectTypeCabinet!.id))
            }}
        >
            <Form>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="roleInput"
                                   className="form-label">Тип кабинета</Label>
                            <Field type="text" className="form-control" id="roleInput"
                                   name="typeCabinet"
                                   placeholder="Введите тип кабинета"/>
                            <ErrorMessage name="typeCabinet">{(msg) => <div
                                className="text-danger">{msg}</div>}</ErrorMessage>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="organization_idInput"
                                   className="form-label">Организация</Label>
                            <Field as="select" className="form-select"
                                   id="organization_idInput"
                                   name="organization_id"
                                   placeholder="Введите организацию">
                                {
                                    organizations.map(item => (
                                        <option key={item.id}
                                                value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Field>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} className="mt-3">
                        <div className="hstack gap-2 justify-content-end">
                            <button type="submit"
                                    className="btn btn-primary">Обновить
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Formik>
    );
};

export default UpdateDataTypeCabinetForm;

import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import WidgetRequiredFlatpikerPeriod from "../../components/widgets/requireds/WidgetRequiredFlatpikerPeriod";
import SelectOrganization from "../../components/department/SelectOrganization";
import GraphPedagogicalControlRatings
    from "../../components/department/PedagogicalControl/GraphPedagogicalControlRatings";
import GraphPedagogicalControlTask from "../../components/department/PedagogicalControl/GraphPedagogicalControlTask";
import TablePedagogicalControlRatings
    from "../../components/department/PedagogicalControl/controlRating/TablePedagogicalControlRatings";
import TablePedagogicalControlSubjectCheck
    from "../../components/department/PedagogicalControl/subjectCheck/TablePedagogicalControlSubjectCheck";
import TablePedagogicalControlRatingSubjectCheckOrganization
    from "../../components/department/PedagogicalControl/controlRatingSubjectCheckOrganization/TablePedagogicalControlRatingSubjectCheckOrganization";
import ControlRatingSubjectCheckOrganizationEmployee
    from "../../components/department/PedagogicalControl/controlRatingSubjectCheckOrganizationEmployee/ControlRatingSubjectCheckOrganizationEmployee";

const PedagogicalControl = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Педагогический контроль</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <WidgetRequiredFlatpikerPeriod/>
                                <SelectOrganization/>
                            </Row>
                            <Row>
                                <Col xxl={6} xl={6}>
                                    <GraphPedagogicalControlRatings/>
                                </Col>
                                <Col xxl={6} xl={6}>
                                    <GraphPedagogicalControlTask/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <TablePedagogicalControlRatings/>
                            <TablePedagogicalControlSubjectCheck/>
                            <TablePedagogicalControlRatingSubjectCheckOrganization/>
                            <ControlRatingSubjectCheckOrganizationEmployee/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default PedagogicalControl;

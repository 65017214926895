import React, {useEffect} from 'react';
import {Col, Container, Row} from "reactstrap";
import PortfolioEvents from "../../components/parent/PortfolioEvents";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {ParentSlice} from "../../store/reducers/parent/ParentSlice";
import {getChildren} from "../../store/reducers/parent/ActionCreator";
import {Helmet} from "react-helmet";

const ChildrenPortfolio = () => {
    const dispatch = useAppDispatch();
    const {children, child} = useAppSelector(state => state.ParentReducer)

    useEffect(() => {
        dispatch(getChildren()).then()
    }, [dispatch]);


    return (
        <div className="page-content">
            <Container fluid>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Портфолио</title>
                </Helmet>
                <Row>
                    <Col sm={5} lg={4} xl={3} xxl={2} className="mt-2 mt-sm-0 mt-lg-0 mt-xl-0 mt-xxl-0">
                        <select className="form-select" value={child} onChange={(e) => dispatch(ParentSlice.actions.setChild(e.target.value)) } >
                            {
                                children.map(child => <option key={child.account_uid} value={child.account_uid}>{child.account_name}</option>)
                            }
                        </select>
                    </Col>
                </Row>
                <Row>
                    <PortfolioEvents/>
                </Row>
            </Container>
        </div>
    );
};

export default ChildrenPortfolio;

import React, { FC, useEffect, useState } from 'react';
import AxiosClient from "../../../api/AxiosClient";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";

interface IAddDisinfectantGeneralCleaning {
    updateDisinfect: boolean;
    setUpdateDisinfect: (value: boolean) => void;
    addMyDisinfect: any[];
    setAddMyDisinfect: (value: any[]) => void;
    selectDisinfect: any;
}

const AddDisinfectantGeneralCleaning: FC<IAddDisinfectantGeneralCleaning> = ({
                                                                                 updateDisinfect,
                                                                                 setUpdateDisinfect,
                                                                                 addMyDisinfect,
                                                                                 setAddMyDisinfect,
                                                                                 selectDisinfect
                                                                             }) => {
    const [disinfectants, setDisinfectants] = useState<any[]>([]);

    useEffect(() => {
        AxiosClient.get('haccp/getDisinfectantsist')
            .then((r: any) => setDisinfectants(r.data))
            .catch((error) => console.log(error));
    }, []);

    return (
        <Modal
            id="createProjectModal"
            isOpen={updateDisinfect}
            toggle={() => setUpdateDisinfect(!updateDisinfect)}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
        >
            <ModalHeader
                toggle={() => setUpdateDisinfect(!updateDisinfect)}
                className="p-3 bg-soft-primary"
                id="createProjectModalLabel"
            >
                Обновление дезифицирующих средств
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        uid: selectDisinfect?.uid || '',
                        concentration: selectDisinfect?.concentration || ''
                    }}
                    validationSchema={
                        Yup.object({
                            uid: Yup.string().required('Требуется выбрать дезинфицирующее средство'),
                        concentration: Yup.string().required('Требуется указать концентрацию')
                    })}
                    onSubmit={(values) => {
                        const newObject = {
                            uid: values.uid,
                            disinfectant_name: disinfectants.find((value) => value.uid === values.uid).name,
                            concentration: values.concentration,
                            remove: selectDisinfect ? selectDisinfect.remove : "0"
                        };

                        setAddMyDisinfect(
                            Array.isArray(addMyDisinfect)
                                ? addMyDisinfect.some(item => item.uid === values.uid)
                                    ? addMyDisinfect.map(item =>
                                        item.uid === values.uid ? { ...item, ...newObject } : item
                                    )
                                    : [...addMyDisinfect, newObject]
                                : [newObject]
                        );

                        setUpdateDisinfect(false);
                    }}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div className="mb-3">
                                <label className="w-100">
                                    Название:
                                    <Field
                                        as="select"
                                        name="uid"
                                        className={`form-select ${errors.uid && touched.uid && "border border-2 border-danger"}`}
                                    >
                                        <option value="">Не выбрано</option>
                                        {disinfectants.map(el => (
                                            <option key={el.uid} value={el.uid}>{el.name}</option>
                                        ))}
                                    </Field>
                                </label>
                            </div>
                            <div className="mb-3">
                                <label className="w-100">
                                    Концентрация:
                                    <Field name="concentration" className="form-control" />
                                </label>
                            </div>
                            <div className="hstack gap-2 justify-content-end mt-1">
                                <SubmitWithLoading text={"Сохранить"}/>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default AddDisinfectantGeneralCleaning;

import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const TableAllInDoy = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState([
        {
            org: "Организация 1",
            one: '85',
            two: '28',
            three: '3',
            four: '2',
            five: "23",
        },
        {
            org: "Организация 2",
            one: '85',
            two: '28',
            three: '3',
            four: '2',
            five: "23",
        },
        {
            org: "Организация 3",
            one: '85',
            two: '28',
            three: '3',
            four: '2',
            five: "23",
        },
        {
            org: "Организация 4",
            one: '85',
            two: '28',
            three: '3',
            four: '2',
            five: "23",
        },
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);


    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13'>I</span>,
            selector: (row: any) => row.one,
        },
        {
            name: <span className='font-weight-bold fs-13'>II</span>,
            selector: (row: any) => row.two,
        },
        {
            name: <span className='font-weight-bold fs-13'>III</span>,
            selector: (row: any) => row.three,
        },
        {
            name: <span className='font-weight-bold fs-13'>IV</span>,
            selector: (row: any) => row.four,
        },
        {
            name: <span className='font-weight-bold fs-13'>V</span>,
            selector: (row: any) => row.five,
        }
    ]

    return (
        <Card>
            <CardHeader>
                Всего в ДОУ
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableAllInDoy;

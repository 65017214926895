import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const TableCourseTwoPart2 = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState([
        {
            org: "Организация 1",
            FIOnoQuality: 'Степанова А.А',
            DistanceLearn: ['23', '2'],
            pedEducationDistance: '23',
            pedEducationTarget: '1',
            PRRetrainingPed: '3',
            IROIPK: '2'
        },
        {
            org: "Организация 2",
            FIOnoQuality: 'Степанова А.А',
            DistanceLearn: ['23', '2'],
            pedEducationDistance: '23',
            pedEducationTarget: '1',
            PRRetrainingPed: '3',
            IROIPK: '2'
        },
        {
            org: "Организация 3",
            FIOnoQuality: 'Степанова А.А',
            DistanceLearn: ['23', '2'],
            pedEducationDistance: '23',
            pedEducationTarget: '1',
            PRRetrainingPed: '3',
            IROIPK: '2'
        },
        {
            org: "Организация 4",
            FIOnoQuality: 'Степанова А.А',
            DistanceLearn: ['23', '2'],
            pedEducationDistance: '23',
            pedEducationTarget: '1',
            PRRetrainingPed: '3',
            IROIPK: '2'
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Кол-во направленных на целевое об. от ДОУ</span>,
            selector: (row: any) => row.pedEducationTarget,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>ПР завершивших переподготовку пед. направленности</span>,
            selector: (row: any) => row.PRRetrainingPed,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>В ИРО/ИПК/Ак. Минпросвещения</span>,
            selector: (row: any) => row.IROIPK,
        }
    ]

    return (
        <Card>
            <CardHeader>
                Обучение на курсах
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableCourseTwoPart2;

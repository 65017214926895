import React, {useEffect, useState} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
} from "reactstrap";
import {ProjectStatusCharts} from "../ProjectStatusCharts";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import WidgetService from "../../../api/services/widgetService/WidgetService";
import {IDeviationGroupGraph} from "../../../models/widgets/IDeviationGroupGraph";
import {setToUpdateGraphMainPage} from "../../../store/reducers/widgets/WidgetsSlice";

const WidgetGraphRound = () => {
    const {groupAllDeviations, toUpdateGraphMainPage, date} = useAppSelector(state => state.WidgetReducer)
    const dispatch = useAppDispatch();
    const [data, setData] = useState<IDeviationGroupGraph[]>([]);
    const [dataGroup, setDataGroup] = useState<IDeviationGroupGraph[]>([]);

    useEffect(() => {
        toUpdateGraphMainPage &&
            WidgetService.getDeviationGroupGraph(null, date)
                .then(res => {
                    setData(res.data)
                    dispatch(setToUpdateGraphMainPage(false))
                })
    }, [toUpdateGraphMainPage, dispatch, date]);

    useEffect(() => {
        WidgetService.getDeviationGroupGraph(null,  date)
            .then(res => {
                setData(res.data)
            })
    }, [date]);


    useEffect(() => {
        if(groupAllDeviations !== "" && data.length > 0)
            setDataGroup(data.filter(item => item.group_id === groupAllDeviations))
    }, [groupAllDeviations, data])

    return (
        <>
            <Card>
                <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Отклонения</h4>
                </CardHeader>

                <CardBody>
                    {
                        dataGroup && <ProjectStatusCharts series={dataGroup}/>
                    }
                    {
                        dataGroup.length > 0 ?
                            <div className="mt-3">
                                <div className="d-flex justify-content-center align-items-center mb-4">
                                    <div>
                                        <p className="text-muted mb-0">Всего отклонений</p>
                                    </div>
                                    <h2 className="mx-3 ff-secondary mb-0">{dataGroup.map(dev => dev.count).reduce((a, b) => a + b)}</h2>

                                </div>

                                {
                                    (dataGroup).map((deviation, id) => {
                                        return (
                                            <div key={id}
                                                 className="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
                                                <p className="fw-medium mb-0">{deviation.deviation_name}</p>
                                                <div>
                                                        <span
                                                            className="text-muted pe-5">{deviation.count} отклонений</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            :
                            <div className="d-flex justify-content-center align-items-center mb-4">
                                <div>
                                    <p className="text-muted mb-0">Данных нет</p>
                                </div>
                            </div>
                    }
                </CardBody>
            </Card>
        </>
    );
};

export default WidgetGraphRound;

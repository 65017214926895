import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const TableMtbComputersPart2 = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState([
        {
            org: "Организация 1",
            fastEthernet: 'да',
            allPc: '32',
            groupPc: '3',
            appg: '23',
            pcWorkChildren: '12',
            percentIsset: '23',
            pcWithEthernet: '23',
            pcContentFilter: '3'
        },
        {
            org: "Организация 2",
            fastEthernet: 'да',
            allPc: '32',
            groupPc: '3',
            appg: '23',
            pcWorkChildren: '12',
            percentIsset: '23',
            pcWithEthernet: '23',
            pcContentFilter: '3'
        },
        {
            org: "Организация 3",
            fastEthernet: 'да',
            allPc: '32',
            groupPc: '3',
            appg: '23',
            pcWorkChildren: '12',
            percentIsset: '23',
            pcWithEthernet: '23',
            pcContentFilter: '3'
        },
        {
            org: "Организация 4",
            fastEthernet: 'да',
            allPc: '32',
            groupPc: '3',
            appg: '23',
            pcWorkChildren: '12',
            percentIsset: '23',
            pcWithEthernet: '23',
            pcContentFilter: '3'
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);

    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>ПК для детей</span>,
            selector: (row: any) => row.pcWorkChildren,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>% оснащенности групп ПК</span>,
            selector: (row: any) => row.percentIsset,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>ПК с выходом в интернет</span>,
            selector: (row: any) => row.pcWithEthernet,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Система контент-фильтра</span>,
            selector: (row: any) => row.pcContentFilter,
        },
    ]

    return (
        <Card>
            <CardHeader>
                ПК, в том числе и ноутбуки
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableMtbComputersPart2;

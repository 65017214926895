import React from 'react';
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "reactstrap";
import SelectOrganization from "../../components/department/SelectOrganization";
import RegulatoryLegalBase from "../../components/department/TalentedChildren/RegulatoryLegalBase";
import AccountingTalentedChildren from "../../components/department/TalentedChildren/AccountingTalentedChildren";
import FormSupportTalentedChildren from "../../components/department/TalentedChildren/FormSupportTalentedChildren";
import PhoneOrPc from "../../components/department/PhoneOrPC";
import FormSupportTalentedChildrenPart1
    from "../../components/department/TalentedChildren/TablePhone/FormSupportTalentedChildrenPart1";
import FormSupportTalentedChildrenPart2
    from "../../components/department/TalentedChildren/TablePhone/FormSupportTalentedChildrenPart2";
import MethodicalWork from "../../components/department/TalentedChildren/MethodicalWork";
import GraphFormSupportTalentedChildren
    from "../../components/department/TalentedChildren/GraphFormSupportTalentedChildren";

const TalentedChildren = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Одаренные дети</title>
            </Helmet>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <SelectOrganization/>
                            </Row>
                            <Row>

                            </Row>
                            <Row>
                                <Col>
                                    <GraphFormSupportTalentedChildren/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12}>
                            <RegulatoryLegalBase/>
                            <AccountingTalentedChildren/>
                            <PhoneOrPc phone={[<FormSupportTalentedChildrenPart1/>, <FormSupportTalentedChildrenPart2/>]} pc={[<FormSupportTalentedChildren/>]}/>
                            <MethodicalWork/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default TalentedChildren;

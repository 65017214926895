import React, {FC} from 'react';
import {EventContentArg} from "@fullcalendar/core";

interface IItemEventCalendar {
    eventInfo: EventContentArg
}
const ItemEventCalendar:FC<IItemEventCalendar> = ({eventInfo}) => {
    return (
        <div>
            <i className="text-black">{eventInfo.event.title}</i>
        </div>
    );
};

export default ItemEventCalendar;

import React, {useState} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const TableSsnpThreeSeven = () => {
    const [data, setData] = useState([
        {
            org: "Организация 1",
            name: 'Молоко, молочная и кисломолочная продукция',
            norm: 16,
            countProduct: 10,
            kkal: '580',
            protein: 34,
            fats: 56,
            carbohydrates: 79,
        },
        {
            org: "Организация 2",
            name: 'Творог',
            norm: 11,
            countProduct: 14,
            kkal: '580',
            protein: 34,
            fats: 56,
            carbohydrates: 79,
        },
        {
            org: "Организация 3",
            name: 'Сметана',
            norm: 15,
            countProduct: 12,
            kkal: '580',
            protein: 34,
            fats: 56,
            carbohydrates: 79,
        },
        {
            org: "Организация 4",
            name: 'Сыр',
            norm: 11,
            countProduct: 23,
            kkal: '580',
            protein: 34,
            fats: 56,
            carbohydrates: 79,
        }
    ]);

    const columns = [
        {
            id: 'name',
            name: <span className='font-weight-bold fs-13 d-flex'></span>,
            selector: (row: any) => row.name,
            wrap: true
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Норма</span>,
            selector: (row: any) => row.norm,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Кол-во факт</span>,
            selector: (row: any) => row.countProduct,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>ККал</span>,
            selector: (row: any) => row.kkal,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Белки</span>,
            selector: (row: any) => row.protein,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Жиры</span>,
            selector: (row: any) => row.fats,
        },
        {
            name: <span className='font-weight-bold fs-13 d-flex'>Углеводы</span>,
            selector: (row: any) => row.carbohydrates,
        },
    ]

    return (
        <Card>
            <CardHeader>
                Среднесуточный набор продуктов (1-3 лет)
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={data}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"name"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно продуктов:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default TableSsnpThreeSeven;

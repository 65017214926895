import React, {FC, useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import avatar1 from "../../../assets/images/users/user-dummy-img.jpg";
import {Link} from "react-router-dom";
import ImageToPDF from "./ImageToPDF";
import {useAppSelector} from "../../../hooks/redux";

interface ICardPortfolio{
    student: any
}
const CardPortfolio: FC<ICardPortfolio> = ({student}) => {
    const {children} = useAppSelector(state => state.ParentReducer)
    // const countPage = Number(student?.portfolio?.countTheme) * 1.0;

    const [countPage, setCountPage] = useState<number>(0);
    const [countFillPage, setCountFillPage] = useState<number>(0);
    const [percentFillPage, setPercentFillPage] = useState<number>(0);
    const [arrayPathPage, setArrayPathPage] = useState<any>([]);
    const [foundAccount, setFoundAccount] = useState<any>(null);

    useEffect(() => {
        if(student?.portfolio.hasOwnProperty('portfolio_pages')){
            const uniqueIds = new Set(student?.portfolio?.portfolio_pages.map((item: any) => item.type_page_portfolio_id));
            setCountFillPage(uniqueIds.size);
        }
        const account = children?.find(account => account?.account_uid === student?.portfolio.child_uid);
        setFoundAccount(account);
        setCountPage(student?.portfolio.countTheme ? Number(student?.portfolio?.countTheme) * 1.0 : 0);
    }, [student]);

    useEffect(()=> {
        if (countPage !== 0){
            setPercentFillPage(Math.round(countFillPage * 100 / countPage));
        }
    }, [countPage, countFillPage])

    useEffect(() => {
        if(student?.portfolio.hasOwnProperty('portfolio_pages')){
            const path_page = student?.portfolio.photo_page;
            const sortedPages : any = [...student?.portfolio.portfolio_pages].sort((a, b) => a.type_page_portfolio_id - b.type_page_portfolio_id);

            setArrayPathPage([])

            if (path_page){
                setArrayPathPage((prevState: any) => [...prevState,  path_page])
            }
            if (countPage !== 0) {
                sortedPages?.forEach((page: any) => {
                    if (page.photo_page) {
                        setArrayPathPage((prevState: any) => [...prevState, page.photo_page]);
                    }
                });
            }
        }
    }, [student])


    return (
        <div className="mt-4" >
            <Card className="team-box">
                <div className="team-cover">
                    {
                        student.gender === 'Мужской' ?
                             <img src={require('../../../assets/images/portfolio/1639669879_173-catherineasquithgallery-com-p-fon-zvezdochki-rozovie-372.png')} alt="" className="img-fluid" />
                            :
                            <img src={require('../../../assets/images/portfolio/grizly-club-p-legkii-fon-babochki-17.jpg')} alt="" className="img-fluid" />
                    }
                </div>
                <CardBody className="p-4">
                    <Row className="align-items-center team-row">
                        <Col lg={3} className="col">
                            <div className="team-profile-img text-center">
                                <div className="avatar-xxl img-thumbnail rounded-circle overflow-hidden d-flex justify-content-center align-items-center"
                                     style={{width: '200px', height: '200px'}}>
                                    {
                                        student.portfolio?.photo ?
                                            <img src={process.env.REACT_APP_API_BASE_URL + student.portfolio.photo}
                                                 alt=""
                                                 className="img-fluid rounded-circle"
                                                 style={{width: '100%', height: '100%', objectFit: 'cover'}} />
                                            :
                                            <img src={avatar1}
                                                 alt=""
                                                 className="img-fluid rounded-circle"
                                                 style={{width: '100%', height: '100%', objectFit: 'cover'}} />
                                    }
                                </div>
                                <div className="team-content">
                                    <h5 className="fs-18 mb-1">{student.fio}</h5>
                                </div>
                            </div>
                        </Col>

                        <Col lg={3} className="col">
                            <Row className="text-muted text-center">
                                <Col xs={6} className="border-end border-end-dashed">
                                    <h5 className="mb-1">{countPage}</h5>
                                    <p className="text-muted mb-0">Всего страниц</p>
                                </Col>
                                <Col xs={6}>
                                    <h5 className="mb-1">{countFillPage} ({percentFillPage}%)</h5>
                                    <p className="text-muted mb-0">Заполнено</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={2} className="col">
                            <div className="text-end">
                                <Link to={`/parent/portfolio/more/${student.portfolio?.id}`} className="btn btn-light view-btn">Открыть</Link>
                                {foundAccount ? <ImageToPDF pathPage={arrayPathPage} idPortfolio={student.portfolio?.id}></ImageToPDF> : <Button className="btn btn-light view-btn" disabled>Скачать PDF</Button>}

                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};

export default CardPortfolio;

import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getGroups} from "../../../store/reducers/widgets/ActionCreator";
import AxiosClient from "../../../api/AxiosClient";
import moment from "moment/moment";
import {Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {Field, Form, Formik} from "formik";
import classNames from "classnames";
import Flatpickr from "react-flatpickr";
import {Russian} from "flatpickr/dist/l10n/ru";
import {setGroupAllDeviations, setIsSend} from "../../../store/reducers/widgets/WidgetsSlice";
import DataTable from "react-data-table-component";
import SubmitWithLoading from "../../layouts/UI/SubmitWithLoading";
import downloadPDF from "../../../functions/downloadPDF";

interface IModalUpdateEvolutionChildren {
    open: boolean,
    setOpen: (value: boolean) => void,
    data: any,
    setData: (value: any) => void
    updateList:  () => void
}
const ModalUpdateEvolutionChildren: FC<IModalUpdateEvolutionChildren> = ({setOpen, open, data, setData, updateList}) => {
    const [date, setDate] = useState(new Date());
    const [selectEvolutionDirectionList, setSelectEvolutionDirectionList] = useState('');
    const [selectEvolutionDirectionOfChildList, setSelectEvolutionDirectionOfChildList] = useState('');
    const [selectListEvolutionProgram, setSelectListEvolutionProgram] = useState('');
    const [selectTypeTest, setSelectTypeTest] = useState('');
    const [selectPeriodTest, setSelectPeriodTest] = useState('');
    const [selectUser, setSelectUser] = useState('');
    const [selectChildEvolutionStudentsList, setSelectChildEvolutionStudentsList] = useState('');
    const dispatch = useAppDispatch()
    const {groupAllDeviations} = useAppSelector(state => state.WidgetReducer);
    const [activeTab, setActiveTab] = useState("1");
    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    useEffect(() => {
        dispatch(getGroups([date, date])).then()
    }, [date, dispatch]);

    useEffect(() => {
        if (data) {
            setSelectPeriodTest(data.period_uid)
            setSelectTypeTest(data.type_name)
            setSelectEvolutionDirectionList(data.direction_uid)
            setSelectListEvolutionProgram(data.program_uid)
            setSelectUser(data.inspector_uid)
            setDate(moment(data.data.split('.')[1] + '-' + data.data.split('.')[0] + '-' + data.data.split('.')[2]).toDate())
        }
    }, [data]);

    const columns: any = [
        {
            name: <span className='font-weight-bold fs-13'>ФИО ребенка</span>,
            selector: (row: any) => row.child_name,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold fs-13'>Оценка</span>,
            selector: (row: any, i: number) => (
                <select name="" id="" value={row.mark_name} className="form-select"
                        onChange={(event) => checkChild(event.target.value, row.child_uid)}>
                    {
                        data?.marks.map((elem: any, index: number) => <option key={index}
                                                                                                    value={elem.name}>{elem.name}</option>)
                    }
                </select>
            ),
            center: true,
            style: {padding: '0'}
        }
    ];

    const checkChild = (mark: string, child_uid: string) => {
        if (selectChildEvolutionStudentsList) {
            setData({
                ...data,
                indicators: data.indicators.map((indicator: any) => {
                    if (indicator.uid === selectChildEvolutionStudentsList) {
                        return {
                            ...indicator,
                            children: indicator.children.map((child: any) => {
                                if (child.child_uid === child_uid) {
                                    return {
                                        ...child,
                                        mark_name: mark
                                    }
                                } else return child
                            })
                        }
                    } else return indicator
                })
            })
        }
    }

    const checkAll = () => {
        if (selectEvolutionDirectionOfChildList && selectChildEvolutionStudentsList) {
            setData({
                ...data,
                indicators: data.indicators.map((indicator: any) => {
                    if (indicator.uid === selectChildEvolutionStudentsList) {
                        return {
                            ...indicator,
                            children: indicator.children.map((child: any) => ({
                                ...child,
                                mark_name: selectEvolutionDirectionOfChildList
                            }))
                        }
                    } else return indicator
                })
            })
        }
    }

    const printLevelEvolution = () => {
        dispatch(setIsSend(true))
        AxiosClient.get('/print_child_development_level', {params: {uid: data.uid}})
            .then((d) => {
                dispatch(setIsSend(false))
                downloadPDF(d.data.base64, d.data.filename)
            })
            .catch(() => {
                dispatch(setIsSend(false))
                console.log('error')
            })
    }
    const printAssessment = () => {
        dispatch(setIsSend(true))
        AxiosClient.get('/print_child_development_assessment', {params: {uid: data.uid}})
            .then((d) => {
                dispatch(setIsSend(false))
                downloadPDF(d.data.base64, d.data.filename)
            })
            .catch(() => {
                console.log('error')
                dispatch(setIsSend(false))
            })
    }

    return (
        <Modal id="createProjectModal" isOpen={open} toggle={() => setOpen(!open)}
               modalClassName="zoomIn" tabIndex={-1} centered>
            <ModalHeader toggle={() => setOpen(!open)} className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Редактирование Уровень развития ребенка
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        type_evolution: selectEvolutionDirectionList,
                        period_test: selectPeriodTest,
                        type_test: selectTypeTest,
                        controler: selectUser,
                        programm: selectListEvolutionProgram,
                        pokaz: selectChildEvolutionStudentsList
                    }}
                    onSubmit={() => {
                        if(selectUser && groupAllDeviations && selectListEvolutionProgram && selectPeriodTest && selectTypeTest && selectChildEvolutionStudentsList) {
                            dispatch(setIsSend(true))
                            const newData = {
                                uid: data.uid,
                                create_date: moment(date).format('DD-MM-YYYY'),
                                inspector_uid: data.inspector_uid,
                                group_uid: data.group_uid,
                                start_process: "0",
                                program_uid: data.program_uid,
                                period_uid: data.period_uid,
                                type_name: data.type_name,
                                children: [].concat(...data?.indicators.map((ind: any) => ind.children.map((el: any) => ({
                                    uid: el.child_uid,
                                    mark: el.mark_name,
                                    indicator_uid: ind.uid
                                }))))
                            }
                            AxiosClient.post('/save_evolution_child', {data: newData})
                                .then(() => {
                                    dispatch(setIsSend(false))
                                    setOpen(false)
                                    updateList()
                                })
                                .catch(() => {
                                    dispatch(setIsSend(false))
                                    console.log('error')
                                })
                        }
                    }}
                >
                    {
                        ({errors, touched}) => (
                            <Form>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                     role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "1", "p-2": true})}
                                            onClick={() => {
                                                tabChange("1");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Основное
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({active: activeTab === "2", "p-2": true})}
                                            onClick={() => {
                                                tabChange("2");
                                            }}
                                            type="button">
                                            <i className="fas fa-home"></i>
                                            Дети
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="mt-2">
                                    <TabPane tabId="1" className="align-items-center">
                                        <Row>
                                            <Col>
                                                <label htmlFor="">Дата</label>
                                                <Flatpickr
                                                    disabled
                                                    className="form-control border-0 dash-filter-picker shadow"
                                                    value={date}
                                                    options={{
                                                        locale: Russian, dateFormat: "d M Y"
                                                    }}
                                                    onChange={(dates) => setDate(dates[0])}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <label htmlFor="">Группа:</label>
                                                <select className="form-select shadow" value={groupAllDeviations}
                                                        onChange={(event) => dispatch(setGroupAllDeviations(event.target.value))}
                                                    disabled
                                                >
                                                    <option
                                                            value={data.group_uid}>{data.group_name}</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <label htmlFor="">Направление развития:</label>
                                                <Field as="select" name="type_evolution" className={`form-select shadow ${!selectEvolutionDirectionList && touched.type_evolution && 'border-danger'}`}
                                                       value={selectEvolutionDirectionList}
                                                       onChange={(event: any) => setSelectEvolutionDirectionList(event.target.value)} disabled
                                                >
                                                    <option value={data.direction_uid}>{data.direction}</option>
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <label htmlFor="">Период тестирования:</label>
                                                <Field as="select" name="period_test" className={`form-select shadow ${!selectPeriodTest && touched.period_test && 'border-danger'}`} value={selectPeriodTest}
                                                       onChange={(event: any) => setSelectPeriodTest(event.target.value)} disabled
                                                >
                                                    <option value={data.period_uid}>{data.period}</option>
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <label htmlFor="">Вид тестирования:</label>
                                                <Field as="select" name="type_test"  className={`form-select shadow ${!selectTypeTest && touched.type_test && 'border-danger'}`} value={selectTypeTest}
                                                       onChange={(event: any) => setSelectTypeTest(event.target.value)} disabled
                                                >
                                                    <option value={data.type_name}>{data.type_name}</option>
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <label htmlFor="">Контролер:</label>
                                                <Field as="select" name="controler" className={`form-select shadow ${!selectUser && touched.controler && 'border-danger'}`} value={selectUser}
                                                       onChange={(event: any) => setSelectUser(event.target.value)} disabled>
                                                    <option value={data.inspector_uid}>{data.inspector_name}</option>
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <label htmlFor="">Программа образования:</label>
                                                <Field as="select" name="programm" className={`form-select shadow ${!selectListEvolutionProgram && touched.programm && 'border-danger'}`}
                                                       value={selectListEvolutionProgram}
                                                       onChange={(event: any) => setSelectListEvolutionProgram(event.target.value)} disabled>
                                                    <option value={data.program_uid}>{data.program}</option>
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <SubmitWithLoading text={"Оценка развития"} className="btn w-100 btn-primary" onClick={() => printAssessment()}/>
                                                {/*<button type="button" className="btn w-100 btn-primary" onClick={() => printAssessment()}>Оценка развития</button>*/}
                                            </Col>
                                            <Col>
                                                <SubmitWithLoading text={"Уровень развития"} className="btn w-100 btn-primary" onClick={() => printLevelEvolution()}/>
                                                {/*<button type="button" className="btn w-100 btn-primary" onClick={() => printLevelEvolution()}>Уровень развития</button>*/}
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2" className="align-items-center">
                                        <Row className="mt-2">
                                            <Col>
                                                <label htmlFor="">Показатель:</label>
                                                <Field as="select" name="pokaz" className={`form-select shadow ${!selectChildEvolutionStudentsList && touched.pokaz && 'border-danger'}`}
                                                       value={selectChildEvolutionStudentsList}
                                                       onChange={(event: any) => setSelectChildEvolutionStudentsList(event.target.value)}>
                                                    <option value="">Не выбрано</option>
                                                    {
                                                        data?.indicators.map((elem: any, index: number) =>
                                                            <option key={index}
                                                                    value={elem.uid}>{elem.name}</option>)
                                                    }
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <label htmlFor="">Значение по умолчанию:</label>
                                                <Row>
                                                    <Col>
                                                        <select className="form-select shadow"
                                                                value={selectEvolutionDirectionOfChildList}
                                                                onChange={(event) => setSelectEvolutionDirectionOfChildList(event.target.value)}>
                                                            <option value="">Не выбрано</option>
                                                            {
                                                                data?.marks.map((elem: any, index: number) =>
                                                                    <option key={index}
                                                                            value={elem.name}>{elem.name}</option>)
                                                            }
                                                        </select>
                                                    </Col>
                                                    <Col xxl={3} xl={3}>
                                                        <button type="button" className="btn btn-primary w-100 mt-xl-0 mt-2"
                                                                onClick={() => checkAll()}>Для всех
                                                        </button>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col>
                                                        <DataTable
                                                            columns={columns}
                                                            data={data?.indicators.find((el: any) => el.uid === selectChildEvolutionStudentsList)?.children}
                                                            className="custom-scroll-news"
                                                            fixedHeader={true}
                                                            fixedHeaderScrollHeight={'400px'}
                                                            noDataComponent={'В данной группе нет детей'}
                                                            highlightOnHover
                                                            customStyles={{
                                                                rows: {
                                                                    highlightOnHoverStyle: {
                                                                        backgroundColor: "#ffebb5"
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <SubmitWithLoading text={"Сохранить"}/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </Form>
                        )
                    }
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalUpdateEvolutionChildren;

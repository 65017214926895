import React, {FC, useEffect, useState} from 'react';
import {Button, Nav, NavItem, TabContent, TabPane, UncontrolledTooltip} from "reactstrap";
import search from "../../../functions/search";
import {Link, NavLink} from "react-router-dom";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import {useAppDispatch} from "../../../hooks/redux";
import {setSelectRecommendedActive} from "../../../store/reducers/department/ActionCreator";
import {IRecommendedActive} from "../../../models/IRecommendedActive";

interface ISelectToUpdateRecommendedActive {
    setAddRecommendedActive: (val: boolean) => void
    recommendedActive: IRecommendedActive[]
}
const SelectToUpdateRecommendedActive: FC<ISelectToUpdateRecommendedActive> = ({setAddRecommendedActive, recommendedActive}) => {

    const dispatch = useAppDispatch();
    const [currentRoomId, setCurrentRoomId] = useState<number>(0);

    const [customActiveTab, setCustomActiveTab] = useState<string>("1");
    const toggleCustom = (tab: string) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    const recommendedActiveUpdate = (recommendedActive: IRecommendedActive) => {
        setCurrentRoomId(recommendedActive.id)
        dispatch(setSelectRecommendedActive(recommendedActive))
    }

    useEffect(() => {
        setCurrentRoomId(recommendedActive[0]?.id)
        dispatch(setSelectRecommendedActive(recommendedActive[0]))
    }, [recommendedActive, dispatch])

    return (
        <div className="chat-leftsidebar  ">
            <div className="px-4 pt-4 mb-4">
                <div className="d-flex align-items-start">
                    <div className="flex-grow-1">
                        <h5 className="mb-4">Настройки</h5>
                    </div>
                    <div className="flex-shrink-0">
                        <UncontrolledTooltip placement="bottom" target="addcontact">
                            Добавить рекомендованную активность
                        </UncontrolledTooltip>
                        <Button
                            color=""
                            id="addcontact"
                            className="btn btn-soft-primary btn-sm"
                            onClick={() => setAddRecommendedActive(true)}
                        >
                            <i className="ri-add-line align-bottom"></i>
                        </Button>
                    </div>
                </div>
                <div className="search-box">
                    <input
                        onKeyUp={() => search()}
                        id="search-user"
                        type="text"
                        className="form-control bg-light border-light"
                        placeholder="Поиск рекомендованной активности"
                    />
                    <i className="ri-search-2-line search-icon"></i>
                </div>
            </div>
            <Nav tabs className="nav nav-tabs nav-tabs-custom nav-primary nav-justified mb-3">
                <NavItem>
                    <NavLink
                        to="#"
                        style={{ cursor: "pointer" }}
                        className={classNames({
                            active: customActiveTab === "1",
                        })}
                        onClick={() => {
                            toggleCustom("1");
                        }}
                    >
                        Рекомендованные активности
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent
                activeTab={customActiveTab}
                className="text-muted"
            >
                <TabPane tabId="1" id="chats">
                    <SimpleBar
                        className="chat-room-list pt-3"
                        style={{ margin: "-16px 0px 0px" }}
                    >
                        <div className="d-flex align-items-center px-4 mb-2">
                            <div className="flex-grow-1">
                                <h4 className="mb-0 fs-11 text-muted text-uppercase">
                                    Выберите для изменения данных
                                </h4>
                            </div>
                        </div>
                        <div className="chat-message-list">
                            <ul
                                className="list-unstyled chat-list chat-user-list users-list"
                                id="userList"
                            >
                                {(recommendedActive || []).map((recommendedActive) => (
                                    <li
                                        key={recommendedActive.id}
                                        className={
                                            currentRoomId === recommendedActive.id ? "active" : ""
                                        }
                                    >
                                        <Link to="#"
                                              onClick={() => {
                                                  recommendedActiveUpdate(recommendedActive)
                                                  setAddRecommendedActive(false);
                                              }}
                                        >
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                                    <div className="avatar-xxs">
                                                        <div className="avatar-title rounded-circle bg-primary  userprofile">
                                                            {recommendedActive.name.charAt(0)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <p className="text-truncate mb-0">{recommendedActive.name}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </SimpleBar>
                </TabPane>
            </TabContent>
        </div>
    );
};

export default SelectToUpdateRecommendedActive;

import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../hooks/redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import DataTable from "react-data-table-component";

const ChildrenTypeDisabledPersonsPart3 = () => {
    const {selectOrganizations} = useAppSelector(state => state.DepartmentReducer)

    const [data, setData] = useState([
        {
            org: "Организация 1",
            tnr: '2',
            zpr: '4',
            ras: '1',
        },
        {
            org: "Организация 2",
            tnr: '2',
            zpr: '4',
            ras: '1',
        },
        {
            org: "Организация 3",
            tnr: '2',
            zpr: '4',
            ras: '1',
        },
        {
            org: "Организация 4",
            tnr: '2',
            zpr: '4',
            ras: '1',
        }
    ]);

    const [outData, setOutData] = useState<any[]>([]);

    useEffect(() => {
        let selOrg = selectOrganizations.map(s => s.label)
        setOutData(data.filter(d => selOrg.includes(d?.org)))
    }, [selectOrganizations]);


    const columns = [
        {
            id: "org",
            name: <span className='font-weight-bold fs-13'>Организация</span>,
            selector: (row: any) => row.org,
            sortable: true,
            wrap: true,
            compact: true
        },
        {
            name: <span className='font-weight-bold fs-13'>ТНР</span>,
            selector: (row: any) => row.tnr,
        },
        {
            name: <span className='font-weight-bold fs-13'>ЗПР</span>,
            selector: (row: any) => row.zpr,
        },
        {
            name: <span className='font-weight-bold fs-13'>РАС</span>,
            selector: (row: any) => row.ras,
        },
    ]

    return (
        <Card>
            <CardHeader>
                Дети - инвалиды
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={outData}
                    noDataComponent={'Нет данных'}
                    defaultSortFieldId={"org"}
                    defaultSortAsc
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    paginationComponentOptions={
                        {
                            rowsPerPageText: 'Видно организаций:',
                            rangeSeparatorText: 'из'
                        }
                    }
                    highlightOnHover
                    customStyles={{
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "#ffebb5"
                            }
                        }
                    }}
                />
            </CardBody>
        </Card>
    );
};

export default ChildrenTypeDisabledPersonsPart3;

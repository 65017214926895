import React, {FC} from 'react';
import {Card, CardBody, CardImg, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";

interface IOtherImage {
    url: string
}
const AddOtherImage:FC<IOtherImage> = ({url}) => {
    return (
        <Card>
            <CardBody>
                <UncontrolledDropdown direction='start' className="col text-end settings-image">
                    <DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
                        <i className="ri-more-fill fs-21 text-light"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem className="dropdown-item edit-list" href="#addmemberModal" >
                            <i className="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>Удалить
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <CardImg src={url}/>
            </CardBody>
        </Card>
    );
};

export default AddOtherImage;

import React, {FC, useEffect} from 'react';
import DataTable from "react-data-table-component";
import {ICashAccountingTotal} from "../../../models/parent/ICashAccountingTotal";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getCashAccountingTotal} from "../../../store/reducers/parent/ActionCreator";

interface ITableCashAccountingBalance {
    date: Date[]
}

const TableCashAccountingTotal: FC<ITableCashAccountingBalance> = ({date}) => {

    const dispatch = useAppDispatch();
    const {cashAccountingTotal, child} = useAppSelector(state => state.ParentReducer)

    useEffect(() => {
        dispatch(getCashAccountingTotal(date, child)).then()
    }, [date, child, dispatch]);


    const columnsEvolutions: any = [
        {
            selector: (row: ICashAccountingTotal) => <span className="fs-13">{row.name}</span>,
            wrap: true,
            width: '50%'
        },
        {
            selector: (row: ICashAccountingTotal) => {
                if (!row.count){
                    return <span className="fs-13">&mdash;</span>
                }
                return <span className="fs-13">{row.count}</span>
            },
            minWidth: '50px',
            center: true
        }
    ];


    return (
        <DataTable
            columns={columnsEvolutions}
            data={cashAccountingTotal.slice(1)}
            noDataComponent={'Нет данных'}
            noTableHead
            highlightOnHover
            customStyles={{
                rows: {
                    highlightOnHoverStyle: {
                        backgroundColor: "#ffebb5"
                    }
                }
            }}
        />
    );
};

export default TableCashAccountingTotal;

import React, {FC} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import TableSSNP from "./TableSSNP";

interface IModalKKalAll {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void
}
const ModalKKalAll: FC<IModalKKalAll> = ({isOpen, setIsOpen}) => {
    return (
        <Modal id="createProjectModal" isOpen={isOpen}
               toggle={() => setIsOpen(!isOpen)}
               modalClassName="zoomIn" tabIndex={-1} centered size={"xl"}>
            <ModalHeader toggle={() => setIsOpen(!isOpen)}
                         className="p-3 bg-soft-primary"
                         id="createProjectModalLabel">
                Подробнее
            </ModalHeader>
            <ModalBody>
                <TableSSNP/>
            </ModalBody>
        </Modal>
    );
};

export default ModalKKalAll;

import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Card, CardBody, CardHeader, Col, Label, Nav, NavItem, NavLink, Row} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {addChildren} from "../../../store/reducers/department/ActionCreator";

const ChildrenAdd = () => {

    const dispatch = useAppDispatch();
    const {
        groups
    } = useAppSelector(state => state.DepartmentReducer)

    return (
        <>
            <Formik
                initialValues={
                    {
                        name: '',
                        group_id: groups[0]?.id,
                        birthday: '',
                        repead_education: 'false',
                        home_education: 'false'
                    }
                }
                validationSchema={
                    Yup.object(
                        {
                            name: Yup.string().required('Введите название кабинета'),
                            group_id: Yup.string().required('Обязательное поле'),
                            birthday: Yup.string().required('Обязательное поле'),
                            repead_education: Yup.string().required('Обязательное поле'),
                            home_education: Yup.string().required('Обязательное поле')
                        }
                    )
                }
                onSubmit={
                    (value, {resetForm}) => {
                        dispatch(addChildren(value))
                        resetForm()
                    }
                }
            >
                <Form>
                    <Card className="mx-n3">
                        <CardHeader>
                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                 role="tablist">
                                <NavItem>
                                    <NavLink
                                        className="active">
                                        <i className="fas fa-home"></i>
                                        Данные о ребенке
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody className="p-4">
                            <Row>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="nameInput" className="form-label">ФИО
                                            ребенка</Label>
                                        <Field type="text" className="form-control" id="nameInput"
                                               name="name"
                                               placeholder="Введите ФИО ребенка"/>
                                        <ErrorMessage name="name">{(msg) => <div
                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                    </div>
                                    <div className="mb-3">
                                        <Label htmlFor="group_idInput"
                                               className="form-label">Группа</Label>
                                        <Field as="select" className="form-select" id="group_idInput"
                                               name="group_id"
                                               placeholder="Выберите группу">
                                            {
                                                groups.map(item => (
                                                    <option key={item.id}
                                                            value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Field>
                                    </div>
                                    <div className="mb-3">
                                        <Label htmlFor="birthdayInput" className="form-label">Дата
                                            рождения</Label>
                                        <Field type="date" className="form-control" id="birthdayInput"
                                               name="birthday"
                                               placeholder="Введите дату рождения"/>
                                        <ErrorMessage name="birthday">{(msg) => <div
                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                    </div>
                                    <div className="mb-3">
                                        <Label htmlFor="repead_educationInput" className="form-label">Повторное
                                            обучение</Label>
                                        <Field as='select' className="form-select"
                                               id="repead_educationInput"
                                               name="repead_education">
                                            <option value='true'>Повторное обучение</option>
                                            <option value='false'>Не повторное обучение</option>
                                        </Field>
                                    </div>
                                    <div className="mb-3">
                                        <Label htmlFor="home_educationInput" className="form-label">Домашнее
                                            обучение</Label>
                                        <Field as='select' className="form-select"
                                               id="home_educationInput"
                                               name="home_education">
                                            <option value='true'>Домашнее обучение</option>
                                            <option value='false'>Не домашнее обучение</option>
                                        </Field>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} className="mt-3">
                                    <div className="hstack gap-2 justify-content-end">
                                        <button type="submit"
                                                className="btn btn-primary">Добавить
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Form>
            </Formik>
        </>
    );
};

export default ChildrenAdd;

import React, {FC} from 'react';
import {Field} from "formik";
import {Card, CardBody, Input} from "reactstrap";
import DataTable from "react-data-table-component";

interface ITableGroupParty {
    groups: any[]
}

const TableGroupParty: FC<ITableGroupParty> = ({groups}) => {


    const columns: any = [
        {
            name: <Input type="checkbox" className="border-primary m-0" style={{width: '20px', height: '20px'}} />,
            selector: (row: any, i: number) => (
                <Field type="checkbox" className="border-primary m-0" style={{width: '20px', height: '20px'}} name={`checkbox[${i}].${row.group_code}`}/>
            ),
            style: {
                fontSize: '14px'
            },
            width: '20%'
        },
        {
            id: "name",
            name: <span className='font-weight-bold fs-13'>Группы</span>,
            selector: (row: any) => row.group_name,
            sortable: true
        }
    ]

    return (
        <Card>
            <CardBody>
                <DataTable columns={columns}
                           data={groups ? [...groups].sort((a, b) => a.group_name.localeCompare(b.group_name)) : []}
                           noDataComponent={'Нет детей'}
                           fixedHeaderScrollHeight={"32rem"}
                           fixedHeader={true}/>

            </CardBody>
        </Card>
    );
};

export default TableGroupParty;
